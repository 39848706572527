const actionTypes = {
  GET_RFP_BY_USER_ID_START: "GET_RFP_BY_USER_ID_START",
  GET_RFP_BY_USER_ID_SUCCESS: "GET_RFP_BY_USER_ID_SUCCESS",
  GET_RFP_BY_USER_ID_FAILED: "GET_RFP_BY_USER_ID_FAILED",

  //Submit Technical Requirements

  GET_RFP_BY_ID_SUBMIT_TECHNICAL_SUCCESS: "GET_RFP_BY_ID_SUBMIT_TECHNICAL_SUCCESS",
  GET_RFP_BY_ID_SUBMIT_TECHNICAL_START: "GET_RFP_BY_ID_SUBMIT_TECHNICAL_START",
  GET_RFP_BY_ID_SUBMIT_TECHNICAL_FAILED: "GET_RFP_BY_ID_SUBMIT_TECHNICAL_FAILED",
  GET_RFP_BY_ID_SUBMIT_TECHNICAL_UPDATE: "GET_RFP_BY_ID_SUBMIT_TECHNICAL_UPDATE",

  //Submit Update Stepper
  SUBMIT_RFP_UPDATE_STEPPER: "SUBMIT_RFP_UPDATE_STEPPER",

  //Submit Commercial
  GET_RFP_BY_ID_SUBMIT_COMMERCIAL_START: "GET_RFP_BY_ID_SUBMIT_COMMERCIAL_START",
  GET_RFP_BY_ID_SUBMIT_COMMERCIAL_SUCCESS: "GET_RFP_BY_ID_SUBMIT_COMMERCIAL_SUCCESS",
  GET_RFP_BY_ID_SUBMIT_COMMERCIAL_FAILED: "GET_RFP_BY_ID_SUBMIT_COMMERCIAL_FAILED",
  GET_RFP_BY_ID_SUBMIT_COMMERCIAL_UPDATE: "GET_RFP_BY_ID_SUBMIT_COMMERCIAL_UPDATE",
}

export default actionTypes;