/* eslint-disable */
import { Subject } from "rxjs";

const isLoading = new Subject();
const loaderCount = new Subject();
let loadingCount = 0;

export const loaderService = {
  onShowHide,
  show,
  onLoaderCount,
  getLoaderCount
};
// enable subscribing to show loader
function onShowHide() {
  return isLoading.asObservable();
}

function onLoaderCount() {
  return loaderCount.asObservable();
}

function getLoaderCount() {
  return loadingCount;
}

// core alert method
function show(isShow) {
  isLoading.next(isShow);
  if (isShow) {
    loaderCount.next(++loadingCount);
  } else {
    if (loadingCount > 0) loaderCount.next(--loadingCount);
  }
  console.log("loader Count --->", loadingCount);
}
