/* eslint-disable */
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import parse from "autosuggest-highlight/parse";
import { FieldArray, Formik } from "formik";
import throttle from "lodash/throttle";
import ChipInput from "material-ui-chip-input";
import PropTypes from "prop-types";
import React, { useState } from "react";
import NumberFormatCustom from "src/components/formatter/formatter";
import RichText from "src/components/RichText/RichText";
import CompanyService from "src/services/company/company.service";
import "src/views/shared/css/common.scss";
import { companyTypes, currencies } from "src/views/shared/data/dropdowns.js";
import Thumb from "../../components/Thumb/Thumb.component";
import { customCompanyValidations } from "../../utils/utils";
import ErrorComponent from "./../../components/ErrorComponent";
import { CompanyModel } from "./CompanyModel";
import { useStyles } from "./CompanyStyle";
import { CompanyValidations } from "./CompanyValidations";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const CompanyDetails = ({
  handleCompanySubmit,
  handleNext,
  handleSave,
  handleSkip,
  handleBack,
  completed,
  totalStepCount,
  currentStepCompleteStatus = true,
  isSubmitView,
  className,
  companyState,
  setCompanyState,
  step,
  deleteProductModal,
  ...rest
}) => {
  var buttonStyle = {
    backgroundColor: "#000"
  };

  const classes = useStyles();

  const defaultDate = "2010";
  const companyService = new CompanyService();
  const [hasError, setHasError] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  var type = "";
  const [error_values, setErrorValues] = React.useState(null);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAlHpWIMqHXMAji1Kjp2c7AkjGViBwhLFg&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  React.useEffect(() => {
    console.log("comapyState ===>", companyState);
    if (error_values !== null) {
      customCompanyValidations(companyState).then(validation_response => {
        setErrorValues(validation_response);
      });
    }
  }, [companyState]);
  const updateState = event => {
    setCompanyState({
      ...companyState,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeKeywords = keywords => {
    companyState.keywords = keywords;
    setCompanyState(new CompanyModel(companyState));
  };

  const handleChangeFiscalYearMonth = date => {
    companyState.fiscalYearMonth = date;
    setCompanyState(new CompanyModel(companyState));
  };

  const handleOverviewChange = richText => {
    companyState.overview = richText;
  };

  const removeLocations = index => {
    companyState.locations.pop(index);
    setCompanyState(new CompanyModel(companyState));
  };

  const addLocations = () => {
    companyState.locations.push({ officeLocation: "", officeFunction: "" });
    setCompanyState(new CompanyModel(companyState));
  };

  const handleOfficeBlur = (event, index, type) => {
    // type: 1 - Location , 2 - Function
    if (type === 1) {
      companyState.locations[index].officeLocation = event.target.value;
    } else {
      companyState.locations[index].officeFunction = event.target.value;
    }
    setCompanyState(new CompanyModel(companyState));
  };

  const setFieldValue = value => {
    console.log("value 00000----<", value);
    if (value !== undefined) {
      companyState.logo = value;
      setCompanyState(new CompanyModel(companyState));
    }
  };

  const handleOfficeChange = (officeFunction, index, type) => {
    // type: 1 - Location , 2 - Function
    const { locations } = companyState;
    const newLocations = locations.slice(0);
    if (type === 1) {
      newLocations[index].officeLocation = officeFunction;
    } else {
      newLocations[index].officeFunction = officeFunction;
    }
    companyState.locations = newLocations;
    setCompanyState(new CompanyModel(companyState));
  };
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const getCompanyLogo = () => {
    return companyState.logo;
  };

  // console.log(isSubmitView, "isSubmitView");
  console.log("ishere");
  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={companyState}
        validationSchema={CompanyValidations}
        validator={() => ({})}
        onSubmit={async values => {
          values.overview = companyState.overview;
          // console.log(values.overview, "values...");

          if (values.totalRevenue === "") {
            values.totalRevenue = 0;
          }
          if (values.currency === "") {
            values.currency = "USD";
          }
          if (values.companyType === "") {
            values.companyType = "Company Type";
          }
          const autoClose = true;
          const keepAfterRouteChange = false;
          var validation_response = await customCompanyValidations(values);
          setErrorValues(validation_response);
          console.log(validation_response);
          // alert('hi');
          if (Object.keys(validation_response).length === 0) {
            companyState.step = step;
            companyState.type = type;
            //alert('hi');
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            handleCompanySubmit(companyState);
          } else {
            //window.scrollTo(0, 0)
            //alertService.error(validation_response.join(''), { autoClose, keepAfterRouteChange })
          }
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          handleCompanySubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <div>
            {isSubmitView ? (
              <div
                /*  className={classes.pageSize} */ className="requestly-rfp-view company-information"
              >
                <Card className={classes.CardBorder}>
                  <CardContent className={classes.cardScrollArea + " p-0"}>
                    <Card>
                      <CardHeader
                        title="Company Information"
                        className={
                          classes.companyHeadings + " requestly-rfp-heading"
                        }
                      />
                    </Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={9} xs={12}>
                          <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                              <Typography className="label-title">
                                {" "}
                                Company Name{" "}
                              </Typography>
                              <Typography
                                style={{ marginTop: "0px", fontSize: "14px" }}
                              >
                                {values.companyName}
                              </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography className="label-title">
                                {" "}
                                Company Overview{" "}
                              </Typography>
                              <RichText
                                showContentOnly={isSubmitView}
                                value={companyState.overview}
                                onTextChange={handleOverviewChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Divider variant="fullWidth" />
                        <Grid
                          item
                          md={3}
                          xs={4}
                          className={
                            classes.profileImg + "custome-css thumb-align-right"
                          }
                        >
                          <Thumb
                            className={
                              classes.profilePics +
                              "img-thumbnail size-su-change"
                            }
                            file={values.logo}
                            stopChange={isSubmitView}
                          />
                          <input
                            style={{ display: "none" }}
                            id="logo"
                            name="logo"
                            type="file"
                            className="form-control"
                            onChange={event => {
                              setFieldValue(event.currentTarget.files[0]);
                            }}
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <Tooltip
                            title="Enter a keyword or phrase, press enter after each one"
                            arrow
                          >
                            <ChipInput
                              error={Boolean(
                                touched.keywords && errors.keywords
                              )}
                              fullWidth
                              disabled={isSubmitView}
                              helperText={touched.keywords && errors.keywords}
                              label="Keywords"
                              defaultValue={companyState.keywords}
                              onChange={chip => handleChangeKeywords(chip)}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item md={9} xs={8}>
                          <Typography className="label-title">
                            Company Solution Video URL
                          </Typography>
                          <Typography
                            style={{ marginTop: "5px", fontSize: "14px" }}
                          >
                            <Link href="#">{values.videoUrl}</Link>
                          </Typography>
                        </Grid>
                        <Grid item md={9} xs={8}>
                          <Typography className="label-title">
                            Company Website
                          </Typography>
                          <Typography
                            style={{ marginTop: "5px", fontSize: "14px" }}
                          >
                            {values.website}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Accordion
                      defaultExpanded={true}
                      className="requestly-fin-info"
                    >
                      <AccordionSummary
                        className="requestly-tab-height"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-financial-content"
                        id="panel-financial"
                      >
                        <Typography className={classes.heading}>
                          Financial Information
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography component="span" variant="body2">
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <Typography className="label-title">
                                  Company Type
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {values.companyType}
                                </Typography>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Typography className="label-title">
                                  No Of Employees
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {values.headCount}
                                </Typography>
                              </Grid>
                              <Grid item md={8} xs={8}>
                                <Typography className="label-title">
                                  Annual Revenue
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {values.totalRevenue}
                                </Typography>
                              </Grid>
                              <Grid item md={4} xs={4}>
                                <Typography className="label-title">
                                  Currency
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {values.currency}
                                </Typography>
                              </Grid>
                              <Grid item md={8} xs={8}>
                                <Typography className="label-title">
                                  Fiscal Year End
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {/*  {values.fiscalYearMonth} */}
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      defaultExpanded={true}
                      className="requestly-fin-info"
                    >
                      <AccordionSummary
                        className="requestly-tab-height"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-financial-content"
                        id="panel-financial"
                      >
                        <Typography className={classes.heading}>
                          Company Office Locations
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent>
                          {values.locations.map(location => (
                            <Grid container spacing={3}>
                              <Grid item md={8} xs={8}>
                                <Typography className="label-title">
                                  Office Location
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {location.officeLocation}
                                </Typography>
                              </Grid>

                              <Grid item md={4} xs={4}>
                                <Typography className="label-title">
                                  Office Function
                                </Typography>
                                <Typography
                                  style={{ marginTop: "5px", fontSize: "14px" }}
                                >
                                  {location.officeFunction}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </CardContent>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                  <div class="footer-btn-fixed">
                    <footer class="page-footer green">
                      <Grid container spacing={2} justify="center">
                        <Grid item md={3} sm={3} xs={3}>
                          <Button
                            className={classes.NextButton}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={values => {
                              type = "next";
                              handleSubmit(values);
                            }}
                          >
                            Next{" "}
                          </Button>
                        </Grid>
                      </Grid>
                    </footer>
                  </div>
                </Card>
              </div>
            ) : (
              <form
                onKeyDown={onKeyDown}
                onSubmit={handleCompanySubmit}
                className={"requestly-rfp-form company-information mb-5"}
              >
                <div className={classes.pageSize + " company-info-div"}>
                  <Card className={classes.CardBorder}>
                    <CardContent className={classes.cardScrollArea + " p-0"}>
                      <Card>
                        <CardHeader
                          title="Company Information"
                          subheader="Please provide information on your company. This will be seen
                          by prospects and customers when they evaluate vendors."
                          className={
                            classes.companyHeadings + " requestly-rfp-heading"
                          }
                        />
                        <Divider />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item md={8} xs={8}>
                              <TextField
                                error={Boolean(
                                  touched.companyName && errors.companyName
                                )}
                                fullWidth
                                multiline
                                helperText={
                                  touched.companyName && errors.companyName
                                }
                                label="Company name"
                                name="companyName"
                                disabled={isSubmitView}
                                onBlur={updateState}
                                onChange={handleChange}
                                //defaultValue={companyState.companyName}
                                value={values.companyName}
                                variant="outlined"
                                className="requestly-rfp-textbox"
                              />
                              {error_values !== null &&
                              error_values.companyName !== undefined ? (
                                <ErrorComponent
                                  text={error_values.companyName}
                                />
                              ) : (
                                ""
                              )}
                            </Grid>

                            <Grid
                              item
                              md={4}
                              xs={4}
                              className={
                                classes.profileImg + " thumb-align-right"
                              }
                            >
                              {console.log("valie ---->", values.logo)}
                              <Thumb
                                className={classes.profilePics}
                                file={getCompanyLogo()}
                                stopChange={isSubmitView}
                              />
                              <input
                                style={{ display: "none" }}
                                id="logo"
                                name="logo"
                                type="file"
                                accept=".jpg,.jpeg,.png,.svg,"
                                className="form-control"
                                onChange={event => {
                                  setFieldValue(event.currentTarget.files[0]);
                                }}
                              />
                              {error_values !== null &&
                              error_values.logo !== undefined ? (
                                <ErrorComponent text={error_values.logo} />
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Typography
                                style={{
                                  marginTop: "-25px",
                                  color: "#547087",
                                  fontSize: "16px"
                                }}
                              >
                                {" "}
                                Company Overview{" "}
                              </Typography>
                              <RichText
                                showContentOnly={isSubmitView}
                                value={companyState.overview}
                                style={{ color: "black !important" }}
                                className="requestly-bg-gray"
                                onTextChange={handleOverviewChange}
                              />
                              {error_values !== null &&
                              error_values.overview !== undefined ? (
                                <ErrorComponent text={error_values.overview} />
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Tooltip
                                className="requestly-tooltip"
                                title="Enter a KEYWORD or PHRASE, press ENTER after each oneXXX"
                                placement="right-start"
                                arrow
                              >
                                <ChipInput
                                  error={Boolean(
                                    touched.keywords && errors.keywords
                                  )}
                                  fullWidth
                                  disabled={isSubmitView}
                                  helperText={
                                    touched.keywords && errors.keywords
                                  }
                                  label="Keywords"
                                  defaultValue={companyState.keywords}
                                  onChange={chip => handleChangeKeywords(chip)}
                                  variant="outlined"
                                  className="requestly-bg-gray multiselect-textbox"
                                />
                              </Tooltip>
                              {error_values !== null &&
                              error_values.keywords !== undefined ? (
                                <ErrorComponent text={error_values.keywords} />
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Tooltip
                                title="Enter or PASTE a YouTube video url"
                                arrow
                              >
                                <TextField
                                  error={Boolean(
                                    touched.videoUrl && errors.videoUrl
                                  )}
                                  fullWidth
                                  disabled={isSubmitView}
                                  helperText={
                                    touched.videoUrl && errors.videoUrl
                                  }
                                  label="Company Solution Video URL"
                                  name="videoUrl"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.videoUrl}
                                  variant="outlined"
                                  className="requestly-bg-gray requestly-h-40"
                                />
                              </Tooltip>
                              {error_values !== null &&
                              error_values.videoUrl !== undefined ? (
                                <ErrorComponent text={error_values.videoUrl} />
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.website && errors.website
                                )}
                                fullWidth
                                helperText={touched.website && errors.website}
                                label="Company Website"
                                name="website"
                                onBlur={updateState}
                                onChange={handleChange}
                                value={values.website}
                                variant="outlined"
                                placeholder="https://www.google.com"
                                className="requestly-bg-gray requestly-h-40"
                              />
                              {error_values !== null &&
                              error_values.website !== undefined ? (
                                <ErrorComponent text={error_values.website} />
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Accordion
                          defaultExpanded={true}
                          className="requestly-fin-info"
                        >
                          <AccordionSummary
                            className="requestly-tab-height"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel-financial-content"
                            id="panel-financial"
                          >
                            <Typography className={classes.heading}>
                              Financial Information
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography component="span" variant="body2">
                              <CardContent>
                                <Grid container spacing={3}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      error={Boolean(
                                        touched.companyType &&
                                          errors.companyType
                                      )}
                                      fullWidth
                                      disabled={isSubmitView}
                                      helperText={
                                        touched.companyType &&
                                        errors.companyType
                                      }
                                      label="Company Type"
                                      name="companyType"
                                      onChange={updateState}
                                      select
                                      SelectProps={{ native: true }}
                                      value={values.companyType}
                                      variant="outlined"
                                      className="requestly-bg-gray requestly-h-40"
                                    >
                                      {companyTypes.map(option => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      label="Number of Employees"
                                      name="headCount"
                                      value={values.headCount}
                                      onChange={handleChange}
                                      onBlur={updateState}
                                      id="formatted-number"
                                      className="requestly-bg-gray requestly-h-40"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom
                                      }}
                                    />
                                  </Grid>
                                  <Grid item md={8} xs={8}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      label="Annual Revenue"
                                      name="totalRevenue"
                                      value={values.totalRevenue}
                                      onChange={handleChange}
                                      onBlur={updateState}
                                      id={values.currency}
                                      className="requestly-bg-gray requestly-h-40"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom
                                      }}
                                    />
                                  </Grid>
                                  <Grid item md={4} xs={4}>
                                    <TextField
                                      error={Boolean(
                                        touched.currency && errors.currency
                                      )}
                                      fullWidth
                                      disabled={isSubmitView}
                                      helperText={
                                        touched.currency && errors.currency
                                      }
                                      label="Currency"
                                      name="currency"
                                      select
                                      SelectProps={{ native: true }}
                                      value={values.currency}
                                      onBlur={updateState}
                                      onChange={handleChange}
                                      className="requestly-bg-gray requestly-h-40"
                                      variant="outlined"
                                    >
                                      {currencies.map(option => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <DatePicker
                                        fullWidth
                                        inputVariant="outlined"
                                        label="Fiscal Year End"
                                        value={values.fiscalYearMonth}
                                        onBlur={updateState}
                                        onChange={handleChangeFiscalYearMonth}
                                        className="requestly-bg-gray requestly-h-40"
                                        views={["date", "month"]}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          defaultExpanded={true}
                          className="requestly-fin-info"
                        >
                          <AccordionSummary
                            className="requestly-tab-height"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel-location-content"
                            id="panel-location"
                          >
                            <Typography className={classes.heading}>
                              {" "}
                              Company Office Locations{" "}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.widthFill}
                            >
                              <CardContent>
                                <Grid container spacing={3}>
                                  <Grid item md={12} xs={12}>
                                    <FieldArray
                                      name="locations"
                                      render={arrayHelpers => (
                                        <div className={classes.customInputs}>
                                          {values.locations &&
                                            values.locations.map(
                                              (value, index) => (
                                                <div key={index}>
                                                  <Grid item md={5} xs={12}>
                                                    <Autocomplete
                                                      style={{
                                                        display: "inlineFlex"
                                                      }}
                                                      className={
                                                        classes.testMan
                                                      }
                                                      blurOnSelect={true}
                                                      fullWidth
                                                      disableClearable
                                                      disabled={isSubmitView}
                                                      id="google-map-demo"
                                                      name={`locations[${index}].officeLocation`}
                                                      getOptionLabel={option =>
                                                        typeof option ===
                                                        "string"
                                                          ? option
                                                          : option.description
                                                      }
                                                      filterOptions={x => x}
                                                      options={options}
                                                      autoComplete
                                                      includeInputInList
                                                      filterSelectedOptions
                                                      value={
                                                        values.locations[index]
                                                          .officeLocation
                                                      }
                                                      onChange={(
                                                        event,
                                                        newValue
                                                      ) => {
                                                        values.locations[
                                                          index
                                                        ].officeLocation =
                                                          newValue?.description ??
                                                          "";
                                                        setInputValue("");
                                                      }}
                                                      onOpen={() => {
                                                        setInputValue(
                                                          values.locations[
                                                            index
                                                          ].officeLocation
                                                        );
                                                      }}
                                                      onInputChange={(
                                                        event,
                                                        newInputValue
                                                      ) => {
                                                        setInputValue(
                                                          newInputValue
                                                        );
                                                      }}
                                                      renderInput={params => (
                                                        <TextField
                                                          {...params}
                                                          label="Office Location"
                                                          variant="outlined"
                                                          fullWidth
                                                          className="react-bg-gray requestly-h-40"
                                                        />
                                                      )}
                                                      renderOption={option => {
                                                        const matches =
                                                          option
                                                            .structured_formatting
                                                            .main_text_matched_substrings;
                                                        const parts = parse(
                                                          option
                                                            .structured_formatting
                                                            .main_text,
                                                          matches.map(match => [
                                                            match.offset,
                                                            match.offset +
                                                              match.length
                                                          ])
                                                        );

                                                        return (
                                                          <Grid
                                                            container
                                                            alignItems="center"
                                                          >
                                                            <Grid item>
                                                              <LocationOnIcon
                                                                className={
                                                                  classes.icon
                                                                }
                                                              />
                                                            </Grid>
                                                            <Grid item xs>
                                                              {parts.map(
                                                                (
                                                                  part,
                                                                  index
                                                                ) => (
                                                                  <span
                                                                    key={index}
                                                                    style={{
                                                                      fontWeight: part.highlight
                                                                        ? 700
                                                                        : 400
                                                                    }}
                                                                  >
                                                                    {part.text}
                                                                  </span>
                                                                )
                                                              )}

                                                              <Typography
                                                                variant="body2"
                                                                color="textPrimary"
                                                              >
                                                                {
                                                                  option
                                                                    .structured_formatting
                                                                    .secondary_text
                                                                }
                                                              </Typography>
                                                            </Grid>
                                                          </Grid>
                                                        );
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item md={5} xs={12}>
                                                    <Tooltip
                                                      title="Enter an office function such as HQ, Marketing, Support and press ENTER after each one"
                                                      arrow
                                                    >
                                                      <ChipInput
                                                        error={Boolean(
                                                          touched.officeFunction &&
                                                            errors.officeFunction
                                                        )}
                                                        fullWidth
                                                        disabled={isSubmitView}
                                                        helperText={
                                                          touched.officeFunction &&
                                                          errors.officeFunction
                                                        }
                                                        label="Office Function"
                                                        name={`locations[${index}].officeFunction`}
                                                        defaultValue={
                                                          values?.locations[
                                                            index
                                                          ]?.officeFunction
                                                            ? typeof values
                                                                .locations[
                                                                index
                                                              ]
                                                                .officeFunction ===
                                                              "object"
                                                              ? values
                                                                  .locations[
                                                                  index
                                                                ].officeFunction
                                                              : [
                                                                  values
                                                                    .locations[
                                                                    index
                                                                  ]
                                                                    .officeFunction
                                                                ]
                                                            : []
                                                        }
                                                        onChange={chip =>
                                                          handleOfficeChange(
                                                            chip,
                                                            index,
                                                            2
                                                          )
                                                        }
                                                      />
                                                    </Tooltip>
                                                  </Grid>
                                                  <Grid item md={2} xs={12}>
                                                    {index > 0 &&
                                                    !isSubmitView ? (
                                                      <Button
                                                        style={{
                                                          marginRight: "0px",
                                                          padding: "1px",
                                                          minWidth: "30px"
                                                        }}
                                                        type="button"
                                                        onClick={() =>
                                                          removeLocations(index)
                                                        }
                                                      >
                                                        {" "}
                                                        &nbsp;{" "}
                                                        <DeleteIcon
                                                          className="site-delete-icon"
                                                          style={{
                                                            color: "#757575"
                                                          }}
                                                        />{" "}
                                                      </Button>
                                                    ) : null}
                                                  </Grid>
                                                </div>
                                              )
                                            )}
                                          {!isSubmitView ? (
                                            <Button
                                              style={{
                                                marginTop: "0px",
                                                padding: "6px 16px",
                                                fontSize: "14px"
                                              }}
                                              className={classes.blueBtn}
                                              color="secondary"
                                              variant="contained"
                                              type="button"
                                              onClick={addLocations}
                                            >
                                              Add Location
                                            </Button>
                                          ) : null}
                                        </div>
                                      )}
                                    />
                                  </Grid>
                                  {error_values !== null &&
                                  error_values.locations !== undefined ? (
                                    <Grid item md={12} xs={12}>
                                      <ErrorComponent
                                        text={error_values.locations}
                                      />
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </CardContent>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Card>
                    </CardContent>
                    <Divider />
                    <div style={{ display: deleteProductModal && "none" }}>
                      <div class="footer-btn-fixed">
                        <footer class="page-footer green">
                          <Grid container spacing={2} justify="center">
                            <Grid item md={3} sm={3} xs={3}>
                              <Button
                                className={classes.NextButton}
                                type="submit"
                                variant="contained"
                                onClick={values => {
                                  type = "next";
                                  handleSubmit(values);
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </footer>
                      </div>
                    </div>
                  </Card>
                </div>
              </form>
            )}
          </div>
        )}
      </Formik>
      <div className={classes.root}></div>
    </React.Fragment>
  );
};
CompanyDetails.propTypes = {
  className: PropTypes.string
};
export default CompanyDetails;
