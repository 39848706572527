
export class NdaAnswerModel {  
  ndas=[]
  isData = false;
  constructor(ndaInfo = null){  
          if (ndaInfo) {
                 this.isData = true;
                 ndaInfo.forEach(element => {
                        this.ndas.push(new ndaAnswers(element))
                 });
          }
  }    
}  

export class ndaAnswers{
  vendorMNDA = false;
  companyMNDA = false;
  requestlyMNDA = false;
  companyNDADocument="";
  companyNDAAnsDocument="";
  companyNDAAnsDocument_docs="";
  isData = false;
  email="";
  userName= "";
  constructor(ndaInfo = null){  
          if (ndaInfo) {
                 this.isData = true;
                 this.companyNDADocument = ndaInfo.companyNDADocument || this.companyNDADocument;
                 this.companyNDAAnsDocument_docs = ndaInfo.companyNDAAnsDocument_docs || this.companyNDAAnsDocument_docs;
                 this.companyNDAAnsDocument =ndaInfo.companyNDAAnsDocument || this.companyNDAAnsDocument;
                  this.vendorMNDA = ndaInfo.vendorMNDA || this.vendorMNDA;
                  this.companyMNDA = ndaInfo.companyMNDA || this.companyMNDA;
                  this.requestlyMNDA = ndaInfo.requestlyMNDA || this.requestlyMNDA;
                  this.email = ndaInfo.email || this.email;
                  this.userName = ndaInfo.userName || this.userName;
          }
  }    
}