import UserActionTypes from "./types";


const initialState = {
  productData: {},
  loading: false,
  error: false,

};
const productReducer = (state = initialState, action) => {
  // console.log(action,"action");
  switch (action.type) {
    case UserActionTypes.GET_RFP_BY_USER_START:
      return {
        ...state,
        productData: '',
        loading: true,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_SUCCESS:
      return {
        ...state,
        productData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };





    default:
      return state;
  }
};

export default productReducer;