/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import {
  Route, Redirect
} from 'react-router-dom';
import LoginView from 'src/views/auth/LoginView';
import RegisterView from 'src/views/auth/RegisterView';
import MainLayout from 'src/layouts/MainLayout';

export default function AuthLayout() {
  return (
    <>
      <MainLayout />
      <Route path="/auth/register" exact component={RegisterView} />
      <Route path="/auth/login" component={LoginView} />
      <Route exact path="/auth">
        <Redirect from="/auth" to="/auth/login" exact />
      </Route>
    </>
  );
}
