import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getRfpByUserFailed, getRfpByUserSuccess } from "./actions";
import ProductService from "./service";
import actionTypes from "./types";

function* helloSaga() {
  console.log('Hello Sagas!')
}

function* watchGetRfpByUserStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_USER_START, fetchProductListApiAsync);
}

function* fetchProductListApiAsync() {
  try {

    const response = yield call(ProductService.product);
    const data = { response };
    yield put(getRfpByUserSuccess(data));
  } catch (error) {
    yield put(getRfpByUserFailed(error));
  }
}
// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export function* productSaga() {
  yield all([
    helloSaga(),
    watchGetRfpByUserStart()
  ])
}