import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText, makeStyles,
  // Link,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { withRouter } from 'react-router-dom';
import Page from 'src/components/Page';
import { Alert } from 'src/components/toaster/alert.component';
import { alertService } from 'src/components/toaster/alert.service';
import LoginService from 'src/services/sigin-signup/login.service';
import LocalStorageService from 'src/services/storage/local-storage.service';
import { history } from 'src/utils/utils';
import * as Yup from 'yup';
import TermsAndConditions from '../shared/components/alert/TermsAndConditions';
import "./css/RegisterView.scss";

ReactGA.initialize('UA-205584867-1');
hotjar.initialize('2566851', 6);

const useStyles = makeStyles((theme) => ({
  TextField: {
    backgroundColor: '#fff',
    borderRadius: '5px'
  },
  input: {
    color: '#40506b'
  },
  Checkbox: {
    backgroundColor: '#fff',
    color: '#000'
  },
  signupButton: {
    backgroundColor: '#40506b',
    color: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.5)',
  },
  signupButtonHover: {
    backgroundColor: '#ddd',
    color: '#000',
    shadowColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    backgroundColor: '#fff',
    color: '#000',
    height: '75%'
  }
}));
const loginService = new LoginService();
const localStorageService = LocalStorageService.getService();
const RegisterView = () => {
  if (localStorage.token) {
    history.push('/app/dashboard');
  }
  const [registerState, setRegisterState] = React.useState({
    email: '',
    userName: '',
    password: '',
    policy: false
  })
  const [alertState, setAlertState] = React.useState({
    open: false,
    severity: 'success',
    displayMessage: 'Hello World'
  });

  const handleOnAgree = (isAgreed) => {
    setRegisterState({
      email: registerState.email,
      userName: registerState.userName,
      password: registerState.password,
      policy: isAgreed
    });
  }

  const handleUpdate = (registerState) => {
    setRegisterState(registerState);
  }
  const handlePolicyUpdate = (registerState) => {
    setRegisterState({
      email: registerState.email,
      userName: registerState.userName,
      password: registerState.password,
      policy: !registerState.policy
    });
  }
  const classes = useStyles();
  // const navigate = useNavigate();
  const autoClose = true;
  const keepAfterRouteChange = true

  return (
    <React.Fragment>
      <Alert id="left-alert" />
      <Page
        className={classes.root}
        title="Register"
      >
        <div class="authSigninText">Have an account? <a href={(window.location.hostname === 'requestly.co') ? "https://requestly.co/auth/login" : window.location.origin + "/auth/login"}><signinLinkText> Sign in</signinLinkText></a></div>
        {
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
        {
          hotjar.stateChange(window.location.pathname + window.location.search)
        }
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        > <div class='splitPage'>
            <div class='authInfo'>
              <div class="mainImage">
                <img src="../../front/images/requestly_free.jpg" width="100%" />
              </div>
              <p><requestlyText>requestly</requestlyText><signupInfoText> is 100% free, forever</signupInfoText></p>
              <p>We will never sell your peronal or company information</p>
              <p>No credit card needed</p>

              <div class="asSeenOn">
                <div class="asSeenOnTitle">As Seen On</div>
                <img class="asSeenOnLogo" src="/front/images/NBC_logo.png" />
                <img class="asSeenOnLogo" src="/front/images/cbs_logo.png" />
                <img class="asSeenOnLogo" src="/front/images/USA_Today_Logo.png" />
                <img class="asSeenOnLogo" src="/front/images/Fox_News_Logo.png" />
              </div>
            </div>
            <div class='authForm'>
              <Container maxWidth="sm">
                <Formik
                  initialValues={registerState}
                  enableReinitialize={true}
                  validationSchema={
                    Yup.object().shape({
                      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                      userName: Yup.string().max(255).required('Name is required'),
                      password: Yup.string().max(255).required('password is required'),
                      policy: Yup.boolean().oneOf([true], 'Please accept to our T&C')
                    })
                  }
                  onSubmit={(values) => {
                    loginService.userSignup(values).then((res) => {
                      if (res) {
                        alertService.success(
                          res.message ?? "User has been registered successfully."
                        )
                        //   localStorageService.setToken(res);
                        //   localStorage.setItem('user', res.userName);
                        //   setAlertState({
                        //     open: true,
                        //     severity: 'success',
                        //     displayMessage: 'User has been registered successfully.'
                        //   })
                        //  history.push('/app/dashboard/true');
                      }
                    }).error((err) => {
                      console.log(':: err', err);
                    });
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (

                    <form onSubmit={handleSubmit}>
                      <Box mb={2}>
                        <Typography
                          color="green"
                          variant="h2"
                        >
                          <div class="accountTitle">Create your free account</div>
                        </Typography>
                        <Typography
                          align="left"
                          gutterBottom
                          variant="body2"
                        >
                          (Use your company email)
                        </Typography>
                      </Box>
                      <TextField
                        error={Boolean(touched.userName && errors.userName)}
                        fullWidth
                        helperText={touched.userName && errors.userName}
                        className={classes.TextField}
                        label="Name"
                        margin="normal"
                        name="userName"
                        onBlur={() => handleUpdate(values)}
                        onChange={handleChange}
                        value={values.userName}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        className={classes.TextField}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={() => handleUpdate(values)}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        className={classes.TextField}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={() => handleUpdate(values)}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      <Box
                        alignItems="center"
                        display="flex"
                        ml={-1}
                      >
                        <Checkbox
                          checked={values.policy}
                          className={classes.Checkbox}
                          name="policy"
                          onChange={() => handlePolicyUpdate(values)}
                        />
                        <Typography
                          variant="body1"
                        >
                          I have read the
                          {' '}
                          <TermsAndConditions onAgree={handleOnAgree} />
                        </Typography>
                      </Box>
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>
                          {errors.policy}
                        </FormHelperText>
                      )}

                      <Box my={2}>
                        <Button
                          className={classes.signupButton}
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Sign up now
                        </Button>
                      </Box>

                    </form>
                  )}
                </Formik>

              </Container>
            </div>
          </div>
        </Box>
      </Page>
    </React.Fragment >
  );
};

export default withRouter(RegisterView);
