import { AppBar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Tab, Tabs, useTheme } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import { history } from 'src/utils/utils';
import 'src/views/shared/css/common.scss';
import RichText from '../../components/RichText/RichText';
import TechinicalRequirementSubmitSection from '../../components/SubmitSection/index';
import { getRfpByIdSubmitTechnicalUpdate } from "../../store/submitRfp/actions";
import GoBackModal from '../shared/components/GoBackModal';
import { useStyles } from './TechnicalEvaluationAnswerStyle';
import { TechnicalEvaluationAnswerValidations } from './TechnicalEvaluationAnswerValidations';

const TechnicalEvaluationAnswer = ({ handleNext, handleSkip, handleBack, className, step, currentStepCompleteStatus, acceptStatus, totalStepCount, completed, is_submitted = false, getRfpByIdSubmitTechnicalUpdate, submitTechnicalData, submitTechnicalEvalUpdateData, technicalEvaluationState, handleQuestionChange, question_answer_data, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef(null);
  var type = '';
  const [hasCategroyError, setCategroyError] = useState(false);
  const [question_details, setQuestionDetails] = useState([]);
  const [goBackmodal, setGoBackModal] = React.useState(false)
  const [defaultData, setDefaultData] = useState([]);

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);

  useEffect(() => {
    var temp = {};
    if (question_answer_data !== undefined) {
      question_answer_data.map((qa, index) => {
        temp[qa.questionId] = qa;
      })
      setQuestionDetails(temp)
    }
  }, [question_answer_data])

  useEffect(() => {
    if (technicalEvaluationState) {
      setDefaultData(technicalEvaluationState)
    }

  }, [technicalEvaluationState])

  console.log(goBackmodal, "goBackmodal")

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired, };

  function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, 'aria-controls': `scrollable-auto-tabpanel-${index}` };
  }

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  let iconSlidesdiv = [
    'strek', 'iconSlides',
  ]
  iconSlidesdiv = iconSlidesdiv.join(' ')
  return (
    <React.Fragment>
      <Formik innerRef={ref} enableReinitialize={true} initialValues={submitTechnicalEvalUpdateData} validationSchema={TechnicalEvaluationAnswerValidations} validator={() => ({})}
        onSubmit={(values) => {
          submitTechnicalEvalUpdateData.step = step;
          submitTechnicalEvalUpdateData.type = type;
          handleNext(submitTechnicalEvalUpdateData);
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          handleNext,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} className={'requestly-rfp-form company-information mb-5'}>
            <div className={'company-info-div'}>
              <Card className={classes.CardBorder}>
                <CardContent className="p-0">
                  <Card>
                    <CardHeader
                      title="Technical Requirements"
                      subheader="(Add Technical Requirements)"
                      className={classes.companyHeadings + ' requestly-rfp-heading width-auto'} />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item sm={12} lg={12} md={12} xs={12}>
                          <Typography style={{ "marginTop": "5px", "color": "#547087", "fontSize": "16px" }}> Technical Overview </Typography>
                          <RichText
                            showContentOnly={true}
                            value={submitTechnicalEvalUpdateData.overview}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <div className={classes.root}>
                      <div className={iconSlidesdiv}>
                        <AppBar position="static" color="default">
                          <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
                            {submitTechnicalEvalUpdateData.categories && submitTechnicalEvalUpdateData.categories.map((value, index) => (
                              <Tab label={value.categoryName} {...a11yProps(index)} />
                            ))}
                          </Tabs>
                        </AppBar>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                          {values.categories && values.categories.map((category, categoryIndex) => (
                            <div>
                              {category.sections && category.sections.map((section, sectionIndex) => (
                                <TechinicalRequirementSubmitSection
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  sectionIndex={sectionIndex}
                                  section={section}
                                  categoryIndex={categoryIndex}
                                  handleQuestionChange={handleQuestionChange}
                                  isCommercial={false}
                                  question_details={question_details}
                                />
                              ))}
                            </div>
                          ))}
                        </SwipeableViews>
                      </div>
                    </div>
                  </Card>
                </CardContent>
                <Divider />
                <div style={{ display: goBackmodal ? "none" : "" }}>
                  <div class="footer-btn-fixed">
                    <footer class="page-footer green">
                      <Grid container spacing={2} justify="center">
                        {(step !== 0 && !is_submitted) ? (
                          <Grid item md={3} sm={3} xs={3}>
                            {/* <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                            Back
                          </Button> */}
                            <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={() => { defaultData === submitTechnicalEvalUpdateData ? handleBack() : setGoBackModal(true) }}>
                              Back
                            </Button>
                          </Grid>
                        ) : null}
                        {(step !== totalStepCount && !is_submitted) ? (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                              Skip
                            </Button>
                          </Grid>
                        ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                              </Grid> : ''
                          ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Tooltip
                                  title={'You must accept RFP invite to submit the RFP response'}
                                  placement="top"
                                  arrow
                                  onOpen={() => {
                                    if (acceptStatus != 1 && !open) {
                                      setOpen(true);
                                    }
                                  }}
                                  onClose={() => setOpen(false)}
                                  open={open}
                                >
                                  <div onMouseOver={() => {
                                    setOpen(true)
                                  }}
                                    onMouseLeave={() => setOpen(false)}>
                                    <Button style={{ width: '100%' }} disabled={acceptStatus == 1 ? false : true} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                                  </div>
                                </Tooltip>
                              </Grid> : ''
                          ) : step === totalStepCount ?
                            (
                              (!is_submitted) ?
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                                </Grid> : ''
                            ) : (
                              (!is_submitted) ?
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                                </Grid> : ''
                            )}
                        {
                          (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                            <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                          </Grid> : ''
                        }
                      </Grid>
                    </footer>
                  </div>
                </div>
              </Card>
            </div> </form>
        )}
      </Formik>
      <div className={classes.root}>
        {goBackmodal && (
          <GoBackModal
            goBackmodal={goBackmodal}
            handleBack={handleBack}
            closeEditModal={() => {
              setGoBackModal(false)
            }
            }
          />
        )}
      </div>
    </React.Fragment>
  );
};
TechnicalEvaluationAnswer.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  submitTechnicalData: state.submitRfp.rfpSubmitTechnicalData,
  submitTechnicalEvalUpdateData: state.submitRfp.submitRfpTechnicalDataUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdSubmitTechnicalUpdate: (value) => dispatch(getRfpByIdSubmitTechnicalUpdate(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalEvaluationAnswer);
