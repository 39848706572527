import DateFnsUtils from "@date-io/date-fns";
import { Button, Checkbox, Popper, Typography } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from "moment";
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { alertService } from "src/components/toaster/alert.service";
import CompanyService from './../../services/company/company.service';
import './style.css';


const FilterModal = ({ openModal, closeModal, applyFilter, resetFilter, anchorEl, filterTop }) => {
  const history = useHistory();
  const companyService = new CompanyService();
  const [selectedMR, setSelectedMR] = useState({
    "Discovery Meeting": false,
    "Product Demo": false,
    "Request For Proposal": false,
    "Credit Added - 5Pack/£999": false,
    "Enterprise Subscription -Per Year/£12k": false
  });
  const [selectedFromDate, setSelectedFromDate] = useState();
  const [selectedToDate, setSelectedToDate] = useState();

  const closePopUp = () => {
    closeModal(false)
  }

  const handleChange = event => {
    setSelectedMR({ ...selectedMR, [event.target.name]: event.target.checked });
  }

  const handleSave = () => {
    const res = Object.values(selectedMR).some(value => value === true)

    if (selectedToDate && !selectedFromDate) {
      displayError("Please select from date");
    } else if (!selectedToDate && selectedFromDate) {
      let filterData = {
        selectedFromDate,
        selectedToDate: new Date(),
        selectedMR
      }
      applyFilter(filterData);
      closePopUp();
    } else if ((selectedFromDate && selectedToDate) || res) {
      if ((selectedFromDate && selectedToDate) && !moment(selectedFromDate).isSameOrBefore(selectedToDate)) {
        displayError('Please select valid from date');
        return;
      }

      let filterData = {
        selectedFromDate,
        selectedToDate,
        selectedMR
      }
      applyFilter(filterData);
      closePopUp();

    } else {
      clearFilter();
      resetFilter();
      closePopUp();
    }
  }

  const displayError = (error) => {
    const autoClose = true;
    const keepAfterRouteChange = false;
    alertService.error(error, {
      autoClose,
      keepAfterRouteChange
    });
  }

  const handleChangeFromDate = (date) => {
    setSelectedFromDate(date);
  }

  const handleChangeToDate = (date) => {
    setSelectedToDate(date);
  }

  const clearFilter = () => {
    setSelectedMR({
      "Discovery Meeting": false,
      "Product Demo": false,
      "Request For Proposal": false,
      "Credit Added - 5Pack/£999": false,
      "Credit Added -Billed Annually/$1000": false
    });
    setSelectedToDate(null);
    setSelectedFromDate(null);

    // resetFilter();
  }


  return (
    <>
      <Popper placement="bottom-end" className={'popper-container'} id={'popover-filter'} open={openModal} anchorEl={anchorEl} onClose={closeModal} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} >
        <div className="custompop">
          <div className="top">
            <Button className="clear" onClick={clearFilter}>Clear</Button>
            <p>Filters</p>
            <Button className="done" onClick={handleSave}>Done</Button>
          </div>
          <div className="center">
            <FormControl component="fieldset" className="date-filter">
              <Typography className="label">Created On</Typography>
              <div className="date-picker-container">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    disableToolbar
                    autoOk
                    inputVariant="outlined"
                    // label="From Date"
                    label={selectedFromDate && "From"}
                    value={selectedFromDate ? selectedFromDate : null}
                    maxDate={selectedToDate || new Date()}
                    onChange={handleChangeFromDate}
                    className="filter-date-picker"
                    style={{ width: "47%" }}
                    format="yyyy/MM/dd"
                    openTo="year"
                    variant="inline"
                    emptyLabel="From"
                    views={['year', 'month', 'date']} />

                  <DatePicker
                    disableToolbar
                    autoOk
                    inputVariant="outlined"
                    // label="To Date"
                    label={selectedToDate && "To"}
                    value={selectedToDate ? selectedToDate : null}
                    style={{ width: "48%" }}
                    minDate={selectedFromDate ? selectedFromDate : null}
                    maxDate={new Date()}
                    onChange={handleChangeToDate}
                    className="filter-date-picker"
                    format="yyyy/MM/dd"
                    openTo="year"
                    variant="inline"
                    emptyLabel="To"
                    views={['year', 'month', 'date']} />
                </MuiPickersUtilsProvider>
              </div>
            </FormControl>
            <FormControl component="fieldset">
              {/* <RadioGroup
                style={{ width: '100%' }}
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
                multiple={true}
                onChange={handleChange}
              >
                <Typography className="label">Meeting Requests</Typography>
                <FormControlLabel className="radio" value="Discovery Meeting" control={<Radio />} label="Discovery Meeting" />
                <FormControlLabel className="radio" value="Product Demo" control={<Radio />} label="Product Demo" />
                <FormControlLabel className="radio" value="Request for Proposal" control={<Radio />} label="Request for Proposal" />
              </RadioGroup> */}
              {/* <FormGroup> */}
              <Typography className="label">Meeting Requests</Typography>
              <FormControlLabel
                className="checkbox-container"
                control={
                  <Checkbox
                    checked={selectedMR['Discovery Meeting']}
                    onChange={handleChange}
                    name="Discovery Meeting"
                  />
                }
                label="Discovery Meeting"
              />
              <FormControlLabel
                className="checkbox-container"
                control={
                  <Checkbox
                    checked={selectedMR['Product Demo']}
                    onChange={handleChange}
                    name="Product Demo"
                  />
                }
                label="Product Demo"
              />
              <FormControlLabel
                className="checkbox-container"
                control={
                  <Checkbox
                    checked={selectedMR['Request For Proposal']}
                    onChange={handleChange}
                    name="Request For Proposal"
                  />
                }
                label="Request For Proposal"
              />
              <FormControlLabel
                className="checkbox-container"
                control={
                  <Checkbox
                    checked={selectedMR['Credit Added - 5Pack/£999']}
                    onChange={handleChange}
                    name="Credit Added - 5Pack/£999"
                  />
                }
                label="Basic Plan"
              />
              <FormControlLabel
                className="checkbox-container"
                control={
                  <Checkbox
                    checked={selectedMR['Enterprise Subscription -Per Year/£12k']}
                    onChange={handleChange}
                    name="Enterprise Subscription -Per Year/£12k"
                  />
                }
                label="Enterprise plan"
              />
              {/* </FormGroup> */}
            </FormControl>
          </div>
        </div>
      </Popper>
    </>
  )
}

export default FilterModal
