import {
  Box, Container, Link, makeStyles, Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Page from 'src/components/Page';
import { Alert } from 'src/components/toaster/alert.component';
import MainLayout from 'src/layouts/MainLayout';
import LoginService from 'src/services/sigin-signup/login.service';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const VerifyEmailView = ({ match }) => {
  const classes = useStyles();
  const [verifyEmailStatus, setVerifyEmailStatus] = useState('');
  const loginService = new LoginService();
  const { token } = match.params;

  const _verifyEmail = () => {
    loginService.verifyEmail(token).then((res) => {
      if (res?.status === 200) {
        setVerifyEmailStatus('success');
      } else {
        setVerifyEmailStatus('failed');
      }
    }).catch((err) => {
      console.log(':: err ', err)
      setVerifyEmailStatus('failed');
    });
  };
  useEffect(() => {
    if (token) {
      _verifyEmail();
    }
  }, [token]);

  return (
    <React.Fragment>
      <MainLayout />
      <Alert id="left-alert" />
      <Page
        className={classes.root}
        title="Register"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            {verifyEmailStatus === 'success' ? (
              <Typography align="center" color="textPrimary" variant="subtitle2">
                Your email has verified. You can login by click <Link href="/auth/login">here.</Link>
              </Typography>
            ) : verifyEmailStatus === 'failed' ? (
              <Typography align="center" color="error" variant="subtitle2">
                Link has expired or invalid.
              </Typography>
            ) : (
              <Typography align="center" color="secondary" variant="subtitle2">
                Please wait...
              </Typography>
            )}

          </Container>
        </Box>
      </Page>
    </React.Fragment>
  );
};

export default withRouter(VerifyEmailView);
