
export class CompanyModel {
  companyName = '';
  logo = '/static/icon-img.png';
  overview = '';
  keywords = [];
  videoUrl = '';
  website = '';
  companyType = 'Company Type';
  headCount = '';
  totalRevenue = '0';
  currency = 'USD';
  fiscalYearMonth = new Date();
  locations = [{ officeLocation: '', officeFunction: '' }];
  isData = false;
  constructor(companyInfo = null) {
    if (companyInfo) {
      this.isData = true;
      this.companyName = companyInfo.companyName || this.companyName;
      this.logo = companyInfo.logo || this.logo;
      this.overview = companyInfo.overview || this.overview;
      this.keywords = companyInfo.keywords || this.keywords;
      this.videoUrl = companyInfo.videoUrl || this.videoUrl;
      this.headCount = companyInfo.headCount || this.headCount;
      this.website = companyInfo.website || this.website;
      this.companyType = companyInfo.companyType || this.companyType;
      this.totalRevenue = companyInfo.totalRevenue || this.totalRevenue;
      this.currency = companyInfo.currency || this.currency;
      this.fiscalYearMonth = companyInfo.fiscalYearMonth || this.fiscalYearMonth;
      this.locations = companyInfo.locations || this.locations;
    }
  }
}
