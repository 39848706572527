import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import Page from "src/components/Page";
import DemoMeetingRequest from "./DemoMeetingRequest";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DemoMeetingView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root + " requestly-rfp-view"} title="Create RFP">
      <div id="rightContain" className="requestly-rfp-contain">
        <Container maxWidth="md">
          <DemoMeetingRequest />
        </Container>
      </div>
    </Page>
  );
};

export default DemoMeetingView;
