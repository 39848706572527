import * as Yup from 'yup';
export const CompanyValidations = Yup.object().shape({
  //companyName: Yup.string().max(255).required('Company name is required.'),
  //logo: Yup.string().max(255).required('Mandatory to upload the logo.'),
  // overview: Yup.string().max(255).required('Company Overview is required.'),
  // keywords: Yup.string().max(255).required('Company Keywords are mandatory.'),
  // website: Yup.string().max(255).required('Company Website are mandatory.'),
  // videoUrl: Yup.string().max(255).required('Please provide the company solution video url.'),
  // companyType: Yup.string().max(255).required('Please select company type.'),
  // headCount: Yup.string().max(255).required('Please enter the number of employees.'),
  // totalRevenue: Yup.string().max(255).required('Please enter the annual revenue.'),
  // currency: Yup.string().max(255).required('Please select the currency.'),
  // fiscalYearMonth: Yup.string().max(255).required('Please enter the Fiscal year end.')
});
