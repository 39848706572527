import { CardContent, Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from "react-redux";
import 'src/views/shared/css/common.scss';
import { getRfpByIdSubmitTechnicalUpdate } from "../../store/submitRfp/actions";
import { useStyles } from '../../views/TechnicalEvaluation/TechnicalEvaluationStyle';
import TechinicalRequirementSubmitQuestion from '../SubmitQuestion/index';

const TechinicalRequirementSubmitSection = ({ categoryIndex, section, sectionIndex, values, touched, errors, isCommercial, question_details, getRfpByIdSubmitTechnicalUpdate, submitCommercialEvalUpdateData, handleQuestionChange, step, ...rest }) => {
  const classes = useStyles();
  const [is_visible, setIsVisible] = useState(true);

  return (
    <React.Fragment>
      <Accordion expanded={is_visible} className="no-shadow requestly-fin-info-tab">
        <AccordionSummary expandIcon={<ExpandMoreIcon onClick={e => setIsVisible(!is_visible)} />} aria-controls="panel-location-content" id="panel-location" className="requestly-tab-height"
        >
          <Typography className={classes.heading}> {section.sectionName} </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography component="span" variant="body2" className={classes.widthFill}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FieldArray name="locations"
                    render={arrayHelpers => (
                      <div className={classes.customInputs}>
                        {section.questions && section.questions.map((question, questionIndex) => (

                          <TechinicalRequirementSubmitQuestion
                            touched={touched}
                            errors={errors}
                            values={values}
                            handleQuestionChange={handleQuestionChange}
                            questionIndex={questionIndex}
                            categoryIndex={categoryIndex}
                            sectionIndex={sectionIndex}
                            questionInfo={question}
                            isCommercial={isCommercial}
                            question_details={question_details}
                          />
                        ))}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
TechinicalRequirementSubmitSection.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  submitTechnicalEvalUpdateData: state.submitRfp.submitRfpTechnicalDataUpdate,
  submitCommercialEvalUpdateData: state.submitRfp.rfpSubmitCommericalDataUpdate,
  step: state.submitRfp.SubmitRfpStepUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdSubmitTechnicalUpdate: (value) => dispatch(getRfpByIdSubmitTechnicalUpdate(value)),


});

export default connect(mapStateToProps, mapDispatchToProps)(TechinicalRequirementSubmitSection);

