/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class QuesAnsService {
  constructor() {
    this.config = new Configuration();
  }
  async saveQuesAns(quesObj, rfpId) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    quesObj.userId = userId;
    const url = this.config.saveQuesAns;
    return AxiosInstance.post(url, quesObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getQuesAns(questionId, rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.getQuesAns + questionId + '/' + rfpId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getAllQuesAns(rfpId) {
    const self = this;
    const userId = localStorage.getItem('userId');
    const url = this.config.getAllQues + rfpId + '/' + userId;
    return AxiosInstance.get(url)
      .then((response) => {
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getrfpByById(rfpId, isSubmit?= false) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    let url = this.config.getRfpbyId + rfpId;
    if (isSubmit)
      url = url + '/' + userId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
      // Request made and server responded
      console.log(':: error', error.response.data);
      // alert(error.response.data.message);
      console.log(':: error', error.response.status);
      console.log(':: error', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default QuesAnsService;
