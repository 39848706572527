import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EnquiryService from "src/services/enquiry/enquiry.service";
import { history } from "src/utils/utils";
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ModalBox: {
    marginLeft: "36px",
    marginTop: "36px"
  },
  name: {
    marginTop: "20px",
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    outline: "none",
    borderRadius: "12px",
    width: "370px",
    maxWidth: "370px",
    // maxHeight: '370px',
    boxShadow: theme.shadows[5]
  },
  container: {
    // width: "370px",
    position: "relative",
    marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex"
  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "20px"
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  },
  RfpName: {
    width: "100%",
    maxWidth: 360
  },
  buttoncolor: {
    color: "#000000"
  },
  textsize: {
    fontSize: "1rem!important",
    fontWeight: 400
  },
  paper: { textAlign: "center" },
  companyHeadings: { backgroundColor: "#4688f0", color: "#fff" },
  titleTag: { margin: "auto" },
  inputAll: {
    marginTop: "10px !important",
    marginBottom: "8px !important",
    padding: "2px !important"
  },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: "contents", textAlign: "center" },
  listCheckline: { display: "block", marginTop: "10px" },
  titleTagTxt: { fontSize: "18px", fontWeight: "800", margin: "auto" },
  ProductOptions: {
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  ProductOptions2: {
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  questions: {
    marginTop: "20px"
  },
  questionCard: {
    margin: "0px 20px",
    marginTop: "-17px"
  },
  stageCard: {
    border: "1px solid #87CEEB",
    borderRadius: "4px"
  },
  notSelected: {
    cursor: "pointer"
  },
  stageCardResearchActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    maxHeight: "108px"
  },
  stageCardResearchNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    maxHeight: "108px"
  },
  stageCardActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    maxHeight: "108px"
  }
}));

const status = {
  0: "Awaiting vendor acceptance",
  1: "Accepted",
  2: "Declined"
};

const DemoMeetingRequest = ({ product }) => {
  const classes = useStyles();
  const enquiryService = new EnquiryService();
  const [productDemoList, setProductDemoList] = useState();
  let { id } = useParams();

  const getProductDemoList = () => {
    enquiryService
      .getDiscoveryMeetingList()
      .then(res => {
        if (res && res.data) {
          let list = res?.data?.filter(item => item.id === id);

          console.log(list[0]);
          setProductDemoList(list[0]);
        }
      })
      .catch(err => console.log(":: err", err));
  };
  useEffect(() => {
    refreshData();
  }, []);

  const refreshData = () => {
    getProductDemoList();
  };
  const handleSave = e => {
    history.push("/app/dashboard");
  };

  const getStatus = data => {
    return status[data];
  };

  return (
    <>
      <div>
        <form
          className={"requestly-rfp-form"}
          style={{ position: "relative", top: "-2%", fontFamily: "Roboto, sans- serif" }}
        >
          <Card style={{ borderRadius: "13px", fontFamily: "Roboto, sans- serif" }}>
            <CardHeader
              title={`Discovery Meeting Request-${productDemoList?.prouductName ||
                productDemoList?.companyName}`}
              className={classes.companyHeadings + " requestly-rfp-heading"}
            />
            <Divider />
            <CardContent className="" style={{ maxWidth: "732px" }}>
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <p style={{ fontWeight: 700, marginTop: "12px", fontFamily: "Roboto, sans- serif" }}>
                  Discovery Stage
                </p>
              </Grid>
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    marginTop: "-10px",
                    fontFamily: "Roboto, sans- serif",
                    wordBreak: "break-word"
                  }}
                >
                  {productDemoList?.stage?.label}
                </p>
              </Grid>
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <p style={{ fontWeight: 700, marginTop: "46px", fontFamily: "Roboto, sans- serif" }}>Questions</p>
              </Grid>
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: "12px",
                    marginTop: "-10px",
                    wordBreak: "break-word",
                    fontFamily: "Roboto, sans- serif"
                  }}
                >
                  {productDemoList?.question}
                </p>
              </Grid>
              {productDemoList?.message && (
                <>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 700, marginTop: "46px", fontFamily: "Roboto, sans- serif" }}>Reply</p>
                  </Grid>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        marginTop: "-10px",
                        wordBreak: "break-word",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      {productDemoList?.message}
                    </p>
                  </Grid>
                </>
              )}
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <h4
                  style={{
                    fontWeight: 800,
                    marginTop: "60px",
                    fontFamily: "Roboto, sans- serif",
                    textAlign: "center"
                  }}
                >
                  {getStatus(productDemoList?.status)}
                </h4>
              </Grid>
            </CardContent>

            <Box className={classes.footerBox} p={2}>
              <Button
                color="primary"
                className={classes.button + " requestly-rfp-button"}
                type="submit"
                variant="contained"
                style={{
                  float: "right",
                  marginBottom: "26px",
                  marginTop: "40px",
                  width: "190px",
                  textTransform: "none",
                  fontFamily: "Roboto, sans- serif"
                }}
                onClick={handleSave}
              >
                Return to dashboard
              </Button>
            </Box>
          </Card>
        </form>
      </div>
    </>
  );
};
DemoMeetingRequest.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default DemoMeetingRequest;
