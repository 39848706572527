import {
  AppBar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Tab, Tabs, TextField,
  useTheme
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Formik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import RichText from 'src/components/RichText/RichText';
import TechinicalRequirementSection from 'src/components/Section';
import 'src/views/shared/css/common.scss';
import AddSection from '../../components/AddSection/index';
import { alertService } from '../../components/toaster/alert.service';
import { getRfpByIdCommericalUpdate } from "../../store/updateRfp/actions";
import { useStyles } from './CommercialEvaluationStyle';
import { CommercialEvaluationValidations } from './CommercialEvaluationValidations';

const CommercialEvaluation = ({ handleNext, handleSkip, handleBack, className, completed, totalStepCount, currentStepCompleteStatus, commercialEvaluationState, setCommercialEvaluationState, step, isSubmitView, rfpId, CommericalData, getRfpByIdCommericalUpdate, commercialEvalUpdateData, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef(null);
  const [hasCategroyError, setCategroyError] = useState(false);
  let type = '';

  useEffect(() => {
    if (commercialEvalUpdateData && commercialEvalUpdateData.categories !== undefined && commercialEvalUpdateData.categories.length > 0) {
      setValue(1);
      setTempValue(1);
    }

  }, [commercialEvalUpdateData.categories]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired, };

  function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, 'aria-controls': `scrollable-auto-tabpanel-${index}` };
  }


  function deleteTab(event, index) {
    if (window.confirm('Are you sure to delete this Category?')) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      const dataRedux = commercialEvalUpdateData.categories.splice(index, 1);
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    } else {
      event.stopPropagation();
    }
  }

  const [value, setValue] = React.useState(0);
  const [value_temp, setTempValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTempValue(newValue);

  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const updateState = (event) => {
    setCommercialEvaluationState({ ...commercialEvaluationState, [event.target.name]: event.target.value });
  }

  const handleCategoryAdd = (event) => {
    if (ref.current.values.categoryName && event.key === 'Enter') {
      setCategroyError(false);
      const dataRedux = commercialEvalUpdateData.categories.push({ categoryName: ref.current.values.categoryName, sections: [] });
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
      ref.current.values.categoryName = '';
      setValue(commercialEvalUpdateData.categories.length);
    } else {
      setCategroyError(true);
    }
  }

  const handleOverviewChange = (richText) => {
    commercialEvalUpdateData.overview = richText;
  }

  const handleAddSection = (sectionName, indexNumber) => {
    const dataRedux = commercialEvalUpdateData.categories[indexNumber].sections.push({ sectionName: sectionName, questions: [] });
    commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
    getRfpByIdCommericalUpdate(commercialEvalUpdateData)
  }

  let iconSlidesdiv = [
    'strek', 'iconSlides',
  ]
  iconSlidesdiv = iconSlidesdiv.join(' ')

  return (
    <React.Fragment>
      <Formik innerRef={ref} enableReinitialize={true} initialValues={commercialEvalUpdateData} validationSchema={CommercialEvaluationValidations} validator={() => ({})}
        onSubmit={(values) => {
          var categories = values.categories.length;
          var success_flag = false;
          var success_flag_single = true;
          console.log(values, "values...");
          if (categories === 0) {
            success_flag = false;
          } else {
            values.categories.map(ct => {
              if (ct.sections.length > 0) {
                ct.sections.map(sec => {
                  if (sec.sectionName !== '' && sec.questions.length > 0) {
                    sec.questions.map(que => {
                      if (que.question !== '' && que.score !== '' && que.type !== '') {
                        success_flag = true;
                      } else if (que.question === '' || que.score === '' || que.type === '') {
                        success_flag_single = false;
                      }
                    })
                  } else if (sec.sectionName === '') {
                    success_flag_single = false;
                  }
                })

              }
            });
          }
          if (success_flag && success_flag_single) {
            commercialEvalUpdateData.type = type
            commercialEvalUpdateData.step = step;
            handleNext(commercialEvalUpdateData);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false
            window.scrollTo(0, 0)
            alertService.error('Please Fill in atleast 1 Category, Section and Questions. Do not leave any added row blank. Delete any not in use rows or sections.', { autoClose, keepAfterRouteChange })
          }

        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          handleNext,
          isSubmitting,
          touched,
          values
        }) => (
          <div className={'requestly-rfp-form company-information'}>
            <div className={'company-info-div'}>
              <Card className={classes.CardBorder}>
                <CardContent className={classes.cardScrollArea + ' p-0'}>
                  <Card>
                    <CardHeader
                      title="Commercial Requirements"
                      subheader="Add Commercial Requirements"
                      className={classes.companyHeadings + ' requestly-rfp-heading width-auto'} />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Grid container justify="space-between">
                            <Typography style={{ "marginTop": "5px", "color": "#547087", "fontSize": "16px" }} align="left"> Commercial Overview </Typography>
                          </Grid>
                          <RichText
                            showContentOnly={isSubmitView}
                            value={commercialEvalUpdateData.overview}
                            onTextChange={handleOverviewChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <div className={classes.root + ' Commercial-Requirements-header'}>
                      <div className={iconSlidesdiv}>
                        <AppBar position="static" color="default">
                          <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example" className="categorySectionTabs">
                            <Tab className="categorySectionTabs-1" label={<TextField
                              error={Boolean(hasCategroyError)}
                              fullWidth
                              placeholder="New Category"
                              name="categoryName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onKeyPress={handleCategoryAdd}
                              id=""
                              value={values.categoryName}
                              variant="outlined"
                              style={{ color: 'white' }}

                              className="requestly-h-40 requestly-text-box-in-tab commercial-request" />
                            } {...a11yProps(0)} />
                            {commercialEvalUpdateData.categories &&
                              commercialEvalUpdateData.categories.map((value, index) => (
                                <Tab
                                  className={(value_temp === (index + 1)) ? "categorySectionTabs-2 pwa-selected-tab" : "categorySectionTabs-2"} key={index}
                                  icon={
                                    <DeleteIcon
                                      onClick={(event) => deleteTab(event, index)}
                                      style={{ "color": "#eee", marginTop: '11px' }}
                                    />
                                  }
                                  label={
                                    <TextField
                                      error={Boolean(hasCategroyError)}
                                      name={`categoryName-${index}`}
                                      onChange={(event) => {
                                        const categories = commercialEvalUpdateData.categories.map((datum, datumIndex) => {
                                          if (datumIndex === index) {
                                            return {
                                              categoryName: event.target.value,
                                              sections: datum.sections
                                            }
                                          }
                                          return datum;
                                        });
                                        const commercialEvaluation = omit(commercialEvalUpdateData, 'categories');
                                        const dataRedux = { ...commercialEvaluation, categories: [...categories] }
                                        getRfpByIdCommericalUpdate(dataRedux)
                                      }}
                                      id={`categoryName-${index}`}
                                      value={value.categoryName}
                                      className="requestly-h-40 requestly-text-box-in-tab"
                                      style={{ color: 'white' }}
                                    />
                                  }
                                  {...a11yProps(index + 1)}
                                />
                              ))
                            }
                          </Tabs>
                        </AppBar>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                          <TabPanel value={value} index={0}>
                          </TabPanel>
                          {commercialEvalUpdateData.categories && commercialEvalUpdateData.categories.map((category, categoryIndex) => (
                            <div>

                              {category.sections && category.sections.map((section, sectionIndex) => (
                                <TechinicalRequirementSection touched={touched} errors={errors} values={values} sectionIndex={sectionIndex} section={section} categoryIndex={categoryIndex} />
                              ))}
                              <Grid item md={12} sm={12} xs={12} align="left">
                                <AddSection onAddSection={handleAddSection} indexNumber={categoryIndex} />
                              </Grid>
                            </div>
                          ))}
                        </SwipeableViews>
                      </div>
                    </div>
                  </Card>
                </CardContent>
                <Divider />
                <div class="footer-btn-fixed">
                  <footer class="page-footer green">
                    <Grid container spacing={2} justify="center">
                      {step !== 0 ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                            Back
                          </Button>
                        </Grid>
                      ) : null}
                      {step !== totalStepCount ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                            Skip
                          </Button>
                        </Grid>
                      ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                          </Grid>
                        ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                          </Grid>
                        ) : step === totalStepCount ?
                          (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                            </Grid>
                          ) : (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                            </Grid>
                          )}
                    </Grid>
                  </footer>
                </div>
              </Card>

            </div>

          </div>
        )}
      </Formik>
      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
CommercialEvaluation.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  CommericalData: state.updateRfp.rfpCommericalData,
  commercialEvalUpdateData: state.updateRfp.rfpCommericalDataUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdCommericalUpdate: (value) => dispatch(getRfpByIdCommericalUpdate(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(CommercialEvaluation);
