import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getRfpByIdSubmitCommercialFailed, getRfpByIdSubmitCommercialSuccess, getRfpByIdSubmitTechnicalFailed, getRfpByIdSubmitTechnicalSuccess, getRfpByUserIdFailed, getRfpByUserIdSuccess } from "./actions";
import submitRfpService from "./service";
import actionTypes from "./types";

function* helloSaga() {
  console.log('Hello Sagas!')
}

function* watchGetRfpByUserIdStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_USER_ID_START, fetchUserDetailsApiAsync);
}

function* fetchUserDetailsApiAsync({ payload }) {
  try {
    const response = yield call(submitRfpService.getRfpByById, payload);
    const data = { response };
    yield put(getRfpByUserIdSuccess(data));
  } catch (error) {
    yield put(getRfpByUserIdFailed(error));
  }
}

function* watchGetRfpByIdSubmitTechnicalStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_START, fetchSubmitTechnicalDetailsApiAsync);
}

function* fetchSubmitTechnicalDetailsApiAsync({ payload }) {
  try {
    const response = yield call(submitRfpService.SubmitTechnicalDetails, payload);
    const data = { response };
    yield put(getRfpByIdSubmitTechnicalSuccess(data));
  } catch (error) {
    yield put(getRfpByIdSubmitTechnicalFailed(error));
  }
}


// Commerical Requirements


function* watchGetRfpByIdSubmitCommercialStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_START, fetchSubmitCommercialDetailsApiAsync);
}

function* fetchSubmitCommercialDetailsApiAsync({ payload }) {
  try {
    const response = yield call(submitRfpService.SubmitCommercialDetails, payload);
    const data = { response };
    yield put(getRfpByIdSubmitCommercialSuccess(data));
  } catch (error) {
    yield put(getRfpByIdSubmitCommercialFailed(error));
  }
}


export function* submitRfpSaga() {
  yield all([
    helloSaga(),
    watchGetRfpByUserIdStart(),
    watchGetRfpByIdSubmitTechnicalStart(),
    watchGetRfpByIdSubmitCommercialStart()
  ])
}