import { Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PropTypes from "prop-types";
import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import CompanyService from "src/services/company/company.service";
import { history } from "src/utils/utils";
import "./style.css";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    fontSize: "14px",
    textTransform: "capitalize"
  },
  button2: {
    margin: theme.spacing(1),
    backgroundColor: "#52B152",
    fontSize: "14px",
    color: "white",
    textTransform: "capitalize"
  },
  subscriptionPlan: {
    display: "inline-block",
    width: "40%",
    border: "solid",
    borderColor: "grey",
    margin: "10px",
    padding: "10px"
  },
  radioGrpLine: {
    display: "block"
  },
  labelName: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
    color: "black",
    textDecoration: "underline",
    marginBottom: "10px"
  },
  center: {
  },
  selectedRow: {
    width: "100%",
    transition: "all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000)",
    margin: "10px",
    backgroundColor: "white",
    borderRadius: "3px"
  },
  proceedButton: {
    marginTop: "10px",
    ":disabled": {
      backgroundColor: theme.palette.primary || "red"
    }
  }
}));

const itemsPerPage = 10;

const Main = () => {
  const classes = useStyles();
  const [productList, setProductList] = useState([]);
  const [isSelectedId, setSelectedId] = useState();
  const companyService = new CompanyService();
  const [currentPageNo, setCurrentPageNo] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    getProductList(1);
  }, []);

  const getProductList = async pageNo => {
    const parsed = queryString.parse(window.location.search);
    console.log("parsed", parsed);
    if (!parsed?.rfpId) {
      const companyId = localStorage.getItem("companyId");
      companyService
        .getProductList(companyId, pageNo, itemsPerPage, true)
        .then(res => {
          if (res !== undefined && res) {
            setProductList(res?.data && res?.data?.length !== 0 ? res.data : []);
            setPageCount(Math.ceil(res.total_records / itemsPerPage));
          }
        })
        .catch(err => console.log(":: err", err));
    } else {
      getRfpProducts(parsed?.rfpId, pageNo);
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setProductList(productList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(productList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % productList.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    setCurrentPageNo(parseInt(event.selected))
    getProductList(event.selected + 1);
  };

  const getRfpProducts = (rfpId, pageNo) => {
    companyService
      .getRfpProducts(rfpId, pageNo, itemsPerPage)
      .then(res => {
        if (res !== undefined && res) {
          setProductList(res?.data && res?.data?.length !== 0 ? res.data : []);
          setPageCount(Math.ceil(res.total_records / itemsPerPage));
        }
      })
      .catch(err => console.log(":: err", err));
  }

  return (
    <>
      <div className="mainBox">
        <div style={{ display: "block" }}>
          <div className="billing-heading">Select Product</div>
          <hr className="MuiDivider-root" />

          <div>
            <Button
              variant="contained"
              className={`${classes.proceedButton}billing-button2`}
              onClick={() => {
                history.push(`/app/subscription-plan?productId=${isSelectedId}`);
              }}
              style={{
                right: '40px',
                position: 'absolute',
                marginTop: "-20px",
                color: 'white'
              }}
              disabled={!isSelectedId}
            >
              Proceed
            </Button>

            <div className="billing-table">
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Product Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productList &&
                      productList.map((row, index) => (
                        <TableRow
                          key={row._id}
                          className={row._id === isSelectedId && classes.selectedRow}
                          onClick={() => {
                            if (!row.isSubscripton) {
                              setSelectedId(row._id);
                            }
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>{(index + 1) + (currentPageNo * itemsPerPage)}</TableCell>
                          <TableCell component="th" scope="row">
                            {row.productName}
                          </TableCell>
                          <TableCell style={{ color: 'red' }}> {row?.isSubscripton && "Enterprise License"}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                {!productList && (
                  <div className={classes.noRecordFound}>
                    <Typography>No Record Found!</Typography>
                  </div>
                )}
              </TableContainer>
            </div>

            <div className="row">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                pageClassName="paginationReact"
                activeClassName="activeClass"
                previousClassName="previousClass"
                nextClassName="nextClass"
                containerClassName="containerClass"
              />
            </div>


          </div>
        </div>
      </div>
    </>
  );
};
Main.propTypes = {
  className: PropTypes.string
};

export default Main;
