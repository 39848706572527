import {
  Box, Card, Divider, Grid,
  makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import QuestionAnswersBuyer from '../../../components/QuestionAnswersBuyer/index';
import RfpSettings from './RfpSettings';
import RfpTimeLine from './TimeLine';
import VendorResponses from './VendorResponses';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useStyles = makeStyles(({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: '6px 16px',
  }
}));

const BasicRfpConfiguration = ({ className, rfpId, rfpTimeLineState, rfpSettingState, rfpVendorInviteState, vendorResponseState, vendorOrginalResponseState, setVendorResponseState, vendorFilterState, setVendorFilterState, selectedFilterState, updateSelectedFilterState, saveCategoryAns, vendorResponseCommercialState, updateSelectedFilterCommercialState, selectedFilterStateCommercial, vendorFilterStateCommercial, setVendorResponseCommercialState, ...rest }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div >
      <Typography variant="h2" style={{ background: "#fff", padding: '10px' }}>RFP Dashboard{/*<Button color="primary" type="button" variant="contained" style={{ float: 'right' }} onClick={e => history.push('/app/dashboard')}>
        Go Back
            </Button>*/}
      </Typography>

      <Typography variant="h2" style={{ background: "#fff", paddingLeft: '10px' }}>&nbsp;</Typography>
      <Typography variant="h5" style={{ background: "#fff", paddingLeft: '10px' }}>Overview</Typography>
      <RfpTimeLine
        timelineItems={rfpTimeLineState.timelineItems}
        currentStageNumber={rfpTimeLineState.currentStageNumber}
      />
      {/*<Divider />
      <DateTimeLine
        dayList={rfpTimeLineState.dayList}
        timelineItems={rfpTimeLineState.timelineItems}
        currentStageNumber={rfpTimeLineState.currentStageNumber} />*/}

      <div className={classes.root}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="HOME" {...a11yProps(0)} />
            <Tab label="VENDOR RESPONSES" {...a11yProps(1)} />
            <Tab label="RFP SETTINGS" {...a11yProps(2)} />
            <Tab label="VENDOR QUESTIONS" {...a11yProps(3)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={3}>
          <QuestionAnswersBuyer rfpId={rfpId} />
        </TabPanel>


        <TabPanel value={value} index={0}>
          <Grid style={{ "margin-top": " 20px", }} container spacing={3}>
            <Grid style={{ "padding": " 0 10px", }} spacing={3} md={3} item sm={6} xs={12}>
              <Card style={{ "padding": "15px", "fontSize": "12px", }} >
                <Typography style={{ "font-size": "12px", "font-weight": "600" }} align="left"> Stage </Typography>

                <Typography style={{ "font-size": "70px", "color": "#f29a31", }} align="center"> {rfpTimeLineState.currentStageNumber} </Typography>
                <Typography style={{ "font-size": "14px", }} align="center"> {rfpTimeLineState.currentStage.mileStoneName} </Typography>

                <Divider style={{ "margin": "10px 0" }} />

                <Typography style={{ "font-size": "12px", }} align="left"> Total Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpTimeLineState.totalStageNumber} </span>  </Typography>
                <Typography style={{ "font-size": "12px", }} align="left"> Next Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpTimeLineState.nextStage.mileStoneName} </span> </Typography>
              </Card>
            </Grid>
            <Grid style={{ "padding": " 0 10px", }} spacing={3} md={3} item sm={6} xs={12}>
              <Card style={{ "padding": "15px", "fontSize": "12px", }} >
                <Typography style={{ "font-size": "12px", "font-weight": "600" }} align="left"> Invites </Typography>

                <Typography style={{ "font-size": "70px", "color": "#3f83f5", }} align="center"> {rfpVendorInviteState.acceptedRFPCount} </Typography>
                <Typography style={{ "font-size": "14px", }} align="center"> Accepted </Typography>

                <Divider style={{ "margin": "10px 0" }} />

                <Typography style={{ "font-size": "12px", }} align="left"> Sent : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpVendorInviteState.totalInvited} </span>  </Typography>
                <Typography style={{ "font-size": "12px", }} align="left"> Rejected : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpVendorInviteState.rejectedRFPCount} </span> </Typography>
              </Card>
            </Grid>
            <Grid style={{ "padding": " 0 10px", }} spacing={3} md={3} item sm={6} xs={12}>
              <Card style={{ "padding": "15px", "fontSize": "12px", }} >
                <Typography style={{ "font-size": "12px", "font-weight": "600" }} align="left"> Timeline </Typography>

                <Typography style={{ "font-size": "70px", "color": "#ec533a", }} align="center"> {rfpTimeLineState.daysToComplete} </Typography>
                <Typography style={{ "font-size": "14px", }} align="center"> Days to Completion </Typography>

                <Divider style={{ "margin": "10px 0" }} />

                <Typography style={{ "font-size": "12px", }} align="left"> Current Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}>  {rfpTimeLineState.daysToCompleteInCurrentStage} days </span> </Typography>
                <Typography style={{ "font-size": "12px", }} align="left"> Next Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpTimeLineState.daysToCompleteInNextStage} days </span></Typography>
              </Card>
            </Grid>
            <Grid style={{ "padding": " 0 10px", }} spacing={3} md={3} item sm={6} xs={12}>
              <Card style={{ "padding": "15px", "fontSize": "12px", }} >
                <Typography style={{ "font-size": "12px", "font-weight": "600" }} align="left"> Completion </Typography>

                <Typography style={{ "font-size": "70px", "color": "#487b40", }} align="center"> {Math.round(rfpTimeLineState.completedPercent)}% </Typography>
                <Typography style={{ "font-size": "14px", }} align="center"> Completion % </Typography>

                <Divider style={{ "margin": "10px 0" }} />

                <Typography style={{ "font-size": "12px", }} align="left"> Current Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}>{rfpTimeLineState.daysToCompleteInCurrentStage} days </span> </Typography>
                <Typography style={{ "font-size": "12px", }} align="left"> Next Stage : <span style={{ "width": "50%", "float": "right", "font-weight": "600" }}> {rfpTimeLineState.daysToCompleteInNextStage} days </span></Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid style={{ "margin-top": "30px", "margin-bottom": "10px" }} container spacing={3}>
            <Grid spacing={3} md={12} item sm={12} xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead style={{ "background-color": "#40506b" }}>
                    <TableRow>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Vendor </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Invite Acceptance </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> NDA </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Technical Score </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Commercial Score </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Price </TableCell>
                      <TableCell style={{ "color": "#fff", "text-align": "center" }}> Overall Score </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rfpVendorInviteState.vendors.map(vendor => (
                      <TableRow>
                        <TableCell component="th" style={{ "text-align": "center" }}> {vendor.companyName}
                          {
                            (vendor.selectedProducts !== undefined) ?

                              (vendor.selectedProducts[vendor.companyIds] !== undefined)
                                ?
                                "(" + vendor.selectedProducts[vendor.companyIds].join() + ")"
                                :
                                ''
                              :
                              ''
                          }
                        </TableCell>
                        <TableCell style={{ "text-align": "center" }}> {vendor.isRfpAccepted} </TableCell>
                        <TableCell style={{ "text-align": "center" }}> {vendor.nda} </TableCell>
                        <TableCell style={{ "text-align": "center" }}> {vendor.technicalScore} %</TableCell>
                        <TableCell style={{ "text-align": "center" }}> {vendor.commercialScore} %</TableCell>
                        <TableCell style={{ "text-align": "center" }}> {`${formatter.format(vendor.price)}`} </TableCell>
                        <TableCell style={{ "text-align": "center" }}> {vendor.overallScore} % </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <VendorResponses
            vendorResponseState={vendorResponseState}
            setVendorResponseState={setVendorResponseState}
            vendorFilterState={vendorFilterState}
            setVendorFilterState={setVendorFilterState}
            selectedFilterState={selectedFilterState}
            selectedFilterStateCommercial={selectedFilterStateCommercial}
            updateSelectedFilterState={updateSelectedFilterState}
            updateSelectedFilterCommercialState={updateSelectedFilterCommercialState}
            saveCategoryAns={saveCategoryAns}
            vendorOrginalResponseState={vendorOrginalResponseState}
            vendorResponseCommercialState={vendorResponseCommercialState}
            vendorFilterStateCommercial={vendorFilterStateCommercial}
            setVendorResponseCommercialState={setVendorResponseCommercialState}
          />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <RfpSettings rfpId={rfpId} rfpSettingState={rfpSettingState} />
        </TabPanel>
      </div>
      <Divider />
    </div>
  );
};

BasicRfpConfiguration.propTypes = {
  className: PropTypes.string
};

export default BasicRfpConfiguration;
