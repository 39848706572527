export class RfpTimeLineModel {
  startDate = new Date('Y-m-d');
  endDate = new Date('Y-m-d');
  dayList = [];
  currentStage = { mileStoneName: '', mileStoneDate: new Date().toISOString().slice(0, 10) };
  nextStage = { mileStoneName: '', mileStoneDate: new Date().toISOString().slice(0, 10) };
  currentStageNumber = 0;
  totalStageNumber = 0;
  daysToComplete = 0;
  daysToCompleteInCurrentStage = 0;
  daysToCompleteInNextStage = 0;
  completedPercent = 0;
  timelineItems = [
    { mileStoneName: 'Accept Invite & NDA', description: '', mileStoneDate: '2020-10-03', isFixed: true },
    { mileStoneName: 'Vendor Questions', description: '', mileStoneDate: '2020-10-07', isFixed: true },
    { mileStoneName: 'Feedback', description: '', mileStoneDate: '2020-10-09', isFixed: true },
    { mileStoneName: 'RFP Submission', description: '', mileStoneDate: '2020-10-10', isFixed: true },
    { mileStoneName: 'Short List', description: '', mileStoneDate: '2020-10-11', isFixed: true },
    { mileStoneName: 'Final Presentation', description: '', mileStoneDate: '2020-10-12', isFixed: true },
    { mileStoneName: 'Selection and Contract Agreement', description: '', mileStoneDate: '2020-10-13', isFixed: true },
    { mileStoneName: 'Project Start', description: '', mileStoneDate: '2020-10-17', isFixed: true }
  ];
  constructor(rfpDashBaordInfo = null) {
    if (rfpDashBaordInfo) {
      this.timelineItems = rfpDashBaordInfo || this.timelineItems;
      if (this.timelineItems.length > 0) {
        this.startDate = this.timelineItems[0].mileStoneDate;
        this.endDate = this.timelineItems[(this.timelineItems.length - 1)].mileStoneDate;
      }
      this.dayList = this.getDaysArray(new Date(this.startDate), new Date(this.endDate));
      var diffdate = new Date();
      diffdate.setDate(diffdate.getDate() - 1);
      var afterTimeLineItems = this.timelineItems.filter(function (d) {
        return new Date(d.mileStoneDate) - diffdate > 0;
      });
      if (afterTimeLineItems.length > 0) {
        this.currentStage = afterTimeLineItems[0];
      } else {
        this.currentStage = this.timelineItems[this.timelineItems.length - 1];
      }
      this.totalStageNumber = this.timelineItems.length;


      this.currentStageNumber = this.timelineItems.findIndex(timeLine => timeLine.mileStoneName === this.currentStage.mileStoneName) + 1;

      if (new Date(this.timelineItems[0].mileStoneDate).getTime() > new Date().getTime()) {
        this.completedPercent = (0 / this.totalStageNumber) * 100;
      } else {
        this.completedPercent = (this.currentStageNumber / this.totalStageNumber) * 100;
      }

      if (afterTimeLineItems.length > 1) {
        this.nextStage = afterTimeLineItems[1];
      } else { this.nextStage = { mileStoneName: '', mileStoneDate: new Date().toISOString().slice(0, 10) }; }
      this.daysToComplete = this.getDiffAsDays(new Date(), new Date(this.timelineItems[this.timelineItems.length - 1].mileStoneDate));
      if (this.totalStageNumber > this.currentStageNumber) {
        this.daysToCompleteInCurrentStage = this.getDiffAsDays(new Date(), new Date(this.timelineItems[this.currentStageNumber - 1].mileStoneDate));
      }
      if (this.totalStageNumber > this.currentStageNumber + 1) {
        this.daysToCompleteInNextStage = this.getDiffAsDays(new Date(), new Date(this.timelineItems[this.currentStageNumber].mileStoneDate));
      }
    }
  }

  getDaysArray = function (start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };


  getDiffAsDays = function (date1, date2) {
    if (date2.getTime() <= date1.getTime()) {
      return 0;
    }
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
}
