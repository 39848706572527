import {
  Accordion,
  AccordionDetails, AccordionSummary, Avatar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  Input, InputAdornment, Tab, Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TablePagination, TableRow,
  Tabs, TextField, Typography
} from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Clear from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import algoliasearch from 'algoliasearch/lite';
import { Formik } from 'formik';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { algoliaIndex } from 'src/utils/algoliadata';
import { alertService } from '../../components/toaster/alert.service';
import './style.css';
import { useStyles } from './VendorInviteStyle';
import { VendorInviteValidations } from './VendorInviteValidations';


const searchClient = algoliasearch('JE3UA8IZ0R', 'dfb17da784818795fc420e50bfb6cfdb');
const searchIndex = searchClient.initIndex(algoliaIndex);

const VendorInvite = ({ handleNext, handleSkip, handleBack, className, rfpId, completed, currentStepCompleteStatus, vendorInviteState, setVendotInviteState, step, totalStepCount, deleteEmail, ...rest }) => {
  const classes = useStyles();

  const [searchCompanyName, setSearchCompanyName] = useState('');
  const [emailIndex, setEmailIndex] = useState(null);
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [emailList, setEmailList] = useState([]);
  const [categories, setCategories] = useState({});
  const [subCategories, setSubcategories] = useState({});
  const [allKeywords, setAllKeywords] = useState({});
  const [company, setCompany] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedAllKeywords, setSelectedAllKeywords] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [nbPages, setNbPages] = useState(0);
  const [product, setProduct] = useState([]);
  const [temp_string, setTempString] = useState('');
  const [open, setOpen] = useState(false);

  const fieldType = {
    COMPANY: 'COMPANY'
  };
  useEffect(() => {

    const companies = vendorInviteState.companyIds?.map((datum,) => ({
      _id: datum?._id ?? datum,
      email: datum?.companyName ?? `Company - ${datum?._id ?? datum}`,
      product: datum?.product ?? `${(vendorInviteState.selectedProductsNew[datum?._id] !== undefined) ? vendorInviteState.selectedProductsNew[datum?._id].join(', ') ?? datum : ''}`,
      type: fieldType.COMPANY,
      isStoredOnServer: true
    })) ?? [];
    console.log(vendorInviteState);
    ////alert('hi');
    setEmailList([...vendorInviteState.emails, ...companies]);
  }, [JSON.stringify(vendorInviteState.emails), JSON.stringify(vendorInviteState.companyIds), vendorInviteState.companyIds])

  var type = '';

  const getProductList = async () => {
    await searchIndex
      .search(searchCompanyName ?? '', {
        attributesToRetrieve: [
          'product',
          'category',
          'subCategory',
          'description',
          'logo',
          'company',
          'companyId'
        ],
        facets: ['category', 'subCategory', 'allKeywords', 'company'],
        facetFilters: [selectedCategories, selectedSubCategories, selectedAllKeywords, selectedCompany],
        hitsPerPage: 20,
        page
      })
      .then(({
        hits, facets, nbPages, page, nbHits
      }) => {
        const productDetail = hits;
        const { category, subCategory, allKeywords, company } = facets;
        // console.log(category);
        //alert('bye');
        category ? setCategories(category) : setCategories([]);
        subCategory ? setSubcategories(subCategory) : setSubcategories([]);
        allKeywords ? setAllKeywords(allKeywords) : setAllKeywords([]);
        company ? setCompany(company) : setCompany([]);

        setProduct(productDetail);
        setNbPages(nbPages);
        setOptions(productDetail);
      })
      .catch((err) => {
        console.log(':: err', err);
      });
  };

  useEffect(() => {
    getProductList();
  }, [
    searchCompanyName,
    selectedCategories,
    selectedSubCategories,
    page,
    selectedCompany,
    selectedAllKeywords
  ])


  // search via algolia for search bar
  const searchFromAlgolia = async (val) => {
    if (val.length < 3) {
      return;
    }
    await searchIndex
      .search(val, {
        attributesToRetrieve: [
          'product',
          'category',
          'subCategory',
          'description',
          'logo',
          'company',
          'companyId'
        ],
        facets: ['category', 'subCategory', 'allKeywords', 'company'],
        hitsPerPage: 20
      })
      .then(async ({ hits, facets }) => {
        hits = hits.slice(0, 4);
        const products = hits;
        const { category, subCategory } = facets;
        if (category) {
          // eslint-disable-next-line no-unused-expressions
          Object.keys(category)[0]
            ? products.push({
              category: Object.keys(category)[0]
            })
            : '';
          // eslint-disable-next-line no-unused-expressions
          Object.keys(category)[1]
            ? products.push({
              category: Object.keys(category)[1]
            })
            : '';
        }
        if (subCategory) {
          // eslint-disable-next-line no-unused-expressions
          Object.keys(subCategory)[0]
            ? products.push({
              subCategory: Object.keys(subCategory)[0]
            })
            : '';
          // eslint-disable-next-line no-unused-expressions
          Object.keys(subCategory)[1]
            ? products.push({
              subCategory: Object.keys(subCategory)[1]
            })
            : '';
        }
        setOptions(products);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const changeCategoryFilter = (category, event) => {
    const cat = category;
    const e = event.target.checked;
    if (e) {
      setSelectedCategories([...selectedCategories, cat]);
      setPage(0);
    } else {
      setSelectedCategories([...selectedCategories.filter((item) => item !== cat)]);
      setPage(0);
    }
  };

  const changeCompanyFilter = (companyParam, event) => {
    const cat = companyParam;
    const e = event.target.checked;
    if (e) {
      setSelectedCompany([...selectedCompany, cat]);
      setPage(0);
    } else {
      setSelectedCompany(selectedCompany.filter((item) => item !== cat));
      setPage(0);
    }
  };
  const handleOpen = () => {
    if (temp_string.length > 1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const changeAllKeywords = (keywords, event) => {
    const cat = keywords;
    const e = event.target.checked;
    if (e) {
      setSelectedAllKeywords([...selectedAllKeywords, cat]);
      setPage(0);
    } else {
      setSelectedAllKeywords(selectedAllKeywords.filter((item) => item !== cat));
      setPage(0);
    }
  };

  const changeSubCategoryFilter = (category, event) => {
    event.persist();
    if (event.target.checked) {
      setSelectedSubCategories([...selectedSubCategories, category]);
      setPage(0);
    } else {
      setSelectedSubCategories(selectedSubCategories.filter((item) => item !== category));
      setPage(0);
    }
  };

  const handlePageClick = (data) => {
    setPage(data.selected);
  };

  // go to company page (for search bar)
  const goToCompanyPage = async (val) => {
    if (val && val.product) {
      const query = {
        search: val.product,
        category: [].join(','),
        subCategory: [].join(','),
        allKeywords: [].join(','),
        company: [].join(',')
      };
      const url = `/product?${queryString.stringify(query)}`;
      const win = window.open(url, '_blank');
      if (win != null) {
        win.focus();
      }
    }
  };

  return (
    <Formik
      initialValues={vendorInviteState}
      enableReinitialize={true}
      validationSchema={VendorInviteValidations}
      onSubmit={(values) => {
        if (emailList.length === 0) {
          const autoClose = true;
          const keepAfterRouteChange = false
          window.scrollTo(0, 0)
          alertService.error('Please add at least 1 Vendor', { autoClose, keepAfterRouteChange })
        } else {
          values.type = type;
          values.step = step;
          const emails = emailList.filter(datum => !datum.type || datum.type !== fieldType.COMPANY);

          const companyIds = emailList
            .filter(datum => datum.type && datum.type === fieldType.COMPANY)
            .map(datum => datum._id);

          const selectedProducts = emailList
            .filter(datum => datum.type && datum.type === fieldType.COMPANY)
            .map(datum => datum.product);
          var selected_temp = {}
          var selectedProductId_temp = {}
          emailList.map((email) => {
            if (selected_temp[email._id] === undefined) {
              selected_temp[email._id] = [];
              selected_temp[email._id].push(email.product);

              selectedProductId_temp[email._id] = [];
              selectedProductId_temp[email._id].push(email.productId);
            } else {
              selected_temp[email._id].push(email.product);
              selectedProductId_temp[email._id].push(email.productId);
            }

          })

          const withOutEmailValues = omit(values, 'emails')
          console.log(selectedProducts);
          handleNext({ emails, companyIds: [...companyIds], selectedProducts: selected_temp, selectedProductsIds: selectedProductId_temp, ...omit(withOutEmailValues, 'companyIds') });
        }

      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <div className={'requestly-rfp-form p-0'}>
          <Card className={classes.CardBorder + ' company-info-div'} style={{ width: '960px', maxWidth: '100%' }}>
            <CardContent className={classes.cardScrollArea + ' p-0'}>
              <CardHeader subheader="Select the  vendors you would like to include in your RFP"
                title="Vendor Invite"
                className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'} />
              <Divider />
              <CardContent>
                <Grid className={classes.item + ' requstly-pragraph-css'} item md={12} sm={12} xs={12} style={{ marginBottom: '20px' }}>

                  <Typography >Select from the list of recommended vendors or search for a vendor.</Typography>

                </Grid>

                <Box className="mt-3">
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid xl={3} lg={3} md={3} sm={3}>
                    </Grid>
                  </Grid>
                  <TableContainer>
                    <h4 type="heading" className="font-family">Invitee List</h4>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: '80%' }} className="font-family" >Name</TableCell>
                          <TableCell style={{ width: '20%' }} className="font-family" >Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          emailList.length ? (rowsPerPage > 0
                            ? emailList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : emailList).map((datumEmail, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell style={{ width: '80%' }}>
                                    {
                                      (datumEmail?.product)
                                        ?
                                        datumEmail?.product
                                        : ''
                                    }
                                    {(!datumEmail?.email || email !== '') && emailIndex === index ? (
                                      <TextField
                                        id="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(event) => {
                                          setEmail(event.target.value);
                                        }}
                                        style={{ width: '100%' }}
                                      />
                                    ) : " (" + datumEmail?.email + ")"}

                                  </TableCell>
                                  <TableCell style={{ width: '20%' }}>
                                    {(!datumEmail?.email || email !== '') && index === emailIndex ? (
                                      <>
                                        <Check
                                          display="inline"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            if (!datumEmail?.email) {
                                              const newData = emailList[index];
                                              newData.email = email;
                                              newData.rfpId = rfpId;
                                              newData.rfpStatus = 0;
                                              emailList.splice(index, 1, newData);
                                            } else {
                                              const newData = emailList[index];
                                              newData.email = email;
                                              emailList.splice(index, 1, newData);
                                            }
                                            setEmail('');
                                            setEmailIndex(null);
                                            setEmailList([...emailList]);
                                          }}
                                        />
                                        <Clear
                                          display="inline"
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            if (!datumEmail?.email) {
                                              emailList.splice(index, 1);
                                            }
                                            setEmail('');
                                            setEmailIndex(null);
                                            setEmailList([...emailList]);
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {!datumEmail.type || datumEmail.type !== fieldType.COMPANY ? (
                                          <Edit
                                            display="inline"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              setEmail(datumEmail?.email);
                                              setEmailIndex(index);
                                            }}
                                          />
                                        ) : null}
                                        {!datumEmail.isStoredOnServer ? (
                                          <DeleteOutline
                                            className="site-delete-icon"
                                            display="inline"
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              emailList.splice(index, 1);
                                              setEmail('');
                                              setEmailIndex(null);
                                              setEmailList([...emailList]);
                                            }}
                                          />
                                        ) : null}
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            : (
                              <TableRow>
                                <TableCell colSpan={2} style={{ width: '100%' }}>
                                  <Typography align="center" variant="subtitle2" color="#000" className="font-family">
                                    No Record Found
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {emailList.length ? (
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={emailList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  ) : null}
                </Box>
                <Box mt={3}>
                  <Grid className={classes.finalBoxTable}>
                    <Card>
                      <CardContent>
                        <Tabs
                          value={selectedTab}
                          onChange={(event, value) => {
                            setSelectedTab(value);
                          }}
                          aria-label="simple tabs example"
                        >
                          <Tab label="Company List" className="font-family" />
                          {/* <Tab label="Invited List" />*/}
                        </Tabs>
                        <Box
                          role="tabpanel"
                          hidden={selectedTab !== 0}
                          id={`full-width-tabpanel-0`}
                          aria-labelledby={`full-width-tab-0`}
                        >
                          <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} style={{ zIndex: '999' }}>
                              <div className={`${classes.searchBarWrap} searchBarProduct`}>

                                <Autocomplete
                                  open={open}
                                  onOpen={handleOpen}
                                  onClose={() => setOpen(false)}
                                  id="custom-input-demo"
                                  value={searchCompanyName}
                                  freeSolo
                                  classes={''}

                                  getOptionLabel={(option) => (typeof option === 'string' ? `${searchCompanyName}` : `${searchCompanyName}`)}
                                  filterOptions={(x) => x}
                                  options={options}
                                  disableClearable
                                  openOnFocus={false}
                                  onChange={async (event, newValue) => {
                                    const companyName = newValue.product || newValue.category || newValue.subCategory || newValue
                                    setSearchCompanyName(companyName);
                                    setSelectedAllKeywords([]);
                                    setSelectedCategories([]);
                                    setSelectedCompany([]);
                                    setSelectedSubCategories([]);
                                  }}
                                  onInputChange={(e, val) => {
                                    if (val.length === 0) {
                                      setOpen(false)
                                    }
                                    setTempString(val)
                                    searchFromAlgolia(val);
                                  }}
                                  renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                      <Input
                                        {...params.inputProps}
                                        className={classes.searchInputStyle}
                                        variant="filled"
                                        type="search"
                                        placeholder="Search vendors, industry, category, keywords"
                                        startAdornment={
                                          <InputAdornment position="start">
                                            <SearchIcon className={classes.iconSize} />
                                          </InputAdornment>
                                        }
                                      />

                                    </div>
                                  )}

                                  renderOption={(option) => {
                                    return (
                                      <Grid
                                        container
                                        alignItems="center"
                                        className="autocomplete-margin-class"
                                      >

                                        <Typography variant="body2" className={classes.autoCompleteTextStyle}>
                                          {option && option.product
                                            ? `Product: ${option.product}`
                                            : `Category: ${option.category ? option.category : option.subCategory
                                            }`}
                                        </Typography>
                                      </Grid>
                                    );
                                  }}

                                />
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container className={`${classes.containerPadding}`}>
                            <Grid container>
                              <Grid item xs={12} sm={4} lg={4} md={4} className={`${classes.gridStyle}`}>
                                <Typography className={classes.filterByText + ' font-family'}>
                                  Filter by
                                </Typography>
                                <Accordion
                                  defaultExpanded={true}
                                  className="requestly-fin-info-tab">
                                  <AccordionSummary

                                    expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionFilter}
                                  >
                                    <Typography
                                      className={classes.filterListText + ' font-family'}
                                      style={{ fontSize: '12px !important' }}
                                    >
                                      Category
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container className="algolia-cat-block">
                                      {Object.keys(categories).map((key, index) => {
                                        return (
                                          <>
                                            <Grid item xs={3} sm={3} lg={3} md={3}>
                                              <Checkbox
                                                disableRipple
                                                display="inline"
                                                className="checkbox-vendor"
                                                checked={selectedCategories.includes(`category:${key}`)}
                                                onChange={(event) => {
                                                  changeCategoryFilter(`category:${key}`, event);
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              />
                                            </Grid>
                                            <Grid item xs={9} sm={9} lg={9} md={9}>
                                              <Typography display="inline" className={classes.filterItem}>
                                                {`${key} (${categories[key]})`}
                                              </Typography>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion
                                  defaultExpanded={true}
                                  className="requestly-fin-info-tab">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionFilter}
                                  >
                                    <Typography
                                      className={classes.filterListText + ' font-family'}
                                      style={{ fontSize: '12px !important' }}
                                    >
                                      Sub Category
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container className="algolia-cat-block">
                                      {Object.keys(subCategories).map((key, index) => {
                                        return (
                                          <>
                                            <Grid item xs={4} sm={4} lg={4} md={4}>
                                              <Checkbox
                                                disableRipple
                                                display="inline"
                                                className="checkbox-vendor"
                                                checked={selectedSubCategories.includes(`subCategory:${key}`)}
                                                onChange={(event) => {
                                                  changeSubCategoryFilter(`subCategory:${key}`, event);
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              />
                                            </Grid>
                                            <Grid item xs={8} sm={8} lg={8} md={8}>
                                              <Typography display="inline" className={classes.filterItem}>
                                                {`${key} (${subCategories[key]})`}
                                              </Typography>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion
                                  defaultExpanded={true}
                                  className="requestly-fin-info-tab">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionFilter}
                                  >
                                    <Typography
                                      className={classes.filterListText + ' font-family'}
                                      style={{ fontSize: '12px !important' }}
                                    >
                                      Company
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container className="algolia-cat-block">
                                      {Object.keys(company).map((key, index) => {
                                        return (
                                          <>
                                            <Grid item xs={4} sm={4} lg={4} md={4}>
                                              <Checkbox
                                                disableRipple
                                                display="inline"
                                                className="checkbox-vendor"
                                                checked={selectedCompany.includes(`company:${key}`)}
                                                onChange={(event) => {
                                                  changeCompanyFilter(`company:${key}`, event);
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              />
                                            </Grid>
                                            <Grid item xs={8} sm={8} lg={8} md={8}>
                                              <Typography display="inline" className={classes.filterItem}>
                                                {`${key} (${company[key]})`}
                                              </Typography>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                                <Accordion
                                  defaultExpanded={true}
                                  className="requestly-fin-info-tab">
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.accordionFilter}
                                  >
                                    <Typography
                                      className={classes.filterListText + ' font-family'}
                                      style={{ fontSize: '12px !important' }}
                                    >
                                      Keywords
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container className="algolia-cat-block">
                                      {Object.keys(allKeywords).map((key, index) => {
                                        return (
                                          <>
                                            <Grid item xs={4} sm={4} lg={4} md={4}>
                                              <Checkbox
                                                disableRipple
                                                display="inline"
                                                className="checkbox-vendor"
                                                checked={selectedAllKeywords.includes(`allKeywords:${key}`)}
                                                onChange={(event) => {
                                                  changeAllKeywords(`allKeywords:${key}`, event);
                                                }}
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                              />
                                            </Grid>
                                            <Grid item xs={8} sm={8} lg={8} md={8}>
                                              <Typography display="inline" className={classes.filterItem}>
                                                {`${key} (${allKeywords[key]})`}
                                              </Typography>
                                            </Grid>
                                          </>
                                        );
                                      })}
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>

                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                lg={8}
                                md={8}
                                className={`${classes.gridStyle} ${classes.gridStyleList}`}
                              >
                                {product.map((detail, index) => {
                                  return (
                                    <div>
                                      {index !== 0 ? (<Divider />) : null}
                                      <Box
                                        className={`${classes.listItemDetailStyle}`}
                                        key={detail.objectID}
                                      >
                                        <Grid container>
                                          <Grid item xs={2} sm={2} lg={2} md={2}>
                                            <Box
                                              classes={{
                                                root: classes.productImage
                                              }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => goToCompanyPage({ product: detail.product })}
                                            >

                                              {
                                                detail.squareLogo && detail.squareLogo !== ''
                                                  && (detail.squareLogo !== "https://requestly-media-public.s3.eu-west-2.amazonaws.com/company_logos/undefined")
                                                  ? (
                                                    <Avatar classes={{
                                                      img: classes.logoFit
                                                    }} alt="Logo" src={detail.squareLogo} />
                                                  ) :
                                                  detail.logo && detail.logo !== ''
                                                    && (detail.logo !== "https://requestly-media-public.s3.eu-west-2.amazonaws.com/company_logos/")
                                                    ? (
                                                      <Avatar classes={{
                                                        img: classes.logoFit
                                                      }} alt="Logo" src={detail.logo} />
                                                    ) : <div style={{ height: "30px", width: "30px", backgroundColor: "black" }}></div>
                                              }
                                            </Box>
                                          </Grid>
                                          <Grid item xs={10} sm={10} lg={10} md={10}>
                                            <Grid container>
                                              <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                lg={6}
                                                md={6}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => goToCompanyPage({ product: detail.product })}
                                              >
                                                <Typography className={classes.productNameStyle}>
                                                  {detail.product}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={6} sm={6} lg={6} md={6}>
                                                {emailList.find(datum => datum.product === detail.product) ? (
                                                  <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    className={classes.selectedVendorContainer}
                                                    onClick={(event) => {
                                                      const index = emailList.findIndex(datum => datum._id === detail.company || datum._id === detail.companyId);
                                                      emailList.splice(index, 1);
                                                      setEmailList([...emailList]);
                                                    }}
                                                  >
                                                    <Typography className={classes.selectedVendorText}>
                                                      Selected
                                                    </Typography>
                                                    <CloseIcon
                                                      className={classes.selectedVendorIcon}
                                                    />
                                                  </Box>
                                                ) : (
                                                  <Button
                                                    className={classes.inviteVendorBtn + ' font-family'}
                                                    onClick={() => {
                                                      console.log(detail);
                                                      emailList.push({
                                                        _id: detail.companyId ?? detail.company,
                                                        email: detail.company,
                                                        type: fieldType.COMPANY,
                                                        product: detail.product,
                                                        productId: detail.objectID,
                                                      })
                                                      setEmailList([...emailList]);
                                                    }}
                                                  >
                                                    Invite Vendor
                                                  </Button>
                                                )}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                lg={12}
                                                md={12}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => goToCompanyPage({ product: detail.product })}
                                              >
                                                <Typography className={classes.productOverview + ' font-family'}>
                                                  {detail.description && detail.description.replace(/<[^>]+>/g, '')}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </div>
                                  );
                                })}
                              </Grid>
                              {nbPages > 1 ? (
                                <Grid container justify="center">
                                  <ReactPaginate
                                    previousLabel="<"
                                    nextLabel=">"
                                    breakLabel="..."
                                    breakClassName="break-me"
                                    pageCount={nbPages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    forcePage={parseInt(page)}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    subContainerClassName="pages pagination"
                                    activeClassName="active"
                                  />
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Box>
              </CardContent>
            </CardContent>
            <Divider />
            <div class="footer-btn-fixed">
              <footer class="page-footer green">
                <Grid container spacing={2} justify="center">
                  {step !== 0 ? (
                    <Grid item md={3} sm={3} xs={3}>
                      <Button className={classes.BackButton + ' font-family'} color="primary" type="button" variant="contained" onClick={handleBack}>
                        Back
                      </Button>
                    </Grid>
                  ) : null}
                  {step !== totalStepCount ? (
                    <Grid item md={3} sm={3} xs={3}>
                      <Button className={classes.ButtonSkip + ' font-family'} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                        Skip
                      </Button>
                    </Grid>
                  ) : null}
                  {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                    (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                      </Grid>
                    ) : null}
                  {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                    (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                      </Grid>
                    ) : step === totalStepCount ?
                      (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                        </Grid>
                      ) : (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.NextButton + ' font-family'} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                        </Grid>
                      )}
                </Grid>
              </footer>
            </div>
          </Card>
        </div>
      )}
    </Formik>
  );
};

VendorInvite.propTypes = {
  className: PropTypes.string
};

export default VendorInvite;
