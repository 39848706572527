export class QuestionAnswerBuyerModel {  
        questions = [];  
        constructor(questions = null){  
                if (questions && questions.length) {
                        questions.forEach(question => {
                                        this.questions.push(new QuestionAnswerBuyer(question));
                         });
                }
        }    
}  

class QuestionAnswerBuyer { 
        vendorQuestionId= '';
        userId= ''; 
        questionId = '';
        question = '';
        answer  =  '';  
        rfpId= '';
        askQuestion = '';
        categoryName = '';
        sectionName = '';
        constructor(questionInfo = null){  
                if (questionInfo) {
                        this.vendorQuestionId = questionInfo.vendorQuestionId || this.vendorQuestionId;
                        this.categoryName = questionInfo.categoryName || this.categoryName;
                        this.sectionName = questionInfo.sectionName || this.sectionName;
                        this.userId = questionInfo.userId || this.userId;
                        this.questionId = questionInfo.questionId || this.questionId;
                        this.question = questionInfo.question || this.question;
                        this.answer = questionInfo.answer || this.answer;
                        this.rfpId = questionInfo.rfpId || this.rfpId;
                        this.askQuestion = questionInfo.askQuestion || this.askQuestion;
                }
        }    
}