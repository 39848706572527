/* eslint-disable */
import { Box, Container, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Page from "src/components/Page";
import EnquiryService from "src/services/enquiry/enquiry.service";
import RFPService from "src/services/rfp/rfp.service";
import { loaderService } from "../../../components/loader/loader.service";
import { getRfpByUserStart } from "../../../store/products/actions";
import data from "./data";
import ProductCard from "./ProductCard";
import Toolbar from "./Toolbar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: "100%"
  }
}));

const ProductList = ({ match, getRfpByUserStart, productData }) => {
  const classes = useStyles();
  const [products, setRfpState] = useState(data);
  const [productCopy, setRfpCopyState] = useState(data);
  const [sortOrderStatus, sortOrderStatusState] = useState(false);
  const [filterVal, filterValState] = useState("select");
  const [productDemoList, setProductDemoList] = useState();
  const [dicoveryMeetingList, setDiscoveryMeetingList] = useState();
  const [new_filter_val, setNewFilterVal] = useState("all");
  const [allData, setAllData] = useState([]);
  const [allDataCopy, setAllDataCopy] = useState([]);

  const rfpService = new RFPService();
  const enquiryService = new EnquiryService();
  const autoClose = false;
  const keepAfterRouteChange = true;
  const isNewUser = match.params.isFirstLogin;
  if (isNewUser == "true") {
    // alertService.success("Welcome on Requestly, please create RFP or add company information", { autoClose, keepAfterRouteChange })
  }

  const filterRfp = e => {
    const products = [...productCopy];
    switch (e.target.value) {
      case "completed":
        let filteredResult = products.filter(product => {
          return new Date(product.rfpTimeline) <= new Date();
        });
        setRfpState(filteredResult);
        break;
      case "inprogress":
        let filteredProducts = products.filter(product => {
          return new Date(product.rfpTimeline) >= new Date();
        });
        setRfpState(filteredProducts);
        break;
      case "all":
        setRfpState(products);
        break;
      default:
        setRfpState(products);
        break;
    }
  };

  const sortRfp = value => {
    filterValState(value);
    switch (value) {
      case "title":
        let productCopy = [...allDataCopy];
        productCopy.sort(function(a, b) {
          if (!sortOrderStatus) {
            return ((a.rfpName && a.rfpName.toLowerCase()) ||
              (a.prouductName && a.prouductName.toLowerCase()) ||
              (a.companyName && a.companyName.toLowerCase())) <
              ((b.rfpName && b.rfpName.toLowerCase()) ||
                (b.prouductName && b.prouductName.toLowerCase()) ||
                (b.companyName && b.companyName.toLowerCase()))
              ? 1
              : -1;
          } else {
            return ((a.rfpName && a.rfpName.toLowerCase()) ||
              (a.prouductName && a.prouductName.toLowerCase()) ||
              (a.companyName && a.companyName.toLowerCase())) >
              ((b.rfpName && b.rfpName.toLowerCase()) ||
                (b.prouductName && b.prouductName.toLowerCase()) ||
                (b.companyName && b.companyName.toLowerCase()))
              ? 1
              : -1;
          }
        });
        setAllData(productCopy);

        break;
      case "createddate":
        let productsCopy = [...allDataCopy];
        productsCopy.sort(function(a, b) {
          if (!sortOrderStatus) {
            return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1;
          } else {
            return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
          }
        });
        setAllData(productsCopy);

        // let dmCopy = [...dicoveryMeetingList];
        // dmCopy.sort(function (a, b) {
        //   if (!sortOrderStatus) {
        //     return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        //   } else {
        //     return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        //   }
        // })
        // setDiscoveryMeetingList(dmCopy);

        // let productDiscCopy = [...productDemoList];
        // productDiscCopy.sort(function (a, b) {
        //   if (!sortOrderStatus) {
        //     return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        //   } else {
        //     return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        //   }
        // })
        // setProductDemoList(productDiscCopy);

        break;

      case "publishdate":
        let productCopy2 = [...allDataCopy];
        productCopy2 = productCopy2.filter(el => el.isRfp);
        productCopy2.sort(function(a, b) {
          if (!sortOrderStatus) {
            return new Date(a.rfpPublishedDate) < new Date(b.rfpPublishedDate)
              ? 1
              : -1;
          } else {
            return new Date(a.rfpPublishedDate) > new Date(b.rfpPublishedDate)
              ? 1
              : -1;
          }
        });
        setAllData(productCopy2);
        break;
      default:
        setAllData(allDataCopy);
        break;
    }
  };

  useEffect(() => {
    sortRfp(filterVal);
  }, [sortOrderStatus]);

  const sortOrder = async e => {
    sortOrderStatusState(e.target.checked);
  };

  useEffect(() => {
    // rfpService.getrfpByUser().then(
    //   res => {
    //     if (res) {
    //       setRfpCopyState(res.rfp);
    //       setRfpState(res.rfp);
    //       if (res.rfp && res.rfp.length > 0) {
    //         res.rfp.forEach(element => {
    //           element.isRfp = true;
    //         });
    //       }
    //       refreshData(res.rfp)
    //     }
    //   }
    // )
    refreshData();
    getRfpByUserStart();
  }, []);

  const getProductDemoList = async () => {
    enquiryService
      .getProductDemoList()
      .then(res => {
        if (res && res.data) {
          setProductDemoList(res.data);
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const getDiscoveryMeetingList = async () => {
    enquiryService
      .getDiscoveryMeetingList()
      .then(res => {
        if (res && res.data) {
          setDiscoveryMeetingList(res.data);
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const combineRfpPDM = data => {
    setAllData(data);
    setAllDataCopy(data);
    loaderService.show(false);
  };

  const refreshData = async () => {
    loaderService.show(true);
    const promises = [
      new Promise((resolve, reject) => {
        rfpService
          .getrfpByUser()
          .then(res => {
            if (res.rfp && res.rfp.length > 0) {
              res.rfp.forEach(element => {
                element.isRfp = true;
              });

              setRfpCopyState(res.rfp);
              setRfpState(res.rfp);
            }
            resolve(res.rfp);
          })
          .catch(err => {
            console.log(":: err", err);
            reject();
          });
      }),

      new Promise((resolve, reject) => {
        enquiryService
          .getDiscoveryMeetingList()
          .then(res => {
            if (res && res.data && res.data.length > 0) {
              res.data.forEach(element => {
                element.isDm = true;
              });

              setDiscoveryMeetingList(res.data);
            }
            resolve(res.data);
          })
          .catch(err => {
            console.log(":: err", err);
            reject();
          });
      }),

      new Promise((resolve, reject) => {
        enquiryService
          .getProductDemoList()
          .then(res => {
            if (res && res.data && res.data.length > 0) {
              res.data.forEach(element => {
                element.isPd = true;
              });

              setProductDemoList(res.data);
            }
            resolve(res.data);
          })
          .catch(err => {
            console.log(":: err", err);
            reject();
          });
      })
    ];

    Promise.all(promises)
      .then(data => {
        console.log("handler", data);
        if (data && data.length > 0) {
          let tempData = [];
          tempData = tempData.concat(data[0], data[1], data[2]);
          combineRfpPDM(tempData);
        } else {
          loaderService.show(false);
        }
      })
      .catch(error => {
        loaderService.show(false);
        console.log(error);
      });
  };

  const filterRfpApi = e => {
    const filter = e.target.value;
    setNewFilterVal(e.target.value);

    if (
      e.target.value === "completed" ||
      e.target.value === "respondrfp" ||
      e.target.value === "updaterfp" ||
      e.target.value === "rfpdashboard" ||
      e.target.value === "rfpCompleted" ||
      e.target.value === "declined"
    ) {
      rfpService.getrfpByUser(e.target.value).then(res => {
        if (res) {
          res.rfp.forEach(element => {
            element.isRfp = true;
          });
          setRfpCopyState(res.rfp);
          setRfpState(res.rfp);
          if (filter === "declined") {
            let tempData = [];
            tempData = res.rfp.concat(dicoveryMeetingList, productDemoList);
            setAllData(tempData);
          } else {
            setAllData(res.rfp);
          }
          sortOrderStatusState(false);
          filterValState("select");
        }
      });
    } else {
      setAllData(allDataCopy);
    }
  };

  console.log("allData", allData);
  return products?.length > 0 ||
    dicoveryMeetingList?.length > 0 ||
    productDemoList?.length > 0 ? (
    <Page className={classes.root} title="Dashboard">
      <div id="rightContain">
        <Container maxWidth={false}>
          <Toolbar
            filterRfp={e => filterRfp(e)}
            sortRfp={e => sortRfp(e.target.value)}
            sortOrder={e => sortOrder(e)}
            filterRfpApi={filterRfpApi}
            productDemoList={productDemoList}
            dicoveryMeetingList={dicoveryMeetingList}
          />
          <Box mt={3}>
            <Grid container spacing={3}>
              {allData &&
                allData.length > 0 &&
                allData.map(product => (
                  <>
                    {/* RFP */}
                    {product?.isRfp
                      ? new_filter_val !== "pending" &&
                        new_filter_val !== "accepted" && (
                          <ProductCard
                            className={classes.productCard}
                            product={product}
                          />
                        )
                      : ((new_filter_val === "declined" &&
                          product.status === 2) ||
                          (new_filter_val === "pending" &&
                            product.status === 0) ||
                          (new_filter_val === "accepted" &&
                            product.status === 1) ||
                          new_filter_val === "all") && (
                          <ProductCard
                            className={classes.productCard}
                            product={product}
                            isDiscoveryMeeting={product?.isDm && true}
                            isProductDemo={product?.isPd && true}
                            isVendorPopUp={product.isEditable ? true : false}
                          />
                        )}
                  </>
                ))}
            </Grid>
          </Box>
          <Box mt={3} display="flex" justifyContent="center"></Box>
        </Container>
      </div>
    </Page>
  ) : (
    <Page className={classes.root} title="Products">
      <div id="rightContain">
        <Container maxWidth={false}>
          <Toolbar
            filterRfp={e => filterRfp(e)}
            sortRfp={e => sortRfp(e.target.value)}
            sortOrder={e => sortOrder(e)}
            filterRfpApi={filterRfpApi}
          />
          <Box mt={3}>
            <Grid container spacing={3}>
              Please click the + button to create your first request
            </Grid>
          </Box>
          <Box mt={3} display="flex" justifyContent="center"></Box>
        </Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  productData: state.product.productData.response
});

const mapDispatchToProps = dispatch => ({
  getRfpByUserStart: value => dispatch(getRfpByUserStart(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
