
export class NDAModel {  
  vendorMNDA = false;
  companyMNDA = false;
  requestlyMNDA = false;
  companyNDADocument="";
  companyNDADocument_docs="";
  isData = false;
  constructor(ndaInfo = null){  
          if (ndaInfo) {
                 this.isData = true;
                 this.companyNDADocument = ndaInfo.companyNDADocument || this.companyNDADocument;
                 this.companyNDADocument_docs = ndaInfo.companyNDADocument_docs || this.companyNDADocument_docs;
                  this.vendorMNDA = ndaInfo.vendorMNDA || this.vendorMNDA;
                  this.companyMNDA = ndaInfo.companyMNDA || this.companyMNDA;
                  this.requestlyMNDA = ndaInfo.requestlyMNDA || this.requestlyMNDA;
          }
  }    
}  