/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class ProductService {
  constructor() {
    this.config = new Configuration();
  }


  async addProduct(productObj) {
    //alert(productObj.companyId);
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    productObj.userId = userId;
    const url = this.config.baseUrl + this.config.addProduct;
    const formData = new FormData();
    for (let i = 0; i < productObj.file.length; i++) {
      formData.append('dataSheets', productObj.file[i]);
    }
    // formData.append('dataSheets',JSON.stringify(productObj.file));
    formData.append('productName', productObj.productName);
    formData.append('productInfo', JSON.stringify(productObj.productInfo));
    formData.append('keywords', JSON.stringify(productObj.keywords));
    formData.append('targetPersona', JSON.stringify(productObj.targetPersona));
    formData.append('competitors', JSON.stringify(productObj.competitors));
    formData.append('notableCustomers', JSON.stringify(productObj.notableCustomers));
    formData.append('productSolVideoUrl', JSON.stringify(productObj.productSolVideoUrl));
    formData.append('productLaunchDate', productObj.productLaunchDate);
    formData.append('pricingProductName', productObj.pricingProductName);
    formData.append('unit', productObj.unit);
    formData.append('userId', productObj.userId);
    formData.append('productId', productObj.productId);
    formData.append('companyId', productObj.companyId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return AxiosInstance.post(url, formData, config)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getProductByUser() {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.baseUrl + this.config.getProductbyUser + userId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }
  async getProductByUserNew(product_id) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.baseUrl + this.config.getProductbyUser + product_id;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async deleteDataSheet(fileName, id) {
    let reqObj = {}
    reqObj.fileName = fileName
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    reqObj.userId = userId;
    reqObj._id = id;
    const url = this.config.baseUrl + this.config.deleteDataSheet;
    return AxiosInstance.post(url, reqObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async publishProduct(product) {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.publish;
    return AxiosInstance.patch(url, { productId: "60d98befebfb4862d5645938", type: "publish" })
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }


  async getWouldLikeOptions() {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.wouldLikeOptions;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
      // Request made and server responded
      console.log(':: error ', error.response.data);
      // alert(error.response.data.message);
      console.log(':: error ', error.response.status);
      console.log(':: error ', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default ProductService;
