import { colors, createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',

      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      light: '#fff',
      main: '#F2564C',
      // main: colors.indigo[500]
    },
    secondary: {
      main: '#40506b'
    },
    text: {
      primary: '#40506b',
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});

export default theme;
