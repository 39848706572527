export class RfpDetailModel {
  acceptedRFPCount = 0;
  rejectedRFPCount = 0;
  totalInvited = 0;
  vendors = [];

  constructor(rfpDetail = null, invitedRFPSteper = null) {
    if (rfpDetail) {
      this.acceptedRFPCount = rfpDetail.acceptedRFPCount || this.acceptedRFPCount;
      this.rejectedRFPCount = rfpDetail.rejectedRFPCount || this.rejectedRFPCount;
      this.totalInvited = rfpDetail.totalVendor || this.totalInvited;
    }
    if (invitedRFPSteper) {
      invitedRFPSteper.forEach(invite => {
        console.log(invite);
        //alert('hi');
        this.vendors.push(new VendorDetailModel(invite));
      });
    }
  }
}

export class VendorDetailModel {
  vendorName = '';
  companyName = '';
  isRfpAccepted = 'No Action';
  nda = 'No';
  technicalScore = 0;
  commercialScore = 0;
  overallScore = 0;
  price = 0;
  selectedProducts = [];
  companyIds = '';
  constructor(vendorInvite = null) {
    if (vendorInvite) {
      this.vendorName = vendorInvite.user || this.vendorName;
      this.companyName = vendorInvite.companyName || this.companyName;
      if (vendorInvite.isRfpAccepted) {
        if (vendorInvite.isRfpAccepted === "1") {
          this.isRfpAccepted = 'Yes';
        } else if (vendorInvite.isRfpAccepted === "2") {
          this.isRfpAccepted = 'No';
        }
      }
      if (vendorInvite.nda) {
        this.nda = 'Yes';
      } else {
        this.nda = 'No';
      }
      this.technicalScore = vendorInvite.technicalScore || this.technicalScore;
      this.commercialScore = vendorInvite.commercialScore || this.commercialScore;
      this.overallScore = (this.technicalScore + this.commercialScore) / 2;
      this.price = vendorInvite.price || this.price;
      this.selectedProducts = vendorInvite.selectedProducts || this.selectedProducts;
      this.companyIds = vendorInvite.companyIds || this.companyIds;

    }
  }
}
