import DateFnsUtils from "@date-io/date-fns";
import { Button, Card, CardContent, CardHeader, Divider, Grid, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  DatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import parse from 'autosuggest-highlight/parse';
import { FieldArray, Formik } from 'formik';
import throttle from 'lodash/throttle';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormatCustom from 'src/components/formatter/formatter';
import RichText from 'src/components/RichText/RichText';
import CompanyService from 'src/services/company/company.service';
import 'src/views/shared/css/common.scss';
import { companyTypes, currencies } from 'src/views/shared/data/dropdowns.js';
import Thumb from '../../components/Thumb/Thumb.component';
import { customCompanyValidations } from '../../utils/utils';
import ErrorComponent from './../../components/ErrorComponent';
import { CompanyModel } from './CompanyModel';
import { useStyles } from './CompanyStyle';
import { CompanyValidations } from './CompanyValidations';
import './style.css';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const CompanyDetails = ({ handleNext, handleSave, handleSkip, handleBack, completed, totalStepCount, currentStepCompleteStatus = true, isSubmitView, className, companyState, setCompanyState, step, ...rest }) => {
  var buttonStyle = {
    backgroundColor: '#000',
  }

  const classes = useStyles();

  const defaultDate = "2010";
  const companyService = new CompanyService();
  const [hasError, setHasError] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  var type = '';

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const [error_values, setErrorValues] = React.useState(null);
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAlHpWIMqHXMAji1Kjp2c7AkjGViBwhLFg&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }
  React.useEffect(() => {
    if (error_values !== null) {
      customCompanyValidations(companyState).then((validation_response) => {
        setErrorValues(validation_response);
      });
    }
  }, [companyState])

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const updateState = async (event) => {
    console.log("called")
    await setCompanyState({ ...companyState, [event.target.name]: event.target.value });
  }

  const handleChangeKeywords = (keywords) => {
    companyState.keywords = keywords;
    setCompanyState(new CompanyModel(companyState));
  }

  const handleChangeFiscalYearMonth = (date) => {
    companyState.fiscalYearMonth = date;
    setCompanyState(new CompanyModel(companyState));
  }

  const handleOverviewChange = (richText) => {
    companyState.overview = richText;
  }

  const removeLocations = (index) => {
    companyState.locations.pop(index);
    setCompanyState(new CompanyModel(companyState));
  }

  const addLocations = () => {
    companyState.locations.push({ officeLocation: "", officeFunction: "" });
    setCompanyState(new CompanyModel(companyState));
  }

  const setFieldValue = (value) => {
    if (value !== undefined) {
      console.log(':: value ', value)
      companyState.logo = value;
      setCompanyState(new CompanyModel(companyState));
    }
  }

  const handleOfficeChange = (officeFunction, index, type) => {
    // type: 1 - Location , 2 - Function 
    const { locations } = companyState;
    const newLocations = locations.slice(0);
    if (type === 1) {
      newLocations[index].officeLocation = officeFunction;
    } else { newLocations[index].officeFunction = officeFunction; }
    companyState.locations = newLocations
    setCompanyState(new CompanyModel(companyState));
  }
  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  // console.log(completed, "completed...")
  // console.log(isSubmitView, "coming.....")
  // console.log(companyState, "companyState");
  // console.log(totalStepCount, "totalStepCount...");
  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={companyState}
        validationSchema={CompanyValidations}
        validator={() => ({})}
        onSubmit={async (values) => {
          console.log(values, "values");

          values.overview = companyState.overview;

          if (values.totalRevenue === '') {
            values.totalRevenue = 0;
          }
          if (values.currency === '') {
            values.currency = 'USD';
          }
          if (values.companyType === '') {
            values.companyType = 'Company Type';
          }
          const autoClose = true;
          const keepAfterRouteChange = false
          var validation_response = await customCompanyValidations(values);
          setErrorValues(validation_response);
          console.log(validation_response)
          // alert('hi');
          if (Object.keys(validation_response).length === 0) {
            //if (validation_response.length === 0) {
            companyState.step = step;
            companyState.type = type;
            const companyData = {
              ...values,
              step,
              type
            }
            handleNext(companyData);
          } else {
            //window.scrollTo(0, 0)
            //alertService.error(validation_response.join(''), { autoClose, keepAfterRouteChange })
          }
        }
        }>
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleNext,
          isSubmitting,
          touched,
          values
        }) => (
          <div>
            {
              isSubmitView ? (
                <div/*  className={classes.pageSize} */ className="requestly-rfp-view company-information">
                  <Card className={classes.CardBorder} >
                    <CardContent className={classes.cardScrollArea + ' p-0'}>
                      <Card>
                        <CardHeader
                          title="Company Information"
                          className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'} />
                      </Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item md={9} xs={12}>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <Typography className="label-title">
                                  Company Name
                                </Typography>
                                <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                  {values.companyName}
                                </Typography>
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <Typography className="label-title require font-family"> Company Overview </Typography>
                                <RichText
                                  showContentOnly={isSubmitView}
                                  value={companyState.overview}
                                  onTextChange={handleOverviewChange}
                                />
                              </Grid>

                            </Grid>
                          </Grid>

                          <Divider variant="fullWidth" />
                          <Grid item md={3} xs={4} className={classes.profileImg + 'custome-css'} style={{ textAlign: 'right' }}>

                            <Thumb className={classes.profilePics + 'img-thumbnail size-su-change logo-clickable'} file={values.logo} stopChange={isSubmitView} />
                            <input style={{ "display": "none" }} id="logo" name="logo" type="file" className="form-control"
                              onChange={(event) => {
                                companyState.logo = event.currentTarget.files[0]
                                setFieldValue('logo', event.currentTarget.files[0]);
                              }} />

                          </Grid>

                          <Grid item md={12} xs={12}>
                            <ChipInput
                              error={Boolean(touched.keywords && errors.keywords)}
                              fullWidth
                              disabled={isSubmitView}
                              helperText={touched.keywords && errors.keywords}
                              label="Keywords"
                              defaultValue={companyState.keywords}
                              onChange={(chip) => handleChangeKeywords(chip)}
                            />
                          </Grid>
                          <Grid item md={9} xs={8}>
                            <Typography className="label-title">
                              Company Solution Video URL
                            </Typography>
                            <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                              <Link href="#" >
                                {values.videoUrl}
                              </Link>
                            </Typography>

                          </Grid>
                          <Grid item md={9} xs={8}>
                            <Typography className="label-title">
                              Company Website
                            </Typography>
                            <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                              {values.website}
                            </Typography>
                          </Grid>

                        </Grid>
                      </CardContent>
                      <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                        <AccordionSummary className="requestly-tab-height accordinon-financial-summary" expandIcon={<ExpandMoreIcon />} aria-controls="panel-financial-content" id="panel-financial" >
                          <Typography className={classes.heading}>Financial Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                          <Typography component="span" variant="body2">
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>

                                  <Typography className="label-title">
                                    Company Type
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {values.companyType}
                                  </Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <Typography className="label-title">
                                    No Of Employees
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {values.headCount}
                                  </Typography>
                                </Grid>
                                <Grid item md={8} xs={8}>
                                  <Typography className="label-title">
                                    Annual Revenue
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {values.totalRevenue}
                                  </Typography>
                                </Grid>
                                <Grid item md={4} xs={4}>
                                  <Typography className="label-title">
                                    Currency
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {values.currency}
                                  </Typography>
                                </Grid>
                                <Grid item md={8} xs={8}>
                                  <Typography className="label-title">
                                    Fiscal Year End
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {/*  {values.fiscalYearMonth} */}
                                  </Typography>


                                </Grid>
                              </Grid>
                            </CardContent>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                        <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-financial-content" id="panel-financial">
                          <Typography className={classes.heading}>Company Office Locations</Typography>
                        </AccordionSummary>
                        <AccordionDetails>

                          <CardContent style={{ width: '100%' }}>
                            {
                              values.locations.map((location) => (
                                <Grid container >
                                  <Grid item md={6} xs={6}>
                                    <Typography className="label-title">
                                      Office Location
                                    </Typography>
                                    <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                      {location.officeLocation}
                                    </Typography>
                                  </Grid>

                                  <Grid item md={6} xs={6}>
                                    <Typography className="label-title">
                                      Office Function
                                    </Typography>
                                    <Typography className="ellipse-text" style={{ "marginTop": "5px", "fontSize": "14px", textOverflow: 'ellipsis', wordWrap: 'break-word' }}>
                                      {location.officeFunction.toString(',').replaceAll(",", ", ")}
                                    </Typography>

                                  </Grid>

                                </Grid>
                              ))
                            }
                          </CardContent>

                        </AccordionDetails>
                      </Accordion>
                    </CardContent>
                    <div class="footer-btn-fixed">
                      <footer class="page-footer green">
                        <Grid container spacing={2} justify="center">
                          {step !== 0 ? (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                                Back
                              </Button>
                            </Grid>
                          ) : null}
                          {step !== totalStepCount ? (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.ButtonSkip + " font-family"} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                                Skip
                              </Button>
                            </Grid>
                          ) : null}
                          {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                            (
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                              </Grid>
                            ) : null}
                          {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                            (
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                              </Grid>
                            ) : step === totalStepCount ?
                              (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                                </Grid>
                              ) : (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.NextButton + " font-family"} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                                </Grid>
                              )}
                        </Grid>
                      </footer>
                    </div>

                  </Card>
                </div>

              )
                : (
                  <form onKeyDown={onKeyDown} onSubmit={handleNext} className={'requestly-rfp-form company-information mb-5'}>
                    <div className={classes.pageSize + ' company-info-div'}>
                      <Card className={classes.CardBorder} >
                        <CardContent className={classes.cardScrollArea + ' p-0'}>
                          <Card>
                            <CardHeader
                              title="Company Information"
                              subheader="`Please provide information on your company. This will be seen
by prospects and customers when they evaluate vendors."
                              className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'} />
                            <Divider />
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item md={8} xs={8}>
                                  <TextField
                                    error={Boolean(touched.companyName && errors.companyName)}
                                    fullWidth
                                    multiline
                                    helperText={touched.companyName && errors.companyName}
                                    label="Company name"
                                    name="companyName"
                                    disabled={isSubmitView}
                                    onBlur={updateState}
                                    onChange={handleChange}
                                    value={values.companyName}
                                    variant="outlined"
                                    required
                                    className="requestly-rfp-textbox"
                                  />
                                  {
                                    (error_values !== null && error_values.companyName !== undefined) ? <ErrorComponent text={error_values.companyName} /> : ''
                                  }

                                </Grid>
                                <Grid item md={4} xs={4} className={classes.profileImg} style={{ textAlign: 'right' }}>
                                  <Thumb className={classes.profilePics + ' img-thumbnail size-su-change logo-clickable'} file={values.logo} stopChange={isSubmitView} />

                                  <input style={{ "display": "none" }} id="logo" name="logo" type="file" className="form-control"
                                    onChange={(event) => {
                                      companyState.logo = event.currentTarget.files[0]
                                      setFieldValue('logo', event.currentTarget.files[0]);
                                    }} />
                                  {
                                    (error_values !== null && error_values.logo !== undefined) ? <ErrorComponent text={error_values.logo} /> : ''
                                  }
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <Typography className="required font-family" style={{ "marginTop": "-25px", "color": "#547087", "fontSize": "16px" }}> Company Overview </Typography>
                                  <RichText
                                    showContentOnly={isSubmitView}
                                    value={companyState.overview}
                                    className="requestly-bg-gray"
                                    onTextChange={handleOverviewChange}
                                    name="overview"
                                  />
                                  {
                                    (error_values !== null && error_values.overview !== undefined) ? <ErrorComponent text={error_values.overview} /> : ''
                                  }
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <ChipInput
                                    error={Boolean(touched.keywords && errors.keywords)}
                                    fullWidth
                                    disabled={isSubmitView}
                                    helperText={touched.keywords && errors.keywords}
                                    label="Keywords"
                                    defaultValue={companyState.keywords}
                                    onChange={(chip) => handleChangeKeywords(chip)}
                                    variant="outlined"
                                    className="requestly-bg-gray multiselect-textbox"
                                    required
                                  />
                                  {
                                    (error_values !== null && error_values.keywords !== undefined) ? <ErrorComponent text={error_values.keywords} /> : ''
                                  }
                                </Grid>
                                <Grid item md={12} xs={12} >
                                  <TextField
                                    error={Boolean(touched.videoUrl && errors.videoUrl)}
                                    fullWidth
                                    disabled={isSubmitView}
                                    helperText={touched.videoUrl && errors.videoUrl}
                                    label="Company Solution Video URL"
                                    name="videoUrl"
                                    onBlur={updateState}
                                    onChange={handleChange}
                                    value={values.videoUrl}
                                    variant="outlined"
                                    className="requestly-bg-gray requestly-h-40"
                                    required
                                  />
                                  {
                                    (error_values !== null && error_values.videoUrl !== undefined) ? <ErrorComponent text={error_values.videoUrl} /> : ''
                                  }
                                </Grid>
                                <Grid item md={12} xs={12}>
                                  <TextField
                                    error={Boolean(touched.website && errors.website)}
                                    fullWidth
                                    disabled={isSubmitView}
                                    helperText={touched.website && errors.website}
                                    label="Company Website"
                                    name="website"
                                    onBlur={updateState}
                                    onChange={handleChange}
                                    value={values.website}
                                    disabled
                                    variant="outlined"
                                    className="requestly-bg-gray requestly-h-40"
                                    required
                                  />
                                  {
                                    (error_values !== null && error_values.website !== undefined) ? <ErrorComponent text={error_values.website} /> : ''
                                  }
                                </Grid>



                              </Grid>
                            </CardContent>
                            <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                              <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-financial-content" id="panel-financial">
                                <Typography className={classes.heading}>Financial Information</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component="span" variant="body2">
                                  <CardContent>
                                    <Grid container spacing={3}>
                                      <Grid item md={12} xs={12}>
                                        <TextField
                                          error={Boolean(touched.companyType && errors.companyType)}
                                          fullWidth
                                          disabled={isSubmitView}
                                          helperText={touched.companyType && errors.companyType}
                                          label="Company Type"
                                          name="companyType"
                                          onChange={updateState}
                                          select
                                          SelectProps={{ native: true }}
                                          value={values.companyType}
                                          variant="outlined"
                                          className="requestly-bg-gray requestly-h-40"
                                          required
                                        >
                                          {companyTypes.map((option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      <Grid item md={12} xs={12}>

                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          label="Number of Employees"
                                          name="headCount"
                                          value={values.headCount}
                                          onChange={handleChange}
                                          onBlur={updateState}
                                          id="formatted-number"
                                          className="requestly-bg-gray requestly-h-40"
                                          InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item md={8} xs={8}>

                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          label="Annual Revenue"
                                          name="totalRevenue"
                                          value={values.totalRevenue}
                                          onChange={handleChange}
                                          onBlur={updateState}
                                          id={values.currency}
                                          className="requestly-bg-gray requestly-h-40"
                                          InputProps={{
                                            inputComponent: NumberFormatCustom,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item md={4} xs={4}>
                                        <TextField
                                          error={Boolean(touched.currency && errors.currency)}
                                          fullWidth
                                          disabled={isSubmitView}
                                          helperText={touched.currency && errors.currency}
                                          label="Currency"
                                          name="currency"
                                          select
                                          SelectProps={{ native: true }}
                                          value={values.currency}
                                          onBlur={updateState}
                                          onChange={handleChange}
                                          className="requestly-bg-gray requestly-h-40"
                                          variant="outlined">
                                          {currencies.map((option) => (
                                            <option key={option.value} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      <Grid item md={12} xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <DatePicker
                                            fullWidth
                                            disableToolbar
                                            autoOk
                                            inputVariant="outlined"
                                            label="Fiscal Year End"
                                            value={values.fiscalYearMonth}
                                            onBlur={updateState}
                                            onChange={handleChangeFiscalYearMonth}
                                            className="requestly-bg-gray requestly-h-40"
                                            format="MM/dd"
                                            openTo="month"
                                            variant="inline"
                                            views={['month', 'date']} />
                                        </MuiPickersUtilsProvider>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                              <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-location-content" id="panel-location">
                                <Typography className={classes.heading}> Company Office Locations </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography component="span" variant="body2" className={classes.widthFill}>
                                  <CardContent>
                                    <Grid container spacing={3}>
                                      <Grid item md={12} xs={12}>
                                        <FieldArray name="locations"
                                          render={arrayHelpers => (
                                            <div className={classes.customInputs}>
                                              {values.locations && values.locations.map((value, index) => (
                                                <div key={index}>
                                                  <Grid item md={5} xs={12}>
                                                    <Autocomplete
                                                      style={{ "display": "inlineFlex" }}
                                                      className={classes.testMan}
                                                      blurOnSelect={true}
                                                      fullWidth
                                                      disableClearable
                                                      disabled={isSubmitView}
                                                      id="google-map-demo"
                                                      name={`locations[${index}].officeLocation`}
                                                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                                                      filterOptions={(x) => x}
                                                      options={options}
                                                      autoComplete
                                                      includeInputInList
                                                      filterSelectedOptions
                                                      value={values.locations[index].officeLocation}
                                                      onChange={(event, newValue) => {
                                                        values.locations[index].officeLocation = newValue?.description ?? ''
                                                        setInputValue('');
                                                      }}
                                                      onOpen={() => {
                                                        setInputValue(values.locations[index].officeLocation);
                                                      }}
                                                      onInputChange={(event, newInputValue) => {
                                                        setInputValue(newInputValue);
                                                      }}
                                                      renderInput={(params) => (
                                                        <TextField
                                                          {...params}
                                                          label="Office Location"
                                                          variant="outlined"
                                                          fullWidth
                                                          className="react-bg-gray requestly-h-40"
                                                          name={`locations[${index}].officeLocation`}
                                                          error={Boolean(touched.locations && errors.locations)}
                                                          required
                                                        />
                                                      )}
                                                      renderOption={(option) => {
                                                        const matches = option.structured_formatting.main_text_matched_substrings;
                                                        const parts = parse(
                                                          option.structured_formatting.main_text,
                                                          matches.map((match) => [match.offset, match.offset + match.length]),
                                                        );

                                                        return (
                                                          <Grid container alignItems="center">
                                                            <Grid item>
                                                              <LocationOnIcon className={classes.icon} />
                                                            </Grid>
                                                            <Grid item xs>
                                                              {parts.map((part, index) => (
                                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                                  {part.text}
                                                                </span>
                                                              ))}

                                                              <Typography variant="body2" color="textPrimary">
                                                                {option.structured_formatting.secondary_text}
                                                              </Typography>
                                                            </Grid>
                                                          </Grid>
                                                        );
                                                      }}
                                                    />
                                                    { }
                                                  </Grid>
                                                  <Grid item md={5} xs={12}>
                                                    <ChipInput
                                                      error={Boolean(touched.officeFunction && errors.officeFunction)}
                                                      fullWidth
                                                      disabled={isSubmitView}
                                                      helperText={touched.officeFunction && errors.officeFunction}
                                                      label="Office Function"
                                                      name={`locations[${index}].officeFunction`}
                                                      defaultValue={values?.locations[index]?.officeFunction
                                                        ? typeof values.locations[index].officeFunction === "object"
                                                          ? values.locations[index].officeFunction
                                                          : [values.locations[index].officeFunction]
                                                        : []}
                                                      onChange={(chip) => handleOfficeChange(chip, index, 2)}
                                                    />
                                                  </Grid>
                                                  <Grid item md={2} xs={12}>
                                                    {(index > 0 && !isSubmitView) ?

                                                      <Button style={{ "marginRight": "0px", "padding": "1px", "minWidth": "30px" }} type="button" onClick={() => removeLocations(index)}>  &nbsp; <DeleteIcon className="site-delete-icon" style={{ "color": "grey", }} /> </Button>
                                                      : null}
                                                  </Grid>
                                                </div>
                                              ))}
                                              {(!isSubmitView) ?
                                                <Button style={{ "marginTop": "0px", "padding": "6px 16px", "fontSize": "14px", }} className={classes.blueBtn + ' addLocation'} color="secondary" variant="contained" type="button" onClick={addLocations} >
                                                  Add Location
                                                </Button>
                                                : null}
                                            </div>
                                          )}
                                        />

                                      </Grid>
                                      {
                                        (error_values !== null && error_values.locations !== undefined) ? <Grid item md={12} xs={12}><ErrorComponent text={error_values.locations} /></Grid> : ''
                                      }
                                    </Grid>
                                  </CardContent>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </Card>
                        </CardContent>
                        <Divider />
                        <div class="footer-btn-fixed">
                          <footer class="page-footer green">
                            <Grid container spacing={2} justify="center">
                              {step !== 0 ? (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                                    Back
                                  </Button>
                                </Grid>
                              ) : null}
                              {step !== totalStepCount ? (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.ButtonSkip + " font-family"} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                                    Skip
                                  </Button>
                                </Grid>
                              ) : null}
                              {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                                (
                                  <Grid item md={3} sm={3} xs={3}>
                                    <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                                  </Grid>
                                ) : null}
                              {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                                (
                                  <Grid item md={3} sm={3} xs={3}>
                                    <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                                  </Grid>
                                ) : step === totalStepCount ?
                                  (
                                    <Grid item md={3} sm={3} xs={3}>
                                      <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                                    </Grid>
                                  ) : (
                                    <Grid item md={3} sm={3} xs={3}>
                                      <Button className={classes.NextButton + " font-family"} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                                    </Grid>
                                  )}
                            </Grid>
                          </footer>
                        </div>
                      </Card>
                    </div>
                  </form>
                )
            }
          </div>
        )}

      </Formik>
      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
CompanyDetails.propTypes = {
  className: PropTypes.string
};
export default CompanyDetails;
