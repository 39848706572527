/* eslint-disable */
import React from 'react';
import GridLoader from "react-spinners/GridLoader";
import './loader.scss';
import { loaderService } from './loader.service';


const defaultProps = {
    visible: true
};

class Loader extends React.Component {
    constructor(props) {
        super(props);
        console.log('props --->', props)
        this.state = {
            visible: false
        };
    }

    componentDidMount() {
        // subscribe to new alert notifications
        this.subscription = loaderService.onShowHide(false)
            .subscribe((show) => {
                // clear alerts when an empty alert is received
                if (show)
                    this.setState({visible: show})
            });   
        
        this.loaderSubscription = loaderService.onLoaderCount(false)
            .subscribe((show) => {
                // clear alerts when an empty alert is received
                console.log('loaderService in LoaderCount --->', loaderService.getLoaderCount())
                if (loaderService.getLoaderCount() <= 0) {
                    this.setState({ visible: false })
                }
            });   
    }


    componentWillUnmount() {
        // unsubscribe & unlisten to avoid memory leaks
        this.subscription.unsubscribe();
        this.loaderSubscription.unsubscribe();
       // this.historyUnlisten();
    }

    

   

    render() {
       // const { alerts } = this.state;
        if (!this.state.visible) return null;
        return (
            <div className="overlay-loader-ui">
           <GridLoader
           margin={2}
          size={20}
          color={"#123abc"}
          loading={this.state.visible}
        />
          </div>
        );
    }
}

Loader.defaultProps = defaultProps;
export { Loader };
