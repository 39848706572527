import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Form, Formik } from 'formik';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { history } from 'src/utils/utils';
import { alertService } from '../../components/toaster/alert.service';
import { useStyles } from './ReferencesStyle';
import { ReferencesValidations } from './ReferencesValidations';

const tableIcons = {
  Add: props => {
    return (<Button style={{ "marginRight": "1px" }} color="primary" type="button" variant="contained">
      Add Contact
    </Button>)
  },
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline className="site-delete-icon" {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit className="site-delete-icon" {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableOptions = {
  actionsColumnIndex: 4,
  search: true,
};



const References = ({ handleNext, handleSkip, handleBack, isSubmitView, completed, className, setReferenceState, referenceState, onMobileChange, step, totalStepCount, mobileNumberHide, currentStepCompleteStatus, acceptStatus, is_submitted = false, ...rest }) => {
  const classes = useStyles();
  const [showMobileInfo, setShowMobileInfo] = useState(true);
  const [nameError, setNameError] = useState({
    error: false,
    label: "Name",
    helperText: "",
    validateInput: false
  });
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);


  const columns = [
    {
      title: 'Name', field: 'name',
      editComponent: props => (
        <TextField
          type="text"
          label="Name"
          error={
            !props.value && nameError.validateInput
              ? nameError.error
              : false
          }
          helperText={
            !props.value && nameError.validateInput
              ? nameError.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={e => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false
              });
            }

            props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: 'Email', field: 'email',
      editComponent: props => (
        <TextField
          type="text"
          label="Email"
          error={
            !props.value && nameError.validateInput
              ? nameError.error
              : false
          }
          helperText={
            !props.value && nameError.validateInput
              ? nameError.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={e => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false
              });
            }

            props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: 'Mobile', field: 'mobile',
      editComponent: props => (
        <TextField
          type="text"
          label="Mobile"
          error={
            !props.value && nameError.validateInput
              ? nameError.error
              : false
          }
          helperText={
            !props.value && nameError.validateInput
              ? nameError.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={e => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false
              });
            }

            props.onChange(e.target.value);
          }}
        />
      )
    },
    {
      title: 'Company', field: 'company',
      editComponent: props => (
        <TextField
          type="text"
          label="Company"
          error={
            !props.value && nameError.validateInput
              ? nameError.error
              : false
          }
          helperText={
            !props.value && nameError.validateInput
              ? nameError.helperText
              : ""
          }
          value={props.value ? props.value : ""}
          onChange={e => {
            if (nameError.validateInput) {
              setNameError({
                ...nameError,
                validateInput: false
              });
            }

            props.onChange(e.target.value);
          }}
        />
      )
    },
  ];
  let editable = {};
  if (!isSubmitView) {
    editable = {
      onRowAdd: (newData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(newData.email)) {
              const autoClose = true;
              const keepAfterRouteChange = false
              window.scrollTo(0, 0)
              alertService.error('Please Enter Valid Email Address', { autoClose, keepAfterRouteChange })
            } else {
              setReferenceState((prevState) => {
                const references = [...prevState.references];
                references.push(newData);
                return { ...prevState, references };
              });
            }
          }, 600);
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            if (oldData) {
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
              if (!pattern.test(newData.email)) {
                const autoClose = true;
                const keepAfterRouteChange = false
                window.scrollTo(0, 0)
                alertService.error('Please Enter Valid Email Address', { autoClose, keepAfterRouteChange })
              } else {
                setReferenceState((prevState) => {
                  const references = [...prevState.references];
                  references[references.indexOf(oldData)] = newData;
                  return { ...prevState, references };
                });
              }
            }
          }, 600);
        }),
      onRowDelete: (oldData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            setReferenceState((prevState) => {
              const references = [...prevState.references];
              references.splice(references.indexOf(oldData), 1);
              return { ...prevState, references };
            });
          }, 600);
        }),
    };
  }
  var type = '';

  console.log(columns, "columns")
  console.log(mobileNumberHide, "mobileNumberHide");
  return (
    <Formik
      initialValues={referenceState}
      enableReinitialize={true}
      validationSchema={ReferencesValidations}
      onSubmit={async (values) => {
        var success_flag = false;
        await values.references.map(ref => {
          if (ref.company !== undefined && ref.company !== ''
            && ref.email !== undefined && ref.email !== ''
            // && ref.mobile !== undefined && ref.mobile !== ''
            && ref.name !== undefined && ref.name !== '') {
            success_flag = true;
          }
        })
        if (success_flag) {
          values.type = type;
          values.step = step;
          onMobileChange(true)
          handleNext(values);
        } else {
          const autoClose = true;
          const keepAfterRouteChange = false
          window.scrollTo(0, 0)
          alertService.error('Please add 1 Complete Reference Field!!', { autoClose, keepAfterRouteChange })
        }
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form onKeyDown={onKeyDown} className={'requestly-rfp-form company-information mb-5'}>
          <div className={classes.pageSize + ' company-info-div'}>
            <Card className={classes.CardBorder}>
              <CardHeader subheader="Provide the reference information" title="Reference Info"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <Divider />
              <CardContent>
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <Typography>The Buyer will request a reference. Please provide below contact details for your commited reference. </Typography>
                  <Typography>This information will not be released to the buyer untill you have been finalized or untill the final stage of the process. </Typography>
                </Grid>
                <Box mt={3}>
                  <Grid className={classes.finalBoxTable + ' Presentation-Agenda-table'}>
                    <MaterialTable
                      title="References"
                      columns={columns}
                      options={tableOptions}
                      data={referenceState.references}
                      icons={tableIcons}
                      editable={editable}
                    />
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {(step !== 0 && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                        </Button>
                      </Grid>
                    ) : null}
                    {(step !== totalStepCount && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                        </Button>
                      </Grid>
                    ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                          </Grid> : ''
                      ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <Grid item md={3} sm={3} xs={3}>
                            <Tooltip
                              title={'You must accept RFP invite to submit the RFP response'}
                              placement="top"
                              arrow
                              onOpen={() => {
                                if (acceptStatus != 1 && !open) {
                                  setOpen(true);
                                }
                              }}
                              onClose={() => setOpen(false)}
                              open={open}
                            >
                              <div onMouseOver={() => {
                                setOpen(true)
                              }}
                                onMouseLeave={() => setOpen(false)}>
                                <Button style={{ width: '100%' }} disabled={acceptStatus == 1 ? false : true} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                              </div>
                            </Tooltip>
                          </Grid> : ''
                      ) : step === totalStepCount ?
                        (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                            </Grid> : ''
                        ) : (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                            </Grid> : ''
                        )}
                    {
                      (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                        <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                      </Grid> : ''
                    }
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );
};

References.propTypes = {
  className: PropTypes.string
};

export default References;
