import AxiosInstance from '../../interceptor/http-intercepor';

const ProductService = {
  product: (filter = "all") => {
    const userId = localStorage.getItem('userId');
    const url = 'rfp/getrfp/' + userId + '?filterBy=' + filter;
    return AxiosInstance.get(url);
  },

}

export default ProductService;
