import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./root-reducer";
import rootSaga from "./root-saga";


const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({ collapsed: true });
const middlewares = [sagaMiddleware, logger];


export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);