import { Card, CardHeader, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import ConfirmModal from '../../../views/product/RequestForPage/ConfirmModal';
import { useStyles } from './SubmitRfpStyle';

const InviteAcceptanceState = (props) => {
  console.log('123:', props)
  const history = useHistory();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {
        (props.inviteAcceptanceState.acceptStatus === 1 || props.inviteAcceptanceState.acceptStatus === 2) ? null : (
          <Grid item className=" rfp-breaccrumb roboto-font" md={12} xl={12} lg={12}>
            <Card className="requestly-rfp-view  radius-15 bg-white mb-4">
              <CardHeader
                title="Submit RFP"
                subheader="(Please submit your details)"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <CardHeader className={classes.acceptInviateHeader} title={<Grid container spacing={3} id="panel-Pricingss">
                <Grid item md={12} xs={12}>
                  <Typography> {props.companyState.companyName} has invited you to participate in their Title {props.projectState.projectName} </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography> Please accept or decline the invite. </Typography>
                </Grid>
                <div style={{ "width": "100%", "display": "block", }} className={classes.customInputs}>
                  <Grid item md={12} xs={12} style={{ "width": "100%", "display": "block", }}>
                    <Button style={{ width: "143px", fontFamily: "Roboto, sans- serif !important", padding: "7px 5px", textTransform: "none", }} className={classes.declineBtn} color="secondary" variant="contained" type="button" onClick={() => { setModalOpen(true) }}>
                      {props.inviteAcceptanceState.acceptStatus === 2 ? 'Declined' : 'Decline'}
                    </Button>

                    {((!props?.creditInfo && !props?.freeCreditInfo) || (Object.keys(props.creditInfo).length === 0 && !props?.freeCreditInfo) || (props?.creditInfo?.type != '2' && props?.creditInfo?.credits <= 2 && !props?.freeCreditInfo) || (props?.freeCreditInfo && props?.freeCreditInfo?.credits < 1)) &&
                      <Button
                        className={classes.buyNowBtn}
                        type="button"
                        variant="contained"
                        style={{
                          marginLeft: "16%",
                          width: "143px",
                          textTransform: "none",
                          fontFamily: "Roboto, sans- serif",
                          color: "white !important",
                          // border: "1px solid #52B152",
                          padding: "7px 5px",
                        }}
                        onClick={() => {
                          localStorage.setItem('isFromMR', window.location.href)
                          history.push(`/app/product-list?rfpId=` + props?.rfpId);
                        }}>
                        Buy Now
                      </Button>
                    }

                    <Button
                      style={{ "padding": "7px 5px", "float": "right", textTransform: "none", fontFamily: "Roboto, sans- serif !important", }}
                      className={classes.acceptBtn}
                      classes={{ disabled: classes.disabledButton }}
                      color="secondary"
                      variant="contained"
                      type="button"
                      onClick={() => props.updateState(1)}
                      disabled={((props?.creditInfo && props?.creditInfo?.credits && props?.creditInfo?.credits > 2) || (props?.creditInfo && props?.creditInfo?.type && props?.creditInfo?.type == '2') || (props?.freeCreditInfo && props?.freeCreditInfo?.credits && props?.freeCreditInfo?.credits > 0)) ? false : true}>
                      {props.inviteAcceptanceState.acceptStatus === 1 ? 'Accepted' : 'Accept'}
                    </Button>

                    {(props?.creditInfo && props?.creditInfo?.type != 2 || !props?.creditInfo && props?.freeCreditInfo || !props?.creditInfo && !props?.freeCreditInfo) && <div
                      style={{
                        textAlign: "right",
                        marginBottom: "10px",
                        marginRight: "25px",
                      }}
                      className="popup2-credits-p"
                    >
                      <p
                        style={{
                          marginBottom: "6px",
                          fontSize: "10px",
                        }}
                        className={!props?.creditInfo?.credits && 'text-color-red'}
                      >
                        You have {props?.creditInfo?.credits || 0} PAID credits
                      </p>
                      <p
                        style={{
                          marginBottom: "4px",
                          fontSize: "10px",
                        }}
                        className={!props?.freeCreditInfo?.credits && 'text-color-red'}
                      >
                        {props?.freeCreditInfo && props?.freeCreditInfo?.credits && props?.freeCreditInfo?.credits > 0 ? 'You have one RFP pass' : 'You have no RFP pass available'}
                      </p>
                    </div>
                    }
                    {(props?.creditInfo && props?.creditInfo?.type == 2) &&
                      <div style={{
                        textAlign: "right",
                        marginBottom: "10px",
                        marginRight: "50px",
                      }}
                        className="popup2-credits-p"
                      >
                        <p
                          style={{
                            marginBottom: "6px",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Enterprise License
                        </p>
                      </div>}

                  </Grid>
                </div>
              </Grid>} />
            </Card></Grid>)

      }
      {
        modalOpen && (
          <ConfirmModal
            modalOpen={modalOpen}
            companyName={props?.companyState?.companyName}
            closePopUp={() => {
              setModalOpen(false)
            }}
            onConfirmClick={() => {
              setModalOpen(false);
              props.updateState(2)
            }}
          />
        )
      }
    </>
  );
};


export default InviteAcceptanceState;
