import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import React, { Component } from 'react';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class SharedAlert extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      vertical: 'top',
      horizontal: 'right',
    }
  }
  // const [state, setState] = React.useState({
  //   open: true,
  //   vertical: 'top',
  //   horizontal: 'right',
  // });

  // const { vertical, horizontal, open } = state;

  handleClose = (state) => {
    this.props.resetAlert();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.value.open !== this.state.open) {
      this.setState({ open: nextProps.value.open });
    }
  }

  render() {
    return (
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={this.props.value.open} autoHideDuration={6000} onClose={this.handleClose} key={'top' + 'right'}>
        <Alert onClose={this.handleClose} severity={this.props.value.severity}>
          {this.props.value.displayMessage}
        </Alert>
      </Snackbar>
    );
  }
};