import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(({
  root: {},
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  footerBackground: {
    background: "#f7f7f7"

  },
  CardBorder: {
    borderRadius: "15px"

  },
  uploadedFileContainer: {
    height: '30px',
    margin: '45px 0 0',
    padding: '8px 433px 2px 8px',
    backgroundColor: '#4688f0',
  },
  uploadedFileText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: {},
  listChecks: {},
  footerBox: {}
}));