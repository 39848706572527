import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  cardScrollArea: {
    //maxHeight: "72vH",
    //overflowX: "scroll",
    //overflowX: "hidden",

  },
  footerBackground: {
    backgroundColor: '#f7f7f7'
  },
  // BackButton:{
  //   left:"620px"
  // },
  // ButtonSkip:{
  //   left:"625px"
  // },
  NextButton: {
    marginLeft: "10px"
  },
  CardBorder: {
    borderRadius: "15px"

  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: {},
  listChecks: {},
  footerBox: {},
  searchInputStyle: {
    width: '100%',
    color: '#000',
    border: '2px solid #fff',
    borderRadius: "20px",
    padding: '8px 40px 3.8px 16px',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    fontSize: '14px',
    color: '#333',
    background: '#ddd'
  },
  searchBarWrap: {
    textAlign: 'left'
  },
  autoCompleteTextStyle: {
    color: '#000',
    fontSize: '.7rem'
  },
  autoCompletePopperProduct: {
    width: '30vW!important',
    maxWidth: '30vW!important',
    top: '-4px!important',
    left: '-20%!important'
  },
  autoCompleteTextStyle: {
    color: '#000',
    fontSize: '.7rem'
  },
  gridStyle: {
    display: 'inline-block'
  },
  containerPadding: {
    padding: '1rem'
  },
  gridStyleList: {
    padding: '0 1rem'
  },
  logoFit: {
    objectFit: 'contain!important'
  },
  productNameStyle: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  productOverview: {
    fontSize: '.8rem',
    color: '#000000!important',
    float: 'left!important',
    textAlign: 'justify',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': 4,
    '-webkitBoxOrient': 'vertical'
  },
  listItemDetailStyle: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  productImage: {
    borderRadius: '0!important'
  },
  inviteVendorBtn: {
    backgroundColor: '#40506b',
    color: '#ddd',
    fontSize: '10px',
    fontWeight: '500',
    width: '50%',
  },
  selectedVendorContainer: {
    backgroundColor: '#eee',
    width: '50%',
    padding: '5px 10px 5px 10px',
    lineHeight: '1.75',
    borderRadius: '4px',
  },
  selectedVendorText: {
    color: '#333',
    fontSize: '12px',
    fontWeight: '500',
  },
  selectedVendorIcon: {
    position: 'relative',
    top: '3px',
    color: '#333',
    fontSize: '12px',
    fontWeight: '500',
    cursor: 'pointer',
  },
  filterByText: {
    margin: '28px 69px 16px 18px',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  filterListText: {
    fontFamily: 'Roboto',
    fontSize: '12px !important',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000',
  },
  accordionFilter: {
    backgroundColor: '#fff !important',
    fontSize: '12px !important'
  },
  filterItem: {
    fontSize: '10px'
  }
}));