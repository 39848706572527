import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography
} from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import PaymentIcon from "@material-ui/icons/Payment";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Inbox, Info } from "react-feather";
import { useLocation } from "react-router-dom";
import NavItem from "./NavItem";

const userName = localStorage.getItem("user");
const user = {
  avatar: "/static/images/avatars/avatar_6.png",
  jobTitle: "Senior Developer",
  name: localStorage.getItem("user")
};

const getInitials = user => {
  let string = user.toString();
  let names = string.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const items = [
  {
    href: "/app/dashboard",
    icon: Inbox,
    title: "Dashboard"
  },
  {
    href: "/app/additional-information",
    icon: Info,
    title: "Additional Information"
  },
  {
    href: '/category/?allKeywords=&category=&company=&employee=&page=0&search=&subCategory=&targetPersona=',
    icon: LanguageIcon,
    title: "Discovery"
  },
  {
    href: "/app/billing-credit",
    icon: PaymentIcon,
    title: "Billing/Credit"
  }
];
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile, isSideBarOpen }) => {
  const classes = useStyles();
  const location = useLocation();

  const [username, setUserName] = useState(localStorage.getItem("user"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        className={"sidebar_heading sidebar-custom-" + isSideBarOpen}
        flexDirection="column"
        p={2}
      >
        <Typography className={classes.name} color="textPrimary" variant="h3">
          {isSideBarOpen ? username : getInitials(username)}
        </Typography>
      </Box>
      <Divider />
      <Box p={2} className={"requestly-menu"}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          className={
            clsx(classes.drawer, {
              [classes.drawerOpen]: isSideBarOpen,
              [classes.drawerClose]: !isSideBarOpen
            }) +
            " custom-drawer-" +
            isSideBarOpen
          }
          open={true}
          classes={{
            paper: clsx({
              [classes.desktopDrawer]: true,
              [classes.drawerOpen]: isSideBarOpen,
              [classes.drawerClose]: !isSideBarOpen
            })
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
