import { Container, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import EnquiryService from "src/services/enquiry/enquiry.service";
import Request from "./Request";
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  let { id } = useParams();
  let { id2 } = useParams();
  const enquiryService = new EnquiryService();
  const [product, setProduct] = useState();
  const getProductDemoList = () => {
    enquiryService
      .getProductDemoList()
      .then(res => {
        if (res && res.data) {
          let list = res?.data?.filter(item => item.id === id);

          setProduct(list[0]);
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const getDiscoveryMeetingList = () => {
    enquiryService
      .getDiscoveryMeetingList()
      .then(res => {
        if (res && res.data) {
          let list = res?.data?.filter(item => item.id === id);
          setProduct(list[0]);
        }
      })
      .catch(err => console.log(":: err", err));
  };
  useEffect(() => {
    if (id2 === "true") {
      getDiscoveryMeetingList();
    } else {
      getProductDemoList();
    }
  }, []);

  return (
    <Page className={classes.root + " requestly-rfp-view"} title="Meeting Request">
      <div id="rightContain" className="requestly-rfp-contain">
        <Container maxWidth="md">
          <Request
            productDemoVendorList={product}
            isDiscovery={id2}
          />
        </Container>
      </div>
    </Page>
  );
};

export default SettingsView;
