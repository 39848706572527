import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import React from 'react';

const getFileExtension = (fileName) => {
  const re = /(?:\.([^.]+))?$/;
  const extension = re.exec(fileName)[1];
  if (extension) {
    return extension;
  }
  return '';
}

export const renderFileList = (list, className, isLocal = false, deleteLocalFile = null, isPublished, companyName = undefined) => {
  console.log(list, "renderFileList")
  return (
    <TableContainer component={Paper} className="roboto-font">
      <Table className={className} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">  File Name </TableCell>
            <TableCell align="left">  Type </TableCell>
            <TableCell align="left">  Owner </TableCell>
            <TableCell align="left">  Company </TableCell>
            <TableCell align="left">  Date </TableCell>
            {isLocal ? (
              <TableCell align="left"></TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {list && list.map((item, index) => (
            (Object.keys(item).length > 0) ?
              <TableRow>
                <TableCell align="left">  </TableCell>
                <TableCell align="left">
                  <a href={item.location} target="_blank">{item.name}</a>
                </TableCell>
                <TableCell align="left">
                  {getFileExtension(item.name).toUpperCase()}
                </TableCell>
                <TableCell align="left">
                  {item.userName || localStorage.getItem("user")}
                </TableCell>
                <TableCell align="left">
                  {item.companyName || companyName}
                </TableCell>
                <TableCell align="left">
                  {moment(item.createdAt ? new Date(item.createdAt) : new Date()).format('DD MMMM, YYYY')}
                </TableCell>
                {isLocal && isPublished === 0 || isPublished === undefined ? (
                  <TableCell align="center">
                    <DeleteIcon
                      className="site-delete-icon"
                      onClick={() => {
                        deleteLocalFile && deleteLocalFile(index)
                      }}
                    />
                  </TableCell>
                ) : isPublished === 0 || isPublished === undefined ? (
                  (deleteLocalFile !== null) ? <TableCell align="center">
                    <DeleteIcon
                      className="site-delete-icon"
                      onClick={() => {
                        deleteLocalFile && deleteLocalFile(item.key)
                      }}
                    />
                  </TableCell> : ''
                ) : ""}
              </TableRow>
              : ''
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const renderFileListDataSheet = (list, className, isLocal = false, deleteLocalFile = null, id, isPublished) => {
  // console.log(isPublished, "renderFileListDataSheet")
  return (
    <TableContainer component={Paper} className="roboto-font">
      <Table className={className} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">  File Name </TableCell>
            <TableCell align="left">  Type </TableCell>
            <TableCell align="left">  Owner </TableCell>
            <TableCell align="left">  Company </TableCell>
            <TableCell align="left">  Date </TableCell>
            {isLocal ? (
              <TableCell align="left"></TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {list && list.map((item, index) => (
            (Object.keys(item).length > 0) ?
              <TableRow>
                <TableCell align="left">  </TableCell>
                <TableCell align="left">
                  <a href={item.location} target="_blank">{item.name}</a>
                </TableCell>
                <TableCell align="left">
                  {getFileExtension(item.name).toUpperCase()}
                </TableCell>
                <TableCell align="left">
                  {item.userName}
                </TableCell>
                <TableCell align="left">
                  {item.companyName}
                </TableCell>
                <TableCell align="left">
                  {moment(new Date(item.createdAt)).format('DD MMMM, YYYY')}
                </TableCell>
                {isLocal && isPublished === 0 || isPublished === undefined ? (
                  <TableCell align="center">
                    <DeleteIcon
                      className="site-delete-icon"
                      onClick={() => {
                        deleteLocalFile && deleteLocalFile(index)
                      }}
                    />
                  </TableCell>
                ) : (
                  (deleteLocalFile !== null && isPublished === 0 || isPublished === undefined) ? <TableCell align="center">
                    <DeleteIcon
                      className="site-delete-icon"
                      onClick={() => {
                        deleteLocalFile && deleteLocalFile(item.key, id)
                      }}
                    />
                  </TableCell> : ''
                )}
              </TableRow>
              : ''
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const renderFileListCompanyDocs = (list, className, isLocal = false, deleteLocalFile = null) => {
  return (
    <TableContainer component={Paper}>
      <Table className={className} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="left">  File Name </TableCell>
            <TableCell align="left">  Download </TableCell>
            {isLocal ? (
              <TableCell align="left"></TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {list && list.map((item, index) => (<TableRow>
            <TableCell align="left">  </TableCell>
            <TableCell align="left">
              <a href={item.location} target="_blank">{item.name}</a>
            </TableCell>
            <TableCell align="left">
              <a href={item.location} target="_blank" download>Download</a>
            </TableCell>
            {isLocal ? (
              <TableCell align="center">
                <DeleteIcon
                  className="site-delete-icon"
                  onClick={() => {
                    deleteLocalFile && deleteLocalFile(index)
                  }}
                />
              </TableCell>
            ) : null}
          </TableRow>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
