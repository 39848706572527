/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class PricingService {
    constructor() {
        this.config = new Configuration();
    }

    async addPricing(pricingObj) {
        loaderService.show(true);
        const self = this;
        const userId = localStorage.getItem('userId');
        pricingObj.userId = userId;
        const url = this.config.addPricing;
        return AxiosInstance.post(url, pricingObj)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    async getPricing(rfpId) {
        const self = this;
        loaderService.show(true);
        const userId = localStorage.getItem('userId');
        const url = this.config.getPricing + rfpId + '/' + userId;
        return AxiosInstance.get(url)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    async getAllPricing(rfpId) {
        const self = this;
        loaderService.show(true);
        const userId = localStorage.getItem('userId');
        const url = this.config.getAllPricing + rfpId + '/' + userId;
        return AxiosInstance.get(url)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    handleResponseError(response) {
        loaderService.show(false);
        throw new Error(`HTTP error, status = ${response.status}`);
    }

    handleError(error) {
        loaderService.show(false);
        if (error.response) {
            const autoClose = true;
            const keepAfterRouteChange = false
            alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
            // Request made and server responded
            console.log(':: error', error.response.data);
            // alert(error.response.data.message);
            console.log(':: error', error.response.status);
            console.log(':: error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(':: error', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log(':: Error', error.message);
        }
    }
}

export default PricingService;