/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class LoginService {
  constructor() {
    this.config = new Configuration();
  }

  async userLogin(userObj) {
    loaderService.show(true);
    const self = this;
    return AxiosInstance.post(this.config.login, userObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async userSignup(userObj) {
    loaderService.show(true);
    const self = this;
    return AxiosInstance.post(this.config.signup, userObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error, userObj.isGoogleLogin);
      });
  }

  async verifyEmail(token) {
    loaderService.show(true);
    const self = this;
    const url = `${this.config.verifyEmail}${token}`;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        return response;
      })
      .then((res) => {
        loaderService.show(false);
        return res;
      })
      .catch((error) => {
        loaderService.show(false);
        return error.response;
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error, notDisplayError) {
    loaderService.show(false);
    if (error.response && !notDisplayError) {
      const autoClose = true;
      const keepAfterRouteChange = false
      if (error.response.data.errors) {
        alertService.error(error.response.data.errors[0].msg, { autoClose, keepAfterRouteChange })
      }
      else {
        alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
        //alertService.error('Error hhh', { id: 'left-alert' });
        // Request made and server responded
        console.log(':: error', error.response.data);
        // alert(error.response.data.message);
        console.log(':: error', error.response.status);
        console.log(':: error', error.response.headers);
      }

    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default LoginService;
