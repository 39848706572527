import actionTypes from "./types";

export const getRfpByUserSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_USER_SUCCESS, payload: data
});

export const getRfpByUserStart = () => ({
  type: actionTypes.GET_RFP_BY_USER_START,

});

export const getRfpByUserFailed = () => ({
  type: actionTypes.GET_RFP_BY_USER_FAILED
});