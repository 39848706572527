import { Button, Fade, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { alertService } from 'src/components/toaster/alert.service';
import { loaderService } from '../../components/loader/loader.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import ProductService from './../../services/product/product.service';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  ModalBox: {
    marginLeft: "36px",
    marginTop: "36px",
  },
  name: {
    marginTop: "20px",
    fontSize: '15px',
    color: '#0C1234 !important',
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    borderRadius: '12px',
    width: "370px",
    maxWidth: '370px',
    // maxHeight: '370px',
    boxShadow: theme.shadows[5],
  },
  container: {
    // width: "370px",
    position: "relative",
    marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex",

  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: '15px',
    color: '#0C1234 !important',
    fontWeight: '500',
    font: 'Roboto',
    lineHeight: '20px',
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  }

}));

const PublishModal = ({ publishModalOpen, closeEditModal, product, isPublished, formValue, }) => {
  const history = useHistory();
  const classes = useStyles();
  const productService = new ProductService();

  const closePopUp = () => {
    closeEditModal(false)
  }
  const publishProductApi = async (product) => {
    var token = localStorage.getItem('token');
    loaderService.show(true);

    var url = 'https://prod-api.requestly.co/product/publish';
    if (window.location.hostname !== 'requestly.co') {
      url = "https://dev-api.requestly.co/product/publish"
    }

    return AxiosInstance.patch(url, { productId: product, type: "publish" })
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
        }
        if (response.data.message === "Product published successfully") {
          console.log(response.message, "response...")
          setTimeout(() => window.location.href = '/app/additional-information?start_point=add_product', 1000);
        }
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
      });
  };

  const unPublishProductApi = async (product) => {
    var token = localStorage.getItem('token');
    loaderService.show(true);
    var url = 'https://prod-api.requestly.co/product/publish';
    if (window.location.hostname !== 'requestly.co') {
      url = "https://dev-api.requestly.co/product/publish"
    }
    return AxiosInstance.patch(url, { productId: product, type: "unpublish" })
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
        }

      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
      });
  };

  const saveProductapi = (callback) => {
    productService.addProduct(formValue).then((res) => {
      if (res) {
        window.scrollTo(0, 0)
        alertService.success("Product information has been updated successfully")
        console.log(res, "res1")
        if (res) {
          callback(res.product._id);
        }
      }
    })

  }
  const publishProduct = async () => {
    const res = saveProductapi(publishProductApi)
  };
  const unPublishProduct = async () => {
    let response = await unPublishProductApi(product);
    console.log(response, "res")
    setTimeout(() => window.location.href = '/app/additional-information?start_point=add_product', 1000);
  };

  const handleSave = () => {
    if (isPublished === 0) {
      publishProduct(product)
    }
    if (isPublished === 1) {
      unPublishProduct(product)
    }
  }



  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        open={publishModalOpen}
        onClose={closePopUp}
        closeAfterTransition

        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>

            <div className={classes.paper}>
              <Grid className={classes.ModalBox}>
                <Grid item className={classes.container} >
                  {isPublished === 0 ? <Typography className={classes.title}>Are you sure you want to publish this product?</Typography> : <Typography className={classes.title}>Are you sure you want to UnPublish this product?</Typography>}
                  {isPublished === 0 ? <Typography className={classes.title}>The product will be Live/published.</Typography> : <Typography className={classes.title}>The product will be removed from listing.</Typography>}
                  <Grid
                    item
                    container
                    className={classes.saveContainer}
                  >
                    <Grid item xs={12}>
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#263344',
                          cursor: 'pointer',
                          marginTop: '8px',
                          marginLeft: "121px"

                        }}
                        onClick={closePopUp}
                      >
                        Cancel
                      </span>
                      <Button
                        className={classes.saveButton}
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        <span style={{ fontSize: '12px' }}>
                          Submit
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        </Fade>
      </Modal>

    </>
  )
}

export default PublishModal
