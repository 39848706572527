import { Container, Grid, StepButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Page from 'src/components/Page';
import { alertService } from 'src/components/toaster/alert.service';
import CommercialDetailService from 'src/services/rfp/commercial-detail.service';
import FinalPresentationService from 'src/services/rfp/final-presentation.service';
import LegalService from 'src/services/rfp/legal.service';
import NDAService from 'src/services/rfp/nda.service';
import PricingService from 'src/services/rfp/pricing.service';
import ProjectService from 'src/services/rfp/project.service';
import ReferenceService from 'src/services/rfp/reference.service';
import RFPService from 'src/services/rfp/rfp.service';
import TechnicalDetailService from 'src/services/rfp/technical-detail.service';
import VendorService from 'src/services/rfp/vendor.service';
import { history } from 'src/utils/utils';
import LegalContracts from 'src/views/LegalContracts/LegalContracts';
import { LegalContractsModel } from 'src/views/LegalContracts/LegalContractsModel';
import { NdaAnswerModel } from 'src/views/NdaAnswer/NdaAnswerModel';
import { ProjectModel } from 'src/views/ProjectInformation/ProjectModel';
import CompanyService from '../../../services/company/company.service';
import { getRfpByIdSubmitCommercialStart, getRfpByIdSubmitCommercialUpdate, getRfpByIdSubmitTechnicalStart, getRfpByIdSubmitTechnicalUpdate, getRfpByUserIdStart, submitRfpUpdateStepper } from "../../../store/submitRfp/actions";
import CommercialEvaluationAnswer from '../../CommercialEvaluationAnswer/CommercialEvaluationAnswer';
import { CommercialEvaluationAnswerModel } from '../../CommercialEvaluationAnswer/CommercialEvaluationAnswerModel';
import CompanyDetails from '../../CompanyInformation/CompanyDetails';
import { CompanyModel } from '../../CompanyInformation/CompanyModel';
import FinalPresentation from '../../FinalPresentation/FinalPresentation';
import { FinalPresentationModel } from '../../FinalPresentation/FinalPresentationModel';
import { InviteAcceptanceModel } from '../../InviteAcceptance/InviteAcceptanceModel';
import NdaAnswer from '../../NdaAnswer/NdaAnswer';
import Pricing from '../../Pricing/Pricing';
import { PricingModel } from '../../Pricing/PricingModel';
import ProjectDetails from '../../ProjectInformation/ProjectDetails';
import References from '../../References/References';
import { ReferencesModel } from '../../References/ReferencesModel';
import TechnicalEvaluationAnswer from '../../TechnicalEvaluationAnswer/TechnicalEvaluationAnswer';
import { TechnicalEvaluationAnswerModel } from '../../TechnicalEvaluationAnswer/TechnicalEvaluationAnswerModel';
import { rapid } from '../CreateRfpView/data/processConfiguration';
import InviteAcceptanceState from './InviteAcceptanceState';
import { getSteps } from './RfpStepsConfig';
import { useStyles } from './SubmitRfpStyle';


const SubmitRfpView = ({ match, getRfpByUserIdStart, rfpSubmitData, getRfpByIdSubmitTechnicalStart, submitRfpUpdateStepper, getRfpByIdSubmitCommercialStart, submitCommericalData, getRfpByIdSubmitCommercialUpdate, getRfpByIdSubmitTechnicalUpdate }) => {
  const [completed, setCompleted] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [question_answer_data, setQuestionAnswerData] = React.useState([]);
  const [totalStepCount, setTotalStepCount] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [companyState, setCompanyState] = React.useState(new CompanyModel());
  const [is_submitted, setSubmitted] = React.useState(false);
  const [projectState, setProjectState] = React.useState(new ProjectModel());
  const [pricingState, setPricingState] = React.useState(new PricingModel());
  const [technicalEvaluationState, setTechnicalEvaluationState] = React.useState(new TechnicalEvaluationAnswerModel());
  const [questionColorChange, setQuestionColorChange] = useState(false)
  const [commercialEvaluationState, setCommercialEvaluationState] = React.useState(new CommercialEvaluationAnswerModel());
  const [inviteAcceptanceState, setInviteAcceptanceState] = React.useState(new InviteAcceptanceModel());
  const [finalPresentationState, setFinalPresentationState] = React.useState(new FinalPresentationModel());
  const [ndaState, setNDAState] = React.useState(new NdaAnswerModel());
  const [legalState, setLegalState] = React.useState(new LegalContractsModel());
  const [referenceState, setReferenceState] = React.useState(new ReferencesModel());
  const [mobileNumberHide, setMobileNumberHide] = React.useState(false)
  const stepsDataKeys = Object.keys(rapid)
  const initialStepsData = stepsDataKeys.filter(function (id) { return rapid[id] });
  const [allSteps, setAllSteps] = React.useState(initialStepsData);
  const [temp_rfp_name, setTempRfpName] = React.useState('');
  const [creditInfo, setCreditInfo] = React.useState('');
  const [freeCreditInfo, setFreeCreditInfo] = React.useState('');
  const classes = useStyles();
  const companyService = new CompanyService();
  const projectService = new ProjectService();
  const vendorService = new VendorService();
  const rfpService = new RFPService();
  const technicalDetailService = new TechnicalDetailService();
  const commercialDetailService = new CommercialDetailService();
  const ndaService = new NDAService();
  const finalPresentationService = new FinalPresentationService();
  const referenceService = new ReferenceService();
  const pricingService = new PricingService();
  const legalService = new LegalService();

  const steps = getSteps(allSteps);
  const autoClose = true;
  const keepAfterRouteChange = true;
  const rfpId = match.params.rfpId;

  const handleReset = () => {
    setActiveStep(0);
  };

  const extractAndSetSteps = (stepsData = null, rfpStepper = null) => {
    var allSteps = ['companyInformation', 'projectInformation'];
    allSteps.push('nda');
    allSteps.push('technicalRequirements');
    if (stepsData && stepsData['commercialEvaluation']) {
      allSteps.push('commercialEvaluation');
    }
    if (stepsData && stepsData['finalPresentation']) {
      allSteps.push('finalPresentation');
    }
    if (stepsData && stepsData['pricingNegotiations']) {
      allSteps.push('pricingNegotiations');
    }
    if (stepsData && stepsData['references']) {
      allSteps.push('references');
    }
    if (stepsData && stepsData['legalContracts']) {
      allSteps.push('legalContracts');
    }
    allSteps.push('legalContracts');
    setTotalStepCount(allSteps.length - 1);
    if (rfpStepper) {
      const newCompleted = completed;
      var currentActiveStep = null;
      allSteps.map((step, index) => {
        if (rfpStepper[step]) {
          newCompleted[index] = true;
        } else {
          if (currentActiveStep === null) {
            currentActiveStep = index;
          }
        }
      });
      setCompleted(newCompleted);
    }
    setAllSteps(allSteps);
  }

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.product_name !== undefined) {
      setTempRfpName(parsed.product_name)
    }
  }, [])

  useEffect(() => {
    getRFPData()
  }, [activeStep]);


  const getRFPData = () => {
    rfpService.getrfpByById(rfpId, true).then(
      res => {
        if (res !== undefined && res) {
          setSubmitted(res.rfp.isSubmitted)
          if (res.rfpStepper && res.rfpStepper.isRfpAccepted) {
            setInviteAcceptanceState(new InviteAcceptanceModel({ acceptStatus: parseInt(res.rfpStepper.isRfpAccepted) }))
          }
          extractAndSetSteps(res.rfp, res.rfpStepper);
          getStepData(activeStep);
          setCreditInfo(res.rfp.creditInfo);
          setFreeCreditInfo(res.rfp.freeCreditInfo);
        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    if (questionColorChange) getTechnicalDetailData()
  }, [questionColorChange])

  const getTechnicalDetailData = () => {
    technicalDetailService.getTechnicalDetailById(rfpId).then(
      res => {


        if (res !== undefined && res) {
          if (res.technicalEval.overview.length === 0) {
            res.technicalEval.overview = '[{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"n\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}]'
          } else {
            res.technicalEval.overview = JSON.parse(res.technicalEval.overview[0]);
          }
          setTechnicalEvaluationState(res.technicalEval)
          setQuestionAnswerData(res.quesAns);

        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    console.log(technicalEvaluationState, "technicalEvaluationState")
    getRfpByIdSubmitTechnicalUpdate(technicalEvaluationState);
  }, [technicalEvaluationState])




  const saveTechnicalDetails = (values) => {

    technicalDetailService.addInvitedTechnicalDetail(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveAcceptReject = (values) => {

    values.rfpId = rfpId;
    rfpService.saveInvite(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getCommercialDetailData = () => {
    commercialDetailService.getCommercialDetailById(rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (res.commercialEval.overview.length === 0) {
            res.commercialEval.overview = '[{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"n\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}]'
          } else {
            res.commercialEval.overview = JSON.parse(res.commercialEval.overview[0]);
          }
          // res.commercialEval.overview = JSON.parse(res.commercialEval.overview[0]);
          setCommercialEvaluationState(new CommercialEvaluationAnswerModel(res.commercialEval));
          setQuestionAnswerData(res.quesAns);
        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    getRfpByIdSubmitCommercialUpdate(commercialEvaluationState);
  }, [commercialEvaluationState])


  const saveCommercialDetails = (values) => {
    commercialDetailService.addInvitedCommercialDetail(values, rfpId).then(
      res => {
        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            var temp_active_step = activeStep;
            setActiveStep(temp_active_step + 1);
            //alert(activeStep + 1)
            getStepData(temp_active_step + 1);
            //alert(activeStep + 1)
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch((err) => {
      console.log(':: err ', err)
      setHasError(true)
    })
  }

  const saveProject = (values) => {
    let rfpId = match.params.rfpId;
    projectService.addProject(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const savePricing = (values) => {
    values.rfpId = rfpId;
    pricingService.addPricing(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getPricing = () => {
    pricingService.getPricing(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setPricingState(new PricingModel(res.pricing));
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveNDA = (values) => {
    values.rfpId = match.params.rfpId;
    ndaService.addNDAAnswer(values).then(
      res => {
        if (values.type === 'submit' || values.type === 'accept-submit') {
          history.push('/app/dashboard');
        } else if (values.type === 'next') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          getStepData(activeStep + 1);
        } else {
          const autoClose = true;
          const keepAfterRouteChange = false;
          alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
        }
      }
    ).catch(() => setHasError(true))
  }

  const submitResponseRfp = (values) => {
    var rfpId = match.params.rfpId;
    ndaService.submitResponse(rfpId).then(
      res => {
        const autoClose = true;
        const keepAfterRouteChange = false;
        // alertService.success('Thanks your RFP is submitted successfully', { autoClose, keepAfterRouteChange });
        window.scrollTo(0, 0)
        window.localStorage.setItem("submitResponseStatus", "true")
        setTimeout(() => window.location.href = '/app/dashboard', 1500);
      }
    ).catch(() => {
      alert('some error has occured')
    })
  }

  const getNDA = () => {
    ndaService.getNDAAns(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setNDAState(new NdaAnswerModel(res.NDAAns));
        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteNDAAns = (fileName) => {
    if (!is_submitted) {
      if (window.confirm('Are you sure to delete this file?')) {
        ndaService.deleteNDAAns(fileName, rfpId).then((res) => {
          if (res !== undefined && res) {
            getStepData(activeStep);
          }
        })
      }
    } else {
      alert('Your RFP is submitted, no more changes are allowed')
    }

  }

  const deleteFinalPresentation = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      finalPresentationService.deleteFinalPresentationFile(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }
  }

  const questionColor = localStorage.getItem("questionColor")

  useEffect(() => {
    setTimeout(() => { setQuestionColorChange(false) }, 2000)
  }, [questionColor])

  const saveLegaldocument = (values) => {
    values.rfpId = match.params.rfpId;
    legalService.addLegalDocument(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getLegalDocument = () => {
    legalService.getLegalDocuments(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setLegalState(new LegalContractsModel(res.legalDoc));
        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteLegalDocument = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      legalService.deleteLegalDocuments(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }
  }

  const saveFinalPresentation = (values) => {
    values.rfpId = match.params.rfpId;
    finalPresentationService.addSubmitFinalPresenation(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getFinalPresentation = () => {
    finalPresentationService.getFinalPresentation(rfpId).then(
      res => {

        if (res !== undefined && res && res.finalPresenation) {
          if (res.finalPresenation.presentationAgenda.length == 1)
            res.finalPresenation.presentationAgenda = JSON.parse(res.finalPresenation.presentationAgenda);
          setFinalPresentationState(new FinalPresentationModel(res.finalPresenation));
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveReference = (values) => {
    values.rfpId = match.params.rfpId;
    referenceService.addReference(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getReference = () => {
    referenceService.getReference(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setReferenceState(new ReferencesModel(res.reference));
        }
      }
    ).catch(() => setHasError(true))
  }

  const getCompanyData = () => {
    companyService.getCompanyByUser(true, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (res.company?.overview) {
            res.company.overview = JSON.parse(res.company.overview);
          }

          setCompanyState(new CompanyModel(res.company))
        }
      }
    ).catch(() => setHasError(true))
  }

  const getProjectData = () => {
    projectService.getProject(rfpId).then(
      res => {
        if (res !== undefined && res) {
          res.project.projectDescription = JSON.parse(res.project.projectDescription);
          const a = new ProjectModel(res.project);
          setProjectState(a);
        }
      }
    ).catch(() => setHasError(true))
  }

  const getStepData = (step) => {
    switch (allSteps[step]) {
      case 'companyInformation':
        getCompanyData();
        break;
      case 'projectInformation':
        getProjectData();
        break;
      case 'technicalRequirements':
        getTechnicalDetailData();
        break;
      case 'commercialEvaluation':
        getCommercialDetailData();
        break;
      case 'nda':
        getNDA();
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        getFinalPresentation();
        break;
      case 'references':
        getReference();
        break;
      case 'pricingNegotiations':
        getPricing();
        break;
      case 'legalContracts':
        getLegalDocument();
        break;
      case 'rfpCompletion':
        break;
      default:

        break;
    }
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    getStepData(step);
  };

  const handleNext = (values) => {
    handleComplete();
    console.log(values.step, "values.step...")
    switch (allSteps[values.step]) {
      case 'companyInformation':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        getStepData(activeStep + 1);
        break;
      case 'projectInformation':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        getStepData(activeStep + 1);
        break;
      case 'technicalRequirements':
        saveTechnicalDetails(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'commercialEvaluation':
        saveCommercialDetails(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'nda':
        saveNDA(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        saveFinalPresentation(values)
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'references':
        setMobileNumberHide(true)
        saveReference(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'pricingNegotiations':
        savePricing(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'legalContracts':
        saveLegaldocument(values);
        if (values.type === 'publish') {
          submitResponseRfp(values);
        }
        break;
      case 'rfpCompletion':
        break;
      default:
        break;
    }
  };
  const isStepOptional = (step) => {
    return true;
  };

  const handleBack = () => {

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleQuestionChange = (value) => {
    setQuestionColorChange(true)
  }

  const mobileNumberChange = (value) => {
    console.log(value, "value")
    setMobileNumberHide(value)
  }

  const handleSkip = (step) => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getStepData(activeStep + 1);
  };

  function getStepContent(step) {
    submitRfpUpdateStepper(step)
    switch (allSteps[step]) {
      case 'companyInformation':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <CompanyDetails
            isSubmitView={true}
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            setCompanyState={setCompanyState}
            companyState={companyState}
            step={step}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'projectInformation':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <ProjectDetails
            isSubmitView={true}
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            projectState={projectState}
            setProjectState={setProjectState}
            step={step}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'technicalRequirements':
        return (<Container maxWidth="sm" className={classes.custWidthRFP}>
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <TechnicalEvaluationAnswer
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            step={step}
            totalStepCount={totalStepCount}
            handleQuestionChange={handleQuestionChange}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            technicalEvaluationState={technicalEvaluationState}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            question_answer_data={question_answer_data}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'commercialEvaluation':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <CommercialEvaluationAnswer
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            step={step}
            totalStepCount={totalStepCount}
            commercialEvaluationState={commercialEvaluationState}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            is_submitted={is_submitted}
            question_answer_data={question_answer_data}
          />
        </Container>);
      case 'nda':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <NdaAnswer
            projectState={projectState}
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            ndaState={ndaState}
            step={step}
            totalStepCount={totalStepCount}
            deleteNDAAns={deleteNDAAns}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            companyState={companyState}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'proofOfconceptValue':
        return (<Container maxWidth="md">
          Coming Soon
        </Container>);
      case 'finalPresentation':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <FinalPresentation
            isSubmitView={true}
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            setFinalPresentationState={setFinalPresentationState} finalPresentationState={finalPresentationState}
            step={step}
            totalStepCount={totalStepCount}
            deleteFinalPresentation={deleteFinalPresentation}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'references':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <References
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            setReferenceState={setReferenceState}
            mobileNumberHide={mobileNumberHide}
            onMobileChange={mobileNumberChange}
            referenceState={referenceState}
            step={step}
            totalStepCount={totalStepCount}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'pricingNegotiations':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <Pricing
            isSubmitView={true}
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            pricingState={pricingState}
            setPricingState={setPricingState}
            step={step}
            totalStepCount={totalStepCount}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            companyState={companyState}
            is_submitted={is_submitted}
          />
        </Container>);
      case 'legalContracts':
        return (<Container maxWidth="md">
          <InviteAcceptanceState
            inviteAcceptanceState={inviteAcceptanceState}
            companyState={companyState}
            projectState={projectState}
            updateState={updateState}
            is_submitted={is_submitted}
            creditInfo={creditInfo}
            freeCreditInfo={freeCreditInfo}
            rfpId={rfpId}
          />
          <LegalContracts
            handleNext={handleNext}
            handleSkip={handleSkip}
            handleBack={handleBack}
            setLegalState={setLegalState}
            legalState={legalState}
            deleteLegalDocument={deleteLegalDocument}
            step={step}
            totalStepCount={totalStepCount}
            completed={Object.values(completed).length}
            currentStepCompleteStatus={completed[step]}
            acceptStatus={inviteAcceptanceState.acceptStatus}
            is_submitted={is_submitted}
          />
        </Container>);
      default:
        return 'Unknown step';
    }
  }

  const updateState = (value) => {

    inviteAcceptanceState.acceptStatus = value;
    setInviteAcceptanceState(new InviteAcceptanceModel(inviteAcceptanceState));

    var data = {
      rfpId: rfpId,
      step: 3,
      acceptStatus: value,
      type: 'save'
    }

    rfpService.saveInvite(data).then(
      res => {

        if (res !== undefined && res) {
          // 
          if (res.message === "Invitation has been rejected") {

            alertService.success('We are sorry to hear that you are not interested in participating in the RFP. We will inform the buyer', { autoClose, keepAfterRouteChange });
            setTimeout(() => window.location.href = '/app/dashboard', 1500);
          } else {
            alertService.success('Congratulations you have accepted the RFP invite', { autoClose, keepAfterRouteChange });

          }
        }
      }
    ).catch(() => setHasError(true))
  }


  return (
    <Page className={classes.root} title="Submit RFP">

      <div id="rightContain">

        <Container maxWidth="lg" className="main-wrapper">
          <div className={classes.root}>
            <Grid container spacing={0}>

              <Grid className={classes.leftStepPanel} item md={2} sm={3} xs={12} className="sd_stepper requestly-positon small-stepper-submit">
                <Stepper nonLinear activeStep={activeStep} orientation="vertical" className="ins_stepper requestly-stepper">
                  {steps.map((label, index) => (
                    <Step key={label} className="requestly-stepper-step ">
                      <StepButton className={classes.buttonStepper + ' requestly-span'} onClick={handleStep(index)} completed={completed[index]}>
                        {label}
                      </StepButton>
                      <StepContent>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Grid>

              <Grid container style={{ height: 'auto', marginBottom: '100px' }} className={classes.inputAll} md={9} item sm={9} xs={12}>

                {getStepContent(activeStep)}
                {activeStep === steps.length && (
                  <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Grid>

            </Grid>
          </div>
        </Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
  rfpSubmitData: state.submitRfp.rfpData,
  submitCommericalData: state.submitRfp.rfpSubmitCommericalData,
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByUserIdStart: (value) => dispatch(getRfpByUserIdStart(value)),
  getRfpByIdSubmitTechnicalStart: (value) => dispatch(getRfpByIdSubmitTechnicalStart(value)),
  getRfpByIdSubmitTechnicalUpdate: (value) => dispatch(getRfpByIdSubmitTechnicalUpdate(value)),
  getRfpByIdSubmitCommercialUpdate: (value) => dispatch(getRfpByIdSubmitCommercialUpdate(value)),
  getRfpByIdSubmitCommercialStart: (value) => dispatch(getRfpByIdSubmitCommercialStart(value)),
  submitRfpUpdateStepper: (value) => dispatch(submitRfpUpdateStepper(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitRfpView);

