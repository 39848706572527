/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Tooltip
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Formik } from "formik";
import ChipInput from "material-ui-chip-input";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import RichText from "src/components/RichText/RichText";
import CompanyService from "src/services/company/company.service";
import ProductService from "src/services/product/product.service";
import { ToastNotifyError } from 'src/ToastMessage/ToastNotify.js';
import {
  renderFileList,
  renderFileListDataSheet
} from "src/views/shared/components/FileList";
import "src/views/shared/css/common.scss";
import ErrorComponent from "../../components/ErrorComponent";
import { customProductValidations } from "../../utils/utils";
import { ProductInfo } from "./product-model";
import PublishModal from "./publishModal";

const useStyles = makeStyles(() => ({
  root: {},
  companyHeadings: {
    backgroundColor: "#40506b",
    color: "#fff"
  },
  uploadedFileContainer: {
    height: "30px",
    margin: "45px 0 0",
    padding: "8px 433px 2px 8px",
    backgroundColor: "#4688f0",
    width: "100%"
  },
  uploadedFileText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#ffffff"
  },
  publishButton: {}
}));

const ProductDetails = ({
  handleProductSubmit,
  handleNext,
  handleSkip,
  handleBack,
  deleteDataSheet,
  isSubmitView,
  className,
  productState,
  setProductState,
  publishButton,
  step,
  deleteProductModal,
  ...rest
}) => {
  const classes = useStyles();
  const companyService = new CompanyService();
  let file = [];
  const [localFile, setLocalFile] = React.useState([]);
  const [product_id, setProductid] = React.useState("");
  const [companyName, setCompanyName] = React.useState();
  const companyId = localStorage.getItem("companyId");
  const [error_values, setErrorValues] = React.useState(null);
  const [publishModalOpen, setPublishModalOpen] = React.useState(false);
  const [stepButton, setStepButton] = React.useState();
  const [formValue, setFormValue] = React.useState();

  const getCompanyData = () => {
    companyService
      .getCompanyByUser(false)
      .then(res => {
        if (res !== undefined && res) {
          if (res?.company?.companyName) {
            setCompanyName(res.company.companyName);
          }
        }
      })
      .catch(err => console.log(":: err", err));
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    if (error_values !== null) {
      customProductValidations(productState).then(validation_response => {
        setErrorValues(validation_response);
      });
    }
    setLocalFile([]);
  }, [productState]);

  const handleFilesChange = files => {
    if (localFile.length >= 3) {
      // const autoClose = true;
      // const keepAfterRouteChange = false;
      // alertService.error("You cannot add more than 3 files.", {
      //   autoClose,
      //   keepAfterRouteChange
      // });
      ToastNotifyError("You cannot add more than 3 files.");
      return;
    }
    if (productState.isPublished === 1) {
      return "";
    }
    console.log("files -->", files);
    const tempFile = [...localFile];
    if (files.length > 0) {
      const fileDate = {
        userName: localStorage.getItem("user"),
        userId: localStorage.getItem("userId"),
        companyName,
        companyId,
        createdAt: new Date(),
        name: files[files.length - 1].name,
        location: URL.createObjectURL(files[files.length - 1]),
        mimetype: "",
        file: files[files.length - 1]
      };
      tempFile.push(fileDate);
    }
    setLocalFile(tempFile);
    file = tempFile;
  };

  const deleteLocalFile = index => {
    const tempFile = [...localFile];
    tempFile.splice(index, 1);
    setLocalFile(tempFile);
    file = tempFile;
  };

  const type = "";

  const handleProductInfoChange = richText => {
    if (productState.isPublished === 1) {
      return;
    }
    productState.productInfo = richText;
  };

  const productService = new ProductService();
  const [hasError, setHasError] = useState(false);

  const handleChangeKeywords = keywords => {
    productState.keywords = keywords;
    productState._id = productState.productId;
    setProductState(new ProductInfo(productState));
  };
  const handleChangeNotableCustomers = notableCustomers => {
    productState.notableCustomers = notableCustomers;
    productState._id = productState.productId;
    setProductState(new ProductInfo(productState));
  };

  const handleChangeTargetPersona = targetPersona => {
    productState.targetPersona = targetPersona;
    productState._id = productState.productId;
    setProductState(new ProductInfo(productState));
  };

  const handleChangeCompetitors = competitors => {
    productState.competitors = competitors;
    productState._id = productState.productId;
    setProductState(new ProductInfo(productState));
  };
  const handleChangeVideoUrls = productSolVideoUrl => {
    productState.productSolVideoUrl = productSolVideoUrl;
    productState._id = productState.productId;
    setProductState(new ProductInfo(productState));
  };

  const updateState = event => {
    setProductState({
      ...productState,
      [event.target.name]: event.target.value
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={productState}
      validator={() => ({})}
      onSubmit={async values => {
        values.step = 1;
        values.file = localFile.map(datum => datum.file);
        values.productId = productState.productId;
        values.companyId = companyId;
        console.log(values);
        const validation_response = await customProductValidations(values);
        setErrorValues(validation_response);
        console.log(validation_response);
        if (Object.keys(validation_response).length === 0) {
          setErrorValues(null);
          if (stepButton === "savePublish") {
            setFormValue(values);
            setPublishModalOpen(true);
          } else if (stepButton === "save") {
            handleProductSubmit(values);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form className="requestly-rfp-form company-information mb-5">
          <div className={`${classes.pageSize} company-info-div`}>
            <Card style={{ marginBottom: "50px" }}>
              <CardHeader
                title="Product Information"
                subheader="(Please provide information on this product. This will be seen by prospects and customers when they evaluate vendors.) "
                className={`${classes.companyHeadings} requestly-rfp-heading`}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      error={Boolean(touched.productName && errors.productName)}
                      fullWidth
                      helperText={touched.productName && errors.productName}
                      label="Product Name"
                      name="productName"
                      onBlur={updateState}
                      onChange={handleChange}
                      value={values.productName}
                      variant="outlined"
                      className="requestly-bg-gray requestly-h-40"
                      disabled={productState.isPublished === 1}
                    />

                    {error_values !== null &&
                      error_values.productName !== undefined ? (
                      <ErrorComponent text={error_values.productName} />
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Typography
                      style={{
                        marginTop: "-15px",
                        color: "#547087",
                        fontSize: "16px"
                      }}
                    >
                      {" "}
                      Product Info{" "}
                    </Typography>
                    <RichText
                      className="requestly-bg-gray"
                      value={productState.productInfo}
                      onTextChange={handleProductInfoChange}
                    />
                    {error_values !== null &&
                      error_values.productInfo !== undefined ? (
                      <ErrorComponent text={error_values.productInfo} />
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Tooltip
                      title="Enter a KEYWORD or PHRASE, press ENTER after each oneXYZ"
                      arrow
                    >
                      <ChipInput
                        error={Boolean(touched.keywords && errors.keywords)}
                        fullWidth
                        disabled={isSubmitView}
                        helperText={touched.keywords && errors.keywords}
                        label="Keywords"
                        defaultValue={productState.keywords}
                        onChange={chip => handleChangeKeywords(chip)}
                        variant="outlined"
                        className="requestly-bg-gray multiselect-textbox"
                        disabled={productState.isPublished === 1}
                      />
                    </Tooltip>
                    {error_values !== null &&
                      error_values.keywords !== undefined ? (
                      <ErrorComponent text={error_values.keywords} />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Tooltip
                      title="Enter the customer COMPANY NAME or ORGANISATION and PRESS ENTER after each one"
                      arrow
                    >
                      <ChipInput
                        error={Boolean(
                          touched.notableCustomers && errors.notableCustomers
                        )}
                        fullWidth
                        disabled={isSubmitView}
                        helperText={
                          touched.notableCustomers && errors.notableCustomers
                        }
                        label="Notable Customers"
                        defaultValue={values.notableCustomers}
                        onChange={chip => handleChangeNotableCustomers(chip)}
                        variant="outlined"
                        className="requestly-bg-gray multiselect-textbox"
                        disabled={productState.isPublished === 1}
                      />
                    </Tooltip>
                    {error_values !== null &&
                      error_values.notableCustomers !== undefined ? (
                      <ErrorComponent text={error_values.notableCustomers} />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Tooltip
                      title="Enter a JOB TITLE or ROLE and PRESS ENTER after each one"
                      arrow
                    >
                      <ChipInput
                        error={Boolean(
                          touched.targetPersona && errors.targetPersona
                        )}
                        fullWidth
                        disabled={isSubmitView}
                        helperText={
                          touched.targetPersona && errors.targetPersona
                        }
                        label="Target Persona"
                        defaultValue={values.targetPersona}
                        onChange={chip => handleChangeTargetPersona(chip)}
                        variant="outlined"
                        className="requestly-bg-gray multiselect-textbox"
                        disabled={productState.isPublished === 1}
                      />
                    </Tooltip>
                    {error_values !== null &&
                      error_values.targetPersona !== undefined ? (
                      <ErrorComponent text={error_values.targetPersona} />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Tooltip
                      title="Enter a COMPETITOR NAME and PRESS ENTER after each one"
                      arrow
                    >
                      <ChipInput
                        error={Boolean(
                          touched.competitors && errors.competitors
                        )}
                        fullWidth
                        disabled={isSubmitView}
                        helperText={touched.competitors && errors.competitors}
                        label="Competitors"
                        defaultValue={values.competitors}
                        onChange={chip => handleChangeCompetitors(chip)}
                        variant="outlined"
                        className="requestly-bg-gray multiselect-textbox"
                        disabled={productState.isPublished === 1}
                      />
                    </Tooltip>
                    {error_values !== null &&
                      error_values.competitors !== undefined ? (
                      <ErrorComponent text={error_values.competitors} />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Tooltip
                      title="PASTE or TYPE the VIDEO URL and PRESS ENTER... It must be a YouTube video/url"
                      arrow
                    >
                      <ChipInput
                        fullWidth
                        disabled={isSubmitView}
                        helperText={
                          touched.productSolVideoUrl &&
                          errors.productSolVideoUrl
                        }
                        label="Product solution video url"
                        defaultValue={values.productSolVideoUrl}
                        onChange={chip => handleChangeVideoUrls(chip)}
                        variant="outlined"
                        className="requestly-bg-gray multiselect-textbox"
                        disabled={productState.isPublished === 1}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      helperText={
                        touched.productLaunchDate && errors.productLaunchDate
                      }
                      label="Product Launch Date"
                      name="productLaunchDate"
                      type="date"
                      onBlur={updateState}
                      onChange={handleChange}
                      value={values.productLaunchDate}
                      variant="outlined"
                      className="requestly-bg-gray requestly-h-40"
                      disabled={productState.isPublished === 1}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    {error_values !== null &&
                      error_values.productLaunchDate !== undefined ? (
                      <ErrorComponent text={error_values.productLaunchDate} />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </CardContent>

              <Accordion defaultExpanded className="requestly-fin-info-tab">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-upload-content"
                  id="panel-upload"
                  className="requestly-tab-height"
                >
                  <Typography className={classes.heading}>
                    Upload Datasheets
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <CardContent>
                      <Grid
                        className={classes.listChecks}
                        container
                        spacing={0}
                      >
                        <Grid md={12} sm={12} xs={12}>
                          <Grid item md={12} xs={12}>
                            <DropzoneArea
                              dropzoneText="Drag and drop a file here or click  Supported Formats : ( .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pages, .odt, .rtf, .pdf, .jpg, .png, .jpeg, .mp4,.mov,.mkv,.mpeg4 )"
                              maxFileSize="104857600"
                              acceptedFiles={[
                                ".doc",
                                ".docx",
                                ".xls",
                                ".xlsx",
                                // ".csv",
                                // ".tsv",
                                ".ppt",
                                ".pptx",
                                ".pages",
                                ".odt",
                                ".rtf",
                                ".pdf",
                                ".jpg",
                                ".png",
                                ".jpeg",
                                ".mp4",
                                ".mov",
                                ".mkv",
                                ".mpeg4"
                              ]}
                              fileObjects={localFile}
                              onChange={handleFilesChange}
                              showFileNames
                              showPreviewsInDropzone={false}
                              filesLimit={-1}
                              showAlerts={false}
                            // showAlerts={productState.isPublished !== 1}
                            />
                          </Grid>
                          {localFile?.length ||
                            (values.dataSheets_docs &&
                              values.dataSheets_docs.length > 0) ? (
                            <Grid item md={12} xs={12}>
                              <Box className={classes.uploadedFileContainer}>
                                <Typography
                                  className={classes.uploadedFileText}
                                >
                                  Uploaded files
                                </Typography>
                              </Box>
                            </Grid>
                          ) : (
                            ""
                          )}

                          {localFile?.length ? (
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{ marginTop: "15px" }}
                            >
                              {renderFileList(
                                localFile,
                                classes.table,
                                true,
                                deleteLocalFile,
                                productState.isPublished
                              )}
                            </Grid>
                          ) : null}

                          {values.dataSheets_docs &&
                            values.dataSheets_docs.length > 0 ? (
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{ marginTop: "15px" }}
                            >
                              {renderFileListDataSheet(
                                values.dataSheets_docs,
                                classes.table,
                                false,
                                deleteDataSheet,
                                productState.productId,
                                productState.isPublished
                              )}
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded className="requestly-fin-info-tab">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-pricing-content"
                  id="panelpPricing"
                  className="requestly-tab-height"
                >
                  <Typography className={classes.heading}>Pricing</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            helperText={
                              touched.pricingProductName &&
                              errors.pricingProductName
                            }
                            label="Product Name"
                            name="pricingProductName"
                            onChange={handleChange}
                            onBlur={updateState}
                            value={values.productName}
                            variant="outlined"
                            className="requestly-bg-gray requestly-h-40"
                            disabled={productState.isPublished === 1}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            helperText={touched.unit && errors.unit}
                            label="Unit"
                            name="unit"
                            onBlur={updateState}
                            onChange={handleChange}
                            value={values.unit}
                            variant="outlined"
                            className="requestly-bg-gray requestly-h-40"
                            disabled={productState.isPublished === 1}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Divider />

              <div
                style={{
                  display: (publishModalOpen || deleteProductModal) && "none"
                }}
              >
                <div className="footer-btn-fixed">
                  <footer className="page-footer green">
                    <Grid item container>
                      <Grid container spacing={2} justify="center">
                        <Grid item md={3} sm={3} xs={3}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setStepButton("save");
                              handleSubmit(values);
                            }}
                            disabled={productState.isPublished === 1}
                          >
                            Save
                          </Button>
                        </Grid>
                        <Grid item md={3} sm={3} xs={3}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setStepButton("savePublish");
                              handleSubmit(values);
                            }}
                            disabled={productState.isPublished === 1}
                            style={{ width: "62%" }}
                          >
                            Save & Publish
                          </Button>
                        </Grid>
                        {productState.isPublished === 1 && (
                          <Grid
                            item
                            md={3}
                            sm={3}
                            xs={3}
                            className={classes.publishButton}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                setPublishModalOpen(true);
                              }}
                            >
                              UnPublish
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </footer>
                </div>
              </div>
            </Card>
          </div>
          {publishModalOpen && (
            <PublishModal
              publishModalOpen={publishModalOpen}
              product={productState.productId}
              isPublished={productState.isPublished}
              formValue={formValue}
              closeEditModal={() => {
                setPublishModalOpen(false);
              }}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

ProductDetails.propTypes = {
  className: PropTypes.string
};

export default ProductDetails;
