import * as Yup from 'yup';
export const ReferencesValidations = Yup.object().shape({
  faceToFaceOnSiteLocation: Yup.string().when("presentationType", {
    is: 'faceToFace',
    then: Yup.string().when("faceToFaceType", {
      is: 'onSiteOffice',
      then: Yup.string().required("Office location is mandatory")
    })
  }),
  conferenceCallDetails: Yup.string().when("presentationType", {
    is: 'remoteLive',
    then: Yup.string().when("remoteLiveType", {
      is: 'internalConferenceCall',
      then: Yup.string().required("Conference call details are mandatory")
    })
  })
});