import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import 'src/views/shared/css/common.scss';
import { getRfpByIdSubmitCommercialUpdate, getRfpByIdSubmitTechnicalUpdate } from "../../store/submitRfp/actions";
import { useStyles } from '../../views/TechnicalEvaluation/TechnicalEvaluationStyle';
import { TechnicalEvaluationAnswerModel } from '../../views/TechnicalEvaluationAnswer/TechnicalEvaluationAnswerModel';
import QuestionAnswers from '../QuestionAnswers/index';





const TechinicalRequirementSubmitQuestion = ({ categoryIndex, handleQuestionChange, sectionIndex, questionIndex, questionInfo, values, touched, errors, isCommercial, question_details, submitTechnicalEvalUpdateData, step, getRfpByIdSubmitTechnicalUpdate, getRfpByIdSubmitCommercialUpdate, submitCommercialEvalUpdateData, match, ...rest }) => {
  const classes = useStyles();
  const handleAnswerBlur = (event, categoryIndex, sectionIndex, questionIndex) => {
    let dataRedux // type: 1 - Location , 2 - Function 

    if (step === 3) {
      submitTechnicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answer = event.target.value;
      getRfpByIdSubmitTechnicalUpdate(new TechnicalEvaluationAnswerModel(submitTechnicalEvalUpdateData))
    }

    if (step === 4) {
      submitCommercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answer = event.target.value;
      getRfpByIdSubmitCommercialUpdate(new TechnicalEvaluationAnswerModel(submitCommercialEvalUpdateData))
    }
  }

  const handleAnswerChange = (event, categoryIndex, sectionIndex, questionIndex) => {
    let dataRedux // type: 1 - Location , 2 - Function 
    if (step === 3) {
      submitTechnicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answer = event.target.value;
      getRfpByIdSubmitTechnicalUpdate(new TechnicalEvaluationAnswerModel(submitTechnicalEvalUpdateData))
    }
    if (step === 4) {
      submitCommercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answer = event.target.value;
      getRfpByIdSubmitCommercialUpdate(new TechnicalEvaluationAnswerModel(submitCommercialEvalUpdateData))
    }
  }

  return (
    <React.Fragment>
      <div key={questionIndex}>
        <Grid item md={12} sm={12} xs={12}>

          {isCommercial ? (
            <Grid item md={6} xs={12}>
              <Typography>{questionInfo.question}</Typography>
            </Grid>) : (<Grid item md={5} xs={12}>
              <Typography>{questionInfo.question}</Typography>
            </Grid>)}
          {questionInfo.type === 'detailed' ?
            (<Grid item md={6} xs={10}>
              <TextField
                error={Boolean(touched.score && errors.score)}
                fullWidth
                multiline
                rows={4}
                required={true}
                helperText={touched.score && errors.score}
                label="Your Answer"
                name={`questions[${questionIndex}].answer`}
                value={questionInfo.answer}
                onBlur={(e) => handleAnswerBlur(e, categoryIndex, sectionIndex, questionIndex)}
                onChange={(e) => handleAnswerChange(e, categoryIndex, sectionIndex, questionIndex)}
                variant="outlined" />
            </Grid>) :
            (<Grid item md={6} xs={10}>
              <RadioGroup className={classes.radioGrpLine} id="questionAns" aria-label="scope" name="answer" value={questionInfo.answer} onChange={(e) => handleAnswerChange(e, categoryIndex, sectionIndex, questionIndex)} >
                <Grid className={classes.item} item md={6} sm={6} xs={6}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                </Grid>
                <Grid className={classes.item} item md={6} sm={6} xs={6}>
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </Grid>
              </RadioGroup>
            </Grid>)}
          {(!isCommercial || 1) ? (
            <Grid item md={1} xs={2}>
              <QuestionAnswers
                questionId={questionInfo.questionId}
                question_details={question_details}
                questionInfo={questionInfo.question}
                handleQuestionChange={handleQuestionChange}
                category={window.location.href.includes("edit-rfp") && step === 4 ? submitTechnicalEvalUpdateData.categories && submitTechnicalEvalUpdateData.categories[categoryIndex].categoryName
                  : window.location.href.includes("submit-rfp") && step === 3 ? submitTechnicalEvalUpdateData.categories && submitTechnicalEvalUpdateData.categories[categoryIndex].categoryName :
                    window.location.href.includes("edit-rfp") && step === 5 ? submitCommercialEvalUpdateData.categories && submitCommercialEvalUpdateData.categories[categoryIndex].categoryName
                      : submitCommercialEvalUpdateData.categories && submitCommercialEvalUpdateData.categories[categoryIndex].categoryName
                }
                section={window.location.href.includes("edit-rfp") && step === 4 ? submitTechnicalEvalUpdateData.categories && submitTechnicalEvalUpdateData.categories[categoryIndex].categoryName
                  : window.location.href.includes("submit-rfp") && step === 3 ? submitTechnicalEvalUpdateData.categories && submitTechnicalEvalUpdateData.categories[categoryIndex].categoryName :
                    window.location.href.includes("edit-rfp") && step === 5 ? submitCommercialEvalUpdateData.categories && submitCommercialEvalUpdateData.categories[categoryIndex].categoryName
                      : submitCommercialEvalUpdateData.categories && submitCommercialEvalUpdateData.categories[categoryIndex].categoryName}
              />
            </Grid>) : null}
        </Grid>
      </div>
      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
TechinicalRequirementSubmitQuestion.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  submitTechnicalEvalUpdateData: state.submitRfp.submitRfpTechnicalDataUpdate,
  submitCommercialEvalUpdateData: state.submitRfp.rfpSubmitCommericalDataUpdate,
  step: state.submitRfp.SubmitRfpStepUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdSubmitTechnicalUpdate: (value) => dispatch(getRfpByIdSubmitTechnicalUpdate(value)),
  getRfpByIdSubmitCommercialUpdate: (value) => dispatch(getRfpByIdSubmitCommercialUpdate(value))

});

export default connect(mapStateToProps, mapDispatchToProps)(TechinicalRequirementSubmitQuestion);
