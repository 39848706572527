import { Box, Button, Card, CardContent, CardHeader, Divider, FormControlLabel, Grid, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import GetAppIcon from '@material-ui/icons/GetApp';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Form, Formik } from 'formik';
import MaterialTable from 'material-table';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { history } from 'src/utils/utils';
import { alertService } from '../../components/toaster/alert.service';
import { useStyles } from './FinalPresentationStyle';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline className="site-delete-icon" {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit className="site-delete-icon" {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableOptions = {
  actionsColumnIndex: 3,
  search: false,
  paging: false
};

const FinalPresentation = ({ handleNext, handleSkip, handleBack, isSubmitView, completed, className, currentStepCompleteStatus, setFinalPresentationState, finalPresentationState, step, totalStepCount, deleteFinalPresentation, acceptStatus, is_submitted = false, ...rest }) => {
  const classes = useStyles();
  const [localFile, setLocalFile] = React.useState([]);
  const [mins, setMins] = useState(false);

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);

  let file = []
  const handleFilesChange = (files) => {
    var tempFile = [...localFile];
    if (files.length > 0) {
      tempFile.push(files[files.length - 1])
    }
    setLocalFile(tempFile)
    file = tempFile
  }

  const deleteLocalFile = (index) => {
    var tempFile = [...localFile];
    tempFile.splice(index, 1)
    setLocalFile(tempFile)
    file = tempFile
  }

  const columns = [
    { title: 'Agenda Item', field: 'agendaItemName' },
    { title: 'Description', field: 'agendaItemDescription' },
    { title: 'Allocated Time', field: 'agendaAllocatedTime' },
  ];

  const updateState = (event) => {
    setFinalPresentationState({ ...finalPresentationState, [event.target.name]: event.target.value });
  }

  let editable = {};
  if (!isSubmitView) {
    editable = {
      onRowAdd: (newData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            setFinalPresentationState((prevState) => {
              const presentationAgenda = [...prevState.presentationAgenda];
              presentationAgenda.push(newData);
              return { ...prevState, presentationAgenda };
            });
          }, 600);
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            if (oldData) {
              setFinalPresentationState((prevState) => {
                const presentationAgenda = [...prevState.presentationAgenda];
                presentationAgenda[presentationAgenda.indexOf(oldData)] = newData;
                return { ...prevState, presentationAgenda };
              });
            }
          }, 600);
        }),
      onRowDelete: (oldData) =>
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            setFinalPresentationState((prevState) => {
              const presentationAgenda = [...prevState.presentationAgenda];
              presentationAgenda.splice(presentationAgenda.indexOf(oldData), 1);
              return { ...prevState, presentationAgenda };
            });
          }, 600);
        }),
    };
  }
  var type = '';
  // console.log(finalPresentationState.presentationAgenda, "finalPresentationState.presentationAgenda");
  finalPresentationState && finalPresentationState.presentationAgenda.map((value, key) => {
    if (!value.agendaAllocatedTime || typeof value.agendaAllocatedTime === "string" && value.agendaAllocatedTime.includes("mins")) {
      return
    }
    // console.log(typeof (value.agendaAllocatedTime));
    return value["agendaAllocatedTime"] = `${value.agendaAllocatedTime} mins`

  })

  return (
    <Formik
      initialValues={finalPresentationState}
      enableReinitialize={true}
      //validationSchema={FinalPresentationValidations}
      onSubmit={(values) => {
        values.type = type;
        values.step = step;
        values.digitalRecordingFiles = localFile;
        console.log(finalPresentationState);
        console.log(values);
        if (values.digitalRecordingFiles.length === 0 && finalPresentationState.digitalRecordingFiles.length === 0 && finalPresentationState.presentationType === 'digitalRecording') {
          const autoClose = true;
          const keepAfterRouteChange = false
          window.scrollTo(0, 0)
          alertService.error('Please upload Digital Recording', { autoClose, keepAfterRouteChange })
        } else {
          handleNext(values);
        }
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
        // console.log(values, "values...")
        return (
          <Form className={'requestly-rfp-form company-information mb-5'}>
            <div className={classes.pageSize + ' company-info-div'}>
              <Card className={classes.CardBorder}>
                <CardContent className={classes.cardScrollArea + ' p-0'}>
                  <CardHeader
                    subheader="Select the format of the final presentation"
                    title="Final Presentation"
                    className={classes.companyHeadings + ' requestly-rfp-heading'} />
                  <Divider />
                  <CardContent>
                    <Grid className={classes.item + ' requstly-pragraph-css'} item md={12} sm={12} xs={12}>
                      <Typography>Vendors always welcome the oppotunity to present their solution in person or via conference this is also a great opportunity to engage and make sure you cover any final points that could impact your project.</Typography>
                    </Grid>
                    <Box mt={3}>
                      <Grid className={classes.listChecks} container spacing={0}>
                        <Grid container className={classes.inputAll}>
                          <RadioGroup aria-label="presentationType" name="presentationType" className={classes.width100}
                            value={finalPresentationState.presentationType} onChange={updateState}
                          >
                            {finalPresentationState.presentationType === 'faceToFace' ?

                              <Grid className={classes.item}  >
                                <FormControlLabel disabled={isSubmitView} value="faceToFace" control={<Radio />} label="Face To Face" />
                                {finalPresentationState.presentationType === 'faceToFace' ?
                                  (<Grid container className={classes.inputAll} className={classes.pdLL}>
                                    <RadioGroup className={classes.width100} aria-label="faceToFaceType" name="faceToFaceType"
                                      value={finalPresentationState.faceToFaceType} onChange={updateState}>
                                      <Grid className={classes.item} >
                                        <FormControlLabel disabled={isSubmitView} value="onSiteOffice" control={<Radio />} label="On Site - Our Office" />
                                        {finalPresentationState.faceToFaceType === 'onSiteOffice' ?
                                          (<Grid className={classes.item} >
                                            <TextField
                                              fullWidth
                                              disabled={isSubmitView}
                                              error={Boolean(touched.faceToFaceOnSiteLocation && errors.faceToFaceOnSiteLocation)}
                                              helperText={touched.faceToFaceOnSiteLocation && errors.faceToFaceOnSiteLocation}
                                              label="Enter Office Location"
                                              name="faceToFaceOnSiteLocation"
                                              onBlur={updateState}
                                              onChange={handleChange}
                                              value={values.faceToFaceOnSiteLocation}
                                              variant="outlined"
                                            />
                                          </Grid>) : null}
                                      </Grid>
                                      <Grid className={classes.item}>
                                        <FormControlLabel disabled={isSubmitView} value="vendorOffice" control={<Radio />} label="Vendor Office" />
                                        {finalPresentationState.faceToFaceType === 'vendorOffice' ?
                                          (<Grid className={classes.item} >
                                            <TextField
                                              fullWidth
                                              error={Boolean(touched.faceToFaceOnSiteLocation && errors.faceToFaceOnSiteLocation)}
                                              helperText={touched.faceToFaceOnSiteLocation && errors.faceToFaceOnSiteLocation}
                                              label="Enter Office Location"
                                              name="faceToFaceOnSiteLocation"
                                              onBlur={updateState}
                                              onChange={handleChange}
                                              value={values.faceToFaceOnSiteLocation}
                                              variant="outlined"
                                            />
                                          </Grid>) : null}
                                      </Grid>
                                    </RadioGroup>
                                  </Grid>) : null}
                              </Grid>

                              : ''}
                            {
                              finalPresentationState.presentationType === 'remoteLive'
                                ?

                                <Grid className={classes.item}>
                                  <FormControlLabel disabled={isSubmitView} value="remoteLive" control={<Radio />} label="Remote - Live" />
                                  {finalPresentationState.presentationType === 'remoteLive' ?
                                    (<Grid container className={classes.inputAll} className={classes.pdLL}>
                                      <RadioGroup className={classes.width100} aria-label="remoteLiveType" name="remoteLiveType" value={finalPresentationState.remoteLiveType} onChange={updateState}>
                                        <Grid className={classes.item}>
                                          <FormControlLabel disabled={isSubmitView} value="internalConferenceCall" control={<Radio />} label="Use our internal conference call system" />
                                          {finalPresentationState.remoteLiveType === 'internalConferenceCall' ?
                                            (<Grid className={classes.item}>
                                              <TextField
                                                fullWidth
                                                disabled={isSubmitView}
                                                error={Boolean(touched.conferenceCallDetails && errors.conferenceCallDetails)}
                                                helperText={touched.conferenceCallDetails && errors.conferenceCallDetails}
                                                label="Provide conference call details"
                                                name="conferenceCallDetails"
                                                onBlur={updateState}
                                                onChange={handleChange}
                                                value={values.conferenceCallDetails}
                                                variant="outlined"
                                              />
                                            </Grid>) : null}
                                        </Grid>
                                        {
                                          (finalPresentationState.remoteLiveType === 'vendorConferenceCall') ?
                                            <Grid className={classes.item} >
                                              <FormControlLabel disabled={isSubmitView} value="vendorConferenceCall" control={<Radio />} label="Vendor conference call system" />
                                              {finalPresentationState.remoteLiveType === 'vendorConferenceCall' ?
                                                (<Grid className={classes.item}>
                                                  <TextField
                                                    fullWidth
                                                    error={Boolean(touched.conferenceCallDetails && errors.conferenceCallDetails)}
                                                    helperText={touched.conferenceCallDetails && errors.conferenceCallDetails}
                                                    label="Provide conference call details"
                                                    name="conferenceCallDetails"
                                                    onBlur={updateState}
                                                    onChange={handleChange}
                                                    value={values.conferenceCallDetails}
                                                    variant="outlined"
                                                  />
                                                </Grid>) : null}
                                            </Grid>
                                            : ''
                                        }

                                      </RadioGroup>
                                    </Grid>) : null}
                                </Grid>

                                :

                                ''
                            }
                            {
                              (finalPresentationState.presentationType === 'digitalRecording' && isSubmitView) ?

                                <Grid className={classes.item2}  >
                                  <FormControlLabel disabled={isSubmitView} value="digitalRecording" control={<Radio />} label="Digital Recording (100mb max/file)  (mp4,m4p,m4v,avi,wmv,mov,qt,flv,smf,mpv,webm,ogg) " />
                                  {(finalPresentationState.presentationType === 'digitalRecording' && isSubmitView) ? (
                                    <Grid item md={12} xs={12}>
                                      <DropzoneArea
                                        acceptedFiles={['.MP4', '.M4P', '.M4V', '.AVI', '.WMV', '.MOV', '.QT', '.FLV', '.SWF', '.MPV', '.WEBM', '.OGG']}
                                        onChange={handleFilesChange}
                                        showFileNames={true}
                                        maxFileSize="104857600"
                                        showPreviewsInDropzone={false} />
                                    </Grid>
                                  ) : null}

                                  {
                                    localFile && localFile.length > 0 ? (
                                      <Grid item md={12} xs={12}>
                                        <TableContainer component={Paper}>
                                          <Table className={classes.table} size="small" aria-label="a dense table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell align="left">  File Name </TableCell>
                                                <TableCell align="center"> Delete </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {localFile && localFile.map((fileDetail, index) => (
                                                <TableRow key={index}>
                                                  <TableCell align="left"> {fileDetail.name} </TableCell>
                                                  <TableCell align="center"> <DeleteIcon className="site-delete-icon" onClick={() => deleteLocalFile(index)}></DeleteIcon> </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Grid>
                                    ) : null
                                  }

                                  {values.digitalRecordingFiles_docs && values.digitalRecordingFiles_docs.length > 0 ? (<TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="left">  File Name </TableCell>
                                          <TableCell align="center"> Download </TableCell>
                                          <TableCell align="center"> Delete </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {values.digitalRecordingFiles_docs && values.digitalRecordingFiles_docs.length > 0 && values.digitalRecordingFiles_docs.map((item) => {
                                          let arr = item.name.split('/');
                                          arr = arr[arr.length - 1];
                                          return (<TableRow>
                                            <TableCell align="left"> {arr.replace(arr.slice(arr.lastIndexOf('-'), arr.lastIndexOf('.')), "")} </TableCell>
                                            <TableCell align="center" href={item.location}
                                            ><a target="_blank" href={item.location}
                                            ><GetAppIcon ></GetAppIcon> </a>  </TableCell>
                                            <TableCell align="center"> <DeleteIcon className="site-delete-icon" onClick={() => deleteFinalPresentation(item.key)}></DeleteIcon> </TableCell>

                                          </TableRow>
                                          )
                                        }
                                        )
                                        }
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                  ) : null}
                                </Grid>


                                : ''
                            }
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mt={3}>
                      <Grid className={classes.finalBoxTable + ' mb-2' + ' Presentation-Agenda-table'}>
                        <MaterialTable
                          title="Presentation Agenda"
                          columns={columns}
                          options={tableOptions}
                          data={finalPresentationState.presentationAgenda}
                          icons={tableIcons}
                          editable={editable}
                        />
                      </Grid>
                    </Box>
                  </CardContent>
                </CardContent>
                <Divider />
                <div class="footer-btn-fixed">
                  <footer class="page-footer green">
                    <Grid container spacing={2} justify="center">
                      {(step !== 0 && !is_submitted) ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                            Back
                          </Button>
                        </Grid>
                      ) : null}
                      {(step !== totalStepCount && !is_submitted) ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                            Skip
                          </Button>
                        </Grid>
                      ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                            </Grid> : ''
                        ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Tooltip
                                title={'You must accept RFP invite to submit the RFP response'}
                                placement="top"
                                arrow
                                onOpen={() => {
                                  if (acceptStatus != 1 && !open) {
                                    setOpen(true);
                                  }
                                }}
                                onClose={() => setOpen(false)}
                                open={open}
                              >
                                <div onMouseOver={() => {
                                  setOpen(true)
                                }}
                                  onMouseLeave={() => setOpen(false)}>
                                  <Button style={{ width: '100%' }} disabled={acceptStatus == 1 ? false : true} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                                </div>
                              </Tooltip>
                            </Grid> : ''
                        ) : step === totalStepCount ?
                          (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                              </Grid> : ''
                          ) : (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                              </Grid> : ''
                          )}
                      {
                        (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                          <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                        </Grid> : ''
                      }
                    </Grid>
                  </footer>
                </div>
              </Card>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
};

FinalPresentation.propTypes = {
  className: PropTypes.string
};

export default FinalPresentation;
