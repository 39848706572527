import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { history } from "src/utils/utils";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  statsItem: {
    alignItems: "center",
    display: "flex"
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  },
  para: {
    left: "36%",
    color: "#546e7a !important",
    marginTop: "10px",
    position: "absolute"
  },
  responsePara: {
    left: "33%",
    color: "#546e7a !important",
    marginTop: "10px",
    position: "absolute"
  },
  LiveRfp: {
    left: "41%",
    color: "#546e7a !important",
    marginTop: "10px",
    position: "absolute"
  },
  PDCardHeader: {
    background: "rgb(76,188,105)",
    color: "#ffffff"
  },
  DMCardHeader: {
    background: "rgb(99, 191,184)",
    color: "#ffffff"
  },
  CardHeader: {
    background: "#40506b",
    color: "#ffffff"
  },
  buttonProgress: {
    position: "relative",
    left: "-20%",
    top: "8px",
    marginTop: "10px",
    width: "392px",
    backgroundColor: "lightGreen",
    "@global": {
      ".MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#38c75f"
      }
    }
  },

  buttonProgressBar: {
    position: "relative",
    left: "-3%",
    top: "8px",
    width: "348px",
    marginTop: "10px",
    backgroundColor: "#cbeffb",
    "@global": {
      ".MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#82d7f7"
      }
    }
  },

  boxContainer: {
    padding: "16px 16px 0px 16px"
  },

  active: {
    position: "absolute",
    right: "-11%",
    top: "24%",
    borderRadius: "50%",
    bottom: "9px",
    backgroundColor: "#52b152",
    height: "10px",
    width: "10px"
  },

  inActive: {
    position: "absolute",
    right: "-11%",
    top: "24%",
    borderRadius: "50%",
    bottom: "9px",
    backgroundColor: "grey",
    height: "10px",
    width: "10px"
  },

  decline: {
    position: "absolute",
    right: "-11%",
    top: "24%",
    borderRadius: "50%",
    bottom: "9px",
    backgroundColor: "red",
    height: "10px",
    width: "10px"
  },

  viewDetail: {
    color: "#546e7a !important",
    marginTop: "10px",
    position: "absolute",
    display: "flex"
  }
}));

const ProductCard = ({
  className,
  product,
  isProductDemo,
  isDiscoveryMeeting,
  isVendorPopUp,
  ...rest
}) => {
  const classes = useStyles();

  const handleDashBoard = product => {
    if (
      product.isInvited &&
      !product.isSubmitted &&
      product.invitedRfpStepper &&
      product.invitedRfpStepper.isRfpAccepted === "2"
    ) {
      return;
    }
    localStorage.setItem("rfpId", product._id);
    if (product.isInvited) {
      history.push(
        "/app/submit-rfp" +
        "/" +
        product._id +
        "?product_name=" +
        product.rfpName
      );
    } else if (product.rfpPublished) {
      history.push(
        "/app/dashboard-rfp" +
        "/" +
        product._id +
        "?product_name=" +
        product.rfpName
      );
    } else {
      history.push(
        "/app/update-rfp" +
        "/" +
        product._id +
        "?product_name=" +
        product.rfpName
      );
    }
  };

  const getTitle = () => {
    if (isProductDemo) {
      return "Product demo";
    } else if (isDiscoveryMeeting) {
      return "Discovery Meeting";
    } else {
      return "RFP";
    }
  };

  const handleEngagementClick = data => {
    let id = data.id;
    if (!isVendorPopUp) {
      if (isProductDemo) {
        history.push(`/app/product-request/${id}`);
      } else if (isDiscoveryMeeting) {
        history.push(`/app/demo-meeting/${id}`);
      } else {
        handleDashBoard(data);
      }
    } else {
      if (isProductDemo) {
        history.push(`/app/discovery-meeting-request/${id}/false`);
      } else if (isDiscoveryMeeting) {
        history.push(`/app/discovery-meeting-request/${id}/true`);
      } else {
        handleDashBoard(data);
      }
    }
  };

  const getStatus = status => {
    if (status === 0) {
      return classes.inActive;
    } else if (status === 1) {
      return classes.active;
    } else {
      return classes.decline;
    }
  };

  const getStatusName = status => {
    if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Accepted";
    } else {
      return "Declined";
    }
  };

  return (
    <>
      <Card
        onClick={() => handleEngagementClick(product)}
        className={"requestly-card"}
        style={{
          width: "320px",
          maxWidth: "100%",
          marginLeft: "20px",
          marginTop: "20px",
          cursor: "pointer"
        }}
      >
        <CardContent>
          <CardHeader
            className={
              isProductDemo
                ? classes.PDCardHeader
                : isDiscoveryMeeting
                  ? classes.DMCardHeader
                  : classes.CardHeader
            }
            title={getTitle()}
          />
          <Box display="flex" justifyContent="center" mb={3}>
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h4"
            className="requestly-card-text"
          >
            {isProductDemo || isDiscoveryMeeting
              ? product?.companyName || product?.prouductName
              : product.rfpName}
          </Typography>
          <p className="requestly-card-date">
            {moment(product.createdAt).format("DD MMM YYYY")}
          </p>
        </CardContent>
        <Box flexGrow={1} />
        <Divider />
        <Box p={2} className={classes.boxContainer}>
          <Grid
            container
            justify="space-between"
            spacing={2}
            style={{ position: "relative" }}
          >
            <Grid className={classes.statsItem} item>
              {isProductDemo && (
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  className={classes.viewDetail}
                >
                  VIEW DETAILS
                  <div style={{ marginLeft: "6em" }}>
                    {getStatusName(product?.status)}
                    <div className={getStatus(product?.status)}></div>
                  </div>
                </Typography>
              )}

              {isDiscoveryMeeting && (
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  className={classes.viewDetail}
                >
                  VIEW DETAILS
                  <div style={{ marginLeft: "6em" }}>
                    {getStatusName(product?.status)}
                    <div className={getStatus(product?.status)}></div>
                  </div>
                </Typography>
              )}

              {!isProductDemo && !isDiscoveryMeeting && (
                <Typography
                  color="textSecondary"
                  display="inline"
                  variant="body2"
                  className={
                    product.submittedVendorIds &&
                      product.submittedVendorIds.length === 0 &&
                      product.rfpPublished &&
                      !product.isInvited
                      ? classes.LiveRfp
                      : product.isSubmitted
                        ? classes.responsePara
                        : classes.para
                  }
                >
                  {product.isInvited ? (
                    product.isSubmitted &&
                      product.invitedRfpStepper &&
                      product.invitedRfpStepper.isRfpAccepted === "1" ? (
                      "Response Submitted"
                    ) : !product.isSubmitted &&
                      product.invitedRfpStepper &&
                      product.invitedRfpStepper.isRfpAccepted === "2" ? (
                      <span style={{ marginLeft: "10px", color: "red" }}>
                        Declined
                      </span>
                    ) : (
                      "Submit Response"
                    )
                  ) : product.rfpPublished &&
                    product.rfpCompletionPercent === 100 ? (
                    "RFP Completed"
                  ) : product.rfpPublished ? (
                    "Live RFP"
                  ) : (
                    "Update RFP"
                  )}
                  <div
                    className={
                      product.rfpPublished &&
                      product.rfpCompletionPercent < 100 &&
                      classes.active + ' active-top'
                    }
                  ></div>
                </Typography>
              )}
            </Grid>
            <Grid>
              <LinearProgress
                variant="determinate"
                className={
                  product.isInvited
                    ? product.isSubmitted
                      ? classes.buttonProgressBar
                      : classes.buttonProgressBar
                    : product.rfpPublished
                      ? classes.buttonProgress
                      : classes.buttonProgressBar
                }
                value={
                  product.isInvited
                    ? product.completedPer === undefined
                      ? product.completedPer === 0
                      : product.completedPer
                    : product.rfpCompletionPercent === undefined
                      ? product.rfpCompletionPercent === 0
                      : product.rfpCompletionPercent
                }
              />
            </Grid>
            <Grid className={classes.statsItem} item></Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ProductCard;
