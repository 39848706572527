import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RadioGroup,
  TextField,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { alertService } from "src/components/toaster/alert.service";
import EnquiryService from "src/services/enquiry/enquiry.service";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ModalBox: {
    marginLeft: "36px",
    marginTop: "36px"
  },
  name: {
    marginTop: "20px",
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    outline: "none",
    borderRadius: "12px",
    width: "370px",
    maxWidth: "370px",
    // maxHeight: '370px',
    boxShadow: theme.shadows[5]
  },
  container: {
    // width: "370px",
    position: "relative",
    marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex"
  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "20px"
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  },
  RfpName: {
    width: "100%",
    maxWidth: 360
  },
  buttoncolor: {
    color: "#000000"
  },
  textsize: {
    fontSize: "1rem!important",
    fontWeight: 400
  },
  paper: { textAlign: "center" },
  companyHeadings: { backgroundColor: "#4688f0", color: "#fff" },
  titleTag: { margin: "auto" },
  inputAll: {
    marginTop: "10px !important",
    marginBottom: "8px !important",
    padding: "2px !important"
  },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: "contents", textAlign: "center" },
  listCheckline: { display: "block", marginTop: "10px" },
  titleTagTxt: { fontSize: "18px", fontWeight: "800", margin: "auto" },
  ProductOptions: {
    // marginLeft: "30px",
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  ProductOptions2: {
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  questions: {
    marginTop: "20px"
  },
  questionCard: {
    margin: "0px 20px",
    marginTop: "-17px"
  },
  notSelected: {
    cursor: "pointer"
  },
  stageCardResearchActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    maxHeight: "108px"
  },
  stageCardResearchNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    maxHeight: "108px"
  },
  stageCardActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    maxHeight: "108px"
  },
  wordLength: {
    textAlign: 'end',
    fontSize: '14px',
    float: 'right',
    width: '80%',
  }
}));

const longText = `Please tell us something about you're looking for`;

const DiscoveryMeeting = () => {
  const history = useHistory();
  const classes = useStyles();
  const [wordLength, setWordLength] = useState(500);
  const [active, setActive] = useState(0);
  const [stageOptions, setStageOptions] = useState([]);
  const [question, setQuestion] = useState(null);
  const enquiryService = new EnquiryService();

  let { id } = useParams();
  let { id2 } = useParams();
  var productId = id;
  var companyName = id2.replaceAll("%20", " ");

  console.log("productId", productId);

  useEffect(() => {
    getDiscoveryStage();
  }, []);

  const getDiscoveryStage = () => {
    enquiryService
      .getDiscoveryOptions()
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          setStageOptions(res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSave = e => {
    e.preventDefault();

    if (active && question) {
      let requestData = {
        stage: active,
        question: question,
        productId: productId
      };
      enquiryService
        .addDiscoveryMeeting(requestData)
        .then(res => {
          if (res) {
            history.push(`/app/dashboard`);
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success(res.message, {
              autoClose,
              keepAfterRouteChange
            });
          }
        })
        .catch(err => console.log(":: err", err));
    } else {
      const autoClose = true;
      const keepAfterRouteChange = false;

      if (!active) {
        alertService.error("Please select the discovery stage", {
          autoClose,
          keepAfterRouteChange
        });
      } else {
        alertService.error("Please enter your question", {
          autoClose,
          keepAfterRouteChange
        });
      }
    }
  };

  const handleChange = e => {
    setWordLength(500 - e.target.value.length);
    setQuestion(e.target.value);
  };

  const handleActive = index => {
    setActive(index);
  };

  return (
    <>
      <form
        className={"requestly-rfp-form dm-popup-form"}
        style={{
          position: "relative",
          top: "-2%",
          // width: "732px",
          height: "auto",
          borderRadius: "13px"
        }}
      >
        <Card className="dm-popup-card">
          <CardHeader
            title={`Discovery Meeting Request-${companyName}`}
            className={
              classes.companyHeadings + " requestly-rfp-heading dm-popup-header"
            }
          />
          <Divider />
          <CardContent
            className=" dm-popup-card-content"
            style={{ maxWidth: "732px" }}
          >
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <p style={{ fontWeight: 700, marginTop: "12px", fontFamily: "Roboto, sans- serif" }}>
                Discovery Stage
              </p>
            </Grid>
            <Grid container lg={12}>
              <Grid
                container
                className={classes.inputAll + " dm-popup-container1"}
                lg={12}
                md={9}
                item
                sm={9}
                xs={12}
              >
                <RadioGroup
                  className={classes.radioGrpLine + " dm-popup-radio-group"}
                  aria-label="scope"
                  name="scope"
                >
                  {stageOptions &&
                    stageOptions.length > 0 &&
                    stageOptions.map(t => (
                      <Grid
                        className={
                          active === t.value
                            ? classes.ProductOptions
                            : classes.notSelected
                        }
                        item
                        md={3}
                        sm={3}
                        xs={6}
                        lg={3}
                      >
                        <div
                          className={classes.stageCard + " dm-popup-radiodiv"}
                          onClick={() => {
                            handleActive(t.value);
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Roboto, sans- serif"
                            }}
                            className={
                              active === t.value
                                ? classes.stageCardResearchActive
                                : classes.stageCardResearchNotActive
                            }
                          >
                            {t.label}
                          </p>
                        </div>
                      </Grid>
                    ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "12px" }} />
            <Grid
              className={classes.questions + " dm-popup-questions-div"}
              container
              spacing={0}
            >
              <Grid
                className={classes.item}
                item
                md={12}
                sm={12}
                xs={12}
                style={{ display: "flex" }}
              >
                <p style={{ fontWeight: 700, fontFamily: "Roboto, sans- serif" }}>Questions</p>
                <span style={{ marginLeft: "7px" }}>
                  <Tooltip
                    title={longText}
                    style={{ height: "0.7em", fontFamily: "Roboto, sans- serif" }}
                    placement="right"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </span>
                <span className="dm-popup-wordlength" className={classes.wordLength}>{wordLength}/500</span>
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            className={classes.questionCard}
            lg={12}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <TextField
              fullWidth
              multiline
              // error={Boolean(touched.rfpName && errors.rfpName)}
              // helperText={touched.rfpName && errors.rfpName}
              placeholder="Outline Your Questions to the Vendor"
              name="rfpName"
              // onBlur={handleNameChange}
              onChange={handleChange}
              // value={values.rfpName}
              variant="outlined"
              className="requestly-rfp-textbox dm-popup-textfield"
              // style={{ height: "100px" }}
              inputProps={{
                style: {
                  height: "177px",
                  fontFamily: "Roboto, sans- serif"
                },
                maxLength: 500
              }}
            />
          </Grid>
          <Box className={classes.footerBox} p={2}>
            <Button
              color="primary"
              className={
                classes.button + " requestly-rfp-button dm-popup-button"
              }
              type="submit"
              variant="contained"
              style={{
                float: "right",
                marginBottom: "26px",
                width: "126px",
                textTransform: "none"
              }}
              onClick={handleSave}
            >
              Send Request
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default DiscoveryMeeting;
