import { combineReducers } from "redux";
import productReducer from "./products/reducer";
import submitRfpReducer from "./submitRfp/reducer";
import updateRfpReducer from "./updateRfp/reducer";






const rootReducer = combineReducers({
  product: productReducer,
  updateRfp: updateRfpReducer,
  submitRfp: submitRfpReducer,
});


export default rootReducer;