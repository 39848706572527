import { Button, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import BillingService from "src/services/billing/billing.service";
import "./styles.css";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    fontSize: "14px",
    textTransform: "capitalize"
  },
  button2: {
    margin: theme.spacing(1),
    backgroundColor: "#52B152",
    fontSize: "14px",
    color: "white",
    textTransform: "capitalize"
  },
  subscriptionPlan: {
    display: "inline-flex",
    width: "30%",
    // border: "1px solid #e1e1e1",
    transition: "all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000)",
    margin: "10px",
    backgroundColor: "white",
    borderRadius: "3px"
  },
  subscriptionPlan1: {
    display: "inline-flex",
    width: "30%",
    // border: "3px solid black",
    transition: "all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000)",
    margin: "10px",
    backgroundColor: "white",
    borderRadius: "3px"
  },
  radioGrpLine: {
    display: "block"
  },
  labelName: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    color: "black",

    marginBottom: "20px",
    letterSpacing: "-0.02em"
  },
  center: {
    textAlign: "center"
  },
  selectedPlan: {
    // backgroundColor: "red",
    width: "100%"
  },
  box: {
    visibility: "visible",
    animationDuration: "1s",
    animationDelay: "0.2s",
    animationName: "fadeScaleIn",
    willChange: "opacity, transform",
    opacity: 1,
    transform: "scale(1.0)",
    margin: "0 0 30px",
    position: "relative",
    textAlign: "center",
    animationFillMode: "both",
    backfaceVisibility: "hidden"
  },
  price: {
    fontSize: "25px",
    fontWeight: 600,
    lineHeight: 1.5
  },
  des: {
    fontSize: "10px",
    color: "#757575",
    margin: "0 0 30px"
  },
  proceedButton: {
    ":disabled": {
      backgroundColor: theme.palette.primary || "red"
    }
  }
}));

const Main = () => {
  const classes = useStyles();
  const billingService = new BillingService();
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [isSelectedId, setSelectedId] = useState();

  useEffect(() => {
    billingService
      .getSubsciptionPlans()
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          setSubscriptionPlan(res.data);
        }
      })
      .catch(err => console.log(":: err", err));
  }, []);

  const createCheckout = (id) => {
    const parsed = queryString.parse(window.location.search);
    const data = {
      productId: parsed?.productId,
      planId: id,
      successUrl: localStorage.getItem('isFromMR') ? localStorage.getItem('isFromMR') : window.location.origin + '/app/billing-credit',
      cancelUrl: window.location.href,
    };
    billingService
      .createCheckoutSession(data)
      .then(res => {
        if (res && res.data) {
          window.open(res.data?.url, "_self");
        }
      })
      .catch(err => console.log(":: err", err));
  };

  return (
    <>
      <div className="mainBox">
        <div style={{ display: "block" }}>
          <div className="billing-heading">Subscription Plans</div>
          <hr className="MuiDivider-root" />

          <div className={classes.center}>
            <RadioGroup
              className={classes.radioGrpLine}
              aria-label="scope"
              name="scope"
            >
              {subscriptionPlan &&
                subscriptionPlan.length > 0 &&
                subscriptionPlan.map((plan, index) => (
                  <span
                    className={plan.id === isSelectedId && classes.selectedPlan}
                  >
                    <span className={classes.box}>
                      <span
                        key={index}
                        className={
                          plan.id === isSelectedId
                            ? classes.subscriptionPlan1
                            : classes.subscriptionPlan
                        }
                      >
                        <div className="insideBox">
                          <Typography className={classes.labelName}>
                            {plan?.name}
                          </Typography>
                          <Typography className={classes.price}>
                            {plan?.currency?.symbol || '$'} {plan?.price}
                          </Typography>
                          <Typography className={classes.des}>
                            {plan?.description}
                          </Typography>
                          {/* <hr className="MuiDivider-root" /> */}
                          <div>
                            <div className="tabbutton">
                              <Button
                                variant="contained"
                                className="billing-button2"
                                onClick={() => {
                                  createCheckout(plan.id);
                                }}
                                className="btn btn-mod btn-small round"
                              >
                                Buy Now
                              </Button>
                            </div>
                          </div>
                          <div className="tabFeature">
                            {plan?.feature &&
                              plan.feature.map(feature => (
                                <div key={`${feature}${index}`}>
                                  <Typography className="tabData">
                                    {feature}
                                  </Typography>
                                </div>
                              ))}
                          </div>
                        </div>
                      </span>
                    </span>
                  </span>
                ))}
            </RadioGroup>

            {/* <Button
              variant="contained"
              className={`${classes.proceedButton}billing-button2`}
              onClick={() => {
                createCheckout();
              }}
              disabled={!isSelectedId}
            >
              Proceed To checkout
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};
Main.propTypes = {
  className: PropTypes.string
};

export default Main;
