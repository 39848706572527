export class PricingModel {
  taxPercentage = 0;
  taxValue = 0;
  discountPercentage = 0;
  discountValue = 0;
  subTotalValue = 0;
  totalValue = 0;
  tempTotalValue = 0;
  email = '';
  userName = '';
  companyName = '';
  products = [new Product()];
  payments = [new Payment()];
  constructor(pricingInfo = null) {
    if (pricingInfo) {
      this.tempTotalValue = pricingInfo.totalValue || this.tempTotalValue;
      this.companyName = pricingInfo.companyName || this.companyName;
      this.email = pricingInfo.email || this.email;
      this.userName = pricingInfo.userName || this.userName;
      this.taxPercentage = pricingInfo.taxPercentage || this.taxPercentage;
      this.discountPercentage = pricingInfo.discountPercentage || this.discountPercentage;
      this.payments = pricingInfo.payments || this.payments;
      this.products = pricingInfo.products || this.products;
      if (pricingInfo.products && pricingInfo.products.length) {
        pricingInfo.products.forEach(product => {
          if (product.price !== '' && product.quantity !== '') {
            product.subTotal = product.price * product.quantity;
            this.subTotalValue = this.subTotalValue + product.subTotal;
          }
        });
        this.totalValue = this.subTotalValue;
        if (this.discountPercentage) {
          this.discountValue = (this.discountPercentage * this.subTotalValue) / 100;
          this.totalValue = this.totalValue - this.discountValue;
        }
        if (this.taxPercentage) {
          this.taxValue = (this.taxPercentage * this.subTotalValue) / 100;
          this.totalValue = this.totalValue + this.taxValue;
        }
      }

    }
  }
}


export class Product {
  productName = '';
  price = '';
  quantity = '';
  subTotal = 0;
  constructor(productInfo = null) {
    if (productInfo) {
      this.productName = productInfo.productName || this.productName;
      this.price = productInfo.price || this.price;
      this.quantity = productInfo.quantity || this.quantity;
      if (this.price !== '' && this.quantity !== '') {
        this.subTotal = this.price * this.quantity;
      }
    }
  }
}

export class Payment {
  amount = '';
  date = '';
  constructor(paymentInfo = null) {
    if (paymentInfo) {
      this.amount = paymentInfo.amount || this.amount;
      this.date = paymentInfo.date || this.date;
    }
  }
}
