import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(({
  root: {},
  uploadedFileContainer: {
    height: '30px',
    padding: '8px 433px 2px 8px',
    backgroundColor: '#4688f0',
  },
  uploadedFileText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
  },
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  buttonPosition1: {
    right: "630px"
  },
  buttonPosition2: {
    right: "315px"
  },
  cardScrollArea: {
    //maxHeight: "72vH",
    //overflowX: "scroll",
    //overflowX: "hidden",

  },
  ButtonSkip: {
    left: "10px"
  },
  NextButton: {
    left: "5px"
  },
  CardBorder: {
    borderRadius: "15px"

  },
  footerBackground: {
    background: "#f7f7f7"

  },
  BackButton: {
    right: "-6px"
  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: {},
  listChecks: {},
  footerBox: {},


}));