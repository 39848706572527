import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { stateToHTML } from "draft-js-export-html";
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichText.scss';


const emptyContentState = convertFromRaw({
  blocks: [
    {
      "key": "20cv5",
      "text": "",
      "type": "unstyled",
      "depth": 0,
      "inlineStyleRanges": [],
      "entityRanges": [],
      "data": {}
    }
  ],
  entityMap: {}
});
export default function RichText(props) {
  const initial = EditorState.createWithContent((props.value && typeof (props.value) === 'object') ? convertFromRaw(props.value) : emptyContentState);

  const [state, setState] = useState({ editorState: initial, editorContentHtml: stateToHTML(initial.getCurrentContent()) })

  const onContentStateChange = (editorState) => {
    console.log(editorState)
    if (editorState) {
      setState({
        editorState: editorState,
        editorContentHtml: stateToHTML(editorState.getCurrentContent())
      });
      props.onTextChange(convertToRaw(editorState.getCurrentContent()))
    }
  }

  useEffect(() => {
    if (props.value !== '' && typeof (props.value) === 'object') {
      const editorState = EditorState.createWithContent(convertFromRaw(props.value));
      setState({
        editorState: editorState,
        editorContentHtml: stateToHTML(editorState.getCurrentContent())
      });
    }
  }, [props.value]);


  return (
    <React.Fragment>
      {(props.showContentOnly) ? (<div dangerouslySetInnerHTML={{ __html: state.editorContentHtml }} >
      </div>) :
        (<Editor
          editorState={state.editorState}
          wrapperClassName="rich-text"
          editorClassName="rich-text__editor"
          onEditorStateChange={onContentStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />)}

    </React.Fragment>
  );
}

