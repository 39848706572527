import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './LegalContractsStyle';
import { LegalContractsValidations } from './LegalContractsValidations';

const LegalContracts = ({ handleNext, handleSkip, handleBack, className, completed, legalState, step, totalStepCount, deleteLegalDocument, currentStepCompleteStatus, acceptStatus, ...rest }) => {
  const classes = useStyles();
  let file = ''

  const handleFilesChange = (files) => {
    file = files;
  }

  var type = '';
  return (
    <Formik
      initialValues={legalState}
      enableReinitialize={true}
      validationSchema={LegalContractsValidations}
      onSubmit={(values) => {
        values.step = step;
        values.type = type;
        values.file = file;
        handleNext(values);
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form className={'requestly-rfp-form company-information mb-5'}>
          <div className={classes.pageSize + ' company-info-div'}>
            <Card>
              <CardHeader
                subheader="Upload legal contracts"
                title="Legal Contracts"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <Divider />

              <CardContent className="Presentation-Agenda-table">
                <Box >
                  <Grid className={classes.listChecks} container spacing={0}>
                    <Grid md={12} sm={12} xs={12}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left"> Vendor Name </TableCell>
                              <TableCell align="left"> Date </TableCell>
                              <TableCell align="left">  File Name </TableCell>
                              <TableCell align="left"> Download </TableCell>
                            </TableRow>
                          </TableHead>
                          {values.legalDocuments && values.legalDocuments.length > 0 ? (<TableBody>
                            {values.legalDocuments && values.legalDocuments.map((item) => {
                              return item.legalDocuments_docs?.map(datum => (
                                <TableRow>
                                  <TableCell align="left"> {datum.companyName} </TableCell>
                                  <TableCell align="left" >
                                    {moment(datum.createdAt).format('DD MMMM, YYYY')}
                                  </TableCell>
                                  <TableCell align="left"> {datum.name} </TableCell>
                                  <TableCell align="left" href={datum.location}
                                    target="_blank"><a href={datum.location}
                                    ><GetAppIcon ></GetAppIcon></a>  </TableCell>
                                  {/* <TableCell align="center"> <DeleteIcon onClick={() =>deleteNDAAns(item.companyNDAAnsDocument[0])}></DeleteIcon> </TableCell> */}

                                </TableRow>
                              )) ?? null;
                            }
                            )
                            }
                          </TableBody>) : (<TableBody><TableRow> Nothing uploaded yet...</TableRow></TableBody>)}
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {step !== 0 ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                          </Button>
                      </Grid>
                    ) : null}
                    {step !== totalStepCount ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                      </Button>
                      </Grid>
                    ) : null}
                    {step !== totalStepCount ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Next
                      </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );
};

LegalContracts.propTypes = {
  className: PropTypes.string
};

export default LegalContracts;
