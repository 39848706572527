import { Box, Button, Card, CardContent, Dialog, Grid, TextField, Tooltip } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Help';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import QuesAnsService from 'src/services/rfp/ques-ans.service';
import { QuestionAnswerBuyerModel } from '../QuestionAnswersBuyer/QuestionAnswerBuyerModel';
import { alertService } from '../toaster/alert.service';
import { QuestionAnswerModel } from './QuestionAnswerModel';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  qsBtn: {
    position: 'absolute', bottom: '5%', right: '0',
  },
  removeBottomPadding: {
    marginLeft: "-16px",
    width: "103%",
  },
  vendorQuestion: {
    fontWeight: "600",
    fontSize: "12px"
  },
  questionsHeading: {
    fontSize: "12px"

  },
  textField: {
    '@global': {
      '.MuiOutlinedInput-inputMultiline': {
        fontSize: "15px"
      },
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuestionAnswers = ({ questionId, question_details, questionColorChangeUpdate, handleQuestionChange, questionInfo = null, category = null, section = null }) => {
  console.log(category + " - " + section);
  console.log(questionInfo);
  //alert('hi');
  let path = window.location.pathname;
  let str = path.split('/');
  const rfpId = str[str.length - 1];
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [questionValue, setQuestionValue] = React.useState({ question: '' });
  const [hasError, setError] = React.useState(false);
  const [questionAnswers, setQuestionAnswers] = React.useState(new QuestionAnswerModel());
  const [questionAnswersAll, setQuestionAnswersAll] = React.useState(new QuestionAnswerBuyerModel());
  const quesAnsService = new QuesAnsService();
  const [modifyQuestionObject, setModifyQuestionObject] = React.useState({});

  const handleClickOpen = () => {
    setOpen(true);
    getQuestionAns();
    getAllQuestionAns();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getQuestionAns = () => {
    quesAnsService.getQuesAns(questionId, rfpId).then(res => {
      setQuestionAnswers(new QuestionAnswerModel(res));
    })
  }
  const getAllQuestionAns = () => {
    quesAnsService.getAllQuesAns(rfpId).then(res => {
      console.log(res);
      if (res?.quesAns) {
        setQuestionAnswersAll(new QuestionAnswerBuyerModel(res.quesAns));
      }
    })
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const updateQuestionValue = (event) => {
    var questionValuee = { ...questionValue }
    questionValuee.question = event.target.value;
    setQuestionValue(questionValuee);
  };

  const changeAskQuestion = (event) => {
    setValue(1);
  };

  function handleCreateQuestion() {
    if (questionValue.question) {
      let questionObj = {};
      questionObj.vendorQuestionId = new Date().getTime();
      questionObj.question = questionValue.question;
      questionObj.questionId = questionId;
      questionObj.rfpId = rfpId;
      quesAnsService.saveQuesAns(questionObj).then(res => {
        const autoClose = true;
        const keepAfterRouteChange = false;
        // alert("question updated succesfully")
        alertService.success("You've successfully submitted your question.", { autoClose, keepAfterRouteChange });
        setQuestionValue({ question: '' });
        if (res && res.status) {
          handleQuestionChange(true)
          getAllQuestionAns()

        }
        console.log(res, "res")
      })
      setError(false);
      //const autoClose = true;
      //const keepAfterRouteChange = false
      questionAnswers.quesAns.push({ question: questionValue.question });
      setQuestionAnswers(new QuestionAnswerModel(questionAnswers));
      setValue(0);



    } else {
      setError(true);
    }
    // 
  };

  React.useEffect(() => {
    const tempData = {}
    if (questionAnswersAll?.questions?.length) {
      const temp = questionAnswersAll?.questions.map((datum, index) => ({
        ...datum,
        index
      }))
      const questionsByCategory = groupBy(questionAnswersAll.questions, 'categoryName')

      Object.keys(questionsByCategory).forEach(datum => {
        const questionsBySection = groupBy(questionsByCategory[datum], 'sectionName')
        tempData[datum] = questionsBySection;
      });
      setModifyQuestionObject(tempData);
    }
  }, [JSON.stringify(questionAnswersAll)]);


  return (
    <div>
      {
        (question_details[questionId] !== undefined)
          ?
          <Tooltip title="Ask A Question" aria-label="Ask A Question">
            <InfoIcon
              style={{ "color": "#4688f0" }}
              onClick={handleClickOpen}
            />
          </Tooltip>
          : <Tooltip title="Ask A Question" aria-label="Ask A Question">
            <InfoIcon
              style={{ "color": "#dddddd" }}
              onClick={handleClickOpen}
            />
          </Tooltip>

      }
      <Dialog
        fullScreen open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="qna-data"
        scroll={'body'}

      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Questions / Answers
            </Typography>
          </Toolbar>
        </AppBar>

        <Card style={{ width: "80%", margin: 'auto' }}>
          <CardContent >
            <Grid container spacing={3} classes={{ root: classes.removeBottomPadding }}>

              <Grid container item>
                <Grid item md={10} xs={10}>
                  <p style={{ color: "rgb(85, 83, 83)", fontWeight: 'bold', fontSize: "12px" }}>{category + ' / ' + section}</p>
                  <p style={{ color: "#707070", fontSize: "12px" }}>Q. {questionInfo}</p>
                </Grid>
                <Grid item md={10} xs={10} >
                  <TextField
                    fullWidth
                    multiline
                    error={Boolean(hasError)}
                    autoFocus
                    margin="dense"
                    rows={4}
                    required={true}
                    value={questionValue.question}
                    onChange={updateQuestionValue}
                    label="Question"
                    variant="outlined"
                    className={classes.textField} />
                </Grid>
                <Grid item md={10} xs={10} style={{ textAlign: 'right', paddingTop: "5px" }}>
                  <Button variant="contained" color="primary" onClick={() => handleCreateQuestion()}>
                    Submit
                  </Button>
                </Grid>
                <Grid item md={10} xs={10}>
                  <Typography className={classes.heading} style={{ color: 'red', fontSize: '12px', paddingTop: "8px" }}>Below is a list of all clarification questions that participating vendors have asked, please check that no one has already asked your question</Typography>
                </Grid>
              </Grid>




              <Grid container >
                {
                  (Object.keys(modifyQuestionObject).length === 0) ?
                    <Grid item md={12} xs={12} xl={12}> No Questions Asked by Vendors</Grid>
                    :
                    Object.keys(modifyQuestionObject).map((categoryKey) => {
                      return (
                        <Grid item md={12} xs={12} xl={12}>
                          <Accordion key={categoryKey} className="requestly-fin-info-tab"
                            defaultExpanded={true}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="requestly-tab-height accordinon-financial-summary"
                            // style={{ marginBottom: '10px' }}
                            >
                              <Typography className={classes.heading}>{categoryKey}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container >
                                {
                                  Object.keys(modifyQuestionObject[categoryKey]).map((sectionKey) => {
                                    return (
                                      <Grid item md={12} xs={12} xl={12}>
                                        <Accordion
                                          key={sectionKey}
                                          className="question-answer requestly-fin-info-tab"
                                          defaultExpanded={true}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="requestly-tab-height accordinon-financial-summary section "
                                            style={{ marginBottom: '10px' }}
                                          >
                                            <Typography className={classes.heading}>{sectionKey}</Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Grid
                                              container
                                              style={{ padding: '20px' }}
                                            >
                                              {
                                                modifyQuestionObject[categoryKey][sectionKey].map(datum => {
                                                  return (
                                                    <Grid
                                                      item
                                                      xl={12}
                                                      md={12}
                                                      sm={12}
                                                      key={datum.vendorQuestionId}
                                                      style={{ padding: '10px' }}
                                                    >
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        md={12}
                                                        sm={12}
                                                        style={{ marginBottom: '5px', fontSize: "12px" }}
                                                      >
                                                        <Typography
                                                          // style={{  }}
                                                          className={classes.questionsHeading}>
                                                          Q. {datum.askQuestion} ?
                                                        </Typography>
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xl={12}
                                                        md={12}
                                                        sm={12}
                                                        style={{ marginBottom: '12px' }}
                                                      >
                                                        <Typography classes={{ root: classes.vendorQuestion }} >
                                                          VQ. {datum.question} ?
                                                        </Typography>
                                                      </Grid>

                                                      <Grid container>
                                                        <Grid
                                                          item
                                                          xl={10}
                                                          md={10}
                                                          sm={12}
                                                          style={{ marginBottom: '5px' }}
                                                        >
                                                          <p
                                                            style={{ marginBottom: '0px', fontSize: "12px" }}
                                                          >Answer:</p>
                                                          <p
                                                            style={{ marginBottom: '0px', color: "#707070", fontSize: "12px" }}
                                                          >{(datum.answer) ? datum.answer : 'Answer not provided'}</p>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  )
                                                })
                                              }
                                            </Grid>
                                          </AccordionDetails>
                                        </Accordion>
                                      </Grid>
                                    )
                                  })
                                }
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      )
                    })
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
    </div >
  );
}


export default QuestionAnswers