/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { Redirect, Route, Router } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import { history } from "src/utils/utils";
import AfterSignUpInformation from "src/views/after-signup";
import BillingCredit from "src/views/Billing-Credit";
import CustomerListView from "src/views/customer/CustomerListView";
import ProductList from 'src/views/product-list';
import DemoMeetingRequest from "src/views/product/DemoMeetingRequest";
import DiscoveryMeeting from "src/views/product/DiscoveryMeeting";
import ProductDemoModal from "src/views/product/ProductDemoModel";
import ProductDemoRequest from "src/views/product/ProductDemoRequest";
import ProductListView from "src/views/product/ProductListView";
import RequestForPage from "src/views/product/RequestForPage";
import CreateRfpView from "src/views/rfp/CreateRfpView";
import EditRfpView from "src/views/rfp/EditRfpView";
import RfpDashboardView from "src/views/rfp/RfpDashboardView";
import SubmitRfpView from "src/views/rfp/SubmitRfpView";
import UpdateRfpView from "src/views/rfp/UpdateRfpView";
import SubscriptionPlans from "src/views/subscription-plans";
export default function AppLayout() {
  return (
    <div>
      <Router history={history}>
        <DashboardLayout>
          <Route
            path="/app/additional-information"
            exact
            component={AfterSignUpInformation}
          />
          <Route path="/app/billing-credit" exact component={BillingCredit} />
          <Route path="/app/subscription-plan" exact component={SubscriptionPlans} />
          <Route path="/app/create-rfp" exact component={CreateRfpView} />
          <Route
            path="/app/product-request/:id"
            exact
            component={ProductDemoRequest}
          />
          <Route
            path="/app/demo-meeting/:id"
            exact
            component={DemoMeetingRequest}
          />
          <Route
            path="/app/product-discovery/:id/:id2"
            exact
            component={ProductDemoModal}
          />
          <Route
            path="/app/discovery-meeting/:id/:id2"
            exact
            component={DiscoveryMeeting}
          />
          <Route
            path="/app/discovery-meeting-request/:id/:id2"
            exact
            component={RequestForPage}
          />
          <Route
            path="/app/dashboard-rfp/:rfpId"
            exact
            component={RfpDashboardView}
          />
          <Route
            path="/app/dashboard/:isFirstLogin?"
            exact
            component={ProductListView}
          />
          <Route path="/app/customers" exact component={CustomerListView} />
          <Route
            path="/app/update-rfp/:rfpId"
            exact
            component={UpdateRfpView}
          />
          <Route path="/app/edit-rfp/:rfpId" exact component={EditRfpView} />
          <Route
            path="/app/submit-rfp/:rfpId"
            exact
            component={SubmitRfpView}
          />
          <Route
            path="/app/product-list"
            exact
            component={ProductList}
          />
          <Route exact path="/app">
            <Redirect from="/app" to="/app/home" exact />
          </Route>
        </DashboardLayout>
      </Router>
    </div>
  );
}
