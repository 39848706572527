import AxiosInstance from '../../interceptor/http-intercepor';
// import { loaderService } from '../../components/loader/loader.service';

const baseUrl = 'http://15.206.88.121:3000/';

const submitRfpService = {
  getRfpByById: (payload) => {
    const userId = localStorage.getItem('userId');
    let url = 'rfp/getrfpbyId/' + payload.rfpId;
    if (payload.isSubmit)
      url = url + '/' + userId;
    return AxiosInstance.get(url)
  },
  SubmitTechnicalDetails: (payload) => {
    const userId = localStorage.getItem('userId');
    const url = 'technical/getTechnical/' + payload + '/' + userId;
    return AxiosInstance.get(url)
  },
  SubmitCommercialDetails: (payload) => {
    const userId = localStorage.getItem('userId');
    const url = 'commercial/getCommercial/' + payload + '/' + userId;
    return AxiosInstance.get(url)
  }

}

export default submitRfpService;