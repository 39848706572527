import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import { Form, Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CompanyService from 'src/services/company/company.service';
import { history } from 'src/utils/utils';
import { renderFileList, renderFileListCompanyDocs } from 'src/views/shared/components/FileList';
import { alertService } from '../../components/toaster/alert.service';
import GoBackModal from '../shared/components/GoBackModal';
// import { renderFileList, renderFileListCompanyDocs } from '../shared/components/FileList';
import { useStyles } from './NdaAnswerStyle';
import { NdaAnswerValidations } from './NdaAnswerValidations';


const NdaAnswer = ({ handleNext, handleSkip, handleBack, className, completed, ndaState, step, currentStepCompleteStatus, acceptStatus, totalStepCount, deleteNDAAns, companyState, projectState, is_submitted = false, ...rest }) => {
  const classes = useStyles();
  const companyService = new CompanyService();
  const [localFile, setLocalFile] = React.useState([]);
  const [companyName, setCompanyName] = React.useState();
  const companyId = localStorage.getItem('companyId')
  const [goBackmodal, setGoBackModal] = React.useState(false)
  const [defaultData, setDefaultData] = useState([]);

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);

  const getCompanyData = () => {
    companyService.getCompanyByUser(false).then(
      res => {
        if (res !== undefined && res) {
          if (res?.company?.companyName) {
            setCompanyName(res.company.companyName);
          }
        }
      }
    ).catch((err) => console.log(':: err', err))
  }

  useEffect(() => {
    getCompanyData()
  }, []);
  let file = []
  const handleFilesChange = (files) => {
    let tempFile = [...localFile];
    if (files.length > 0) {
      const fileDate = {
        userName: localStorage.getItem('user'),
        userId: localStorage.getItem('userId'),
        companyName,
        companyId,
        createdAt: new Date(),
        name: files[files.length - 1].name,
        location: URL.createObjectURL(files[files.length - 1]),
        mimetype: '',
        file: files[files.length - 1]
      }
      tempFile.push(fileDate)
    }
    setLocalFile(tempFile)
    file = tempFile
  }

  const deleteLocalFile = (index) => {
    var tempFile = [...localFile];
    tempFile.splice(index, 1)
    setLocalFile(tempFile)
    file = tempFile
  }

  var type = '';

  useEffect(() => {
    if (ndaState) {
      if (ndaState.companyNDAAnsDocument_docs !== null)
        setDefaultData(ndaState.companyNDAAnsDocument_docs.length)
    }

  }, [ndaState])


  return (
    <>
      <Formik
        initialValues={ndaState}
        enableReinitialize={true}
        validationSchema={NdaAnswerValidations}
        onSubmit={(values) => {
          if (localFile.length === 0 && values.companyNDAAnsDocument.length === 0) {
            const autoClose = true;
            const keepAfterRouteChange = false
            window.scrollTo(0, 0)
            alertService.error('Please upload 1 NDA File', { autoClose, keepAfterRouteChange })
          } else {
            values.step = step;
            values.type = type;
            values.file = localFile.map(datum => datum.file);
            handleNext(values);
          }

        }}>
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <Form className={'requestly-rfp-form company-information mb-5'}>
            <div className={classes.pageSize + ' company-info-div'}>
              <Card className={classes.CardBorder}>
                <CardContent className={classes.cardScrollArea + ' p-0'}>
                  <CardHeader
                    subheader="Select your NDA preferences"
                    title="Non-Disclosure Agreements"
                    className={classes.companyHeadings + ' requestly-rfp-heading width-auto'} />
                  <Divider />
                  <CardContent>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <Typography>Select what form of mutual non-disclosure agreements are acceptable to your organisation.</Typography>
                    </Grid>
                    {(values.vendorMNDA) ?
                      <Box mt={3}>
                        <Grid className={classes.listChecks} container spacing={0}>
                          <Grid md={12} sm={12} xs={12} className="label-padding-set">
                            <FormControlLabel disabled={true} control={(<Checkbox checked={values.vendorMNDA} onChange={handleChange} name="vendorMNDA" />)} label={"Vendor must use " + companyState.companyName + " NDA"} />
                            {values.companyNDADocument && values.companyNDADocument.length > 0 && values.vendorMNDA ? (
                              <Grid item xl={12} lg={12} md={12} xs={12}>
                                <Box className={classes.uploadedFileContainer}>
                                  <Typography className={classes.uploadedFileText}>
                                    Company Nda Docs
                                  </Typography>
                                </Box>
                                {renderFileListCompanyDocs(values.companyNDADocument_docs, classes.table)}
                              </Grid>
                            ) : null}
                            {values.companyNDAAnsDocument_docs && values.companyNDAAnsDocument_docs.length > 0 && values.companyMNDA ? (
                              <Grid item xl={12} lg={12} md={12} xs={12}>
                                <Box className={classes.uploadedFileContainer}>
                                  <Typography className={classes.uploadedFileText}>
                                    Uploaded files
                                  </Typography>
                                </Box>
                                {renderFileList(values.companyNDAAnsDocument_docs, classes.table)}
                              </Grid>
                            ) : null}

                          </Grid>
                        </Grid>
                      </Box>
                      :
                      ''}
                    {
                      (values.companyMNDA) ?
                        <Box mt={3}>
                          <Grid className={classes.listChecks} container spacing={0}>
                            <Grid md={12} sm={12} xs={12} className="label-padding-set">
                              <FormControlLabel disabled={true} control={(<Checkbox checked={values.companyMNDA} onChange={handleChange} name="companyMNDA" />)} label="We can use Vendor's NDA" />
                            </Grid>
                          </Grid>
                        </Box>
                        : ''
                    }
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <Typography>Upload Files</Typography>
                    </Grid>
                    <Box mt={3}>
                      <Grid className={classes.listChecks} container spacing={0}>
                        <Grid md={12} sm={12} xs={12}>
                          <Grid item md={12} xs={12}>
                            <DropzoneArea
                              maxFileSize="104857600"
                              acceptedFiles={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.png', '.jpg', '.jpeg']}
                              onChange={handleFilesChange}
                              showFileNames={true}
                              showPreviewsInDropzone={false} />
                          </Grid>
                          {localFile?.length ? (
                            <Grid item md={12} xs={12} style={{ marginTop: '15px' }}>
                              {renderFileList(localFile, classes.table, true, deleteLocalFile)}
                            </Grid>
                          ) : null}
                          {values.companyNDAAnsDocument_docs && values.companyNDAAnsDocument_docs.length > 0
                            // && values.vendorMNDA 
                            ? (
                              <Grid item xl={12} lg={12} md={12} xs={12}>
                                <Box className={classes.uploadedFileContainer}>
                                  <Typography className={classes.uploadedFileText}>
                                    Uploaded files
                                  </Typography>
                                </Box>
                                {renderFileList(values.companyNDAAnsDocument_docs, classes.table, false, deleteNDAAns)}
                              </Grid>
                            ) : null}

                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </CardContent>
                <Divider />
                <div style={{ display: goBackmodal ? "none" : "" }}>
                  <div class="footer-btn-fixed">
                    <footer class="page-footer green">
                      <Grid container spacing={2} justify="center">
                        {(step !== 0 && !is_submitted) ? (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                              Back
                            </Button>
                            {/* <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={() => { defaultData === ndaState ? handleBack() : setGoBackModal(true) }}>
                              Back
                            </Button> */}
                          </Grid>
                        ) : null}
                        {(step !== totalStepCount && !is_submitted) ? (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                              Skip
                            </Button>
                          </Grid>
                        ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                              </Grid> : ''
                          ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            (!is_submitted) ?
                              <Grid item md={3} sm={3} xs={3}>
                                <Tooltip
                                  title={'You must accept RFP invite to submit the RFP response'}
                                  placement="top"
                                  arrow
                                  onOpen={() => {
                                    if (acceptStatus != 1 && !open) {
                                      setOpen(true);
                                    }
                                  }}
                                  onClose={() => setOpen(false)}
                                  open={open}
                                >
                                  <div onMouseOver={() => {
                                    setOpen(true)
                                  }}
                                    onMouseLeave={() => setOpen(false)}>
                                    <Button style={{ width: '75%' }} disabled={acceptStatus == 1 ? false : true} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                                  </div>
                                </Tooltip>
                              </Grid> : ''
                          ) : step === totalStepCount ?
                            (
                              (!is_submitted) ?
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                                </Grid> : ''
                            ) : (
                              (!is_submitted) ?
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                                </Grid> : ''
                            )}
                        {
                          (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                            <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                          </Grid> : ''
                        }

                      </Grid>
                    </footer>
                  </div>
                </div>
              </Card>
            </div>
          </Form>
        )}
      </Formik>
      <div className={classes.root}>
        {goBackmodal && (
          <GoBackModal
            goBackmodal={goBackmodal}
            handleBack={handleBack}
            closeEditModal={() => {
              setGoBackModal(false)
            }
            }
          />
        )}
      </div>
    </>
  );
};

NdaAnswer.propTypes = {
  className: PropTypes.string
};

export default NdaAnswer;
