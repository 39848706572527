import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function RfpTimeLine({ timelineItems, currentStageNumber }) {
  const classes = useStyles();
  const [start_date, setStartDate] = useState(new Date());
  const [end_date, setEndDate] = useState(new Date());
  const [timeline, updatedTimeline] = useState()
  const [total_rfp_days, setRfpDays] = useState(1);
  const [range_per_stepper, setRangePerStepper] = useState(1);

  function daysDifference(st_date, end_date) {
    //define two date object variables to store the date values  
    var date1 = new Date(st_date);
    var date2 = new Date(end_date);

    //calculate time difference  
    var time_difference = date2.getTime() - date1.getTime();

    //calculate days difference by dividing total milliseconds in a day  
    var result = time_difference / (1000 * 60 * 60 * 24);

    return result;
  }

  useEffect(() => {
    timelineItems.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.mileStoneDate) - new Date(b.mileStoneDate);
    });
    //console.log(currentStageNumber)
    setStartDate(new Date(timelineItems[0].mileStoneDate));
    setEndDate(new Date(timelineItems[timelineItems.length - 1].mileStoneDate));


    //alert('bye');
  }, [timelineItems])

  useEffect(() => {
    if (start_date !== '' && end_date !== '') {
      setRfpDays(daysDifference(start_date, end_date))
    }
  }, [start_date, end_date]);

  const getStepPercent = (next, current, rfp_days) => {
    var difference_days_rfp = daysDifference(current, next);
    console.log(current + ' - ' + next + ' - ' + rfp_days + ' - ' + difference_days_rfp);
    console.log(Math.ceil((difference_days_rfp / rfp_days) * 100));
    if (Math.ceil((difference_days_rfp / rfp_days) * 100) === 0) {
      return Math.ceil(((1 / rfp_days) * 100) / 2);
    } else {
      return Math.ceil((difference_days_rfp / rfp_days) * 100);
    }
  }
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let DateArr = [];
  useEffect(() => {
    timelineItems && timelineItems.map((value) => {
      const res = value["mileStoneDate"].split("-");
      const month = months[parseInt(res[1]) - 1]

      let tempDate = "";
      tempDate = `${res[2]} ${month} ${res[0]}`
      DateArr.push(tempDate)
    })
    const tempArr = timelineItems.map((v, index) => ({ ...v, mileStoneDateWords: DateArr[index] }))
    updatedTimeline(tempArr)
  }, [timelineItems])

  return (
    <div style={{ background: "#fff", paddingBottom: '20px' }}>
      <Grid item md={12} sm={12} xs={12}>
        <div style={{ "display": "inline-bloc", "width": "100%", "overflow-y": "auto", }}>
          <ul class="timeline timeline-horizontal">
            {timeline && timeline.map((timeLineItem, index) => (
              (index < (timelineItems.length - 1)) ?
                <li class="timeline-item" style={{ maxWidth: getStepPercent(timelineItems[index + 1].mileStoneDate, timeLineItem.mileStoneDate, total_rfp_days) + '%', width: getStepPercent(timelineItems[index + 1].mileStoneDate, timeLineItem.mileStoneDate, total_rfp_days) + '%' }}>
                  {(currentStageNumber - 1 === index) ?
                    (<div class="timeline-badge redis"></div>) : (currentStageNumber - 1 >= index) ? (<div class="timeline-badge redis went-passed"></div>) : (<div class="timeline-badge gray"></div>)
                  }
                  {
                    index % 2 === 0 ?
                      (<div class="timeline-panel">
                        <div class="timeline-body">
                          {(currentStageNumber - 1 === index) ?
                            (<p class="redisColors" style={{ "font-weight": "500", }}>{timeLineItem.mileStoneName.substring(0, 20)} {timeLineItem.mileStoneDateWords}</p>) :
                            (
                              <>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)}</p>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-48px" } : { "font-weight": "500" }}> {timeLineItem.mileStoneDateWords}</p>
                              </>
                            )
                          }

                        </div>
                      </div>) :
                      (<div class="timeline-panel belows">
                        <div class="timeline-body">
                          {(currentStageNumber - 1 === index) ?
                            (
                              <>
                                <p class="redisColors" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)}</p>
                                <p class="redisColors" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-48px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneDateWords}</p>
                              </>
                            ) :
                            (
                              <>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)} </p>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-48px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneDateWords}</p>
                              </>
                            )
                          }

                        </div>
                      </div>)}

                </li>
                :
                <li class="timeline-item">

                  {(currentStageNumber - 1 === index) ?
                    (<div class="timeline-badge redis"></div>) : (<div class="timeline-badge gray"></div>)
                  }
                  {
                    index % 2 === 0 ?
                      (<div class="timeline-panel">
                        <div class="timeline-body">
                          {(currentStageNumber - 1 === index) ?
                            (<p class="redisColors" style={{ "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)} {timeLineItem.mileStoneDateWords}</p>) :
                            (
                              <>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)} </p>
                                <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-63px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneDateWords}</p>
                              </>
                            )
                          }

                        </div>
                      </div>) :
                      (<div class="timeline-panel belows">
                        <div class="timeline-body">
                          {(currentStageNumber - 1 === index) ?
                            <p class="redisColors" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)} {timeLineItem.mileStoneDateWords}</p> :
                            <>
                              <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-76px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneName.substring(0, 20)} </p>
                              <p class="" style={timeLineItem.mileStoneName === "Feedback" || timeLineItem.mileStoneName === "Short List" ? { "font-weight": "500", "position": "absolute", top: "-48px" } : { "font-weight": "500" }}>{timeLineItem.mileStoneDateWords}</p>
                            </>
                          }

                        </div>
                      </div>)}

                </li>

            ))}
          </ul>
        </div>
      </Grid>
    </div>
  );
}
