/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class CompanyService {
  constructor() {
    this.config = new Configuration();
  }
  async deleteProductComp(id) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');

    const url = this.config.baseUrl + this.config.deleteProduct + '/' + id;

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return AxiosInstance.delete(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }
  async addCompany(companyObj, isFromRFP = false, rfpId) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    companyObj.userId = userId;

    const url = this.config.baseUrl + this.config.addCompany;
    const formData = new FormData();
    formData.append('logo', companyObj.logo);
    formData.append('companyName', companyObj.companyName);
    formData.append('companyType', companyObj.companyType);
    formData.append('currency', companyObj.currency);
    formData.append('fiscalYearMonth', companyObj.fiscalYearMonth);
    formData.append('headCount', companyObj.headCount);
    formData.append('keywords', JSON.stringify(companyObj.keywords));
    formData.append('locations', JSON.stringify(companyObj.locations));
    formData.append('overview', JSON.stringify(companyObj.overview));
    formData.append('totalRevenue', companyObj.totalRevenue);
    formData.append('userId', companyObj.userId);
    formData.append('videoUrl', companyObj.videoUrl);
    formData.append('type', companyObj.type);
    formData.append('website', companyObj.website);
    if (isFromRFP) {
      companyObj.rfpId = rfpId;
      formData.append('rfpId', companyObj.rfpId);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return AxiosInstance.post(url, formData, config)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(':: error', error)
        self.handleError(error);
      });
  }

  async getCompanyByUser(isFromRFP, rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    console.log("coming...");
    let url = this.config.baseUrl + this.config.getCompanyByUser + userId;
    if (isFromRFP) {
      url = this.config.baseUrl + this.config.getCompanyByUser + userId + '/' + rfpId;
    }
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }


  async getProductList(companyId, page, limit, isLive = undefined) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    let url;
    if (isLive) {
      url = `${this.config.baseUrl}${this.config.getLiveProducts}/${companyId}/${page}/${limit}`;
    } else {
      url = `${this.config.baseUrl}${this.config.getProducts}/${companyId}/${page}/${limit}`;
    }
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getRfpProducts(rfpId, page, limit) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    let url;
    url = `${this.config.baseUrl}${this.config.getRfpProducts}/${rfpId}/${page}/${limit}`;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }


  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      window.scrollTo(0, 0)
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
      //alertService.error('Error hhh', { id: 'left-alert' });
      // Request made and server responded
      console.log(':: error ', error.response.data);
      // alert(error.response.data.message);
      console.log(':: error ', error.response.status);
      console.log(':: error ', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default CompanyService;
