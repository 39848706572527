export class QuestionAnswerModel {
  quesAns = [];
  constructor(questionAnswerInfo = null) {
    if (questionAnswerInfo && questionAnswerInfo.quesAns && questionAnswerInfo.quesAns.length) {
      questionAnswerInfo.quesAns.forEach(question => {
        this.quesAns.push(new QuestionAnswer(question))
      });
    }
  }
}

class QuestionAnswer {
  question = '';
  answer = '';
  constructor(questionInfo = null) {
    if (questionInfo) {
      this.question = questionInfo.question || this.question;
      this.answer = questionInfo.answer || this.answer;
    }
  }
}
