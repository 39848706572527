import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React from 'react';

export default function AddSection(props) {
  const [open, setOpen] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [sectionNameValue, setSectionNameValue] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const useStyles = makeStyles(({
    root: {},
    textStyle: { color: '#000 !important' }

  }));
  const classes = useStyles();
  const updateSectionNameValue = (event) => {
    setSectionNameValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateSection = () => {
    props.onAddSection('', props.indexNumber);
  };

  return (
    <div>
      <Button className={classes.blueBtn} style={{ "color": "#fff", "backgroundColor": "#4688f0", "padding": "6px 16px", "fontSize": "12px", "margin": "0px", marginLeft: '30px', marginTop: '10px', marginBottom: '18px' }} color="primaryd" variant="contained" type="button" onClick={handleCreateSection}>
        Add Section
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Section</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.textStyle}>
            To add new section, Please enter a section name below
          </DialogContentText>
          <TextField
            multiline
            error={Boolean(hasError)}
            autoFocus
            margin="dense"
            onChange={updateSectionNameValue}
            id="name"
            label="Section Name"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateSection} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
