
export const getSteps = (activeSteps) => {
  const stepsName = {
    companyInformation: 'View Company Information',
    projectInformation: 'View Project Information',
    acceptRejectInvite: 'Accept Invite',
    technicalRequirements: 'Technical Requirements',
    commercialEvaluation: 'Commercial Requirements',
    vendorInvite: 'Vendor Invite',
    nda: 'Non Disclosure',
    vendorShortList: 'Vendor Short List',
    proofOfconceptValue: 'Accept POV/POC',
    statementOfWork: 'Statement of Work',
    finalPresentation: 'Final Presentation Info',
    references: 'Reference Info',
    pricingNegotiations: 'Pricing Info',
    contactAgreements: 'Contract Agreements',
    rfpCompletion: 'RFP Completion',
    legalContracts: 'Legal Contracts'
  };
  return activeSteps.map(step => { return stepsName[step] });
}
