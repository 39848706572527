/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class ProjectService {
  constructor() {
    this.config = new Configuration();
  }

  async addProject(projectObj, rfpId) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    // const rfpId = localStorage.getItem('rfpId');
    projectObj.userId = userId;
    projectObj.rfpId = rfpId;
    const url = this.config.baseUrl + this.config.addProject;
    const formData = new FormData();
    for (let i = 0; i < projectObj.file.length; i++) {
      formData.append('supportingMaterial', projectObj.file[i]);
    }
    formData.append('projectName', projectObj.projectName);
    formData.append('projectDescription', JSON.stringify(projectObj.projectDescription));
    formData.append('metrics', projectObj.metrics);
    formData.append('decisionProcess', projectObj.decisionProcess);
    formData.append('businessObjective', projectObj.businessObjective);
    formData.append('decisionCriteria', projectObj.decisionCriteria);
    formData.append('contacts', JSON.stringify(projectObj.contacts));
    formData.append('timelineItems', JSON.stringify(projectObj.timelineItems));
    formData.append('userId', projectObj.userId);
    formData.append('type', projectObj.type);
    formData.append('rfpId', projectObj.rfpId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return AxiosInstance.post(url, formData, config)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getProject(rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    // const rfpId = localStorage.getItem('rfpId');
    const url = this.config.getProject + rfpId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async deleteSupportingMaterial(fileName, rfpId) {
    let reqObj = {}
    reqObj.fileName = fileName
    reqObj.fileName = fileName
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    reqObj.userId = userId;
    reqObj.rfpId = rfpId;
    const url = this.config.baseUrl + this.config.deleteMaterialDocument;
    return AxiosInstance.post(url, reqObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
      // Request made and server responded
      console.log(':: error', error.response.data);
      // alert(error.response.data.message);
      console.log(':: error', error.response.status);
      console.log(':: error', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default ProjectService;
