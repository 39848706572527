import { Button, CardContent, Grid, Input } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from "react-redux";
import 'src/views/shared/css/common.scss';
import { getRfpByIdCommericalUpdate, getRfpByIdTechnicalUpdate } from "../../store/updateRfp/actions";
import { useStyles } from '../../views/TechnicalEvaluation/TechnicalEvaluationStyle';
import TechinicalRequirementQuestion from '../Question/index';

const TechinicalRequirementSection = ({ categoryIndex, section, sectionIndex, values, touched, errors, getRfpByIdTechnicalUpdate, technicalEvalUpdateData, getRfpByIdCommericalUpdate, commercialEvalUpdateData, step, ...rest }) => {
  const classes = useStyles();
  const [is_visible, setIsVisible] = useState(true);
  function deleteSection(event, categoryIndex, sectionIndex) {
    if (window.confirm('Are you sure to delete this section?')) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      if (step === 2) {
        const dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections.splice(sectionIndex, 1);
        technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
        getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
      }

      if (step === 3) {
        const dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections.splice(sectionIndex, 1);
        commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
        getRfpByIdCommericalUpdate(commercialEvalUpdateData)
      }
    } else {
      event.stopPropagation();
    }
  }

  function updateSectionName(event, categoryIndex, sectionIndex) {
    if (step === 2) {
      const dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].sectionName = event.target.value;
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
    }

    if (step === 3) {
      const dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].sectionName = event.target.value;
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    }
  }

  function stopPropogation(event, categoryIndex, sectionIndex) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  }


  const addQuestions = (categoryIndex, sectionIndex) => {

    if (step === 2) {
      const dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions.push({ questionId: new Date().getTime(), question: '', type: 'yes-no', score: 1 });
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
    }
    if (step === 3) {
      const dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions.push({ questionId: new Date().getTime(), question: '', type: 'yes-no', score: 1 });
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    }
  }

  return (
    <React.Fragment>
      <Accordion expanded={is_visible} className="no-margin requestly-fin-info-tab">
        <AccordionSummary className="requestly-tab-height-sectionwise" expandIcon={<ExpandMoreIcon onClick={e => setIsVisible(!is_visible)} />} aria-controls="panel-location-content" id="panel-location">
          <Typography className={classes.heading} id="acrdInputs">
            <Input style={{ "color": "#000", "border": "none", "font-weight": "bold" }} placeholder="Section Name" defaultValue={section.sectionName} onClick={(e) => stopPropogation(e)} onKeyUp={(e) => updateSectionName(e, categoryIndex, sectionIndex)} inputProps={{ 'aria-label': 'description' }} />
            <DeleteIcon className="site-delete-icon" onClick={(e) => deleteSection(e, categoryIndex, sectionIndex)} style={{ "color": "grey", "padding": "1px", "margin": "5px", }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="span" variant="body2" className={classes.widthFill}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FieldArray name="locations"
                    render={arrayHelpers => (
                      <div className={classes.customInputs}>
                        {section.questions && section.questions.map((question, questionIndex) => (
                          <TechinicalRequirementQuestion touched={touched} errors={errors} values={values} questionIndex={questionIndex} categoryIndex={categoryIndex} sectionIndex={sectionIndex} questionInfo={question} />
                        ))}
                      </div>
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Button className={classes.blueBtn} style={{ "color": "#fff", "backgroundColor": "#4688f0", "padding": "6px 16px", "fontSize": "12px", "margin": "0px" }} color="primaryd" variant="contained" type="button" onClick={() => addQuestions(categoryIndex, sectionIndex)}>
                    Add Question
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
TechinicalRequirementSection.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  technicalEvalUpdateData: state.updateRfp.rfpTechnicalDataUpdate,
  commercialEvalUpdateData: state.updateRfp.rfpCommericalDataUpdate,
  step: state.updateRfp.rfpStepUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdTechnicalUpdate: (value) => dispatch(getRfpByIdTechnicalUpdate(value)),
  getRfpByIdCommericalUpdate: (value) => dispatch(getRfpByIdCommericalUpdate(value))

});

export default connect(mapStateToProps, mapDispatchToProps)(TechinicalRequirementSection);
