import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles, Radio, RadioGroup,
  TextField, Typography
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { alertService } from 'src/components/toaster/alert.service';
import { history } from 'src/utils/utils';
import * as Yup from 'yup';
import RFPService from '../../../services/rfp/rfp.service';
import { complete, detailed, rapid, standard } from "./data/processConfiguration";
import './styles.css';

const useStyles = makeStyles(({
  root: {},
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  RfpName: {
    width: '100%',
    maxWidth: 360,

  },
  buttoncolor: {
    color: "#000000",
  },
  textsize: {
    fontSize: '1rem!important',
    fontWeight: 400,
    fontFamily: " Roboto, Helvetica, Arial, sans-serif !important"
  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: { marginTop: '10px !important', marginBottom: '8px !important', padding: '2px !important' },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: 'contents', textAlign: 'center' },
  listCheckline: { display: 'block', marginTop: '10px', },
  titleTagTxt: { fontSize: '18px', fontWeight: '800', margin: 'auto', fontFamily: " Roboto, Helvetica, Arial, sans-serif !important" },
}));

const BasicRfpConfiguration = ({ className, ...rest }) => {
  const [rfpValueState, setRfpValue] = React.useState({
    rfpName: '',
    processTypeValue: 'standard',
    scopeValue: 'private',
    stepsValue: standard,
    isAnonymous: false
  });
  const rfpService = new RFPService();

  const handleStepChange = (event) => {
    let processTypeValue = rfpValueState.processTypeValue;
    if (rfpValueState.processTypeValue === 'rapid' || rfpValueState.processTypeValue === 'standard' || rfpValueState.processTypeValue === 'complete') {
      processTypeValue = 'detailed';
    }
    setRfpValue({
      rfpName: rfpValueState.rfpName,
      processTypeValue: processTypeValue,
      scopeValue: rfpValueState.scopeValue,
      stepsValue: { ...rfpValueState.stepsValue, [event.target.name]: event.target.checked },
      isAnonymous: rfpValueState.isAnonymous
    });
  };

  const handleAnonymousChange = (event) => {
    setRfpValue({
      rfpName: rfpValueState.rfpName,
      processTypeValue: rfpValueState.processTypeValue,
      scopeValue: rfpValueState.scopeValue,
      stepsValue: rfpValueState.stepsValue,
      isAnonymous: event.target.checked
    });
  };

  const handleNameChange = (event) => {
    setRfpValue({
      rfpName: event.target.value,
      processTypeValue: rfpValueState.processTypeValue,
      scopeValue: rfpValueState.scopeValue,
      stepsValue: rfpValueState.stepsValue,
      isAnonymous: rfpValueState.isAnonymous
    });
  };

  const handleScopeChange = (event) => {
    switch (event.target.value) {
      case 'public':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: rfpValueState.processTypeValue,
          scopeValue: 'public',
          stepsValue: rfpValueState.stepsValue,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;
      case 'private':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: rfpValueState.processTypeValue,
          scopeValue: 'private',
          stepsValue: rfpValueState.stepsValue,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;

    }
  }

  const handleProcessTypeChange = (event) => {
    switch (event.target.value) {
      case 'rapid':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: 'rapid',
          scopeValue: rfpValueState.scopeValue,
          stepsValue: rapid,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;
      case 'standard':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: 'standard',
          scopeValue: rfpValueState.scopeValue,
          stepsValue: standard,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;
      case 'complete':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: 'complete',
          scopeValue: rfpValueState.scopeValue,
          stepsValue: complete,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;
      case 'detailed':
        setRfpValue({
          rfpName: rfpValueState.rfpName,
          processTypeValue: 'detailed',
          scopeValue: rfpValueState.scopeValue,
          stepsValue: detailed,
          isAnonymous: rfpValueState.isAnonymous
        });
        break;
    }
  };
  const autoClose = true;
  const keepAfterRouteChange = true;
  const classes = useStyles();

  // console.log(rest, "restcreate");

  return (
    <Formik
      initialValues={rfpValueState}
      enableReinitialize={true}
      validationSchema={
        Yup.object().shape({
          rfpName: Yup.string().max(255).required('RFP Name is required.'),
        })
      }
      onSubmit={(values) => {
        rfpService.addRFP(values).then(res => {
          if (res) {
            alertService.success("RFP has been created successfully.", { autoClose, keepAfterRouteChange })
            history.push('/app/update-rfp/' + res.rfp._id + '?product_name=' + values.rfpName);
          }
        })
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit} className={'requestly-rfp-form'}>
          <Card>
            <CardHeader
              subheader="Please define your evaluation process. You can use the predefined (standard) settings or select your own"
              title="Create RFP"
              className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'}
            />
            <Divider />
            <CardContent className=''>
              <Grid className={classes.item} item md={12} sm={12} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  error={Boolean(touched.rfpName && errors.rfpName)}
                  helperText={touched.rfpName && errors.rfpName}
                  placeholder="RFP Name"
                  name="rfpName"
                  onBlur={handleNameChange}
                  onChange={handleChange}
                  value={values.rfpName}
                  variant="outlined"
                  className="requestly-rfp-textbox"
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid className={classes.titleTag} item md={3} sm={3} xs={12}>
                  <Typography className={classes.titleTagTxt} color="textPrimary" gutterBottom variant="h6">
                    RFP Visibility
                  </Typography>
                </Grid>
                <Grid container className={classes.inputAll} md={9} item sm={9} xs={12}>
                  <RadioGroup className={classes.radioGrpLine} aria-label="scope" name="scope" value={rfpValueState.scopeValue} onChange={handleScopeChange} >
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel value="public" control={<Radio />} label="Public" className="font-family" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel value="private" control={<Radio />} label="Private" className="font-family" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                    </Grid>
                  </RadioGroup>
                  {/* <Grid className={classes.item} item md={3} sm={3} xs={6}>
                <FormControlLabel control={(  <Checkbox /> )} checked={rfpValueState.isAnonymous} onChange={handleAnonymousChange} label="Anonymous"/>
              </Grid> */}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid className={classes.titleTag} item md={3} sm={3} xs={12}>
                  <Typography className={classes.titleTagTxt} color="textPrimary" gutterBottom variant="h6">
                    RFP Process
                  </Typography>
                </Grid>
                <Grid container className={classes.inputAll} md={9} item sm={9} xs={12}>
                  <RadioGroup className={classes.radioGrpLine} aria-label="processType" name="processType" value={rfpValueState.processTypeValue} onChange={handleProcessTypeChange}>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel value="rapid" control={<Radio />} label="Rapid" className='fixed-radio-class font-family' />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel value="standard" control={<Radio />} label="Standard" className='fixed-radio-class font-family' />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel value="complete" control={<Radio />} label="Complete" className='fixed-radio-class font-family' />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel className={classes.buttoncolor + ' fixed-radio-class font-family'} disabled value="detailed" control={<Radio />} label="Custom" />
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Divider />
              <Grid className={classes.listChecks} container spacing={0}>
                {/*  <Grid md={4} sm={12} xs={12}>
            </Grid> */}
                <Grid md={7} sm={12} xs={12}>
                  <List dense className={classes.RfpName}>
                    <ListItem>
                      <ListItemText className={classes.textsize + ' requestly-disabled-label font-family'} disableTypography primary="Company Information" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.companyInformation} onChange={handleStepChange} name="companyInformation" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <List dense className={classes.RfpName + ' requestly-disabled-label font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Project Information" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.projectInformation} onChange={handleStepChange} name="projectInformation" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  <List dense className={classes.RfpName + ' requestly-disabled-label font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Technical Requirements" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.technicalRequirements} onChange={handleStepChange} name="technicalRequirements" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>

                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Commercial Evaluation" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.commercialEvaluation} onChange={handleStepChange} name="commercialEvaluation" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>

                  <List dense className={classes.RfpName + ' requestly-disabled-label font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Vendor Invite" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.vendorInvite} onChange={handleStepChange} name="vendorInvite" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="NDA" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.nda} onChange={handleStepChange} name="nda" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  {/* <List dense className={classes.RfpName}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Vendor Short List" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.vendorShortList} onChange={handleStepChange} name="vendorShortList" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List> */}
                  {/* <List dense className={classes.RfpName}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Proof of Concept Value" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.proofOfconceptValue} onChange={handleStepChange} name="proofOfconceptValue" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List> */}
                  {/* <List dense className={classes.RfpName}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Statement of Work" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.statementOfWork} onChange={handleStepChange} name="statementOfWork" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List> */}
                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Final Presentation" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.finalPresentation} onChange={handleStepChange} name="finalPresentation" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="References" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.references} onChange={handleStepChange} name="references" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Pricing/Negotiations" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.pricingNegotiations} onChange={handleStepChange} name="pricingNegotiations" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  <List dense className={classes.RfpName + ' font-family'}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="Contact Agreements" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.contactAgreements} onChange={handleStepChange} name="contactAgreements" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List>
                  {/* <List dense className={classes.RfpName}>
                    <ListItem>
                      <ListItemText className={classes.textsize} disableTypography primary="RFP Completion" />
                      <ListItemSecondaryAction>
                        <FormControlLabel labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.rfpCompletion} onChange={handleStepChange} name="rfpCompletion" />)} />
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List> */}

                  {/*   <FormControlLabel labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.companyInformation} onChange={handleStepChange} name="companyInformation" />)} label="Company Information" />
                  <FormControlLabel  labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.projectInformation} onChange={handleStepChange} name="projectInformation" />)} label="Project Information" />
                  <FormControlLabel  labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.technicalRequirements} onChange={handleStepChange} name="technicalRequirements" />)} label="Technical Requirements" />
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.commercialEvaluation} onChange={handleStepChange} name="commercialEvaluation" />)} label="Commercial Evaluation" /><br/>
                  <FormControlLabel  labelPlacement="start" disabled control={(<Checkbox checked={rfpValueState.stepsValue.vendorInvite} onChange={handleStepChange} name="vendorInvite" />)} label="Vendor Invite" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.nda} onChange={handleStepChange} name="nda" />)} label="NDA" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.vendorShortList} onChange={handleStepChange} name="vendorShortList" />)} label="Vendor Short List" />
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.proofOfconceptValue} onChange={handleStepChange} name="proofOfconceptValue" />)} label="Proof of Concept/Value" />
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.statementOfWork} onChange={handleStepChange} name="statementOfWork" />)} label="Statement of Work" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.finalPresentation} onChange={handleStepChange} name="finalPresentation" />)} label="Final Presentation" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.references} onChange={handleStepChange} name="references" />)} label="References" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.pricingNegotiations} onChange={handleStepChange} name="pricingNegotiations" />)} label="Pricing/Negotiations" />
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.contactAgreements} onChange={handleStepChange} name="contactAgreements" />)} label="Contact Agreements" /><br/>
                  <FormControlLabel  labelPlacement="start" control={(<Checkbox checked={rfpValueState.stepsValue.rfpCompletion} onChange={handleStepChange} name="rfpCompletion" />)} /> */}
                </Grid>
                <Grid md={3} sm={12} xs={12}>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box className={classes.footerBox} p={2}>
              <FormControlLabel disabled control={(<Checkbox defaultChecked />)} label="Required" className="font-family" />
              <FormControlLabel disabled control={(<Checkbox />)} label="Optional" className="font-family" />
              <Button
                color="primary"
                className={classes.button + ' requestly-rfp-button'}
                type="submit"
                variant="contained"
                style={{ float: "right" }}
                onClick={handleSubmit}>
                Create RFP
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

BasicRfpConfiguration.propTypes = {
  className: PropTypes.string
};

export default BasicRfpConfiguration;
