import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import BillingService from 'src/services/billing/billing.service';
import { history } from "src/utils/utils";
import FilterModal from './filter';
import "./style.css";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    fontSize: "14px",
    color: "white",
    textTransform: "capitalize"
  },
  button2: {
    margin: theme.spacing(1),
    backgroundColor: "#52B152",
    fontSize: "14px",
    color: "white",
    textTransform: "capitalize"
  },
  table: {
    minWidth: 650
  },
  noRecordFound: {
    textAlign: "center",
    width: "100%",
    marginTop: "50px",
  },
  green: {
    color: "#52b152 !important",
    fontWeight: "600"
  }
}));

const Main = () => {
  const classes = useStyles();
  const [billingData, setBillingData] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const billingService = new BillingService();

  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    getBillingData();
  }, []);

  const getBillingData = () => {
    billingService.getCreditHistory().then(res => {
      if (res && res.data && res.data.length > 0) {
        setBillingData(res.data);
        setStoreData(res.data);
      }
    }).catch(err => console.log(":: err", err));
  }

  const handleFilter = (data) => {
    const temp = storeData.filter((el) => {
      const res = Object.values(data.selectedMR).some(value => value === true)
      if (res && (data.selectedFromDate && data.selectedToDate)) {
        const temp = moment(el.acceptDate || el.createdAt).isBetween(data.selectedFromDate, data.selectedToDate, 'days', true);

        if (temp) {
          var keys = [];
          for (var i in data.selectedMR) {
            if (data.selectedMR[i] === true) {
              keys.push(i);
            }
          }
          return (keys.includes(el.transitionType));
        }

      } else if (data.selectedFromDate && data.selectedToDate) {
        return (moment(el.acceptDate || el.createdAt).isBetween(data.selectedFromDate, data.selectedToDate, 'days', true));
      } else if (res) {
        var keys = [];
        for (var i in data.selectedMR) {
          if (data.selectedMR[i] === true) {
            keys.push(i);
          }
        }

        return (keys.includes(el.transitionType));
      }
    });

    setBillingData(temp);
  }


  return (
    <>
      <div className="mainBox">
        <div style={{ display: "block" }}>
          <div className="billing-heading">Billing / Credit</div>
          <div className="billing-heading-line2">
            <Button
              variant="contained"
              id="filter-btn"
              aria-describedby={'popover-filter'}
              className={classes.button}
              startIcon={<FilterAltIcon />}
              onClick={(e) => {
                if (!showFilter) {
                  setShowFilter(true);
                  handleClick(e)
                } else {
                  handleClose();
                  setShowFilter(false)
                }
              }}
            >
              Filter
              {/* <span
                style={{
                  paddingRight: "6px"
                }}
              ></span>
              <span
                style={{
                  color: "white",
                  paddingLeft: "6px",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
                }}
              >
                1
              </span> */}
            </Button>
            <FilterModal
              openModal={showFilter}
              anchorEl={anchorEl}
              applyFilter={handleFilter}
              closeModal={() => {
                handleClose();
                setShowFilter(false)
              }}
              resetFilter={() => setBillingData(storeData)}
            />

            <span className="billing-parent">
              <Button
                variant="contained"
                className="billing-button2"
                startIcon={<AddIcon />}
                onClick={() => {
                  localStorage.removeItem('isFromMR');
                  history.push('/app/product-list')
                }}
              >
                Buy Credits
              </Button>
            </span>
          </div>
        </div>
        <hr className="MuiDivider-root" />
        <div className="billing-table">
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Transaction</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="center">Request Date</TableCell>
                  <TableCell align="center">Accepted Date</TableCell>
                  <TableCell align="center">Accepted By</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingData && billingData.map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.transitionType}
                    </TableCell>
                    <TableCell align="left">{row.productName}</TableCell>
                    <TableCell align="center">{row.requestDate && moment(row.requestDate).format('DD MMM, YYYY')}</TableCell>
                    <TableCell align="center">{row.acceptDate && moment(row.acceptDate).format('DD MMM, YYYY')}</TableCell>
                    <TableCell align="center">{row.acceptedBy}</TableCell>
                    <TableCell align="center" className={row.isSubscripton && classes.green}>{row?.isFree ? 'Free' : (row?.isEnterprise ? 'Enterprise' : ((row.isSubscripton ? '+' : '-') + row.credits))}</TableCell>
                    <TableCell align="center" className={row.isSubscripton && classes.green}>{row.balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {
              (!billingData || !billingData?.length) &&
              <div className={classes.noRecordFound}>
                <Typography>No Record Found!</Typography>
              </div>
            }
          </TableContainer>
        </div>
      </div>


    </>
  );
};
Main.propTypes = {
  className: PropTypes.string
};

export default Main;
