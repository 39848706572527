
import moment from 'moment';

export class ProductInfo {
  productName = '';
  productInfo = '';
  isPublished = 0;
  keywords = [];
  notableCustomers = [];
  targetPersona = [];
  competitors = [];
  productSolVideoUrl = [];
  productLaunchDate = null;
  dataSheets = [];
  pricingProductName = '';
  unit = '';
  isData = false;
  productId = '';
  companyId = '';
  constructor(productInformation = null) {
    if (productInformation) {
      this.isData = true;
      this.productName = productInformation.productName || this.productName;
      this.productInfo = productInformation.productInfo || this.productInfo;
      this.isPublished = productInformation.isPublished || this.isPublished
      this.notableCustomers = productInformation.notableCustomers || this.notableCustomers;
      this.keywords = productInformation.keywords || this.keywords;
      this.targetPersona = productInformation.targetPersona || this.targetPersona;
      this.competitors = productInformation.competitors || this.competitors;
      this.productSolVideoUrl = productInformation.productSolVideoUrl || this.productSolVideoUrl;
      this.productLaunchDate = moment(productInformation.productLaunchDate).format('YYYY-MM-DD') || this.productLaunchDate;
      this.dataSheets = productInformation.dataSheets || this.dataSheets;
      this.dataSheets_docs = productInformation.dataSheets_docs || this.dataSheets_docs;
      this.pricingProductName = productInformation.pricingProductName || this.pricingProductName;
      this.unit = productInformation.unit || this.unit;
      this.productId = productInformation._id || this.productId;
      this.companyId = productInformation.companyId || this.companyId;
    }
  }
}
