import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  TextField
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import EnquiryService from "src/services/enquiry/enquiry.service";
import { history } from "src/utils/utils";
import CompanyService from "../../../services/company/company.service";
import "./style.css";
const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  DividerPosition: {
    position: "relative",
    top: "-25px",
    marginLeft: "48px"
  },
  IconBackground: {
    background: "#fff"
  },
  boxborder: {
    height: "175px",
    width: "100% !important",
    border: "1px solid gainsboro",
    margin: "8px",
    padding: "0px 10px 5px 15px",
    borderRadius: "10px"
  },
  h1: {
    width: "101px",
    marginTop: "-12px",
    marginLeft: "24px",
    background: "#f4f6f8",
    fontsize: "1px"
  },
  CardDMSize: {
    height: "120px",
    background: "rgb(99, 191,184)",
    color: "#fff",
    cursor: "pointer"
  },
  CardPDSize: {
    height: "120px",
    background: "rgb(76,188,105)",
    color: "#fff",
    cursor: "pointer"
  },
  CardSize: {
    height: "120px",
    background: "#4688f0",
    color: "#fff"
  },
  TextPosition: {
    position: "relative",
    fontSize: "12px"
  },
  ViewDetailsPosition: {
    color: "black",
    paddingTop: "10px",
    fontSize: "13px"
  }
}));

const Toolbar = ({
  className,
  filterRfp,
  sortRfp,
  sortOrder,
  filterRfpApi,
  productDemoList,
  dicoveryMeetingList,
  ...rest
}) => {
  const [showCompanyInformation, setShowCompanyInformation] = React.useState(0);
  const [showProductInformation, setShowProductInformation] = React.useState(0);
  const [companyId, setCompanyId] = useState(null);
  const [productDemoVendorList, setProductDemoVendorList] = useState([]);
  const [discoveryMeetingVendorList, setDiscoveryMeetingVendorList] = useState(
    []
  );
  const [modalOpenProductDemoVendor, setModalOpenProductDemoVendor] = useState(
    false
  );
  const [product, setSelectedProduct] = useState();
  const [isDiscoveryMeeting, setIsDiscoveryMeeting] = useState(false);
  const [options, setOptions] = useState(false);

  const classes = useStyles();
  const companyService = new CompanyService();
  const enquiryService = new EnquiryService();

  useEffect(() => {
    getCompanyData();
  }, []);
  useEffect(() => {
    if (companyId) {
      getProductData();
    }
  }, [companyId]);

  useEffect(() => {
    if (productDemoList && productDemoList.length > 0) {
      let data = [];
      productDemoList.map(product => {
        if (product && product.isEditable && !product?.isVisited) {
          data.push(product);
        }
      });
      setProductDemoVendorList(data);
    }

    if (dicoveryMeetingList && dicoveryMeetingList.length > 0) {
      let data = [];
      dicoveryMeetingList.map(product => {
        if (product && product.isEditable && !product?.isVisited) {
          data.push(product);
        }
      });
      setDiscoveryMeetingVendorList(data);
    }
  }, [showProductInformation, dicoveryMeetingList, productDemoList]);


  const getCompanyData = () => {
    companyService
      .getCompanyByUser(false)
      .then(res => {
        if (res !== undefined && res) {
          setShowCompanyInformation(
            res?.company?.companyName && res?.company?.companyName !== ""
              ? 1
              : 0
          );
          if (res?.company?._id) {
            setCompanyId(res.company._id);
          }
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const getProductData = () => {
    companyService
      .getProductList(companyId, 1, 10, false)
      .then(res => {
        if (res !== undefined && res) {
          setShowProductInformation(
            res?.data && res?.data?.length !== 0 ? 1 : 0
          );
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const redirectToRFP = () => {
    history.push("/app/create-rfp");
  };

  const redirectToProduct = () => {
    history.push(
      "/category/loom?allKeywords=&category=&company=&employee=&page=0&search=&subCategory=&targetPersona="
    );
  };

  const handleChange = e => {
    if (e.target.value === "dm" || e.target.value === "pd") {
      const selectedItem = e.target.value === "dm" ? 1 : 2;
      localStorage.setItem('selectedOption', selectedItem);
      setOptions(false);
      redirectToProduct();
    } else {
      setOptions(false);
      redirectToRFP();
    }
  };

  const handleClose = () => {
    setOptions(false);
  };

  const handleOpen = () => {
    setOptions(true);
  };

  const changeVisitStatus = (id, status) => {
    let requestParams = {
      type: status ? 2 : 1,
      _id: id,
    }
    enquiryService.changeVisitStatus(requestParams).then((res) => {
      console.log(res);
      if (res && res.data) {
        history.push(
          `/app/discovery-meeting-request/${id}/${status}`
        );
      }
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end"></Box>

      {showCompanyInformation === 0 ||
        showProductInformation === 0 ||
        (productDemoVendorList && productDemoVendorList.length > 0) ||
        (discoveryMeetingVendorList && discoveryMeetingVendorList.length > 0) ? (
        <Box className={classes.boxborder + " slick-provision"}>
          <h6 className={classes.h1}> TO-DO</h6>
          <Grid container spacing={3} className="grid-scroller">
            {productDemoVendorList &&
              productDemoVendorList.length > 0 &&
              productDemoVendorList.map(pd => (
                <Grid item>
                  <Card
                    onClick={() => {
                      changeVisitStatus(pd.id, false);
                      setIsDiscoveryMeeting(false);
                    }}
                    className={classes.CardPDSize + " request-pd-card"}
                  >
                    <CardContent
                      style={{ paddingVertical: "15px" }}
                      className="request-pd-card-content"
                    >
                      <center>
                        <Typography
                          className={classes.TextPosition + " request-pd-text"}
                          align="center"
                          variant="h6"
                        >
                          Request for <br /> Product <br /> Demo
                        </Typography>
                      </center>
                      <Typography
                        className={
                          classes.ViewDetailsPosition + " request-pd-details"
                        }
                        align="center"
                        variant="h6"
                      >
                        View Details
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

            {discoveryMeetingVendorList &&
              discoveryMeetingVendorList.length > 0 &&
              discoveryMeetingVendorList.map(dm => (
                <Grid item>
                  <Card
                    onClick={() => {
                      changeVisitStatus(dm.id, true);
                      setIsDiscoveryMeeting(true);
                    }}
                    className={classes.CardDMSize + " request-dm-card"}
                  >
                    <CardContent
                      style={{ paddingVertical: "15px" }}
                      className="request-dm-card-content"
                    >
                      <center>
                        <Typography
                          className={
                            classes.TextPosition + " request-dm-heading"
                          }
                          align="center"
                          variant="h6"
                        >
                          Request for <br /> Dicovery <br /> Meeting
                        </Typography>
                      </center>
                      <Typography
                        className={
                          classes.ViewDetailsPosition + " request-dm-details"
                        }
                        align="center"
                        variant="h6"
                      >
                        View Details
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

            {showCompanyInformation === 0 ? (
              <Grid item>
                <Card
                  onClick={() =>
                    history.push({
                      pathname: "/app/additional-information",
                      state: {
                        step: 0
                      }
                    })
                  }
                  className={classes.CardSize + " company-block-card"}
                >
                  <CardContent style={{ padding: "0px" }}>
                    <center>
                      <img src="/company.png" style={{ height: "75px" }} />
                    </center>
                    <Typography
                      className={classes.TextPosition}
                      align="center"
                      variant="h6"
                    >
                      Add Company information
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {showProductInformation === 0 ? (
              <Grid item>
                <Card
                  onClick={() =>
                    history.push({
                      pathname: "/app/additional-information",
                      state: {
                        step: 1
                      }
                    })
                  }
                  className={classes.CardSize + " company-block-card"}
                >
                  <CardContent style={{ padding: "0px" }}>
                    <center>
                      <img src="/product.png" style={{ height: "75px" }} />
                    </center>
                    <Typography
                      className={classes.TextPosition}
                      align="center"
                      variant="h6"
                    >
                      Add Product information
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </Box>
      ) : null}


      <Box className={classes.footerBox} p={2}>
        <div className="req_dropdown">
          <Tooltip title="Create MR">
            <Button
              color="primary"
              className={classes.button + " requestly-rfp-button"}
              type="submit"
              variant="contained"
              style={{
                width: "160px",
                marginBottom: "30px",
                textTransform: "capitalize"
              }}
              onClick={handleOpen}
            >
              {options === true ? <RemoveIcon /> : <AddIcon />}
              <span style={{
                paddingTop: "3px"
              }}>Create Request</span>
            </Button>
          </Tooltip>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={options}
            onClose={handleClose}
            onChange={handleChange}
          >
            <MenuItem value="dm">Discovery Meeting</MenuItem>
            <MenuItem value="pd">Product Demo</MenuItem>
            <MenuItem value="rfp">RFP</MenuItem>
          </Select>
        </div>
      </Box>

      <Divider className={classes.DividerPosition} varient="fullWidth" />
      <div>
        <Grid style={{ marginTop: "10px" }} container spacing={3}>
          <Grid spacing={3} md={2} item sm={2} xs={2}>
            <TextField
              fullWidth
              label="Filter"
              name="companyType"
              select
              SelectProps={{ native: true }}
              variant="outlined"
              onChange={e => filterRfpApi(e)}
            >
              <option value="all">all</option>
              <option value="completed">Response Submitted</option>
              <option value="respondrfp">Respond To RFP</option>
              <option value="updaterfp">Update RFP</option>
              <option value="rfpdashboard">Live RFP</option>
              <option value="rfpCompleted">Completed RFP</option>
              <option value="declined">Declined RFP/DM/PD</option>
              <option value="pending">Pending DM/PD</option>
              <option value="accepted">Accepted DM/PD</option>
            </TextField>
          </Grid>
          <Grid spacing={3} md={2} item sm={2} xs={2}>
            <TextField
              fullWidth
              label="Sort"
              name="companyType"
              select
              SelectProps={{ native: true }}
              variant="outlined"
              onChange={e => sortRfp(e)}
            >
              <option key="Select" value="Select">
                Select
              </option>
              <option value="title">Title</option>
              <option value="createddate">Create Date</option>
              <option value="publishdate">Publish Date</option>
            </TextField>
          </Grid>
          <Grid spacing={3} md={2} item sm={2} xs={2}>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Desc</Grid>
                <Grid item>
                  <Switch onChange={e => sortOrder(e)}></Switch>
                </Grid>
                <Grid item>Asc</Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
