
export class LegalContractsModel {  
  legalDocuments="";
  legalDocuments="";
  isData = false;
  constructor(legalInfo = null){  
          if (legalInfo) {
            this.isData = true;
            this.legalDocuments = legalInfo.legalDocuments || this.legalDocuments;
            this.legalDocuments_docs = legalInfo.legalDocuments_docs || this.legalDocuments_docs;
          }
  }    
}  