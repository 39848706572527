const actionTypes = {
  GET_RFP_BY_USER_ID_START: "GET_RFP_BY_USER_ID_START",
  GET_RFP_BY_USER_ID_SUCCESS: "GET_RFP_BY_USER_ID_SUCCESS",
  GET_RFP_BY_USER_ID_FAILED: "GET_RFP_BY_USER_ID_FAILED",

  //Technical Requirements
  GET_RFP_BY_ID_TECHNICAL_START: "GET_RFP_BY_ID_TECHNICAL_START",
  GET_RFP_BY_ID_TECHNICAL_SUCCESS: "GET_RFP_BY_ID_TECHNICAL_SUCCESS",
  GET_RFP_BY_ID_TECHNICAL_FAILED: "GET_RFP_BY_ID_TECHNICAL_FAILED",
  GET_RFP_BY_ID_TECHNICAL_UPDATE: "GET_RFP_BY_ID_TECHNICAL_UPDATE",
  SAVE_TECHNICAL_DETAILS_START: "SAVE_TECHNICAL_DETAILS_START",
  SAVE_TECHNICAL_DETAILS_SUCCESS: "SAVE_TECHNICAL_DETAILS_SUCCESS",
  SAVE_TECHNICAL_DETAILS_FAILED: "SAVE_TECHNICAL_DETAILS_FAILED",

  //Commerical Requirements
  GET_RFP_BY_ID_COMMERICAL_START: "GET_RFP_BY_ID_COMMERICAL_START",
  GET_RFP_BY_ID_COMMERICAL_SUCCESS: "GET_RFP_BY_ID_COMMERICAL_SUCCESS",
  GET_RFP_BY_ID_COMMERICAL_FAILED: "GET_RFP_BY_ID_COMMERICAL_FAILED",
  GET_RFP_BY_ID_COMMERICAL_UPDATE: "GET_RFP_BY_ID_COMMERICAL_UPDATE",
  SAVE_COMMERCIAL_DETAILS_START: "SAVE_COMMERCIAL_DETAILS_START",
  SAVE_COMMERCIAL_DETAILS_SUCCESS: "SAVE_COMMERCIAL_DETAILS_SUCCESS",
  SAVE_COMMERCIAL_DETAILS_FAILED: "SAVE_COMMERCIAL_DETAILS_FAILED",
  RFP_UPDATE_STEPPER: "RFP_UPDATE_STEPPER"

}

export default actionTypes;