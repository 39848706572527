import {
  AppBar, Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Tab, Tabs, TextField,
  useTheme
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { Formik } from 'formik';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import SwipeableViews from 'react-swipeable-views';
import RichText from 'src/components/RichText/RichText';
import TechinicalRequirementSection from 'src/components/Section';
import 'src/views/shared/css/common.scss';
import AddSection from '../../components/AddSection/index';
import { alertService } from '../../components/toaster/alert.service';
import { getRfpByIdTechnicalUpdate } from "../../store/updateRfp/actions";
import { TechnicalEvaluationModel } from "../TechnicalEvaluation/TechnicalEvaluationModel";
import { useStyles } from './TechnicalEvaluationStyle';
import { TechnicalEvaluationValidations } from './TechnicalEvaluationValidations';



const TechnicalEvaluation = ({ handleNext, handleSkip, handleBack, completed, totalStepCount, currentStepCompleteStatus, className, step, isSubmitView, rfpId, technicalData, getRfpByIdTechnicalUpdate, technicalEvalUpdateData, loading, ...rest }) => {
  const classes = useStyles();
  const theme = useTheme();
  const ref = useRef(null);
  const [hasCategroyError, setCategroyError] = useState(false);

  let type = '';

  useEffect(() => {

    if (technicalEvalUpdateData && technicalEvalUpdateData.categories !== undefined && technicalEvalUpdateData.categories.length > 0) {
      setValue(1);
      setTempValue(1);
    }

  }, [technicalEvalUpdateData.categories]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired, };

  function a11yProps(index) {
    return { id: `scrollable-auto-tab-${index}`, 'aria-controls': `scrollable-auto-tabpanel-${index}` };
  }


  function deleteTab(event, index) {
    if (window.confirm('Are you sure to delete this category?')) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      const dataRedux = technicalEvalUpdateData.categories.splice(index, 1);
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
    } else {
      event.stopPropagation();
    }

  }

  const [value, setValue] = React.useState(0);
  const [value_temp, setTempValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setTempValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  const handleCategoryAdd = (event) => {
    if (ref.current.values.categoryName && event.key === 'Enter') {
      setCategroyError(false);
      const dataRedux = technicalEvalUpdateData.categories.push({ categoryName: ref.current.values.categoryName, sections: [] })
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(new TechnicalEvaluationModel(technicalEvalUpdateData))
      ref.current.values.categoryName = '';
      setValue(technicalEvalUpdateData.categories.length);
    } else {
      setCategroyError(true);
    }
  }

  const handleOverviewChange = (richText) => {
    technicalEvalUpdateData.overview = richText;

  }

  const handleAddSection = (sectionName, indexNumber) => {
    const dataRedux = technicalEvalUpdateData.categories[indexNumber].sections.push({ sectionName: sectionName, questions: [] });
    technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
    getRfpByIdTechnicalUpdate(technicalEvalUpdateData)

  }

  let iconSlidesdiv = [
    'strek', 'iconSlides',
  ]
  iconSlidesdiv = iconSlidesdiv.join(' ')


  return (
    <React.Fragment>

      <Formik innerRef={ref} enableReinitialize={true} initialValues={technicalEvalUpdateData} validationSchema={TechnicalEvaluationValidations} validator={() => ({})}
        onSubmit={(values) => {
          console.log(technicalEvalUpdateData);
          console.log(values);
          values.overview = technicalEvalUpdateData.overview;
          var categories = values.categories.length;
          var success_flag = false;
          var success_flag_single = true;

          if (categories === 0) {
            success_flag = false;
          } else {
            values.categories.map(ct => {
              if (ct.sections.length > 0) {
                ct.sections.map(sec => {
                  if (sec.sectionName !== '' && sec.questions.length > 0) {
                    sec.questions.map(que => {
                      if (que.question !== '' && que.score !== '' && que.type !== '') {
                        success_flag = true;
                      } else if (que.question === '' || que.score === '' || que.type === '') {
                        success_flag_single = false;
                      }
                    })
                  } else if (sec.sectionName === '') {
                    success_flag_single = false;
                  }
                })
              }
            });
          }
          if (success_flag && success_flag_single) {
            technicalEvalUpdateData.type = type
            technicalEvalUpdateData.step = step;
            handleNext(technicalEvalUpdateData);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false
            window.scrollTo(0, 0)
            alertService.error('Please Fill in atleast 1 Category, Section and Questions. Do not leave any added row blank. Delete any not in use rows or sections. Also fill Overview Section', { autoClose, keepAfterRouteChange })
          }
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          handleNext,
          isSubmitting,
          touched,
          values
        }) => (
          <div className={'requestly-rfp-form company-information mb-5'}>
            <div className={'company-info-div'}>

              <Card className={classes.CardBorder}>
                <CardContent className={classes.cardScrollArea + ' p-0'}>
                  <Card>
                    <CardHeader title="Technical Requirements" subheader="Add Technical Requirements" className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1 '} />
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Grid container justify="space-between">
                            <Typography
                              style={{ "marginTop": "5px", "color": "#547087", "fontSize": "16px" }}
                              align="left"
                              className="font-family"
                            >
                              Technical Overview
                            </Typography>
                          </Grid>
                          <RichText
                            showContentOnly={isSubmitView}
                            value={technicalEvalUpdateData.overview}
                            onTextChange={handleOverviewChange}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <div className={classes.root}>
                      <div className={iconSlidesdiv}>
                        <AppBar position="static" color="default">
                          <Tabs value={value} onChange={handleTabChange} indicatorColor="primary" variant="scrollable" textColor="primary" aria-label="scrollable auto tabs example" className="categorySectionTabs">
                            <Tab
                              className="categorySectionTabs-1"
                              label={
                                <TextField
                                  error={Boolean(hasCategroyError)}
                                  fullWidth
                                  placeholder="New Category Name"
                                  name="categoryName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  onKeyPress={handleCategoryAdd}
                                  id=""
                                  value={values.categoryName}
                                  className="requestly-h-40 requestly-text-box-in-tab font-family"
                                  style={{ color: 'white' }}
                                />
                              }
                              {...a11yProps(0)}
                            />
                            {technicalEvalUpdateData.categories && technicalEvalUpdateData.categories.map((value, index) => (
                              <Tab
                                className={(value_temp === (index + 1)) ? "categorySectionTabs-2 pwa-selected-tab" : "categorySectionTabs-2"}
                                key={index}
                                icon={
                                  <>
                                    <DeleteIcon
                                      onClick={(event) => deleteTab(event, index)}
                                      style={{ "color": "#fff", marginTop: '11px' }}
                                    />
                                  </>
                                }
                                label={
                                  <TextField
                                    error={Boolean(hasCategroyError)}
                                    name={`categoryName-${index}`}
                                    onChange={(event) => {
                                      const categories = technicalEvalUpdateData.categories.map((datum, datumIndex) => {
                                        if (datumIndex === index) {
                                          return {
                                            categoryName: event.target.value,
                                            sections: datum.sections
                                          }
                                        }
                                        return datum;
                                      });
                                      let technicalEvaluation = omit(technicalEvalUpdateData, 'categories');
                                      const dataRedux = { ...technicalEvaluation, categories: [...categories] }

                                      getRfpByIdTechnicalUpdate(dataRedux)
                                    }}
                                    id={`categoryName-${index}`}
                                    value={value.categoryName}
                                    className="requestly-h-40 requestly-text-box-in-tab"
                                    style={{ color: 'white' }}
                                  />
                                }
                                {...a11yProps(index + 1)}
                              />
                            ))}
                          </Tabs>
                        </AppBar>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                          <TabPanel value={value} index={0}>

                          </TabPanel>
                          {technicalEvalUpdateData.categories && technicalEvalUpdateData.categories.map((category, categoryIndex) => (
                            <div>
                              {category.sections && category.sections.map((section, sectionIndex) => (
                                <TechinicalRequirementSection
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  sectionIndex={sectionIndex}
                                  section={section}
                                  categoryIndex={categoryIndex}
                                />
                              ))}
                              <Grid item md={12} sm={12} xs={12} align="left">
                                <AddSection onAddSection={handleAddSection} indexNumber={categoryIndex} />
                              </Grid>
                            </div>
                          ))}
                        </SwipeableViews>
                      </div>
                    </div>

                  </Card>
                </CardContent>
                <Divider />


                <div class="footer-btn-fixed">
                  <footer class="page-footer green">
                    <Grid container spacing={2} justify="center">
                      {step !== 0 ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                            Back
                          </Button>
                        </Grid>
                      ) : null}
                      {step !== totalStepCount ? (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                            Skip
                          </Button>
                        </Grid>
                      ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                          </Grid>
                        ) : null}
                      {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                        (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                          </Grid>
                        ) : step === totalStepCount ?
                          (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                            </Grid>
                          ) : (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                            </Grid>
                          )}
                    </Grid>
                  </footer>
                </div>
              </Card>


            </div>

          </div>
        )}
      </Formik>

    </React.Fragment>
  );
};
TechnicalEvaluation.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  technicalData: state.updateRfp.rfpTechnicalData,
  loading: state.updateRfp.loading,
  technicalEvalUpdateData: state.updateRfp.rfpTechnicalDataUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdTechnicalUpdate: (value) => dispatch(getRfpByIdTechnicalUpdate(value)),

});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalEvaluation);
