
import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  leftStepPanel: { backgroundColor: '#fff', },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  custWidthRFP: { maxWidth: '100%', margin: '0', },
  iconSlides: {},
  dltBtns: {},
  blueBtn: { backgroundColor: '#4688f0', padding: '6px 16px', fontSize: '14px', },
  buttonStepper: { textAlign: 'left !important' }
}));