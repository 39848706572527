/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class FinalPresentationService {
  constructor() {
    this.config = new Configuration();
  }

  async addFinalPresenation(ndaObj) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    ndaObj.userId = userId;
    const url = this.config.addFinalPresentaion;
    return AxiosInstance.post(url, ndaObj)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async addSubmitFinalPresenation(finalPresentationObj) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem('userId');
    finalPresentationObj.userId = userId;
    const url = this.config.submitFinalPrsentation;
    const formData = new FormData();
    for (let i = 0; i < finalPresentationObj.digitalRecordingFiles.length; i++) {
      formData.append('digitalRecordingFiles', finalPresentationObj.digitalRecordingFiles[i]);
    }

    if (finalPresentationObj.conferenceCallDetails !== undefined) {
      formData.append('conferenceCallDetails', finalPresentationObj.conferenceCallDetails);
    }
    formData.append('presentationType', finalPresentationObj.presentationType);
    formData.append('faceToFaceType', finalPresentationObj.faceToFaceType);
    formData.append('faceToFaceOnSiteLocation', finalPresentationObj.faceToFaceOnSiteLocation);
    formData.append('remoteLiveType', finalPresentationObj.remoteLiveType);
    formData.append('presentationAgenda', JSON.stringify(finalPresentationObj.presentationAgenda));
    formData.append('userId', finalPresentationObj.userId);
    formData.append('type', finalPresentationObj.type);
    formData.append('rfpId', finalPresentationObj.rfpId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return AxiosInstance.post(url, formData, config)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getFinalPresentation(rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.getFinalPresentation + rfpId + '/' + userId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getAllFinalPresentation(rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.getAllFinalPresentation + rfpId + '/' + userId;
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async deleteFinalPresentationFile(fileName, rfpId) {
    let reqObj = {}
    reqObj.fileName = fileName
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    const url = this.config.baseUrl + this.config.deleteFinalFile;
    return AxiosInstance.post(url, {
      rfpId: rfpId,
      fileName: fileName
    })
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
      // Request made and server responded
      console.log(':: error', error.response.data);
      // alert(error.response.data.message);
      console.log(':: error', error.response.status);
      console.log(':: error', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default FinalPresentationService;
