import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  RadioGroup,
  TextField,
  Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { alertService } from "src/components/toaster/alert.service";
import EnquiryService from "../../../services/enquiry/enquiry.service";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ModalBox: {
    marginLeft: "36px",
    marginTop: "36px"
  },
  name: {
    marginTop: "20px",
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    outline: "none",
    borderRadius: "12px",
    width: "370px",
    maxWidth: "370px",
    // maxHeight: '370px',
    boxShadow: theme.shadows[5]
  },
  container: {
    // width: "370px",
    position: "relative",
    marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex"
  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "20px"
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  },
  RfpName: {
    width: "100%",
    maxWidth: 360
  },
  buttoncolor: {
    color: "#000000"
  },
  textsize: {
    fontSize: "1rem!important",
    fontWeight: 400
  },
  paper: { textAlign: "center" },
  companyHeadings: { backgroundColor: "#4688f0", color: "#fff" },
  titleTag: { margin: "auto" },
  inputAll: {
    marginTop: "10px !important",
    marginBottom: "8px !important",
    padding: "2px !important"
  },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: "contents", textAlign: "center" },
  listCheckline: { display: "block", marginTop: "10px" },
  titleTagTxt: { fontSize: "18px", fontWeight: "800", margin: "auto" },
  ProductOptions: {
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  ProductOptions2: {
    border: "2px solid #87CEEB",
    borderRadius: "4px",
    cursor: "pointer"
  },
  questions: {
    marginTop: "20px"
  },
  questionCard: {
    margin: "0px 20px",
    marginTop: "-17px"
  },
  stageCard: {
    border: "1px solid #87CEEB",
    borderRadius: "4px"
  },
  notSelected: {
    cursor: "pointer"
  },
  stageCardResearchActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardEvaluatingNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "20px",
    maxHeight: "108px"
  },
  stageCardResearchNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "33px",
    maxHeight: "108px"
  },
  stageCardActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    color: "#87CEEB",
    maxHeight: "108px"
  },
  stageCardNotActive: {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "13px",
    maxHeight: "108px"
  },
  wordLength: {
    fontSize: "14px",
    float: "right",
    width: "64%",
    textAlign: "end",
  }
}));

const longText = "Please tell us something about you're looking for";

const ProductDemoModal = () => {
  const history = useHistory();
  const classes = useStyles();
  // const companyService = new CompanyService();
  const [meeting, setMetting] = useState(null);
  const [stage, setStage] = useState(false);
  const [question, setQuestion] = useState(null);
  const [type, setType] = useState([]);
  const [stageList, setStageList] = useState([]);
  const enquiryService = new EnquiryService();
  const [wordLength, setWordLength] = useState(500);

  let { id } = useParams();
  let { id2 } = useParams();
  var productId = id;
  var companyName = id2.replaceAll("%20", " ");

  const closePopUp = () => {
    history.push("/app/dashboard");
  };

  const handleMetting = index => {
    setMetting(index);
  };

  const handleStage = index => {
    setStage(index);
  };

  const handleSave = e => {
    e.preventDefault();
    console.log(meeting, stage, question);

    if (meeting && stage && question) {
      let requestData = {
        type: meeting,
        stage: stage,
        description: question,
        productId: productId
      };

      enquiryService
        .addProductDemo(requestData)
        .then(res => {
          if (res) {
            closePopUp();
            const autoClose = true;
            const keepAfterRouteChange = false;
            history.push(`/app/dashboard`);
            alertService.success(res.message, {
              autoClose,
              keepAfterRouteChange
            });
          }
        })
        .catch(err => console.log(":: err", err));
    } else {
      const autoClose = true;
      const keepAfterRouteChange = false;
      if (!meeting) {
        alertService.error("Please select the meeting type", {
          autoClose,
          keepAfterRouteChange
        });
      } else if (!stage) {
        alertService.error("Please select the discovery stage", {
          autoClose,
          keepAfterRouteChange
        });
      } else {
        alertService.error("Please enter your question", {
          autoClose,
          keepAfterRouteChange
        });
      }

    }
  };

  useEffect(() => {
    getProductDemoOptions();
  }, []);
  console.log(companyName, "companyName");

  const getProductDemoOptions = () => {
    enquiryService
      .getProductDemoOptions()
      .then(res => {
        if (res && res.data) {
          setType(res.data.type);
          setStageList(res.data.stage);
        }
      })
      .catch(err => console.log(":: err", err));
  };

  return (
    <>
      <form
        className={"requestly-rfp-form  dm-popup-form"}
      // style={{ width: "732px" }}
      >
        <Card className="dm-popup-card">
          <CardHeader
            title={`Product Demo Request - ${companyName}`}
            className={
              classes.companyHeadings +
              " requestly-rfp-heading dm-popup-header"
            }
          />
          <Divider />
          <CardContent
            className=" dm-popup-card-content"
            style={{ maxWidth: "732px" }}
          >
            <Grid className={classes.item} item md={12} sm={12} xs={12}>
              <span style={{ fontWeight: 700, marginTop: "12px", fontFamily: "Roboto, sans- serif" }}>
                I would like a
              </span>
            </Grid>
            <Grid container lg={12}>
              <Grid
                container
                className={classes.inputAll + " dm-popup-container1"}
                lg={12}
                md={9}
                item
                sm={9}
                xs={12}
              >
                <RadioGroup
                  className={classes.radioGrpLine + " dm-popup-radio-group"}
                  aria-label="scope"
                  name="scope"
                >
                  {type &&
                    type.length > 0 &&
                    type.map(t => (
                      <Grid
                        className={
                          meeting === t.value
                            ? classes.ProductOptions
                            : classes.notSelected
                        }
                        item
                        md={3}
                        sm={3}
                        xs={6}
                        lg={4}
                      >
                        <div
                          className={classes.stageCard + " dm-popup-radiodiv"}
                          onClick={() => {
                            handleMetting(t.value);
                          }}
                        >
                          <span
                            style={
                              meeting === t.value
                                ? {
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  marginBottom: "12px",
                                  color: "#87CEEB",
                                  fontFamily: "Roboto, sans- serif"
                                }
                                : {
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  marginBottom: "12px",
                                  fontFamily: "Roboto, sans- serif"
                                }
                            }
                          >
                            {t.label}
                          </span>
                        </div>
                      </Grid>
                    ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "12px" }} />

            <Grid
              className={classes.item}
              item
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "12px", marginBottom: "9px", fontFamily: "Roboto, sans- serif" }}
            >
              <span style={{ fontWeight: 700 }}>Discovery Stage</span>
            </Grid>
            <Grid container lg={12}>
              <Grid
                container
                className={classes.inputAll + " dm-popup-container1"}
                lg={12}
                md={9}
                item
                sm={9}
                xs={12}
              >
                <RadioGroup
                  className={classes.radioGrpLine + " dm-popup-radio-group"}
                  aria-label="scope"
                  name="scope"
                >
                  {stageList &&
                    stageList.length > 0 &&
                    stageList.map(st => (
                      <Grid
                        className={
                          stage === st.value
                            ? classes.ProductOptions
                            : classes.notSelected
                        }
                        item
                        md={3}
                        sm={3}
                        xs={6}
                        lg={3}
                      >
                        <div
                          className={classes.stageCard + " dm-popup-radiodiv"}
                          onClick={() => {
                            handleStage(st.value);
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "Roboto, sans- serif"
                            }}
                            className={
                              stage === st.value
                                ? classes.stageCardResearchActive
                                : classes.stageCardResearchNotActive
                            }
                          >
                            {st.label}
                          </p>
                        </div>
                      </Grid>
                    ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: "12px" }} />
            <Grid
              className={classes.questions + " dm-popup-questions-div"}
              container
              spacing={0}
            >
              <Grid
                className={classes.item}
                item
                md={12}
                sm={12}
                xs={12}
                style={{ display: "flex", fontFamily: "Roboto, sans- serif" }}
              >
                <p style={{ fontWeight: 700 }}>Questions</p>
                <span style={{ marginLeft: "7px", width: '20%' }}>
                  <Tooltip
                    title={longText}
                    style={{ height: "0.7em" }}
                    placement="right"
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </span>
                <span className={classes.wordLength}>{wordLength}/500</span>
              </Grid>
            </Grid>
          </CardContent>

          <Grid
            className={classes.questionCard}
            lg={12}
            item
            md={12}
            sm={12}
            xs={12}
          >
            <TextField
              fullWidth
              multiline
              placeholder="Outline Your Questions to the Vendor"
              name="rfpName"
              onChange={val => {
                setWordLength(500 - val.target.value.length);
                setQuestion(val.target.value);
              }}
              value={question}
              variant="outlined"
              className="requestly-rfp-textbox dm-popup-textfield"
              inputProps={{
                style: {
                  height: "147px",
                  fontFamily: "Roboto, sans- serif"
                },
                maxLength: 500
              }}
            />
          </Grid>
          <Box className={classes.footerBox} p={2}>
            <Button
              color="primary"
              className={
                classes.button + " requestly-rfp-button dm-popup-button"
              }
              type="submit"
              variant="contained"
              style={{
                float: "right",
                marginBottom: "26px",
                width: "126px",
                textTransform: "none"
              }}
              onClick={handleSave}
            >
              Send Request
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ProductDemoModal;
