/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';
import { history } from 'src/utils/utils';
import VerifyEmailView from 'src/views/auth/VerifyEmailView';
import ProductPage from 'src/views/company-info/ProductInfo';
import SubDetails from 'src/views/company-info/SubDetails';
import AppLayout from './app.layout.route';
import AuthLayout from './auth.layout.route';
import { PrivateRoute } from './private.route';

export default function Layouts() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact /*component={HomeLayout}*/ render={
          () => {
            window.location.href = '/home.html'
          }
        } />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/product" component={ProductPage} />
        <Route path="/search" component={SubDetails} />
        <Route path="/category" component={SubDetails} />
        <PrivateRoute path="/app" component={AppLayout} />
        <Redirect from="/" to="/home" exact />
        <Route path="/auth" component={AuthLayout} />
        <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmailView} />
        <PrivateRoute path="/app" component={AppLayout} />
        <Redirect from="/" to="/auth" exact />
      </Switch>
    </Router>
  );
}
