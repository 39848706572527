import axios from 'axios';
import { alertService } from 'src/components/toaster/alert.service';
import Configuration from 'src/utils/config';
import LocalStorageService from '../services/storage/local-storage.service';
import { history } from '../utils/utils';
// LocalstorageService
const localStorageService = LocalStorageService.getService();
const config = new Configuration();
const AxiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers.token = token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add a response interceptor

AxiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401) {
    alertService.error('Token is expired or invalid Token');
    localStorage.clear();
    history.push('/auth/login');

  }
  return Promise.reject(error);
});

export default AxiosInstance;
