import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";

const options = {
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  pauseOnHover: true,
  autoClose: 3000,
  draggable: false,
};

const optionsInfo = {
  type: toast.TYPE.INFO,
  ...options,
};

const optionsSuccess = {
  type: toast.TYPE.SUCCESS,
  ...options,
};

const optionsError = {
  type: toast.TYPE.ERROR,
  ...options,
};

toast.configure({});

export const ToastNotifyInfo = (message) => {
  toast(message, optionsInfo);
};
export const ToastNotifySuccess = (message) => {
  toast(message, optionsSuccess);
};
export const ToastNotifyError = (message) => {
  toast(message, optionsError);
};
