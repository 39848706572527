import React from 'react';
import requestly_logo from "../image/requestly_logo_white.png";
const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={requestly_logo}
      style={{ height: "46px" }}
      {...props}
    />
  );
};

export default Logo;
