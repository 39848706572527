import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles, Radio, RadioGroup,
  TextField, Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { alertService } from 'src/components/toaster/alert.service';
import { history } from 'src/utils/utils';
import * as Yup from 'yup';
import RFPService from '../../../services/rfp/rfp.service';
import { complete, detailed, rapid, standard } from "./data/processConfiguration";



const useStyles = makeStyles(({
  root: {},
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: { marginTop: '10px !important', marginBottom: '8px !important', padding: '2px !important' },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: 'contents', textAlign: 'center' },
  listCheckline: { display: 'block', marginTop: '10px', width: "100%" },
  titleTagTxt: { fontSize: '18px', fontWeight: '800', margin: 'auto', },
}));

const RfpSettings = ({ className, rfpId, rfpSettingState, setRfpSetting, ...rest }) => {
  const rfpService = new RFPService();
  const [temp_rfp_name, setTempRfpName] = React.useState('');

  const handleStepChange = (event) => {
    let processTypeValue = rfpSettingState.processTypeValue;
    if (rfpSettingState.processTypeValue === 'rapid' || rfpSettingState.processTypeValue === 'standard' || rfpSettingState.processTypeValue === 'complete') {
      processTypeValue = 'detailed';
    }
    setRfpSetting({
      rfpName: rfpSettingState.rfpName,
      processTypeValue: processTypeValue,
      scopeValue: rfpSettingState.scopeValue,
      stepsValue: { ...rfpSettingState.stepsValue, [event.target.name]: event.target.checked },
      isAnonymous: rfpSettingState.isAnonymous
    });
  };

  const handleAnonymousChange = (event) => {
    setRfpSetting({
      rfpName: rfpSettingState.rfpName,
      processTypeValue: rfpSettingState.processTypeValue,
      scopeValue: rfpSettingState.scopeValue,
      stepsValue: rfpSettingState.stepsValue,
      isAnonymous: event.target.checked
    });
  };

  const handleNameChange = (event) => {
    setRfpSetting({
      rfpName: event.target.value,
      processTypeValue: rfpSettingState.processTypeValue,
      scopeValue: rfpSettingState.scopeValue,
      stepsValue: rfpSettingState.stepsValue,
      isAnonymous: rfpSettingState.isAnonymous
    });
  };

  const handleScopeChange = (event) => {
    switch (event.target.value) {
      case 'public':
        setRfpSetting({
          rfpName: rfpSettingState.rfpName,
          processTypeValue: rfpSettingState.processTypeValue,
          scopeValue: 'public',
          stepsValue: rfpSettingState.stepsValue,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
      case 'private':
        setRfpSetting({
          rfpName: '',
          processTypeValue: rfpSettingState.processTypeValue,
          scopeValue: 'private',
          stepsValue: rfpSettingState.stepsValue,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
    }
  }
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.product_name !== undefined) {
      setTempRfpName(parsed.product_name)
    }
  }, [])
  const handleProcessTypeChange = (event) => {
    switch (event.target.value) {
      case 'rapid':
        setRfpSetting({
          rfpName: rfpSettingState.rfpName,
          processTypeValue: 'rapid',
          scopeValue: rfpSettingState.scopeValue,
          stepsValue: rapid,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
      case 'standard':
        setRfpSetting({
          rfpName: rfpSettingState.rfpName,
          processTypeValue: 'standard',
          scopeValue: rfpSettingState.scopeValue,
          stepsValue: standard,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
      case 'complete':
        setRfpSetting({
          rfpName: rfpSettingState.rfpName,
          processTypeValue: 'complete',
          scopeValue: rfpSettingState.scopeValue,
          stepsValue: complete,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
      case 'detailed':
        setRfpSetting({
          rfpName: rfpSettingState.rfpName,
          processTypeValue: 'detailed',
          scopeValue: rfpSettingState.scopeValue,
          stepsValue: detailed,
          isAnonymous: rfpSettingState.isAnonymous
        });
        break;
    }
  };
  const autoClose = true;
  const keepAfterRouteChange = true;
  const classes = useStyles();

  return (
    <Formik initialValues={rfpSettingState}
      enableReinitialize={true}
      validationSchema={
        Yup.object().shape({
          rfpName: Yup.string().max(255).required('RFP Name is required.'),
        })
      }
      onSubmit={(values) => {
        rfpService.addRFP(values).then(res => {
          if (res) {
            alertService.success("RFP has been created successfully.", { autoClose, keepAfterRouteChange })
            history.push('/app/update-rfp/' + res.rfp._id);
          }
        })
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Card className="rfp-setting-block">
            <CardContent>
              <Grid container>
                <Grid className={classes.item} item md={8} sm={8} xs={8}>
                  <TextField
                    fullWidth
                    multiline
                    disabled
                    error={Boolean(touched.rfpName && errors.rfpName)}
                    helperText={touched.rfpName && errors.rfpName}
                    label="RFP Name"
                    name="rfpName"
                    onBlur={handleNameChange}
                    onChange={handleChange}
                    value={temp_rfp_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid className={classes.item} item md={4} sm={4} xs={4}>
                  <Button style={{ height: '80%', marginLeft: '20px' }} onClick={() => history.push(`/app/edit-rfp/${rfpId}`)} variant="contained" color="primary">View/Edit RFP</Button>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid className={classes.titleTag} item md={3} sm={12} xs={12}>
                  <Typography className={classes.titleTagTxt} color="textPrimary" gutterBottom variant="h6">
                    RFP Visibility
                  </Typography>
                </Grid>
                <Grid container className={classes.inputAll} md={9} item sm={12} xs={12}>
                  <RadioGroup className={classes.radioGrpLine} aria-label="scope" name="scope" value={rfpSettingState.scopeValue} onChange={handleScopeChange} >
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="public" control={<Radio />} label="Public" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="private" control={<Radio />} label="Private" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid className={classes.titleTag} item md={3} sm={12} xs={12}>
                  <Typography className={classes.titleTagTxt} color="textPrimary" gutterBottom variant="h6">
                    RFP Process
                  </Typography>
                </Grid>
                <Grid container className={classes.inputAll} md={9} item sm={12} xs={12}>
                  <RadioGroup className={classes.radioGrpLine} aria-label="processType" name="processType" value={rfpSettingState.processTypeValue} onChange={handleProcessTypeChange}>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="rapid" control={<Radio />} label="Rapid" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="standard" control={<Radio />} label="Standard" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="complete" control={<Radio />} label="Complete" />
                    </Grid>
                    <Grid className={classes.item} item md={3} sm={3} xs={6}>
                      <FormControlLabel disabled value="detailed" control={<Radio />} label="Custom" />
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>

              <Divider />
              <Grid className={classes.listChecks} container spacing={0}>
                <Grid md={4} sm={12} xs={12}>
                </Grid>
                <Grid md={5} sm={12} xs={12}>
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.companyInformation} onChange={handleStepChange} name="companyInformation" />)} label="Company Information" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.projectInformation} onChange={handleStepChange} name="projectInformation" />)} label="Project Information" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.technicalRequirements} onChange={handleStepChange} name="technicalRequirements" />)} label="Technical Requirements" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.commercialEvaluation} onChange={handleStepChange} name="commercialEvaluation" />)} label="Commercial Evaluation" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.vendorInvite} onChange={handleStepChange} name="vendorInvite" />)} label="Vendor Invite" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.nda} onChange={handleStepChange} name="nda" />)} label="NDA" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.finalPresentation} onChange={handleStepChange} name="finalPresentation" />)} label="Final Presentation" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.references} onChange={handleStepChange} name="references" />)} label="References" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.pricingNegotiations} onChange={handleStepChange} name="pricingNegotiations" />)} label="Pricing/Negotiations" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.contactAgreements} onChange={handleStepChange} name="contactAgreements" />)} label="Contact Agreements" />
                  <FormControlLabel className={classes.listCheckline} disabled control={(<Checkbox checked={rfpSettingState.stepsValue.rfpCompletion} onChange={handleStepChange} name="rfpCompletion" />)} label="RFP Completion" />
                </Grid>
                <Grid md={3} sm={12} xs={12}>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

RfpSettings.propTypes = {
  className: PropTypes.string
};

export default RfpSettings;
