import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(({
  root: {},
  item: {
    // display: 'flex',flexDirection: 'column',
    // margin: '5px'
  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: {},
  listChecks: {},
  footerBox: {}
}));