import { all, call } from "redux-saga/effects";
import { productSaga } from "./products/saga";
import { submitRfpSaga } from "./submitRfp/saga";
import { updateRfpSaga } from "./updateRfp/saga";

export default function* rootSaga() {
  yield all([
    call(productSaga),
    call(updateRfpSaga),
    call(submitRfpSaga)
  ]);
}