import React from 'react';
import { Redirect, Route } from 'react-router-dom';

/* PrivateRoute component definition */
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (localStorage.token // your auth mechanism goes here
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/auth' }} />)}
  />
);
