import { v4 as uuid } from 'uuid';

export default [
  {
    "createdAt": "2020-09-11T15:35:30.887Z",
    "_id": "5f5b95af2f5dd3cde517e762",
    "userId": "5f57120966199a6954e04973",
    "__v": 0,
    "commercialEvaluation": false,
    "companyInformation": true,
    "contactAgreements": true,
    "finalPresentation": true,
    "isAnonymous": false,
    "nda": false,
    "pricingNegotiations": true,
    "processTypeValue": "rapid",
    "projectInformation": true,
    "proofOfconceptValue": true,
    "references": true,
    "rfpCompletion": true,
    "rfpName": "",
    "scopeValue": "public",
    "statementOfWork": true,
    "technicalRequirements": true,
    "vendorInvite": true,
    "vendorShortList": false
  },

];
