import { Container, Grid, StepButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Page from 'src/components/Page';
import { alertService } from 'src/components/toaster/alert.service';
import CommercialDetailService from 'src/services/rfp/commercial-detail.service';
import FinalPresentationService from 'src/services/rfp/final-presentation.service';
import LegalService from 'src/services/rfp/legal.service';
import NDAService from 'src/services/rfp/nda.service';
import PricingService from 'src/services/rfp/pricing.service';
import ProjectService from 'src/services/rfp/project.service';
import ReferenceService from 'src/services/rfp/reference.service';
import RFPService from 'src/services/rfp/rfp.service';
import TechnicalDetailService from 'src/services/rfp/technical-detail.service';
import VendorService from 'src/services/rfp/vendor.service';
import { history } from 'src/utils/utils';
import LegalContracts from 'src/views/LegalContractsBuyer/LegalContracts';
import { LegalContractsModel } from 'src/views/LegalContractsBuyer/LegalContractsModel';
import { NdaAnswerModel } from 'src/views/NdaBuyer/NdaAnswerModel';
import { ProjectModel } from 'src/views/ProjectInformation/ProjectModel';
import ReturnToDashboard from '../../../components/ReturnToDashboard';
import CompanyService from '../../../services/company/company.service';
import { getRfpByIdSubmitCommercialUpdate, getRfpByIdSubmitTechnicalUpdate, submitRfpUpdateStepper } from "../../../store/submitRfp/actions";
import CommercialEvaluationAnswer from '../../CommercialEvaluationAnswer/CommercialEvaluationAnswer';
import { CommercialEvaluationAnswerModel } from '../../CommercialEvaluationAnswer/CommercialEvaluationAnswerModel';
import CompanyDetails from '../../CompanyInformation/CompanyDetails';
import { CompanyModel } from '../../CompanyInformation/CompanyModel';
import FinalPresentation from '../../FinalPresentationBuyer/FinalPresentation';
import { FinalPresentationModel } from '../../FinalPresentationBuyer/FinalPresentationModel';
import NdaAnswer from '../../NdaBuyer/NdaAnswer';
import { PricingModel } from '../../Pricing/PricingModel';
import Pricing from '../../PricingBuyer/Pricing';
import ProjectDetails from '../../ProjectInformation/ProjectDetails';
import { ReferencesModel } from '../../References/ReferencesModel';
import References from '../../ReferencesBuyer/References';
import TechnicalEvaluationAnswer from '../../TechnicalEvaluationAnswer/TechnicalEvaluationAnswer';
import { TechnicalEvaluationAnswerModel } from '../../TechnicalEvaluationAnswer/TechnicalEvaluationAnswerModel';
import VendorInvite from '../../VendorInvite/VendorInvite';
import { VendorInviteModel } from '../../VendorInvite/VendorInviteModel';
import { rapid } from '../CreateRfpView/data/processConfiguration';
import { getSteps } from './RfpStepsConfig';
import { useStyles } from './SubmitRfpStyle';

const SubmitRfpView = ({ match, getRfpByIdSubmitTechnicalUpdate, getRfpByIdSubmitCommercialUpdate, submitRfpUpdateStepper }) => {
  const [completed, setCompleted] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [totalStepCount, setTotalStepCount] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [question_answer_data, setQuestionAnswerData] = React.useState([]);
  const [companyState, setCompanyState] = React.useState(new CompanyModel());
  const [projectState, setProjectState] = React.useState(new ProjectModel());
  const [pricingState, setPricingState] = React.useState([]);
  const [technicalEvaluationState, setTechnicalEvaluationState] = React.useState(new TechnicalEvaluationAnswerModel());
  const [commercialEvaluationState, setCommercialEvaluationState] = React.useState(new CommercialEvaluationAnswerModel());
  const [vendorInviteState, setVendotInviteState] = React.useState(new VendorInviteModel());
  const [finalPresentationState, setFinalPresentationState] = React.useState(new FinalPresentationModel());
  const [ndaState, setNDAState] = React.useState(new NdaAnswerModel());
  const [legalState, setLegalState] = React.useState(new LegalContractsModel());
  const [referenceState, setReferenceState] = React.useState([]);
  const stepsDataKeys = Object.keys(rapid)
  const initialStepsData = stepsDataKeys.filter(function (id) { return rapid[id] });
  const [allSteps, setAllSteps] = React.useState(initialStepsData);

  const classes = useStyles();
  const companyService = new CompanyService();
  const projectService = new ProjectService();
  const vendorService = new VendorService();
  const rfpService = new RFPService();
  const technicalDetailService = new TechnicalDetailService();
  const commercialDetailService = new CommercialDetailService();
  const ndaService = new NDAService();
  const finalPresentationService = new FinalPresentationService();
  const referenceService = new ReferenceService();
  const pricingService = new PricingService();
  const legalService = new LegalService();

  const steps = getSteps(allSteps);
  const autoClose = true;
  const keepAfterRouteChange = true;
  const rfpId = match.params.rfpId;

  const handleReset = () => {
    setActiveStep(0);
  };

  const extractAndSetSteps = (stepsData = null, rfpStepper = null) => {
    var allSteps = ['companyInformation', 'projectInformation'];
    allSteps.push('nda');
    allSteps.push('vendorInvite');
    if (stepsData && stepsData['technicalRequirements']) {
      allSteps.push('technicalRequirements');
    }
    if (stepsData && stepsData['commercialEvaluation']) {
      allSteps.push('commercialEvaluation');
    }
    if (stepsData && stepsData['finalPresentation']) {
      allSteps.push('finalPresentation');
    }
    if (stepsData && stepsData['pricingNegotiations']) {
      allSteps.push('pricingNegotiations');
    }
    if (stepsData && stepsData['references']) {
      allSteps.push('references');
    }
    if (stepsData && stepsData['legalContracts']) {
      allSteps.push('legalContracts');
    }
    allSteps.push('legalContracts');
    setTotalStepCount(allSteps.length - 1);
    if (rfpStepper) {
      const newCompleted = completed;
      var currentActiveStep = null;
      allSteps.map((step, index) => {
        newCompleted[index] = true;
        if (rfpStepper[step]) {
          newCompleted[index] = true;
        } else {
          if (currentActiveStep === null) {
            currentActiveStep = index;
          }
        }
      });
      setCompleted(newCompleted);
      // setActiveStep(currentActiveStep);
    }
    setAllSteps(allSteps);
  }

  useEffect(() => {
    getRFPData()
  }, [activeStep]);

  const getRFPData = () => {
    rfpService.getrfpByById(rfpId, true).then(
      res => {

        if (res !== undefined && res) {
          extractAndSetSteps(res.rfp, res.rfpStepper);
          getStepData(activeStep);
        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteEmail = (oldData) => {
    if (oldData._id) {
      vendorService.deleteVendorEmail(oldData._id).then(res => {
        if (res) {

        }
      })
    }
  }

  const getTechnicalDetailData = () => {
    technicalDetailService.getTechnicalDetailById(rfpId).then(
      res => {
        if (res !== undefined && res) {
          if (res.technicalEval.overview.length === 0) {
            res.technicalEval.overview = '[{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"n\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}]'
          } else {
            res.technicalEval.overview = JSON.parse(res.technicalEval.overview[0]);
          }
          setTechnicalEvaluationState(res.technicalEval)
          console.log(res.quesAns, "res.quesAns")
          setQuestionAnswerData(res.quesAns);

        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    getRfpByIdSubmitTechnicalUpdate(technicalEvaluationState)
  }, [technicalEvaluationState])

  const saveTechnicalDetails = (values) => {

    technicalDetailService.addInvitedTechnicalDetail(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveAcceptReject = (values) => {

    values.rfpId = rfpId;
    rfpService.saveInvite(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getCommercialDetailData = () => {
    commercialDetailService.getCommercialDetailById(rfpId).then(
      res => {
        if (res !== undefined && res) {
          if (res.commercialEval.overview.length === 0) {
            res.commercialEval.overview = '[{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"n\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}]'
          } else {
            res.commercialEval.overview = JSON.parse(res.commercialEval.overview[0]);
          }
          // res.commercialEval.overview = JSON.parse(res.commercialEval.overview[0]);
          setCommercialEvaluationState(new CommercialEvaluationAnswerModel(res.commercialEval));
          setQuestionAnswerData(res.quesAns);
        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    getRfpByIdSubmitCommercialUpdate(commercialEvaluationState)
  }, [commercialEvaluationState])

  const saveCommercialDetails = (values) => {
    commercialDetailService.addInvitedCommercialDetail(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveProject = (values) => {
    let rfpId = match.params.rfpId;
    projectService.addProject(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const savePricing = (values) => {
    values.rfpId = rfpId;
    pricingService.addPricing(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getPricing = () => {
    pricingService.getAllPricing(rfpId).then(
      res => {

        if (res !== undefined && res && res.pricing) {
          const pricing = [];
          res.pricing.forEach(price => {
            pricing.push(new PricingModel(price));
          });
          setPricingState(pricing);
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveVendor = (values) => {
    values.rfpId = match.params.rfpId;
    vendorService.addVendor(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveNDA = (values) => {
    values.rfpId = match.params.rfpId;
    ndaService.addNDAAnswer(values).then(
      res => {
        if (values.type === 'submit' || values.type === 'accept-submit') {
          history.push('/app/dashboard');
        } else if (values.type === 'next') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          getStepData(activeStep + 1);
        } else {
          const autoClose = true;
          const keepAfterRouteChange = false;
          alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
        }
      }
    ).catch(() => setHasError(true))
  }

  const getNDA = () => {
    ndaService.getAllNDAAns(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setNDAState(new NdaAnswerModel(res.NDAAns));

        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteNDAAns = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      ndaService.deleteNDAAns(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }
  }

  const deleteFinalPresentation = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      finalPresentationService.deleteFinalPresentationFile(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }
  }

  const saveLegaldocument = (values) => {
    values.rfpId = match.params.rfpId;
    legalService.addLegalDocument(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getLegalDocument = () => {
    legalService.getAllLegalDocuments(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setLegalState(new LegalContractsModel(res.legalDoc));
          // const loc = res.company.locations[0].replace(/'/g, '"');
          //res.company.locations = JSON.parse(loc)
          // setProjectState(new ProjectModel(res.project))
        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteLegalDocument = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      legalService.deleteLegalDocuments(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }
  }

  const saveFinalPresentation = (values) => {
    values.rfpId = match.params.rfpId;
    finalPresentationService.addSubmitFinalPresenation(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getFinalPresentation = () => {
    finalPresentationService.getAllFinalPresentation(rfpId).then(
      res => {

        debugger;
        if (res !== undefined && res && res.finalPresenation && res.finalPresenation.length) {
          res.finalPresenation[0].presentationAgenda = JSON.parse(res.finalPresenation[0].presentationAgenda);
          debugger;
          const finalPresenations = [];
          res.finalPresenation.forEach(fp => {
            finalPresenations.push(new FinalPresentationModel(fp));
          });
          const finalPresentation = new FinalPresentationModel(res.finalPresenation[0]);
          finalPresentation.finalPresenations = finalPresenations;
          setFinalPresentationState(finalPresentation);
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveReference = (values) => {
    values.rfpId = match.params.rfpId;
    referenceService.addReference(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'submit' || values.type === 'accept-submit') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getReference = () => {
    referenceService.getAllReference(rfpId).then(
      res => {

        if (res !== undefined && res && res.reference) {
          const references = [];
          res.reference.forEach(reference => {
            references.push(new ReferencesModel(reference));
          });
          setReferenceState(references);
        }
      }
    ).catch(() => setHasError(true))
  }

  const getCompanyData = () => {
    companyService.getCompanyByUser(true, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (res.company?.overview) {
            res.company.overview = JSON.parse(res.company.overview);
          }
          setCompanyState(new CompanyModel(res.company))
        }
      }
    ).catch(() => setHasError(true))
  }

  const getVendor = () => {
    vendorService.getVendor(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setVendotInviteState(new VendorInviteModel(res.vendor));
        }
      }
    ).catch(() => setHasError(true))
  }

  const getProjectData = () => {
    projectService.getProject(rfpId).then(
      res => {

        if (res !== undefined && res) {
          res.project.projectDescription = JSON.parse(res.project.projectDescription);
          const a = new ProjectModel(res.project);
          setProjectState(a);
        }
      }
    ).catch(() => setHasError(true))
  }

  const getStepData = (step) => {
    switch (allSteps[step]) {
      case 'companyInformation':
        getCompanyData();
        break;
      case 'projectInformation':
        getProjectData();
        break;
      case 'technicalRequirements':
        getTechnicalDetailData();
        break;
      case 'commercialEvaluation':
        getCommercialDetailData();
        break;
      case 'vendorInvite':
        getVendor();
        break;
      case 'nda':
        getNDA();
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        getFinalPresentation();
        break;
      case 'references':
        getReference();
        break;
      case 'pricingNegotiations':
        getPricing();
        break;
      case 'legalContracts':
        getLegalDocument();
        break;
      case 'rfpCompletion':
        break;
      default:

        break;
    }
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    getStepData(step);
  };

  const handleNext = (values) => {
    handleComplete();
    switch (allSteps[values.step]) {
      case 'companyInformation':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        getStepData(activeStep + 1);
        break;
      case 'projectInformation':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        getStepData(activeStep + 1);
        break;
      case 'technicalRequirements':
        saveTechnicalDetails(values);

        break;
      case 'vendorInvite':
        saveVendor(values);
        break;
      case 'commercialEvaluation':
        saveCommercialDetails(values);
        break;
      case 'acceptRejectInvite':
        saveAcceptReject(values);

        break;
      case 'nda':
        saveNDA(values);
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        saveFinalPresentation(values)
        break;
      case 'references':
        saveReference(values);

        break;
      case 'pricingNegotiations':
        savePricing(values);

        break;
      case 'legalContracts':
        saveLegaldocument(values);
        break;
      case 'rfpCompletion':
        break;
      default:
        break;
    }
  };
  const isStepOptional = (step) => {
    // return step === 2;
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = (step) => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getStepData(activeStep + 1);
  };

  function getStepContent(step) {
    submitRfpUpdateStepper(step)
    switch (allSteps[step]) {
      case 'companyInformation':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <CompanyDetails isSubmitView={true} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setCompanyState={setCompanyState} companyState={companyState} step={step} />
        </Container>);
      case 'projectInformation':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <ProjectDetails isSubmitView={true} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} projectState={projectState} setProjectState={setProjectState} step={step} />
        </Container>);
      case 'technicalRequirements':
        return (<Container maxWidth="sm" className={classes.custWidthRFP}>
          <ReturnToDashboard rfp_id={rfpId} />
          <TechnicalEvaluationAnswer handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setTechnicalEvaluationState={setTechnicalEvaluationState} technicalEvaluationState={technicalEvaluationState} step={step} totalStepCount={totalStepCount} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} question_answer_data={question_answer_data} />
        </Container>);
      case 'commercialEvaluation':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <CommercialEvaluationAnswer handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setCommercialEvaluationState={setCommercialEvaluationState} commercialEvaluationState={commercialEvaluationState} step={step} totalStepCount={totalStepCount} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} question_answer_data={question_answer_data} />
        </Container>);
      case 'vendorInvite':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <VendorInvite handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} rfpId={rfpId} vendorInviteState={vendorInviteState} setVendotInviteState={setVendotInviteState} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} deleteEmail={deleteEmail} />
        </Container>);
      case 'nda':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <NdaAnswer handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} ndaState={ndaState} step={step} totalStepCount={totalStepCount} deleteNDAAns={deleteNDAAns} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} companyState={companyState} />
        </Container>);
      case 'proofOfconceptValue':
        return (<Container maxWidth="md">
          Coming Soon
        </Container>);
      case 'finalPresentation':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <FinalPresentation isSubmitView={true} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setFinalPresentationState={setFinalPresentationState} finalPresentationState={finalPresentationState} step={step} totalStepCount={totalStepCount} deleteFinalPresentation={deleteFinalPresentation} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} />
        </Container>);
      case 'references':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <References handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setReferenceState={setReferenceState} referenceState={referenceState} step={step} totalStepCount={totalStepCount} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} />
        </Container>);
      case 'pricingNegotiations':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <Pricing isSubmitView={true} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} pricingState={pricingState} setPricingState={setPricingState} step={step} totalStepCount={totalStepCount} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} />
        </Container>);
      case 'legalContracts':
        return (<Container maxWidth="md">
          <ReturnToDashboard rfp_id={rfpId} />
          <LegalContracts handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setLegalState={setLegalState} legalState={legalState} deleteLegalDocument={deleteLegalDocument} step={step} totalStepCount={totalStepCount} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} />
        </Container>);
      default:
        return 'Unknown step';
    }
  }


  return (
    <Page className={classes.root} title="Submit RFP">
      <div id="rightContain">
        <Container maxWidth="lg" className="main-wrapper">
          <div className={classes.root}>
            <Grid container spacing={0}>
              <Grid className={classes.leftStepPanel} item md={2} sm={3} xs={12} className="sd_stepper requestly-positon small-stepper-submit">

                <Stepper nonLinear activeStep={activeStep} orientation="vertical" className="ins_stepper requestly-stepper">
                  {/*  <Grid style={{ 'padding': '10px', cursor: 'pointer' }}>
                    <ArrowBackIcon onClick={() => history.goBack()} />
                  </Grid> */}
                  {steps.map((label, index) => (
                    <Step key={label} className="requestly-stepper-step">
                      <StepButton className={classes.buttonStepper + ' requestly-span'} onClick={handleStep(index)} completed={completed[index]}>
                        {label}
                      </StepButton>
                      <StepContent>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>

              </Grid>
              <Grid container className={classes.inputAll} md={9} item sm={9} xs={12}>
                <Typography>{getStepContent(activeStep)}</Typography>
                {activeStep === steps.length && (
                  <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </Page>
  );
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({

  getRfpByIdSubmitTechnicalUpdate: (value) => dispatch(getRfpByIdSubmitTechnicalUpdate(value)),
  getRfpByIdSubmitCommercialUpdate: (value) => dispatch(getRfpByIdSubmitCommercialUpdate(value)),
  submitRfpUpdateStepper: (value) => dispatch(submitRfpUpdateStepper(value))


  // rfpUpdateStepper
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitRfpView);

