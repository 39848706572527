import actionTypes from "./types";

export const getRfpByUserIdSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_USER_ID_SUCCESS, payload: data
});

export const getRfpByUserIdStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_USER_ID_START,
  payload
});

export const getRfpByUserIdFailed = () => ({
  type: actionTypes.GET_RFP_BY_USER_ID_FAILED
});


//Technical Requirements

export const getRfpByIdTechnicalSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_ID_TECHNICAL_SUCCESS, payload: data
});

export const getRfpByIdTechnicaStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_TECHNICAL_START,
  payload
});

export const getRfpByIdTechnicaFailed = () => ({
  type: actionTypes.GET_RFP_BY_ID_TECHNICAL_FAILED
});


export const getRfpByIdTechnicalUpdate = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_TECHNICAL_UPDATE,
  payload
});

export const saveTechnicalDetailsStart = (payload) => ({
  type: actionTypes.SAVE_TECHNICAL_DETAILS_START,
  payload
});

export const saveTechnicalDetailsSuccess = (data) => ({
  type: actionTypes.SAVE_TECHNICAL_DETAILS_SUCCESS, payload: data
});

export const saveTechnicalDetailsFailed = () => ({
  type: actionTypes.SAVE_TECHNICAL_DETAILS_FAILED
});


//Commerical Requirements

export const getRfpByIdCommericalStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_COMMERICAL_START,
  payload
});

export const getRfpByIdCommericalFailed = () => ({
  type: actionTypes.GET_RFP_BY_ID_COMMERICAL_FAILED
});


export const getRfpByIdCommericalSuccess = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_COMMERICAL_SUCCESS,
  payload
});

export const getRfpByIdCommericalUpdate = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_COMMERICAL_UPDATE,
  payload
});

export const saveCommercialDetailsStart = (payload) => ({
  type: actionTypes.SAVE_COMMERCIAL_DETAILS_START,
  payload
});

export const saveCommercialDetailsSuccess = (data) => ({
  type: actionTypes.SAVE_COMMERCIAL_DETAILS_SUCCESS, payload: data
});

export const saveCommercialDetailsFailed = () => ({
  type: actionTypes.SAVE_COMMERCIAL_DETAILS_FAILED
});

export const rfpUpdateStepper = (payload) => ({
  type: actionTypes.RFP_UPDATE_STEPPER,
  payload
});





