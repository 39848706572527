export const rapid = {
      companyInformation: true,
      projectInformation: true,
      technicalRequirements: true,
      commercialEvaluation: false,
      vendorInvite: true,
      nda: false,
      vendorShortList: false,
      proofOfconceptValue: false,
      statementOfWork: false,
      finalPresentation: false,
      references: false,
      pricingNegotiations: false,
      contactAgreements: false,
      rfpCompletion: false
    };

export const standard = {
      companyInformation: true,
      projectInformation: true,
      technicalRequirements: true,
      commercialEvaluation: false,
      vendorInvite: true,
      nda: true,
      vendorShortList: false,
      proofOfconceptValue: false,
      statementOfWork: false,
      finalPresentation: false,
      references: true,
      pricingNegotiations: true,
      contactAgreements: false,
      rfpCompletion: true
    };

export const complete = {
      companyInformation: true,
      projectInformation: true,
      technicalRequirements: true,
      commercialEvaluation: true,
      vendorInvite: true,
      nda: true,
      vendorShortList: true,
      proofOfconceptValue: true,
      statementOfWork: true,
      finalPresentation: true,
      references: true,
      pricingNegotiations: true,
      contactAgreements: true,
      rfpCompletion: true
    };

export const detailed = {
      companyInformation: true,
      projectInformation: true,
      technicalRequirements: true,
      commercialEvaluation: true,
      vendorInvite: true,
      nda: true,
      vendorShortList: true,
      proofOfconceptValue: true,
      statementOfWork: true,
      finalPresentation: true,
      references: true,
      pricingNegotiations: true,
      contactAgreements: true,
      rfpCompletion: true
    };