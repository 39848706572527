import AxiosInstance from '../../interceptor/http-intercepor';


const updateRfpService = {
  userDetails: (payload, isSubmit?= false) => {
    const url = 'rfp/getrfpbyId/' + payload;
    const userId = localStorage.getItem('userId');
    if (isSubmit)
      url = url + '/' + userId;
    return AxiosInstance.get(url);
  },
  TechnicalDetails: (payload) => {
    const userId = localStorage.getItem('userId');
    const url = 'technical/getTechnical/' + payload + '/' + userId;
    return AxiosInstance.get(url);
  },
  TechnicalDetailsSubmit: (payload) => {
    const userId = localStorage.getItem('userId');
    payload.userId = userId;
    payload.overview = JSON.stringify(payload.overview);
    const url = 'technical/create'
    return AxiosInstance.post(url, payload);
  },
  commericalDetails: (payload) => {
    const userId = localStorage.getItem('userId');
    const url = 'commercial/getCommercial/' + payload + '/' + userId;
    return AxiosInstance.get(url);
  },
  commericalDetailsSubmit: (payload) => {
    const userId = localStorage.getItem('userId');
    payload.userId = userId;
    payload.overview = JSON.stringify(payload.overview);
    const url = 'technical/create'
    return AxiosInstance.post(url, payload);
  },

}

export default updateRfpService;
