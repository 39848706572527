import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormLabel, Grid,
  makeStyles, TextField
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldArray, Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import RichText from 'src/components/RichText/RichText';
import CompanyService from 'src/services/company/company.service';
import { renderFileList } from 'src/views/shared/components/FileList';
import 'src/views/shared/css/common.scss';
import { contactRoles } from 'src/views/shared/data/dropdowns.js';
import FooterActionComponent from '../../components/FooterActionComponent';
import { customProjectInfoValidation } from '../../utils/utils';
import ErrorComponent from './../../components/ErrorComponent';
import { ProjectModel } from './ProjectModel';
import './style.css';

const useStyles = makeStyles(() => ({
  root: {},
  companyHeadings: {
    backgroundColor: '#40506b', color: '#fff',
  },
  customInputs: {},
  width100: { width: '100%', },
  blueBtn: { backgroundColor: '#4688f0', padding: '6px 16px', fontSize: '14px', },

  CardBorder: {
    borderRadius: "15px"

  },
  pageSize: {
    paddingBottom: "2px"
  },

  cardScrollArea: {
  },
  BackButton: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  ButtonSkip: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  NextButton: {
    marginLeft: "10px"
  },
  footerBackground: {
    background: '#f7f7f7'
  },
  uploadedFileContainer: {
    fontFamily: 'Roboto',
    height: '30px',
    margin: '45px 0 0',
    padding: '8px 433px 2px 8px',
    backgroundColor: '#4688f0',
  },
  uploadedFileText: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.14',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#ffffff',
  }
}));

const ProjectDetails = ({ handleNext, handleSkip, isSubmitView, completed, totalStepCount, currentStepCompleteStatus = true, handleBack, className, setProjectState, projectState, deleteSupportingMaterial, step, ...rest }) => {
  const classes = useStyles();
  var type = '';
  const companyService = new CompanyService();
  const [companyName, setCompanyName] = React.useState();
  const companyId = localStorage.getItem('companyId')
  const [error_values, setErrorValues] = React.useState(null);

  const getCompanyData = () => {
    companyService.getCompanyByUser(false).then(
      res => {
        if (res !== undefined && res) {
          if (res?.company?.companyName) {
            setCompanyName(res.company.companyName);
          }
        }
      }
    ).catch((err) => console.log(':: err', err))
  }

  useEffect(() => {
    getCompanyData()
  }, []);
  useEffect(() => {
    if (error_values !== null) {
      customProjectInfoValidation(projectState).then((validation_response) => {
        setErrorValues(validation_response);
      });
    }
  }, [projectState])

  const removeContacts = (index) => {
    projectState.contacts.splice(index, 1);
    setProjectState(new ProjectModel(projectState));
  }

  const addContacts = () => {
    projectState.contacts.push({ contactName: '', contactRole: '' });
    setProjectState(new ProjectModel(projectState));
  }

  const addMilestoneEvents = () => {
    projectState.timelineItems.push({ mileStoneName: '', description: '', mileStoneDate: '', isFixed: false });
    setProjectState(new ProjectModel(projectState));
  }

  const removeMilestones = (index) => {
    projectState.timelineItems.splice(index, 1);
    setProjectState(new ProjectModel(projectState));
  }

  const updateState = (event) => {
    setProjectState({ ...projectState, [event.target.name]: event.target.value });
  }

  const handleContactNameBlur = (event, index, type) => { // type: 1 - Location , 2 - Function 
    if (type === 1) {
      projectState.contacts[index].contactName = event.target.value;
    } else { projectState.contacts[index].contactName = event.target.value; }
    setProjectState(new ProjectModel(projectState));
  }
  const handleContactRoleBlur = (event, index, type) => { // type: 1 - Location , 2 - Function 
    if (type === 1) {
      projectState.contacts[index].contactRole = event.target.value;
    } else { projectState.contacts[index].contactRole = event.target.value; }
    setProjectState(new ProjectModel(projectState));
  }

  const handleTimeLineBlur = (event, index, type) => { // type: 1 - name , 2 - description , 3 - date 
    if (type === 1) { projectState.timelineItems[index].mileStoneName = event.target.value; }
    else if (type === 2) { projectState.timelineItems[index].description = event.target.value; }
    else { projectState.timelineItems[index].mileStoneDate = event.target.value; }
    setProjectState(new ProjectModel(projectState));
  }

  const handleTimeLineChange = (event, index, type) => { // type: 1 - Location , 2 - Function 
    const { timelineItems } = projectState;
    const newTimelineItems = timelineItems.slice(0);
    if (type === 1) { newTimelineItems[index].mileStoneName = event.target.value; }
    else if (type === 2) { newTimelineItems[index].description = event.target.value; }
    else { newTimelineItems[index].mileStoneDate = event.target.value; }
    projectState.timelineItems = newTimelineItems;
    setProjectState(new ProjectModel(projectState));
  }

  const setFieldValue = (value) => {
    projectState.logo = value;
    setProjectState(new ProjectModel(projectState));
  }

  const handleContactRoleChange = (event, index, type) => { // type: 1 - Location , 2 - Function 
    const { contacts } = projectState;
    const newContacts = contacts.slice(0);
    if (type === 1) {
      newContacts[index].contactRole = event.target.value;
    } else { newContacts[index].contactRole = event.target.value; }
    projectState.contacts = newContacts
    setProjectState(new ProjectModel(projectState));
  }

  const handleProjectDescChange = (richText) => {
    projectState.projectDescription = richText;
  }

  const handleContactNameChange = (event, index, type) => { // type: 1 - Location , 2 - Function 
    const { contacts } = projectState;
    const newContacts = contacts.slice(0);
    if (type === 1) {
      newContacts[index].contactName = event.target.value;
    } else { newContacts[index].contactName = event.target.value; }
    projectState.contacts = newContacts
    setProjectState(new ProjectModel(projectState));
  }

  const [localFile, setLocalFile] = React.useState([]);

  let file = []

  const handleFilesChange = (files) => {
    let tempFile = [];

    for (var i = 0; i < files.length; i++) {
      const fileDate = {
        userName: localStorage.getItem('user'),
        userId: localStorage.getItem('userId'),
        companyName,
        companyId,
        createdAt: new Date(),
        name: files[i].name,
        location: URL.createObjectURL(files[i]),
        mimetype: '',
        file: files[i]
      }
      tempFile.push(fileDate)
    }
    setLocalFile(tempFile)
    file = tempFile
  }

  var buttonStyle = {
    backgroundColor: '#000',
  }

  const deleteLocalFile = (index, value) => {

    var tempFile = [...localFile];

    tempFile.splice(index, 1)

    setLocalFile(tempFile)
    file = tempFile
  }

  const handleFileDelete = (files) => {
    console.log(files, "files drop...");
  }

  console.log(projectState, "projectState")
  return (

    <>
      <FooterActionComponent />

      <Formik
        enableReinitialize={true}
        initialValues={projectState}
        onSubmit={async (values) => {
          const autoClose = true;
          const keepAfterRouteChange = false
          var validation_response = await customProjectInfoValidation(values);
          setErrorValues(validation_response);
          console.log(validation_response)
          // alert('hi');
          if (Object.keys(validation_response).length === 0) {
            values.step = step;
            values.file = localFile.map(datum => datum.file);
            values.type = type
            handleNext(values);
          } else {
            //window.scrollTo(0, 0)
            //alertService.error(validation_response.join(''), { autoClose, keepAfterRouteChange })
          }
        }}
      >

        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <div className="requestly-rfp-view company-information mb-5">

            {
              isSubmitView ? (
                <Card className={classes.CardBorder}>
                  <CardContent className={classes.cardScrollArea + ' p-0'}>
                    <CardHeader title="Project Information"
                      className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'} >
                    </CardHeader>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography className="label-title">
                            Project Name

                          </Typography>
                          <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                            {values.projectName}
                          </Typography>


                        </Grid>
                        <Divider variant="fullWidth" />

                        <Grid item md={12} xs={12}>
                          <Typography className="label-title font-family"> Project Description </Typography>
                          <RichText
                            showContentOnly={isSubmitView}
                            value={projectState.projectDescription}
                            onTextChange={handleProjectDescChange}
                          />
                        </Grid>


                        <Grid item md={12} xs={12}>
                          <Typography className="supporting-heading font-family" style={{ "marginTop": "5px", fontWeight: "400px" }}>
                            Supporting Material
                          </Typography>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            {values.supportingMaterial_docs && values.supportingMaterial_docs.length > 0
                              ? renderFileList(values.supportingMaterial_docs, classes.table)
                              : null}
                          </Grid>

                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography className="label-title font-family">
                            Business Objective
                          </Typography>
                          <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                            {values.businessObjective}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography className="label-title font-family">
                            Metrics
                          </Typography>
                          <Typography style={{ "marginTop": "5px", "fontSize": "14px" }} className="font-family">
                            {values.metrics}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography className="label-title font-family">
                            Decision Criteria
                          </Typography>
                          <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                            {values.decisionCriteria}
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Typography className="label-title font-family">
                            Decision Process
                          </Typography>
                          <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                            {values.decisionProcess}
                          </Typography>
                        </Grid>
                      </Grid>


                    </CardContent>
                    <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                      <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-financial-content" id="panel-financial">
                        <Typography className={classes.heading}>Key Contacts and Responsibilities</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container md={12} xs={12} style={{ paddingLeft: '20px' }}>
                          {

                            values.contacts.map((contact) => (
                              <>
                                <Grid item md={6}>
                                  <Typography className="label-title">
                                    Contact Name
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {contact.contactName}
                                  </Typography>
                                </Grid>
                                <Grid item md={6}>
                                  <Typography className="label-title">
                                    Contact Role
                                  </Typography>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px" }}>
                                    {contact.contactRole.replace("-", " ")}
                                  </Typography>
                                </Grid>
                              </>
                            ))

                          }
                        </Grid>
                      </AccordionDetails>

                    </Accordion>
                    <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                      <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-financial-content" id="panel-financial">
                        <Typography className={classes.heading + ' font-family"'}>RFP Timelines</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardContent style={{ "width": "100%" }} className="rfp-steps">
                          {
                            values.timelineItems.map((timeline) => (
                              <Grid container md={12} xs={12} style={{ borderBottom: '1px grey' }}>
                                <Grid item md={4} xs={12}>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "16px", fontWeight: "400px" }}>
                                    {timeline.mileStoneName}
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px", fontWeight: "400px", paddingLeft: '5px', paddingRight: '10px' }}>
                                    {timeline.description}
                                  </Typography>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                  <Typography style={{ "marginTop": "5px", "fontSize": "14px", fontWeight: "400px" }}>
                                    {moment(timeline.mileStoneDate).format('DD MMM YYYY')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))
                          }
                        </CardContent>
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                  <div class="footer-btn-fixed">
                    <footer class="page-footer green">
                      <Grid container spacing={2} justify="center">
                        {step !== 0 ? (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.BackButton + " font-family"} color="primary" type="button" variant="contained" onClick={handleBack}>
                              Back
                            </Button>
                          </Grid>
                        ) : null}
                        {step !== totalStepCount ? (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.ButtonSkip + " font-family"} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                              Skip
                            </Button>
                          </Grid>
                        ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                            </Grid>
                          ) : null}
                        {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                          (
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                            </Grid>
                          ) : step === totalStepCount ?
                            (
                              <Grid item md={3} sm={3} xs={3}>
                                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                              </Grid>
                            ) : (
                              <Grid item md={3} sm={3} xs={3}>
                                <Button className={classes.NextButton + " font-family"} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                              </Grid>
                            )}
                      </Grid>
                    </footer>
                  </div>
                </Card>


              )


                : (
                  <form onSubmit={handleSubmit} className={'requestly-rfp-form company-information'}>
                    <div className={classes.pageSize + ' company-info-div'}>
                      <Card className={classes.CardBorder}>
                        <CardContent className={classes.cardScrollArea + ' p-0'}>
                          <CardHeader
                            title="Project Information"
                            subheader="Please provide information on your project.Provide as much information as possible in order to clearly."
                            className={classes.companyHeadings + ' requestly-rfp-heading requestly-rfp1'} >
                          </CardHeader>
                          <Divider />
                          <CardContent>
                            <Grid container spacing={3}>
                              <Grid item md={12} xs={12}>
                                <TextField
                                  error={Boolean(touched.projectName && errors.projectName)}
                                  fullWidth
                                  multiline
                                  disabled={isSubmitView}
                                  helperText={touched.projectName && errors.projectName}
                                  label="Project Name"
                                  name="projectName"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.projectName}
                                  variant="outlined"
                                  className="requestly-rfp-textbox"
                                />
                                {
                                  (error_values !== null && error_values.projectName !== undefined) ? <ErrorComponent text={error_values.projectName} /> : ''
                                }
                              </Grid>

                              <Grid item md={12} xs={12}>
                                <Typography style={{ "marginTop": "5px", "color": "#547087", "fontSize": "16px" }} className="font-family"> Project Description </Typography>
                                <RichText
                                  showContentOnly={isSubmitView}
                                  value={projectState.projectDescription}
                                  onTextChange={handleProjectDescChange}
                                />
                                {
                                  (error_values !== null && error_values.projectDescription !== undefined) ? <ErrorComponent text={error_values.projectDescription} /> : ''
                                }
                              </Grid>


                              <Grid item md={12} xs={12}>
                                <FormLabel component="legend" className="font-family">Supporting Materials</FormLabel>
                                {isSubmitView ? null :
                                  (
                                    <DropzoneArea
                                      dropzoneText={".doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf, .png, .jpg, .jpeg"}
                                      acceptedFiles={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.png', '.jpg', '.jpeg']}
                                      maxFileSize="104857600"
                                      showFileNames={true}
                                      filesLimit={10}
                                      onChange={handleFilesChange}
                                      showPreviewsInDropzone={false} />)}


                              </Grid>
                              {localFile?.length ? (
                                <Grid item md={12} xs={12} style={{ marginTop: '15px' }}>
                                  {renderFileList(localFile, classes.table, true, deleteLocalFile)}
                                </Grid>
                              ) : null}
                              <Grid
                                item
                                md={12}
                                xs={12}
                              >

                                {values.supportingMaterial_docs && values.supportingMaterial_docs.length > 0
                                  ? <>
                                    <Box className={classes.uploadedFileContainer}>
                                      <Typography className={classes.uploadedFileText}>
                                        Uploaded files
                                      </Typography>
                                    </Box>
                                    {renderFileList(values.supportingMaterial_docs, classes.table, false, deleteSupportingMaterial)
                                    }                                  </> : null}
                              </Grid>


                              <Grid item md={12} xs={12}>
                                <FormLabel component="legend" className="font-family">Provide information on the business objective you're trying to meet</FormLabel>
                                <TextField
                                  fullWidth
                                  multiline
                                  disabled={isSubmitView}
                                  helperText={touched.businessObjective && errors.businessObjective}
                                  label="Business Objective"
                                  name="businessObjective"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.businessObjective}
                                  variant="outlined"
                                  className="requestly-rfp-textbox"
                                />
                              </Grid>

                              <Grid item md={12} xs={12}>
                                <FormLabel component="legend" className="font-family">Provide metrics on what this project needs to be achieved</FormLabel>
                                <TextField
                                  fullWidth
                                  multiline
                                  disabled={isSubmitView}
                                  helperText={touched.metrics && errors.metrics}
                                  label="Metrics"
                                  name="metrics"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.metrics}
                                  variant="outlined"
                                  className="requestly-rfp-textbox"
                                />
                              </Grid>


                              <Grid item md={12} xs={12}>
                                <FormLabel component="legend" className="font-family">The decision process and the people involved in making the decision</FormLabel>
                                <TextField
                                  fullWidth
                                  multiline
                                  disabled={isSubmitView}
                                  helperText={touched.decisionProcess && errors.decisionProcess}
                                  label="Decision Process"
                                  name="decisionProcess"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.decisionProcess}
                                  variant="outlined"
                                  className="requestly-rfp-textbox"
                                />
                              </Grid>
                              <Grid item md={12} xs={12}>
                                <FormLabel component="legend" className="font-family">The criteria, includes information on how you plan to evaluate vendors</FormLabel>
                                <TextField
                                  fullWidth
                                  multiline
                                  disabled={isSubmitView}
                                  helperText={touched.decisionCriteria && errors.decisionCriteria}
                                  label="Decision Criteria"
                                  name="decisionCriteria"
                                  onBlur={updateState}
                                  onChange={handleChange}
                                  value={values.decisionCriteria}
                                  variant="outlined"
                                  className="requestly-rfp-textbox"
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                          <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                            <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-upload-content" id="panel-upload">
                              <Typography className={classes.heading + ' font-family'}>Key Contacts and Responsibilities</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <CardContent>
                                  <Grid container spacing={3}>
                                    <Grid item md={12} xs={12}>
                                      <FieldArray
                                        error={Boolean(touched.contacts && errors.contacts)}
                                        name="contacts"
                                        render={arrayHelpers => (
                                          <div className={classes.width100}>
                                            {values.contacts && values.contacts.map((contact, index) => (
                                              <div style={{ 'paddingBottom': '10px' }} key={index} className={classes.customInputs}>
                                                <Grid item md={5} xs={12}>
                                                  <TextField
                                                    error={Boolean(touched.contacts && errors.contacts)}
                                                    fullWidth
                                                    disabled={isSubmitView}
                                                    helperText={touched.contactName && errors.contactName}
                                                    label="Contact Name"
                                                    value={contact.contactName}
                                                    name={`contacts[${index}].contactName`}
                                                    onBlur={(e) => handleContactNameBlur(e, index, 1)}
                                                    onChange={(e) => handleContactNameChange(e, index, 1)}
                                                    variant="outlined"
                                                    className="requestly-rfp-textbox requestly-bg-gray requestly-h-40"
                                                  />
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                  <TextField
                                                    error={Boolean(touched.contacts && errors.contacts)}
                                                    fullWidth
                                                    disabled={isSubmitView}
                                                    helperText={touched.contactRole && errors.contactRole}
                                                    label="Contact Role"
                                                    name={`contacts.${index}.contactRole`}
                                                    select
                                                    value={contact.contactRole}
                                                    SelectProps={{ native: true }}
                                                    className="requestly-rfp-textbox requestly-bg-gray requestly-h-40"
                                                    onBlur={(e) => handleContactRoleBlur(e, index, 2)}
                                                    onChange={(e) => handleContactRoleChange(e, index, 2)}
                                                    variant="outlined">
                                                    {contactRoles.map((option) => (
                                                      <option key={option.value} value={option.value}> {option.label} </option>
                                                    ))}
                                                  </TextField>
                                                </Grid>
                                                {isSubmitView ? null :
                                                  (
                                                    <Grid item md={1} xs={12}>
                                                      {index > 0 ?
                                                        <Button style={{ "minWidth": "30px", "padding": "1px", }} className={classes.addMinus} type="button" onClick={() => removeContacts(index)}>
                                                          &nbsp; <DeleteIcon className="site-delete-icon" style={{ "color": "#333", }} />
                                                        </Button>
                                                        : null}
                                                    </Grid>)}
                                              </div>
                                            ))}
                                            {isSubmitView ? null :
                                              (
                                                <Button style={{ "padding": "6px 16px", "fontSize": "14px", }} className={classes.blueBtn} color="secondary" variant="contained" type="button" onClick={addContacts}>
                                                  Add Contact
                                                </Button>)}
                                          </div>
                                        )}
                                      />
                                    </Grid>


                                    {
                                      (error_values !== null && error_values.contacts !== undefined) ? <Grid item md={12} xs={12}> <ErrorComponent text={error_values.contacts} /></Grid> : ''
                                    }
                                    {
                                      (error_values !== null && error_values.contactRole !== undefined) ? <Grid item md={12} xs={12}> <ErrorComponent text={error_values.contactRole} /></Grid> : ''
                                    }

                                  </Grid>
                                </CardContent>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion defaultExpanded={true} className="requestly-fin-info-tab">
                            <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-pricing-content" id="panelpPricing">
                              <Typography className={classes.heading + ' font-family'}>RFP Timelines</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <CardContent>
                                  <Grid container spacing={3}>
                                    {
                                      (error_values !== null && error_values.timelineItems !== undefined) ? <Grid item md={12} xs={12}><ErrorComponent text={error_values.timelineItems} /> </Grid> : ''
                                    }
                                    <Grid item md={12} xs={12}>
                                      <FieldArray
                                        name="timelineItems"
                                        render={arrayHelpers => (
                                          <div id="dateFnt" className={classes.width100}>
                                            {values.timelineItems && values.timelineItems.map((timeLineItem, index) => (
                                              <div key={index} className={classes.customInputs}>
                                                <Grid item md={4} xs={12}>
                                                  <TextField
                                                    error={errors &&
                                                      errors.timelineItems &&
                                                      errors.timelineItems[index] &&
                                                      errors.timelineItems[index].mileStoneName &&
                                                      (touched &&
                                                        touched.timelineItems &&
                                                        touched.timelineItems[index] &&
                                                        touched.timelineItems[index].mileStoneName)}
                                                    fullWidth
                                                    multiline
                                                    disabled={isSubmitView}
                                                    helperText={errors &&
                                                      errors.timelineItems &&
                                                      errors.timelineItems[index] &&
                                                      errors.timelineItems[index].mileStoneName}
                                                    name={`timelineItems[${index}].mileStoneName`}
                                                    onBlur={(e) => handleTimeLineBlur(e, index, 1)}
                                                    onChange={(e) => handleTimeLineChange(e, index, 1)}
                                                    value={timeLineItem.mileStoneName}
                                                    variant="outlined"
                                                    className="requestly-rfp-textbox"
                                                    label={(timeLineItem.mileStoneName === 'Selection and Contract Agreement') ? 'Selection/Agreement' : timeLineItem.mileStoneName}
                                                  />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                  <TextField
                                                    error={Boolean(touched.description && errors.description)}
                                                    fullWidth
                                                    multiline
                                                    disabled={isSubmitView}
                                                    helperText={touched.description && errors.description}
                                                    name={`timelineItems[${index}].description`}
                                                    label="Description"
                                                    onBlur={(e) => handleTimeLineBlur(e, index, 2)}
                                                    onChange={(e) => handleTimeLineChange(e, index, 2)}
                                                    value={timeLineItem.description}
                                                    variant="outlined"
                                                    className="requestly-rfp-textbox"
                                                  />
                                                </Grid>
                                                {isSubmitView ?
                                                  (<Grid item md={2} xs={12}>
                                                    <TextField
                                                      error={Boolean(touched.mileStoneDate && errors.mileStoneDate)}
                                                      fullWidth
                                                      disabled={isSubmitView}
                                                      helperText={touched.mileStoneDate && errors.mileStoneDate}
                                                      name="{`timelineItems[${index}].mileStoneDate`}"
                                                      label="Date"
                                                      type="date"
                                                      value={timeLineItem.mileStoneDate}
                                                      onBlur={(e) => handleTimeLineBlur(e, index, 3)}
                                                      onChange={(e) => handleTimeLineChange(e, index, 3)}
                                                      variant="outlined"
                                                      className="requestly-rfp-textbox requestly-bg-gray roboto-font roboto-font-input"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }} />

                                                  </Grid>) : (<Grid item md={3} xs={12}>
                                                    <TextField
                                                      error={errors &&
                                                        errors.timelineItems &&
                                                        errors.timelineItems[index] &&
                                                        errors.timelineItems[index].mileStoneDate &&
                                                        (touched &&
                                                          touched.timelineItems &&
                                                          touched.timelineItems[index] &&
                                                          touched.timelineItems[index].mileStoneDate)}
                                                      fullWidth
                                                      disabled={isSubmitView}
                                                      helperText={errors &&
                                                        errors.timelineItems &&
                                                        errors.timelineItems[index] &&
                                                        errors.timelineItems[index].mileStoneDate}
                                                      name="{`timelineItems[${index}].mileStoneDate`}"
                                                      label="Date"
                                                      type="date"
                                                      value={timeLineItem.mileStoneDate}
                                                      onBlur={(e) => handleTimeLineBlur(e, index, 3)}
                                                      onChange={(e) => handleTimeLineChange(e, index, 3)}
                                                      variant="outlined"
                                                      className="requestly-rfp-textbox requestly-bg-gray roboto-font roboto-font-input"
                                                      InputLabelProps={{
                                                        shrink: true,
                                                      }} />
                                                  </Grid>)}
                                                {isSubmitView ? null :
                                                  (<Grid item md={1} xs={12} >
                                                    {(!values.timelineItems[index].isFixed) > 0 ?
                                                      <Button style={{ "marginRight": "5px", "padding": "1px", "minWidth": "30px" }} className={classes.addMinus} type="button" onClick={() => removeMilestones(index)}>
                                                        &nbsp; <DeleteIcon className="site-delete-icon" style={{ "color": "#333", }} />
                                                      </Button>
                                                      : null}
                                                  </Grid>)}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                      />
                                    </Grid>
                                    {isSubmitView ? null :
                                      (<Grid item md={12} xs={12} align='right'>
                                        <Button style={{ "padding": "6px 16px", "fontSize": "14px", }} className={classes.addPlus} color="secondary" variant="contained" type="button" onClick={addMilestoneEvents}>
                                          Add Event
                                        </Button>
                                      </Grid>)}
                                  </Grid>
                                </CardContent>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </CardContent>
                        <Divider />
                        <div class="footer-btn-fixed">
                          <footer class="page-footer green">
                            <Grid container spacing={2} justify="center">
                              {step !== 0 ? (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.BackButton + " font-family"} color="primary" type="button" variant="contained" onClick={handleBack}>
                                    Back
                                  </Button>
                                </Grid>
                              ) : null}
                              {step !== totalStepCount ? (
                                <Grid item md={3} sm={3} xs={3}>
                                  <Button className={classes.ButtonSkip + " font-family"} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                                    Skip
                                  </Button>
                                </Grid>
                              ) : null}
                              {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                                (
                                  <Grid item md={3} sm={3} xs={3}>
                                    <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                                  </Grid>
                                ) : null}
                              {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                                (
                                  <Grid item md={3} sm={3} xs={3}>
                                    <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                                  </Grid>
                                ) : step === totalStepCount ?
                                  (
                                    <Grid item md={3} sm={3} xs={3}>
                                      <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                                    </Grid>
                                  ) : (
                                    <Grid item md={3} sm={3} xs={3}>
                                      <Button className={classes.NextButton + " font-family"} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                                    </Grid>
                                  )}
                            </Grid>
                          </footer>
                        </div>
                      </Card>
                    </div>

                  </form>
                )
            }
          </div>

        )
        }
      </Formik >
    </>
  );
};

ProjectDetails.propTypes = {
  className: PropTypes.string
};

export default ProjectDetails;
