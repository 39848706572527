import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid, makeStyles
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import 'src/views/shared/css/common.scss';
import * as Yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {},
  companyHeadings: {
    backgroundColor: '#40506b', color: '#fff',
  },
  customInputs: {},
  width100: { width: '100%', },
  blueBtn: { backgroundColor: '#40506b', padding: '6px 16px', fontSize: '14px', },
  perSclass: {
    p: { color: '#000 !important' }
  }
}));

const Pricing = ({ handleNext, handleSkip, isSubmitView, completed, totalStepCount, handleBack, className, setPricingState, pricingState, deleteSupportingMaterial, step, currentStepCompleteStatus, acceptStatus, ...rest }) => {
  const classes = useStyles();
  let file = ''
  var type = '';

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function createData(name, calories, fat, carbs, protein, price) {
    return {
      name,
      calories,
      fat,
      history: [
        { date: '2020-01-05', customerId: '11091700', amount: 3 },
        { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
      ],
    };
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align="center">
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="center">
            {row.companyName}
          </TableCell>
          <TableCell align="center">{row.totalValue.toFixed(2)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Product</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Amount</TableCell>
                      <TableCell align="center">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.products.map((product) => (
                      <TableRow key={product.price}>
                        <TableCell component="th" scope="row" align="center">
                          {product.productName}
                        </TableCell>
                        <TableCell align="center">{product.quantity}</TableCell>
                        <TableCell align="center">{product.price}</TableCell>
                        <TableCell align="center">
                          {product.subTotal.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow key={row.taxValue}>
                      <TableCell align="center"></TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <b>Total Tax</b>
                      </TableCell>
                      <TableCell align="center">{row.taxPercentage}%</TableCell>
                      <TableCell align="center">
                        {row.taxValue.toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={row.taxValue}>
                      <TableCell align="center"></TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <b>Total Discount</b>
                      </TableCell>
                      <TableCell align="center">{row.discountPercentage}%</TableCell>
                      <TableCell align="center">
                        {row.discountValue.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired,
    }).isRequired,
  };

  const rows = [
    createData('Frozen yoghurt', 159, 6.0),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Eclair', 262, 16.0),
    createData('Cupcake', 305, 3.7),
    createData('Gingerbread', 356, 16.0),
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={pricingState}
      validationSchema={
        Yup.object().shape({

        })
      }
      onSubmit={(values) => {
        values.step = step;
        values.type = type
        handleNext(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit} className={'requestly-rfp-form company-information mb-5'}>
          <div className={classes.pageSize + ' company-info-div'}>
            <Card>
              <CardHeader title="Pricing Information"
                subheader="(Vendors pricing information )"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <Divider />
              <CardContent>
                <Grid container id="panel-Pricingss" className="Presentation-Agenda-table">
                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell align="center">Vendor Name</TableCell>
                          <TableCell align="center">Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pricingState.map((row) => (
                          <Row key={row.companyName} row={row} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {step !== 0 ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                        </Button>
                      </Grid>
                    ) : null}
                    {step !== totalStepCount ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                        </Button>
                      </Grid>
                    ) : null}
                    {step !== totalStepCount ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Next
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </form>
      )}
    </Formik>
  );
};

Pricing.propTypes = {
  className: PropTypes.string
};

export default Pricing;
