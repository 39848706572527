import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment, makeStyles, TextField
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldArray, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { history } from 'src/utils/utils';
import 'src/views/shared/css/common.scss';
import * as Yup from 'yup';
import { Payment, PricingModel, Product } from './PricingModel';

const useStyles = makeStyles(() => ({
  root: {},
  companyHeadings: {
    backgroundColor: '#40506b', color: '#fff',
  },
  footerBackground: {
    background: "#f7f7f7"

  },
  CardBorder: {
    borderRadius: "15px"

  },
  customInputs: {},
  width100: { width: '100%', },
  blueBtn: { backgroundColor: '#4688f0', padding: '6px 16px', fontSize: '14px', },
  perSclass: {
    p: { color: '#000 !important' }
  }
}));

const Pricing = ({ handleNext, handleSkip, isSubmitView, completed, totalStepCount, handleBack, className, setPricingState, pricingState, deleteSupportingMaterial, step, currentStepCompleteStatus, companyState, acceptStatus, is_submitted = false, ...rest }) => {
  const classes = useStyles();
  const ref = useRef(null);
  let file = ''
  var type = '';
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);

  const removeProduct = (index) => {
    pricingState.products.splice(index, 1);
    setPricingState(new PricingModel(pricingState));
  }

  const removePayment = (index) => {
    pricingState.payments.splice(index, 1);
    setPricingState(new PricingModel(pricingState));
  }

  const addProduct = () => {
    setPricingState((prevState) => {
      const products = [...prevState.products];
      products.push(new Product());
      return { ...prevState, products };
    });
  }

  const addPayments = () => {
    setPricingState((prevState) => {
      const payments = [...prevState.payments];
      payments.push(new Payment());
      return { ...prevState, payments };
    });
  }

  const updateState = (event) => {
    pricingState[event.target.name] = event.target.value;
    setPricingState(new PricingModel(pricingState));
  }

  const handleProductBlur = (event, index, fieldName) => {
    pricingState.products[index][fieldName] = event.target.value;
    setPricingState(new PricingModel(pricingState));
  }

  const handleProductChange = (event, index, fieldName) => {
    const { products } = pricingState;
    const newProducts = products.slice(0);
    newProducts[index][fieldName] = event.target.value;
    pricingState.products = newProducts
    setPricingState(new PricingModel(pricingState));
  }

  const handlePaymentBlur = (event, index, fieldName) => {
    pricingState.payments[index][fieldName] = event.target.value;
    setPricingState(new PricingModel(pricingState));
  }

  const handlePaymentChange = (event, index, fieldName) => { // type: 1 - Location , 2 - Function 
    const { payments } = pricingState;
    const newPayments = payments.slice(0);
    newPayments[index][fieldName] = event.target.value;
    pricingState.payments = newPayments
    setPricingState(new PricingModel(pricingState));
  }
  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const getProperIcon = (sign) => {
    if (sign == 'USD') {
      return '$'
    } else if (sign == 'EUR') {
      return '€'
    } else if (sign == 'GBP') {
      return '£'
    } else {
      return '$';
    }

  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={pricingState}
      validationSchema={
        Yup.object().shape({

        })
      }
      innerRef={ref}
      onSubmit={(values) => {
        values.step = step;
        values.type = type
        handleNext(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onKeyDown={onKeyDown} className={'requestly-rfp-form company-information mb-5'}>
          <div className="company-info-div">
            <Card className={classes.CardBorder}>
              <CardHeader
                title="Pricing Information"
                subheader="Please provide pricing information"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <Divider />
              <CardContent>
                <Grid container spacing={3} id="panel-Pricingss">
                  <Grid item md={12} xs={12}>
                    <FieldArray
                      name="contacts"
                      render={arrayHelpers => (
                        <div className={classes.width100}>
                          {values.products && values.products.map((product, index) => (
                            <div key={index} className={classes.customInputs}>
                              <Grid item md={4} xs={12}>
                                <TextField
                                  error={Boolean(touched.productName && errors.productName)}
                                  fullWidth
                                  helperText={touched.productName && errors.productName}
                                  label="Product"
                                  value={product.productName}
                                  name="productName"
                                  onBlur={(e) => handleProductBlur(e, index, 'productName')}
                                  onChange={(e) => handleProductChange(e, index, 'productName')}
                                  variant="outlined"
                                  className="pricing-textbox"
                                />
                              </Grid>
                              <Grid item md={2} xs={12}>
                                <TextField
                                  error={Boolean(touched.price && errors.price)}
                                  fullWidth
                                  helperText={touched.price && errors.price}
                                  label="Price"
                                  name="price"
                                  value={product.price.toLocaleString('en-GB')}
                                  onBlur={(e) => handleProductBlur(e, index, 'price')}
                                  onChange={(e) => handleProductChange(e, index, 'price')}
                                  onInput={(e) => {
                                    if (e.target.value < 0) {
                                      alert('not allowed to put value in negative');
                                      e.target.value = 0;
                                    }
                                  }}
                                  className="pricing-textbox"
                                  variant="outlined">
                                </TextField>
                              </Grid>
                              <Grid item md={2} xs={12}>
                                <TextField
                                  error={Boolean(touched.quantity && errors.quantity)}
                                  fullWidth
                                  helperText={touched.quantity && errors.quantity}
                                  label="Quantity"
                                  name="quantity"
                                  type="number"
                                  value={product.quantity}
                                  onBlur={(e) => handleProductBlur(e, index, 'quantity')}
                                  onChange={(e) => handleProductChange(e, index, 'quantity')}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                  }}
                                  className="pricing-textbox"
                                  variant="outlined">
                                </TextField>
                              </Grid>
                              <Grid item md={3} xs={12}>
                                <TextField
                                  error={Boolean(touched.subTotal && errors.subTotal)}
                                  fullWidth
                                  InputProps={{
                                    startAdornment: <InputAdornment id="perSclass5" position="start">{getProperIcon(companyState.currency)}</InputAdornment>,
                                  }}
                                  helperText={touched.subTotal && errors.subTotal}
                                  label="Sub Total"
                                  disabled={true}
                                  name={`contacts.${index}.subTotal`}
                                  value={(product.subTotal).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  className="pricing-textbox"
                                  variant="outlined">
                                </TextField>
                              </Grid>
                              <Grid item md={1} xs={12}>
                                {index > 0 ?
                                  <Button style={{ "minWidth": "30px", "padding": "1px", background: '#fff' }} className={classes.addMinus} type="button" onClick={() => removeProduct(index)}>
                                    &nbsp; <DeleteIcon className="site-delete-icon" style={{ "color": "#333", }} />
                                  </Button>
                                  : null}
                              </Grid>
                            </div>
                          ))}
                          <div className={classes.customInputs}>
                            <Grid item md={4} xs={12}>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                error={Boolean(touched.discountPercentage && errors.discountPercentage)}
                                fullWidth
                                helperText={touched.discountPercentage && errors.discountPercentage}
                                label="Discount Percentage"
                                type="number"
                                onBlur={updateState}
                                onChange={handleChange}
                                className="pricing-textbox"
                                onInput={(e) => {
                                  if (e.target.value < 0 || e.target.value > 100) {
                                    alert('not allowed to put value in negative or more than 100');
                                    e.target.value = 0;
                                  }
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment id="perSclass" position="start">%</InputAdornment>, type: "number"
                                }}
                                name="discountPercentage"
                                value={values.discountPercentage}
                                variant="outlined">
                              </TextField>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <TextField
                                error={Boolean(touched.discountValue && errors.discountValue)}
                                fullWidth
                                helperText={touched.discountValue && errors.discountValue}
                                label="Discount"
                                className="pricing-textbox"
                                InputProps={{
                                  startAdornment: <InputAdornment id="perSclass1" position="start">{getProperIcon(companyState.currency)}</InputAdornment>,
                                }}
                                disabled={true}
                                name="discountValue"
                                value={(values.discountValue).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                variant="outlined">
                              </TextField>
                            </Grid>
                            <Grid item md={1} xs={12}>
                            </Grid>
                          </div>
                          <div className={classes.customInputs}>
                            <Grid item md={4} xs={12}>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <TextField
                                error={Boolean(touched.taxPercentage && errors.taxPercentage)}
                                fullWidth
                                helperText={touched.taxPercentage && errors.taxPercentage}
                                label="Tax Percentage"
                                type="number"
                                onBlur={updateState}
                                onChange={handleChange}
                                name="taxPercentage"
                                className="pricing-textbox"
                                onInput={(e) => {
                                  if (e.target.value < 0 || e.target.value > 100) {
                                    alert('not allowed to put value in negative or more than 100');
                                    e.target.value = 0;
                                  }
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment id="perSclass2" position="start">%</InputAdornment>,
                                }}
                                value={values.taxPercentage}
                                variant="outlined">
                              </TextField>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <TextField
                                error={Boolean(touched.taxPercentage && errors.taxPercentage)}
                                fullWidth
                                InputProps={{
                                  startAdornment: <InputAdornment id="perSclass3" position="start">{getProperIcon(companyState.currency)}</InputAdornment>,
                                }}
                                helperText={touched.taxPercentage && errors.taxPercentage}
                                label="Tax"
                                disabled={true}
                                name="taxValue"
                                value={(values.taxValue).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                className="pricing-textbox"
                                variant="outlined">
                              </TextField>
                            </Grid>
                            <Grid item md={1} xs={12}>
                            </Grid>
                          </div>
                          <div className={classes.customInputs}>
                            <Grid item md={8} xs={12}>
                              <Button style={{ "padding": "6px 16px", "fontSize": "14px", }} className={classes.blueBtn} color="secondary" variant="contained" type="button" onClick={addProduct}>
                                Add Product
                              </Button>
                            </Grid>
                            <Grid item md={3} xs={12}>
                              <TextField
                                error={Boolean(touched.totalValue && errors.totalValue)}
                                fullWidth
                                helperText={touched.totalValue && errors.totalValue}
                                label="Total Value"
                                className="pricing-textbox"
                                InputProps={{
                                  startAdornment: <InputAdornment id="perSclass4" position="start">{getProperIcon(companyState.currency)}</InputAdornment>,
                                }}
                                disabled={true}
                                name="totalValue"
                                value={(values.totalValue).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                variant="outlined">
                              </TextField>
                            </Grid>
                            <Grid item md={1} xs={12}>
                            </Grid>
                          </div>
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Accordion className="requestly-fin-info-tab" style={{ boxShadow: 'none' }}>
                <AccordionSummary className="requestly-tab-height" expandIcon={<ExpandMoreIcon />} aria-controls="panel-pricing-content" id="panelpPricing" >
                  <Typography className={classes.heading}>Payment Schedule</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <FieldArray
                            name="timelineItems"
                            render={arrayHelpers => (
                              <div className={classes.width100}>
                                {values.payments && values.payments.map((payment, index) => (
                                  <div key={index} className={classes.customInputs}>
                                    <Grid item md={6} xs={12}>
                                      <TextField
                                        error={Boolean(touched.amount && errors.amount)}
                                        fullWidth
                                        helperText={touched.amount && errors.amount}
                                        name="amount"
                                        className="pricing-textbox"
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start" id="perSclass5">{getProperIcon(companyState.currency)}</InputAdornment>,
                                        }}
                                        label="Payment Amount"
                                        onBlur={(e) => handlePaymentBlur(e, index, 'amount')}
                                        onChange={(e) => handlePaymentChange(e, index, 'amount')}
                                        value={
                                          (index > 0)
                                            ?
                                            payment.amount
                                            :
                                            (payment.amount === '' || payment.amount === 0)
                                              ?
                                              values.totalValue
                                              :
                                              payment.amount
                                        }
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={8}>
                                      <TextField
                                        error={Boolean(touched.mileStoneDate && errors.mileStoneDate)}
                                        fullWidth
                                        helperText={touched.mileStoneDate && errors.mileStoneDate}
                                        name="date"
                                        label="Payment Date"
                                        type="date"
                                        value={payment.date}
                                        onBlur={(e) => handlePaymentBlur(e, index, 'date')}
                                        onChange={(e) => handlePaymentChange(e, index, 'date')}
                                        variant="outlined"
                                        className="pricing-textbox"
                                        InputLabelProps={{
                                          shrink: true,
                                        }} />
                                    </Grid>
                                    <Grid item md={1} xs={4} >
                                      {/*  <Button style={{ "minWidth": "40px", "padding": "4px 3px 4px 0px", }} className={classes.addMinus} color="primary" variant="contained" type="button" onClick={() => removePayment(index)}>
                                          &nbsp; <DeleteIcon style={{ "color": "#fff", }} />
                                        </Button> */}
                                      {index > 0 ?
                                        <DeleteIcon className="site-delete-icon" style={{ "color": "grey", }} onClick={() => removePayment(index)} />
                                        : null}
                                    </Grid>
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} align='right'>
                          <Button style={{ "padding": "6px 16px", "fontSize": "14px", }} className={classes.addPlus} color="secondary" variant="contained" type="button" onClick={addPayments}>
                            Add Payment
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Divider />
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {(step !== 0 && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                        </Button>
                      </Grid>
                    ) : null}
                    {(step !== totalStepCount && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                        </Button>
                      </Grid>
                    ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                          </Grid> : ''
                      ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <Grid item md={3} sm={3} xs={3}>
                            <Tooltip
                              title={'You must accept RFP invite to submit the RFP response'}
                              placement="top"
                              arrow
                              onOpen={() => {
                                if (acceptStatus != 1 && !open) {
                                  setOpen(true);
                                }
                              }}
                              onClose={() => setOpen(false)}
                              open={open}
                            >
                              <div onMouseOver={() => {
                                setOpen(true)
                              }}
                                onMouseLeave={() => setOpen(false)}>
                                <Button style={{ width: '100%' }} disabled={acceptStatus == 1 ? false : true} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                              </div>
                            </Tooltip>
                          </Grid> : ''
                      ) : step === totalStepCount ?
                        (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                            </Grid> : ''
                        ) : (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                            </Grid> : ''
                        )}
                    {
                      (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                        <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                      </Grid> : ''
                    }
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </form>
      )}
    </Formik>
  );
};

Pricing.propTypes = {
  className: PropTypes.string
};

export default Pricing;
