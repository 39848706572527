export class VendorResponseModel {
  responses = [];
  vendorId = '';
  constructor(vendorResponses = null) {
    if (vendorResponses) {
      vendorResponses.forEach(vendorResponse => {
        //console.log(vendorResponse); alert('hi')
        // if (vendorResponse.categories !== undefined && vendorResponse.categories.length > 0) {
        this.responses.push(new VendorCategoryDetailModel(vendorResponse));
        // }
      });

    }
  }
}

export class VendorResponseCommercialModel {
  responses = [];

  constructor(vendorResponses = null) {
    if (vendorResponses) {
      vendorResponses.forEach(vendorResponse => {
        this.responses.push(new VendorCategoryDetailCommercialModel(vendorResponse));
      });
    }
  }
}
export class VendorCategoryDetailCommercialModel {
  user = '';
  companyName = '';
  vendorScore = 0;
  total = 0;
  categories = [];
  maxSectionLength = 0;
  rfpId = '';
  id = '';
  vendorId = '';
  type = "commercial";
  constructor(categoryDetail = null) {
    if (categoryDetail) {
      if (categoryDetail && categoryDetail.categories) {
        categoryDetail.categories.forEach(category => {
          if (category.sections.length > this.maxSectionLength) {
            this.maxSectionLength = category.sections.length;
          }
          this.categories.push(new VendorCategoryScoreCommercialModel(category));
          if (category.commercialScore !== undefined) {
            this.vendorScore = this.vendorScore + category.commercialScore;
          } else if (category.technicalScore !== undefined) {
            this.vendorScore = this.vendorScore + category.technicalScore;
          } else {
            this.vendorScore = this.vendorScore + category.technicalScore;
            //this.vendorScore = this.vendorScore + category.vendorScore;
          }
          this.total = this.total + category.total;
        });
      }
      this.user = categoryDetail.user || this.user;
      this.companyName = categoryDetail.companyName || this.companyName;
      this.rfpId = categoryDetail.rfpId || this.rfpId;
      this.id = categoryDetail._id || this.id;
      this.vendorId = categoryDetail.vendorId || this.vendorId;
      //this.type = 'commercial';

    }
  }
}

export class VendorCategoryDetailModel {
  user = '';
  companyName = '';
  vendorScore = 0;
  total = 0;
  categories = [];
  maxSectionLength = 0;
  rfpId = '';
  id = '';
  vendorId = '';
  constructor(categoryDetail = null) {
    if (categoryDetail) {
      if (categoryDetail && categoryDetail.categories) {
        categoryDetail.categories.forEach(category => {
          if (category.sections.length > this.maxSectionLength) {
            this.maxSectionLength = category.sections.length;
          }
          this.categories.push(new VendorCategoryScoreModel(category));
          this.vendorScore = this.vendorScore + category.technicalScore;
          this.total = this.total + category.total;
        });
      }
      this.user = categoryDetail.user || this.user;
      this.companyName = categoryDetail.companyName || this.companyName;
      this.rfpId = categoryDetail.rfpId || this.rfpId;
      this.id = categoryDetail._id || this.id;
      this.vendorId = categoryDetail.vendorId || this.vendorId;
      //this.type = 'technical';
    }
  }
}

export class VendorCategoryScoreCommercialModel {
  categoryName = '';
  technicalScore = 0;
  total = 0;
  sections = [];

  constructor(categoryScore = null) {
    if (categoryScore) {
      if (categoryScore && categoryScore.sections) {

        categoryScore.sections.forEach(section => {
          const sectionInfo = new VendorSectionScoreCommercialModel(section);
          this.sections.push(sectionInfo);
          this.technicalScore = this.technicalScore + sectionInfo.technicalScore;
          this.total = this.total + sectionInfo.total;
        });
      }
      this.categoryName = categoryScore.categoryName || this.categoryName;
    }
  }
}

export class VendorCategoryScoreModel {
  categoryName = '';
  technicalScore = 0;
  total = 0;
  sections = [];

  constructor(categoryScore = null) {
    if (categoryScore) {
      if (categoryScore && categoryScore.sections) {
        categoryScore.sections.forEach(section => {
          const sectionInfo = new VendorSectionScoreModel(section);
          this.sections.push(sectionInfo);
          this.technicalScore = this.technicalScore + sectionInfo.technicalScore;
          this.total = this.total + sectionInfo.total;
        });
      }
      this.categoryName = categoryScore.categoryName || this.categoryName;
    }
  }
}
export class VendorSectionScoreCommercialModel {
  sectionName = '';
  technicalScore = 0;
  total = 0;
  sectionPercentage = 0;
  sectionQuestionAnsweredPercentage = 0;
  totalQuestions = 0;
  totalAnsweredQuestion = 0;
  questions = [];
  constructor(sectionInfo = null) {
    if (sectionInfo) {
      this.sectionName = sectionInfo.sectionName || this.sectionName;
      if (sectionInfo.questions && sectionInfo.questions.length) {
        this.totalQuestions = sectionInfo.questions.length;
        sectionInfo.questions.forEach(question => {
          this.questions.push(new VendorQuestionScoreModel(question))
          if (question.answer !== '') {
            this.totalAnsweredQuestion = this.totalAnsweredQuestion + 1;
          }
        });
      }
      if (sectionInfo.commercialScore !== undefined) {
        this.technicalScore = sectionInfo.commercialScore || this.technicalScore
      } else {
        this.technicalScore = sectionInfo.technicalScore || this.technicalScore

      }
      if (sectionInfo.totalScore !== undefined) {
        this.total = sectionInfo.totalScore || this.technicalScore;
      } else {
        this.total = sectionInfo.total || this.technicalScore;
      }
      this.sectionPercentage = ((this.technicalScore / this.total) * 100).toFixed(2);
      this.sectionQuestionAnsweredPercentage = ((this.totalAnsweredQuestion / this.totalQuestions) * 100).toFixed(2);
    }
  }
}

export class VendorSectionScoreModel {
  sectionName = '';
  technicalScore = 0;
  total = 0;
  sectionPercentage = 0;
  sectionQuestionAnsweredPercentage = 0;
  totalQuestions = 0;
  totalAnsweredQuestion = 0;
  questions = [];
  constructor(sectionInfo = null) {
    if (sectionInfo) {
      this.sectionName = sectionInfo.sectionName || this.sectionName;
      if (sectionInfo.questions && sectionInfo.questions.length) {
        this.totalQuestions = sectionInfo.questions.length;
        sectionInfo.questions.forEach(question => {
          this.questions.push(new VendorQuestionScoreModel(question))
          if (question.answer !== '') {
            this.totalAnsweredQuestion = this.totalAnsweredQuestion + 1;
          }
        });
      }
      this.technicalScore = sectionInfo.technicalScore || this.technicalScore;
      this.total = sectionInfo.totalScore || this.technicalScore;
      this.sectionPercentage = ((this.technicalScore / this.total) * 100).toFixed(2);
      this.sectionQuestionAnsweredPercentage = ((this.totalAnsweredQuestion / this.totalQuestions) * 100).toFixed(2);
    }
  }
}

export class VendorQuestionScoreModel {
  question = '';
  type = '';
  score = 0;
  answer = '';
  answerRating = '-';
  constructor(questionInfo = null) {
    if (questionInfo) {
      this.question = questionInfo.question || this.question;
      this.type = questionInfo.type || this.type;
      this.score = questionInfo.score || this.score;
      this.answer = questionInfo.answer || this.answer;
      this.answerRating = questionInfo.answerRating || this.answerRating;
    }
  }
}

export class VendorFilterDropdownModel {
  vendor = [];
  categories = [];
  sections = [];
  constructor(filterInfo = null) {
    if (filterInfo) {
      this.vendor = filterInfo.vendor || this.vendor;
      this.categories = filterInfo.categories || this.categories;
      this.sections = filterInfo.sections || this.sections;
    }
  }
}

export class VendorFilterDropdownModelCommercial {
  vendor = [];
  categories = [];
  sections = [];
  constructor(filterInfo = null) {
    if (filterInfo) {
      this.vendor = filterInfo.vendor || this.vendor;
      this.categories = filterInfo.categories || this.categories;
      this.sections = filterInfo.sections || this.sections;
    }
  }
}
