import {
  Accordion,
  AccordionDetails, AccordionSummary, Box, Button,
  Card,
  CardContent, Grid,
  TextField
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import QuesAnsService from 'src/services/rfp/ques-ans.service';
import { QuestionAnswerBuyerModel } from './QuestionAnswerBuyerModel';
import './style.scss';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  companyHeadings: {
    backgroundColor: '#4688f0', color: '#fff', padding: '12px 10px', borderRadius: '4px',
  },
  companyHeadings1: {
    backgroundColor: '#4688f0', color: '#000', padding: '12px 10px', borderRadius: '4px',
  },
  qsBtn: {
    marginLeft: '10px',
    marginTop: '35px'
  },
  question: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#707070',
  },
  vendorQuestion: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.17',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#000000',
  },
  removeBottomPadding: {
    marginLeft: "-43px",
    width: "110%",
  },
  removePadding: {
    padding: "0px 21px"
  },
  accordianHeight: {
    '@global': {
      '.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: "30px",
      },
    },
  },

  input: {
    color: "#707070",
    border: "none"
  },
  '@global': {
    '.MuiOutlinedInput-inputMultiline': {
      fontSize: "10px"
    },
  },
  noPadding: {

  }

}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionAnswersBuyer({ questionId, rfpId }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tempAnswerObject, setTempAnswerObject] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [questionValue, setQuestionValue] = React.useState({ question: '' });
  const [hasError, setError] = React.useState(false);
  const [modifyQuestionObject, setModifyQuestionObject] = React.useState({});
  const [questionAnswers, setQuestionAnswers] = React.useState(new QuestionAnswerBuyerModel());
  const quesAnsService = new QuesAnsService();

  React.useEffect(() => {
    getAllQuestionAns();
  }, [])

  React.useEffect(() => {
    if (!tempAnswerObject.length || tempAnswerObject.length === 0) {
      const temp = questionAnswers?.questions?.map(datum => ({
        vendorQuestionId: datum.vendorQuestionId,
        answer: datum.answer ?? null
      }))
      setTempAnswerObject([...temp])
    }
  }, [questionAnswers.questions.length])

  const getAllQuestionAns = () => {
    quesAnsService.getAllQuesAns(rfpId).then(res => {
      if (res?.quesAns) {
        setQuestionAnswers(new QuestionAnswerBuyerModel(res.quesAns));
      }
    })

  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const updateQuestionValue = (event, question, index) => {
    event.stopPropagation();
    questionAnswers.questions[index].answer = event.target.value;
    setQuestionAnswers(new QuestionAnswerBuyerModel(questionAnswers.questions));
  };
  const updateQuestionValueBlur = (event, question, index) => {
    questionAnswers.questions[index].answer = event.target.value;
    setQuestionAnswers(new QuestionAnswerBuyerModel(questionAnswers));
  };

  const updateAnswer = (question) => {
    quesAnsService.saveQuesAns(question).then(res => {
      const index = tempAnswerObject.findIndex(datum => datum.vendorQuestionId === question.vendorQuestionId);
      if (index !== -1) {
        tempAnswerObject[index].answer = question.answer;
      }
      setTempAnswerObject([...tempAnswerObject])
    })
    setQuestionValue(question)
  }

  const changeAskQuestion = (event) => {
    setValue(1);
  };

  const handleCreateQuestion = () => {
    if (questionValue.question) {
      let questionObj = {};
      questionObj.vendorQuestionId = new Date().getTime();
      questionObj.question = questionValue.question;
      questionObj.questionId = questionId;
      questionObj.rfpId = rfpId;
      quesAnsService.saveQuesAns(questionObj).then(res => {
        console.log(res);
      })
      setError(false);
      questionAnswers.questions.push({ question: questionValue.question });
      setQuestionAnswers(new QuestionAnswerBuyerModel(questionAnswers));
      setValue(0);
    } else {
      setError(true);
    }
    // 
  };

  const isChangeMade = (question) => {
    if (!question.answer) {
      return false;
    }
    const index = tempAnswerObject.findIndex(datum =>
      datum.vendorQuestionId === question.vendorQuestionId &&
      datum.answer &&
      question.answer &&
      datum.answer === question.answer
    )
    return !!(index === -1)
  }

  React.useEffect(() => {
    const tempData = {}
    if (questionAnswers?.questions?.length) {
      const temp = questionAnswers?.questions.map((datum, index) => ({
        ...datum,
        index
      }))
      const questionsByCategory = groupBy(questionAnswers.questions, 'categoryName')
      Object.keys(questionsByCategory).forEach(datum => {
        const questionsBySection = groupBy(questionsByCategory[datum], 'sectionName')
        tempData[datum] = questionsBySection;
      });
      setModifyQuestionObject(tempData);
    }
  }, [JSON.stringify(questionAnswers)]);

  console.log(Object.keys(modifyQuestionObject).length === 0 && modifyQuestionObject.constructor === Object, "modifyQuestionObject...");
  return (
    <div>
      <Card>
        <CardContent classes={{ root: classes.removePadding }}>
          <CardContent classes={{ root: classes.removePadding }} >
            <Grid container classes={{ root: classes.removeBottomPadding }}>
              {Object.keys(modifyQuestionObject).length === 0 && modifyQuestionObject.constructor === Object && <Grid item md={12} xs={12} xl={12}><p style={{ margin: 'auto' }}>There Are No Vendor Questions Available</p></Grid>}
              {
                // Object.keys(modifyQuestionObject)
                Object.keys(modifyQuestionObject).map((categoryKey, index) => {
                  return (
                    <Grid item md={12} xs={12} xl={12}>
                      <Accordion key={categoryKey} className="question-answer requestly-fin-info-tab" defaultExpanded={index === 0 ? true : false}>

                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon style={{}} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="requestly-tab-height accordinon-financial-summary"
                          style={{ paddingRight: "8%" }}
                        >
                          <Typography className={classes.heading}>{categoryKey}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container style={{ paddingLeft: '28.5px' }}>
                            {
                              Object.keys(modifyQuestionObject[categoryKey]).map((sectionKey, index) => {

                                return (
                                  <Grid item md={12} xs={12} xl={12} className={index === 0 ? classes.noPadding : classes.Padding}>
                                    <Accordion key={sectionKey} className="question-answer requestly-fin-info-tab" defaultExpanded={index === 0 ? true : false}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="requestly-tab-height accordinon-financial-summary section"
                                        style={{ paddingRight: "8%" }}
                                      >
                                        <Typography className={classes.heading}>{sectionKey}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid
                                          container
                                          style={{ padding: '20px', border: '2px solid #dddddd' }}
                                        >
                                          {
                                            modifyQuestionObject[categoryKey][sectionKey].map(datum => {
                                              return (
                                                <Grid
                                                  item
                                                  xl={12}
                                                  md={12}
                                                  sm={12}
                                                  key={datum.vendorQuestionId}
                                                  style={{ padding: '10px' }}
                                                >
                                                  <Grid
                                                    item
                                                    xl={12}
                                                    md={12}
                                                    sm={12}
                                                    style={{ marginBottom: '5px' }}
                                                  >
                                                    <Typography className={classes.question}>
                                                      Q. {datum.askQuestion} ?
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xl={12}
                                                    md={12}
                                                    sm={12}
                                                    style={{ marginBottom: '12px' }}
                                                  >
                                                    <Typography className={classes.vendorQuestion}>
                                                      VQ. {datum.question} ?
                                                    </Typography>
                                                  </Grid>
                                                  <Grid container>
                                                    <Grid
                                                      item
                                                      xl={10}
                                                      md={10}
                                                      sm={12}
                                                      style={{ marginBottom: '5px' }}
                                                    >
                                                      <TextField
                                                        fullWidth
                                                        multiline
                                                        autoFocus
                                                        margin="dense"
                                                        rows={4}
                                                        value={datum.answer}
                                                        InputProps={{
                                                          className: classes.input
                                                        }}
                                                        onChange={(e) => {
                                                          const index = questionAnswers.questions.findIndex(item => item.vendorQuestionId === datum.vendorQuestionId);
                                                          updateQuestionValue(e, datum, index)
                                                        }}
                                                        label="Answer"
                                                        variant="outlined"
                                                      />
                                                    </Grid>
                                                    {isChangeMade(datum) ? (
                                                      <Grid
                                                        item
                                                        xl={1}
                                                        md={1}
                                                        sm={12}
                                                      >
                                                        <Button
                                                          className={classes.qsBtn}
                                                          variant="contained"
                                                          color="primary"
                                                          onClick={(e) => updateAnswer(datum)}
                                                        >
                                                          Submit
                                                        </Button>
                                                      </Grid>
                                                    ) : null}
                                                  </Grid>
                                                </Grid>
                                              )
                                            })
                                          }
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                })
              }
            </Grid>
          </CardContent>
        </CardContent>
      </Card>
    </div>
  );
}
