import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  footerBackground: {
    background: "#f7f7f7"

  },
  CardBorder: {
    borderRadius: "15px"

  },
  widthFill: { width: '100%' },
  customInputs: { width: '100%' },
  addPlus: {},
  addMinus: {},
  profileImg: {}
}));