/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class LegalService {
    constructor() {
        this.config = new Configuration();
    }

    async addLegalDocument(ndaObj) {
        loaderService.show(true);
        const self = this;
        const userId = localStorage.getItem('userId');
        ndaObj.userId = userId;
        const url = this.config.addLegalDocument;
        const formData = new FormData();
        for (let i = 0; i < ndaObj.file.length; i++) {
            formData.append('legalDocuments', ndaObj.file[i]);
        }
        formData.append('userId', ndaObj.userId);
        formData.append('rfpId', ndaObj.rfpId);
        formData.append('type', ndaObj.type);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return AxiosInstance.post(url, formData, config)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    async getLegalDocuments(rfpId) {
        const self = this;
        loaderService.show(true);
        const userId = localStorage.getItem('userId');
        const url = this.config.getLegalDocument + rfpId + '/' + userId;
        return AxiosInstance.get(url)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    async getAllLegalDocuments(rfpId) {
        const self = this;
        loaderService.show(true);
        const userId = localStorage.getItem('userId');
        const url = this.config.getAllLegalDocument + rfpId + '/' + userId;
        return AxiosInstance.get(url)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }


    async deleteLegalDocuments(fileName, rfpId) {
        let reqObj = {}
        reqObj.fileName = fileName
        const self = this;
        loaderService.show(true);
        const userId = localStorage.getItem('userId');
        reqObj.userId = userId;
        reqObj.rfpId = rfpId;
        const url = this.config.baseUrl + this.config.deleteLegalDocument;
        return AxiosInstance.post(url, reqObj)
            .then((response) => {
                loaderService.show(false);
                if (response.status !== 200) {
                    this.handleResponseError(response);
                }
                return response.data;
            })
            .then((res) => {
                return res;
            })
            .catch((error) => {
                self.handleError(error);
            });
    }

    handleResponseError(response) {
        loaderService.show(false);
        throw new Error(`HTTP error, status = ${response.status}`);
    }

    handleError(error) {
        loaderService.show(false);
        if (error.response) {
            const autoClose = true;
            const keepAfterRouteChange = false
            alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
            //alertService.error('Error hhh', { id: 'left-alert' });
            // Request made and server responded
            console.log(':: error', error.response.data);
            // alert(error.response.data.message);
            console.log(':: error', error.response.status);
            console.log(':: error', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(':: error', error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log(':: Error', error.message);
        }
    }
}

export default LegalService;
