import React from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import BasicRfpConfiguration from './BasicRfpConfiguration';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();

  return (

    <Page className={classes.root + ' requestly-rfp-view'} title="Create RFP">
      <div id="rightContain" className="requestly-rfp-contain">
        <Container maxWidth="md" className="requestly-rfp-container">
          <BasicRfpConfiguration />
        </Container>
      </div>
    </Page>
  );
};

export default SettingsView;
