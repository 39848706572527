import {
  BottomNavigation,
  Box, Container, Grid, makeStyles,
  TablePagination, TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import BusinessIcon from '@material-ui/icons/Business';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { alertService } from 'src/components/toaster/alert.service';
import { history } from 'src/utils/utils';
import { SharedAlert } from 'src/views/shared/components/alert/Alert';
import { loaderService } from '../../components/loader/loader.service';
import Page from '../../components/Page';
import AxiosInstance from '../../interceptor/http-intercepor';
import CompanyDetails from '../AdditionalCompanyInformation/CompanyDetails';
import { CompanyModel } from '../AdditionalCompanyInformation/CompanyModel';
import { ProductInfo } from '../ProductInformation/product-model';
import ProductDetails from '../ProductInformation/ProductDetails';
import CompanyService from './../../services/company/company.service';
import ProductService from './../../services/product/product.service';
import LoginService from './../../services/sigin-signup/login.service';
import LocalStorageService from './../../services/storage/local-storage.service';
import DeleteModal from "./DeleteModal";
import './signup.scss';


const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '27px!important'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    textAlign: 'left!important'
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addProductContainer: {
    cursor: 'pointer', width: '70%', maxWidth: '70%', float: 'left', color: "black"
  },
  container: {
    cursor: 'pointer',
    width: '65%',
    float: 'left',
    color: "black",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  productStatusGreen: {
    position: "relative",
    width: "60px",
    padding: "4px 2px 1px 5px",
    borderRadius: "4px",
    color: "green",
    fontSize: "14px",
    fontWeight: "400",
  },
  productStatusRed: {
    position: "relative",
    width: "80px",
    padding: "6px 2px 2px 4px",
    borderRadius: "4px",
    color: "#F2564C",
    fontSize: "12px",
    fontWeight: "400",
    letterSpacing: "1px"
  },
  statusIcon: {
    position: "relative",
    top: "-2px",
    padding: "2px",
    fontSize: "20px"
  },
  textProduct: {
    color: "#40506b !important",
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "1.43",
    letterSpacing: "0.01071em"
  },
  textProduct2: {
    color: "#40506b !important",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.43",
    letterSpacing: "0.01071em"
  },
  productColor: {
    position: "relative",
    margin: "40px",
    color: "#546e7a"
  },
  filterTextField: {
    '@global': {
      '.MuiOutlinedInput-root': {
        position: "relative",
        height: "48px",
        borderRadius: "4px",
        marginBottom: "28px"
      }
    },
  },

  leftStepPanel: { backgroundColor: '#fff', }
}));

const AfterSignUpInformation = (props) => {
  const [steps, setSteps] = React.useState([]);

  const [companyState, setCompanyState] = React.useState(new CompanyModel());
  const [productState, setProductState] = React.useState(new ProductInfo());
  const localStorageService = LocalStorageService.getService();
  const loginService = new LoginService();
  const productService = new ProductService();
  const companyService = new CompanyService();
  const classes = useStyles();
  const [hasError, setHasError] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [totalProducts, setTotalProducts] = React.useState(0)
  const [activeStep, setActiveStep] = React.useState(0);
  const [add_counter, setAddCounter] = React.useState(1);
  const [publishButton, setPublishButton] = React.useState(false)
  const [deleteProductModal, setDeleteProductModal] = React.useState(false)
  const [productId, setProductId] = React.useState()
  const [id, setId] = React.useState()



  const [alertState, setAlertState] = React.useState({
    open: false,
    severity: 'success',
    displayMessage: 'Hello World'
  });
  const resetAlert = () => {
    setAlertState({
      open: false,
      severity: 'success',
      displayMessage: 'Hello World'
    })
  }
  useEffect(() => {
    getStepData(activeStep)
  }, activeStep);

  const deleteProduct = (index, label) => {
    console.log(label.product_id, "coming");
    setProductId(label.product_id);
    setDeleteProductModal(true)
  }

  const filterProductsApi = async (val) => {
    var token = localStorage.getItem('token');
    var company_id = localStorage.getItem('companyId');
    loaderService.show(true);
    let url = "";
    if (window.location.hostname === 'requestly.co') {
      if (val === "all") {
        url = `https://prod-api.requestly.co/product/getProducts/${company_id}/1/10`
      } else {
        url = `https://prod-api.requestly.co/product/getProducts/${company_id}/1/10?published=${val}`
      }
    } else {
      if (val === "all") {
        url = `https://dev-api.requestly.co/product/getProducts/${company_id}/1/10`
      } else {
        url = `https://dev-api.requestly.co/product/getProducts/${company_id}/1/10?published=${val}`
      }
    }
    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
        }
        return response.data;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
      });
  };

  const filterProductApi = async (val) => {
    var data_array = [{ name: 'Company Information', icon: <BusinessIcon />, product_id: null }, { name: 'Add Product', icon: <AddIcon /> }];
    setPage(0);
    let res = await filterProductsApi(val);
    if (res) {
      res.data.map((prod, index) => {
        data_array.push({
          name: prod.productName, icon: '', product_id: prod._id, isPublished: prod.isPublished, date: prod.productLaunchDate, publisherName: prod.userName
        });
      })
      setSteps(data_array)
      setTotalProducts(res?.total_records ?? 0);
    }
  }

  const filterProducts = (e) => {
    filterProductApi(e.target.value)
  }
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let DateArr = [];
  useEffect(() => {

    steps && steps.map((value) => {
      if (value["date"] !== undefined) {
        const res = moment(value["date"]).format('YYYY-MM-DD').split("-");

        const month = months[parseInt(res[1]) - 1]

        let tempDate = "";
        tempDate = `${res[2]} ${month} ${res[0]}`
        value["date"] = tempDate
      }
    })
  }, [steps])

  const getSteps = () => {
    var data_array = [{ name: 'Company Information', icon: <BusinessIcon />, product_id: null }, { name: 'Add Product', icon: <AddIcon /> }];
    var company_id = localStorage.getItem('companyId');
    if (!company_id || company_id === 'null') {
      setSteps(data_array);
    } else {
      var temp_page = page + 1;
      companyService.getProductList(company_id, temp_page, limit, false).then(
        res => {
          res.data.map((prod, index) => {
            data_array.push({
              name: prod.productName, icon: '', product_id: prod._id, isPublished: prod.isPublished, date: prod.productLaunchDate, publisherName: prod.userName
            });
          })
          setSteps(data_array)
          setTotalProducts(res?.total_records ?? 0);
        }
      ).catch((error) => console.log(':: error', error))
    }
  }

  useEffect(() => {
    const query = queryString.parse(props.location.search);
    console.log(query.start_point);
    if (query.start_point !== undefined) {
      setActiveStep(1);
    } else {
      setActiveStep(props?.location?.state?.step ?? 0)
    }


  }, [])
  useEffect(() => {
    getSteps()
  }, [page, limit, add_counter])

  const getStepData = (step, product) => {
    //alert(step);
    switch (true) {
      case (step === 0):
        companyService.getCompanyByUser(false).then(
          res => {
            if (res.company?.overview) {
              try {
                res.company.overview = JSON.parse(res.company.overview);
              } catch (error) {
                var prdoinfo = res.company.overview.replace('["', '[');
                prdoinfo = prdoinfo.replace('"]', ']');
                prdoinfo = prdoinfo.replace(/\n/g, '');
                if (JSON.parse(prdoinfo) !== undefined) {
                  try {
                    res.company.overview = JSON.parse(prdoinfo);
                  } catch (error) {
                    res.company.overview = ""
                  }
                }
              }

            } else {
              res.company.overview = ""
            }
            setCompanyState(new CompanyModel(res.company))
          }
        ).catch((error) => console.log(':: error ', error))
        break;
      case (step === 1):
        var company_id = localStorage.getItem('companyId');
        var res = {};
        res.companyId = company_id;
        var prdoinfo = '[\"{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"\n\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}\"]';
        prdoinfo = prdoinfo.replace('["', '[');
        prdoinfo = prdoinfo.replace('"]', ']');
        prdoinfo = prdoinfo.replace(/\n/g, '');
        res.productInfo = JSON.parse(prdoinfo)[0];
        setProductState(new ProductInfo(res))
        setPublishButton(true)
        break;
      case (step > 1):
        productService.getProductByUserNew(product.product_id).then(
          res => {
            if (res.product.productInfo === undefined || res.product.productInfo === null) {
              res.product.productInfo = ""
            } else {
              var prdoinfo = res.product.productInfo.replace('["', '[');
              prdoinfo = prdoinfo.replace('"]', ']');
              prdoinfo = prdoinfo.replace(/\n/g, '');
              if (JSON.parse(prdoinfo)[0] !== undefined) {
                res.product.productInfo = JSON.parse(prdoinfo)[0];
              } else {
                var prdoinfo = '[\"{\"blocks\":[{\"key\":\"20cv5\",\"text\":\"\n\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}\"]';
                prdoinfo = prdoinfo.replace('["', '[');
                prdoinfo = prdoinfo.replace('"]', ']');
                prdoinfo = prdoinfo.replace(/\n/g, '');
                res.product.productInfo = JSON.parse(prdoinfo)[0];
              }
            }
            setPublishButton(true)
            setProductState(new ProductInfo(res.product))
          }
        ).catch(err => console.log(':: err', err))
        break;
    }
  }

  const [skipped, setSkipped] = React.useState(new Set());
  const myForm = React.useRef(null);
  const token = localStorageService.getAccessToken();
  const autoClose = true;
  const keepAfterRouteChange = true;

  const isStepOptional = (step) => {
    return true;
  };

  const deleteDataSheet = (fileName, id) => {
    if (window.confirm('Are you sure to delete this file?')) {
      productService.deleteDataSheet(fileName, id).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep, { product_id: id });
        }
      })
    }

  }

  const getStepContent = (step) => {
    switch (true) {
      case (step === 0):
        return (
          <>
            <Container maxWidth="md">
              <CompanyDetails handleCompanySubmit={handleCompanySubmit} handleSave={saveCompanyInfo} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setCompanyState={setCompanyState} companyState={companyState} step={step} danny={'hi'} deleteProductModal={deleteProductModal} />
            </Container></>
        );
      case (step > 0):
        return (
          <Container maxWidth="md">
            <ProductDetails setAddCounter={setAddCounter} handleProductSubmit={handleProductSubmit} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} productState={productState} publishButton={publishButton} setProductState={setProductState} deleteDataSheet={deleteDataSheet} step={step} deleteProductModal={deleteProductModal} />
          </Container>
        );
      default:
        return 'Unknown step';
    }
  }

  const handleTestNext = (values, isValidating) => {

  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleStep = (step, label) => () => {
    setActiveStep(step);
    getStepData(step, label);
    if (label.name === "Add Product") setPublishButton(false)
  };

  const saveCompanyInfo = (values) => {
    if (token !== null && token !== undefined) {
      companyService.addCompany(values, false).then((res) => {
        if (res) {
          alertService.success("Company has been saved successfully.", { autoClose, keepAfterRouteChange })
        }
      })
    }
    else {
      alert('user is not logged in');
    }
  }

  const handleCompanySubmit = (values) => {
    if (token !== null && token !== undefined) {
      companyService.addCompany(values, false).then((res) => {
        if (res) {
          if (res.token) {
            localStorage.setItem('token', res.token)
          }
          if (res.company?._id) {
            localStorage.setItem('companyId', res.company._id)
          }
          alertService.success("Company information has been updated successfully.", { autoClose, keepAfterRouteChange })
        }
      }).error((err) => {
        console.log(':: err', err);
      });
    }
    else {
      alert('user is not logged in');
    }
  };

  const handleProductSubmit = (values) => {
    productService.addProduct(values).then((res) => {
      if (res) {
        window.scrollTo(0, 0)
        alertService.success("Product information has been updated successfully", { autoClose, keepAfterRouteChange })
        setProductState(new ProductInfo());
        setTimeout(() => window.location.href = '/app/additional-information?start_point=add_product', 2000);
      }
    }).error((err) => {
      console.log(':: err', err);
    });
  }

  const handleNext = (values) => {
    switch (values.step) {
      case 0:
        if (token !== null && token !== undefined) {
          companyService.addCompany(values, false).then((res) => {
            if (res) {
              getStepData(1);
              // history.push('/app/dashboard');
              alertService.success("Company has been created successfully.", { autoClose, keepAfterRouteChange })
              let newSkipped = skipped;
              if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
              }
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setSkipped(newSkipped);
            }
          }).error((err) => {
            console.log(':: err', err);
          });
        }
        else {
          alert('user is not logged in');
        }
      case 1:
        productService.addProduct(values).then((res) => {
          if (res) {
            alertService.success("Product information has been updated successfully", { autoClose, keepAfterRouteChange })
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
              newSkipped = new Set(newSkipped.values());
              newSkipped.delete(activeStep);
            }
            history.push('/app/dashboard');
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
          }
        }).error((err) => {
          console.log(':: err', err);
        });
        history.push('/app/dashboard');
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = (step) => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getStepData(activeStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Page className={classes.root} title="Product Information - Add, Edit, Delete your products" >
      <div id="rightContain">

        <Container maxWidth="lg" className="main-wrapper">
          <div>
            <Grid container spacing={0} className={classes.marginTop}>
              <Grid className={classes.leftStepPanel} item md={3} sm={3} xs={12} className="sd_stepper">
                <SharedAlert value={alertState} resetAlert={resetAlert} />
                <Stepper
                  connector={false}
                  nonLinear={true}
                  activeStep={-1}
                  orientation="vertical"
                  style={{
                    height: '80vh',
                    overflow: 'auto',
                    marginBottom: '15px !important',
                    backgroundColor: 'transparent'
                  }}
                >
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    // console.log(label, "label...")
                    return (
                      <>
                        <Step style={{ marginTop: '20px' }}
                          name={label.name}
                          key={label} {...stepProps}>
                          {label.name === "Add Product" ? <Grid>
                            <TextField
                              fullWidth
                              label="Filter"
                              name="companyType"
                              select
                              SelectProps={{ native: true }}
                              variant="outlined"
                              className={classes.filterTextField}
                              onChange={(e) => filterProducts(e)}
                            >
                              <option value='all'>All</option>
                              <option value="1">Published</option>
                              {/*<option value='inprogress'>In-Progress</option>*/}
                              <option value='0'>UnPublished</option>
                            </TextField>

                          </Grid> : ""}
                          <StepLabel
                            onClick={handleStep(index, label)}
                            icon={label.icon}

                            style={{ color: label.isPublished === 1 ? "blue" : "black" }}

                            {...labelProps}
                            className={label.name === "Add Product" ? classes.addProductContainer : classes.container}
                          >
                            <Typography variant="caption" className={label.isPublished === 1 ? classes.textProduct : classes.textProduct2}>{label.name}</Typography>

                          </StepLabel>
                          {id !== index && label.isPublished === 1 ? <span className={classes.productStatusGreen}> Live <FiberManualRecordIcon className={classes.statusIcon} /></span>
                            : ''}
                          {id !== index && label.isPublished === 0 ? <span className={classes.productStatusRed}> Offline <FiberManualRecordIcon className={classes.statusIcon} /></span>
                            : ''}

                          {label.isPublished === 1 ? <span style={{ cursor: 'pointer', width: '15px', maxWidth: '15%', float: 'right' }}><DeleteIcon className="site-delete-icon" style={{ fontSize: '18px' }} onClick={e => deleteProduct(index, label)} /></span> : ""}
                          {label.isPublished === 0 ? <span style={{ cursor: 'pointer', width: '15px', maxWidth: '15%', float: 'right' }}><DeleteIcon className="site-delete-icon" style={{ fontSize: '18px' }} onClick={e => deleteProduct(index, label)} /></span> : ""}


                          {label.isPublished === 1 ? <p style={{ fontSize: "10px", fontWeight: '700', color: '#6f6f6f', paddingTop: "5px" }}>Published on {label.date}  by {label.publisherName}</p> : ""}
                          {label.isPublished === 1 ? <hr /> : ""}
                        </Step>
                      </>
                    );
                  })}
                  {/*  */}
                </Stepper>
                <BottomNavigation>
                  <TablePagination
                    component="div"
                    count={totalProducts}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25]}
                    onChangeRowsPerPage={(event) => {
                      setLimit(event.target.value);
                    }}
                    onChangePage={(event, page) => {
                      setPage(page)
                    }}
                    style={{ overflowY: 'hidden' }}
                  />
                </BottomNavigation>
              </Grid>
              <Grid container className={classes.inputAll} md={9} item sm={9} xs={12}>
                <form action="/" method="POST" ref={myForm}>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed - you&apos; re finished
                      </Typography>
                      <Button onClick={handleReset} className={classes.button}>
                        Reset
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography component="span" variant="body2" className={classes.instructions}>
                        {
                          getStepContent(activeStep)
                        }
                      </Typography>
                      <Box display="flex" justifyContent="flex-end" p={0}>

                      </Box>
                      <div />

                    </div>
                  )}
                </form>

              </Grid>
            </Grid>
          </div>
        </Container>
        {
          deleteProductModal && (
            <DeleteModal
              deleteProductModal={deleteProductModal}
              product={productId}
              closeEditModal={() => {
                setDeleteProductModal(false)
              }
              }
            />
          )
        }
      </div >
    </Page >
  );
};

export default AfterSignUpInformation;
