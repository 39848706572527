import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  ButtonSkip: {
    left: "10px"
  },
  NextButton: {
    left: "5px"
  },
  BackButton: {
    right: "-12px"
  },
  CardBorder: {
    borderRadius: "15px"

  },
  cardScrollArea: {
    //maxHeight: "72vH",
    //overflowX: "scroll",
    //overflowX: "hidden",

  },
  footerBackground: {
    background: "#f7f7f7"
  },
  widthFill: { width: '100%' },
  customInputs: { width: '100%' },
  addPlus: {},
  addMinus: { margin: '0px !important' },
  profileImg: {}
}));