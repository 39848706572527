import actionTypes from "./types";

export const getRfpByUserIdSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_USER_ID_SUCCESS, payload: data
});

export const getRfpByUserIdStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_USER_ID_START,
  payload
});

export const getRfpByUserIdFailed = () => ({
  type: actionTypes.GET_RFP_BY_USER_ID_FAILED
});

//Submit Technical Requirements

export const getRfpByIdSubmitTechnicalSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_SUCCESS, payload: data
});

export const getRfpByIdSubmitTechnicalStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_START,
  payload
});

export const getRfpByIdSubmitTechnicalFailed = () => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_FAILED
});

export const getRfpByIdSubmitTechnicalUpdate = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_UPDATE,
  payload
});

//Submit Update Stepper
export const submitRfpUpdateStepper = (payload) => ({
  type: actionTypes.SUBMIT_RFP_UPDATE_STEPPER,
  payload
});

//Submit Commercial Requirements

export const getRfpByIdSubmitCommercialSuccess = (data) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_SUCCESS, payload: data
});

export const getRfpByIdSubmitCommercialStart = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_START,
  payload
});

export const getRfpByIdSubmitCommercialFailed = () => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_FAILED
});

export const getRfpByIdSubmitCommercialUpdate = (payload) => ({
  type: actionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_UPDATE,
  payload
});