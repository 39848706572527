
export class VendorInviteModel {
  recommendedVendorMNDA = false;
  recommendedVendorMNDA2 = false;
  recommendedVendorMNDA3 = false;
  otherVendorMNDA = false;
  otherVendorMNDA2 = false
  otherVendorMNDA3 = false;
  emails = [];
  companyIds = [];
  selectedProductsNew = {};
  isData = false;
  constructor(vendorInviteInfo = null) {
    if (vendorInviteInfo) {
      this.isData = true;
      this.recommendedVendorMNDA = vendorInviteInfo.recommendedVendorMNDA || this.recommendedVendorMNDA;
      this.recommendedVendorMNDA2 = vendorInviteInfo.recommendedVendorMNDA2 || this.recommendedVendorMNDA2;
      this.recommendedVendorMNDA3 = vendorInviteInfo.recommendedVendorMNDA3 || this.recommendedVendorMNDA3;
      this.otherVendorMNDA = vendorInviteInfo.otherVendorMNDA || this.otherVendorMNDA;
      this.otherVendorMNDA2 = vendorInviteInfo.otherVendorMNDA2 || this.otherVendorMNDA2;
      this.otherVendorMNDA3 = vendorInviteInfo.otherVendorMNDA3 || this.otherVendorMNDA3;
      this.emails = vendorInviteInfo.emails || this.emails;
      this.companyIds = vendorInviteInfo.companyIds || this.companyIds;
      this.selectedProductsNew = vendorInviteInfo.selectedProducts || this.selectedProductsNew;

    }
  }
}
