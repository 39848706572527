
export class ReferencesModel {  
        references = []
        companyName = '';
  constructor(referencesInfo = null){  
          if (referencesInfo) {
                this.companyName = referencesInfo.companyName || this.companyName;
                if (referencesInfo.references && referencesInfo.references.length) {
                        referencesInfo.references.forEach(reference => {
                               this.references.push(new Reference(reference)) 
                        });
                }
          }
  }    
}  

export class Reference {  
        name = '';
        email  =  '';  
        mobile =  '';
        company =  '';
        constructor(referenceInfo = null){  
                if (referenceInfo) {
                        this.name = referenceInfo.name || this.name;
                        this.email = referenceInfo.email || this.email;
                        this.mobile = referenceInfo.mobile || this.mobile;
                        this.company = referenceInfo.company || this.company;
                }
        }    
}