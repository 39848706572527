import React from 'react';

const ErrorComponent = (props) => {
  return (
    <p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required" style={{ color: '#f44336', fontSize: '0.75rem' }}>{props.text}</p>
  );
};


export default ErrorComponent;
