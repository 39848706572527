
export class FinalPresentationModel {  
  presentationType = 'faceToFace';
  faceToFaceType = 'onSiteOffice';
  faceToFaceOnSiteLocation = '';
  remoteLiveType = 'internalConferenceCall';
  conferenceCallDetails = '';
  digitalRecordingFiles = [];
  finalPresenations = [];
  userName = '';
  presentationAgenda = [
        {
                agendaItemName: 'Introduction',
                agendaItemDescription: 'Oppotunity to understand who is at the meeting and their objectives',
                agendaAllocatedTime: 5
        },
        {
                agendaItemName: 'Solution Demo',
                agendaItemDescription: 'Present your proposition to our team',
                agendaAllocatedTime: 35
        },
        {
                agendaItemName: 'POC',
                agendaItemDescription: 'Oppotunity to present POC outcomes, challenges with out Execs',
                agendaAllocatedTime: 30
        },
        {
                agendaItemName: 'Company Intro',
                agendaItemDescription: 'Tell us a little more about your company',
                agendaAllocatedTime: 15
        },
        {
                agendaItemName: 'Q & A',
                agendaItemDescription: 'Final Oppotunity for our team to ask any outstanding question they have',
                agendaAllocatedTime: 15
        }
  ];
  constructor(finalPresentationInfo = null){  
          if (finalPresentationInfo) {
                  this.userName = finalPresentationInfo.userName || this.userName;
                  this.presentationType = finalPresentationInfo.presentationType || this.presentationType;
                  this.faceToFaceType = finalPresentationInfo.faceToFaceType || this.faceToFaceType;
                  this.faceToFaceOnSiteLocation = finalPresentationInfo.faceToFaceOnSiteLocation || this.faceToFaceOnSiteLocation;
                  this.remoteLiveType = finalPresentationInfo.remoteLiveType || this.remoteLiveType;
                  this.conferenceCallDetails = finalPresentationInfo.conferenceCallDetails || this.conferenceCallDetails;
                  this.presentationAgenda = finalPresentationInfo.presentationAgenda || this.presentationAgenda;
                  this.digitalRecordingFiles = finalPresentationInfo.digitalRecordingFiles || this.digitalRecordingFiles;
          }
  }    
}  