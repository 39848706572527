export const companyTypes = [
  {
    value: null,
    label: 'Company Type'
  },
  {
    value: 'educational-institutions',
    label: 'Educational Institutions'
  },
  {
    value: 'government-agency',
    label: 'Government Agency'
  },
  {
    value: 'non-profit',
    label: 'Non Profit'
  },
  {
    value: 'partnership',
    label: 'Partnership'
  },
  {
    value: 'privately-held',
    label: 'Privately Held'
  },
  {
    value: 'public-company',
    label: 'Public Company'
  },
  {
    value: 'self-employed',
    label: 'Self-Employed'
  },
  {
    value: 'sole-proprietorship',
    label: 'Sole Proprietorship'
  }
];

export const currencies = [
  {
    value: null,
    label: 'Select Currency'
  },
  {
    value: "EUR",
    label: "EUR",
  },
  {
    value: "GBP",
    label: "GBP",
  },
  {
    value: "USD",
    label: "USD",
  }];

export const questionType = [{
  value: "yes-no",
  label: "yes/no",
},
{
  value: "detailed",
  label: "detailed",
}
];

export const questionScoringType = [
  {
    value: "-",
    label: "Select Score",
  },
  {
    value: "not-met",
    label: "Not Met",
  },
  {
    value: "partially-met",
    label: "Partially Met",
  },
  {
    value: "fully-met",
    label: "Fully Met",
  }
];

export const contactRoles = [
  {
    value: null,
    label: 'Select Role'
  },
  {
    value: "rfp-manager",
    label: "RFP Manager",
  },
  {
    value: "executive-sponsor",
    label: "Executive Sponsor",
  },
  {
    value: "business-contact",
    label: "Business Contact",
  },
  {
    value: "technical-contact",
    label: "Technical Contact",
  },
  {
    value: "procurement",
    label: "Procurement",
  },
  {
    value: "legal-contact",
    label: "Legal Contact",
  }];
