import { rapid } from './data/processConfiguration';
export class RfpSettingModel {
  rfpName = '';
  processTypeValue = 'rapid';
  scopeValue = 'public';
  stepsValue = rapid;

  constructor(rfpSetting = null) {
    if (rfpSetting) {
      this.rfpName = rfpSetting.rfpName || this.rfpName;
      this.processTypeValue = rfpSetting.processTypeValue || this.processTypeValue;
      this.scopeValue = rfpSetting.scopeValue || this.scopeValue;
      this.stepsValue.commercialEvaluation = rfpSetting.commercialEvaluation || this.stepsValue.commercialEvaluation;
      this.stepsValue.companyInformation = rfpSetting.companyInformation || this.stepsValue.companyInformation;
      this.stepsValue.projectInformation = rfpSetting.projectInformation || this.stepsValue.projectInformation;
      this.stepsValue.technicalRequirements = rfpSetting.technicalRequirements || this.stepsValue.technicalRequirements;
      this.stepsValue.vendorInvite = rfpSetting.vendorInvite || this.stepsValue.vendorInvite;
      this.stepsValue.nda = rfpSetting.nda || this.stepsValue.nda;
      this.stepsValue.vendorShortList = rfpSetting.vendorShortList || this.stepsValue.vendorShortList;
      this.stepsValue.proofOfconceptValue = rfpSetting.proofOfconceptValue || this.stepsValue.proofOfconceptValue;
      this.stepsValue.statementOfWork = rfpSetting.statementOfWork || this.stepsValue.statementOfWork;
      this.stepsValue.finalPresentation = rfpSetting.finalPresentation || this.stepsValue.finalPresentation;
      this.stepsValue.references = rfpSetting.references || this.stepsValue.references;
      this.stepsValue.pricingNegotiations = rfpSetting.pricingNegotiations || this.stepsValue.pricingNegotiations;
      this.stepsValue.contactAgreements = rfpSetting.contactAgreements || this.stepsValue.contactAgreements;
      this.stepsValue.rfpCompletion = rfpSetting.rfpCompletion || this.stepsValue.rfpCompletion;
    }
  }
}
