import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, makeStyles, Slide, Typography } from '@material-ui/core';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
  root: {},
  companyHeadings: { backgroundColor: '#40506b', color: '#fff' },
  myParagraphs: { color: '#000 !important', fontSize: '14px', textAlign: 'justify', float: 'none' },
}));

export default function TermsAndConditions(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onAgree(true);
  };

  (function (d, s, id) {
    var js, tjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    tjs.parentNode.insertBefore(js, tjs);
  }(document, 'script', 'termly-jssdk'));


  return (
    <span>
      <Link
        color="primary"
        component={RouterLink}
        to="#"
        onClick={handleClickOpen}
        underline="always"
        variant="h6"
      >
        Terms and Conditions
      </Link>
      <Dialog
        className="terms-style"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={classes.companyHeadings} id="alert-dialog-slide-title">{"Terms and Conditions"}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.myParagraphs} id="alert-dialog-slide-description">
            <Typography>
              <div name="termly-embed" data-id="3fbf7624-c71c-429e-97e7-d71b37d1980f" data-type="iframe"></div>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" className={classes.button}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}