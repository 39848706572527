/* eslint-disable linebreak-style */

class Configuration {

  //baseUrl = 'http://13.232.216.190:5000/'; // dev server
  // baseUrl = 'http://15.206.88.121:3000/'; // client server
  // baseUrl='http://localhost:5000/';
  baseUrl = (window.location.hostname === 'requestly.co') ? 'https://prod-api.requestly.co/' : 'https://dev-api.requestly.co/';
  // baseUrl = "http://localhost:3000/"

  login = 'users/login';

  signup = 'users/signup';

  me_api = 'users/me';

  verifyEmail = 'users/verify-email/'

  addCompany = 'company/addCompany';

  deleteProduct = 'product/deleteProduct';

  getProducts = 'product/getProducts';

  getRfpProducts = 'rfp/products';

  getLiveProducts = 'product/getProductsList';

  getCompanyByUser = 'company/getCompany/';

  getCompanySuggestion = 'company/getCompanySuggestion/';

  addProduct = 'product/addProduct';

  getProductbyUser = 'product/getProduct/';

  submitRfp = 'rfp/submit-rfp/';

  addRfp = 'rfp/create';

  getRfp = 'rfp/getrfp/';

  getRfpbyId = 'rfp/getrfpbyId/';

  addProject = 'project/addProject';

  getProject = 'project/getProject/';

  addVendor = 'vendor/create';

  getVendor = 'vendor/getvendor/';

  addTechnicalDetail = 'technical/create';

  addInvitedTechnicalDetail = 'invitedTechnicalDetail/create';

  addinvitedCommercialDetail = 'invitedCommercialDetail/create';

  getTechincalDetail = 'technical/getTechnical/';

  addCommercialDetail = 'commercial/create';

  getCommercialDetail = 'commercial/getCommercial/';

  addNDA = 'nda/create';

  getNda = 'nda/getnda/';

  addFinalPresentaion = 'finalPresentation/create';

  getFinalPresentation = 'finalPresentation/getPresentation/';

  getAllFinalPresentation = 'finalPresentation/getAllPresentation/';

  deleteFinalFile = 'finalPresentation/deleteFinalFiles';

  submitFinalPrsentation = 'finalPresentation/addFinalPresentation';

  deleteDataSheet = 'product/deleteSheets';

  publish = 'product/publish'

  deleteMaterialDocument = 'project/deleteMaterial';

  deleteNDA = 'nda/deleteNDA';

  deleteEmail = 'vendor/deleteEmail';

  addReference = 'reference/create';

  getReference = 'reference/getreference/';

  getAllReferences = 'reference/getAllreference/'

  addPricing = 'pricing/create';

  getPricing = 'pricing/getpricing/';

  getAllPricing = 'pricing/getallpricing/'

  addNDAAns = 'ndaAns/create';

  getNdaAns = 'ndaAns/getndaans/';

  getAllNdaAns = 'ndaAns/getallndas/';

  deleteNDAAns = 'ndaAns/deleteNDAans';

  addLegalDocument = 'legal/create';

  getLegalDocument = 'legal/getLegalDocument/';

  getAllLegalDocument = 'legal/getAllLegalDocument/';

  deleteLegalDocument = 'legal/deleteLegalDocument';

  saveAcceptRejectInvite = 'inviteAcceptVendor/create';

  getRFPDashboardById = 'rfpDashboard/getrfpDetail/';

  getRFPTimeline = 'rfpDashboard/getrfpTimeline/';

  getRFPDashboardDetail = 'rfpDashboard/getrfpForDashboard/';

  getRFPVendorResponse = 'rfpDashboard/getVendorResponse/';

  updateRfpCategories = 'rfpDashboard/updateRfpDetails';

  saveQuesAns = 'questionAnswer/create';

  getQuesAns = 'questionAnswer/getQuestionAns/';

  getAllQues = 'questionAnswer/getAllQuestionAns/'

  submitEnquiry = 'enquiry/submit-enquiry/';

  wouldLikeOptions = 'client/would-like-options';

  productDemoOptions = 'client/product-demo-options';

  addProductDemo = 'client/add-product-demo';

  discoveryStage = 'client/discovery-stage';

  addDiscoveryMeeting = 'client/add-discovery-meeting';

  discoveryMeetingList = 'client/discovery-meeting-list';

  productDemoList = 'client/product-demo-list';

  acceptRejectProductDemo = 'client/accept-reject-product-demo';

  acceptRejectDiscoveryMeeting = 'client/accept-reject-meeting';

  creditHistory = 'product/credit-history';

  changeVisitStatus = 'client/change-visit-status';

  subscriptionPlans = 'subscription/plans';

  createCheckoutSession = 'subscription/create-checkout-session';
}
export default Configuration;
