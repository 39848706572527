import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { alertService } from "src/components/toaster/alert.service";
import video from 'src/image/videos_icon.png';
import CompanyService from "src/services/company/company.service";
import EnquiryService from "src/services/enquiry/enquiry.service";
import { renderFileList } from "src/views/shared/components/FileList";
import ConfirmModal from "./ConfirmModal";
import './style.css';

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  ModalBox: {
    marginLeft: "36px",
    marginTop: "36px"
  },
  name: {
    marginTop: "20px",
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    outline: "none",
    borderRadius: "12px",
    width: "370px",
    maxWidth: "370px",
    boxShadow: theme.shadows[5]
  },
  container: {
    position: "relative",
    marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex"
  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: "15px",
    color: "#0C1234 !important",
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "20px"
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  },
  RfpName: {
    width: "100%",
    maxWidth: 360
  },
  buttoncolor: {
    color: "#000000"
  },
  textsize: {
    fontSize: "1rem!important",
    fontWeight: 400
  },
  paper: { textAlign: "center" },
  companyHeadings: { backgroundColor: "#4688f0", color: "#fff" },
  titleTag: { margin: "auto" },
  inputAll: {
    marginTop: "10px !important",
    marginBottom: "8px !important",
    padding: "2px !important"
  },
  listChecks: {},
  footerBox: {},
  radioGrpLine: { display: "contents", textAlign: "center" },
  listCheckline: { display: "block", marginTop: "10px" },
  titleTagTxt: { fontSize: "18px", fontWeight: "800", margin: "auto" },
  ProductOptions: {
  },
  ProductOptions2: {
  },
  discovery: {
    marginTop: "20px"
  },
  questions: {
  },

  questionCard: {
    margin: "0px 20px",
    marginTop: "-17px"
  },
  stageCard: {
    border: "1px solid #87CEEB",
    borderRadius: "4px"
  },
  dropZone: {
    height: "180px"
  },
  wordLength: {
    fontSize: "12px",
    float: "right",
    width: "60%",
    textAlign: "end",
    display: "inline-block"
  },
  errorFileUrl: {
    color: "red",
    fontSize: "10px",
    marginLeft: "5px"
  }
}));

const longText = `
Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.
`;

const status = {
  0: "Pending",
  1: "Accepted",
  2: "Declined"
};

const ProductDemoVendorModal = ({ isDiscovery, productDemoVendorList }) => {
  const history = useHistory();
  const classes = useStyles();

  const [localFile, setLocalFile] = useState([]);
  const [reply, setReply] = useState(null);
  const [meetingLink, setMeetingLink] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [wordLength, setWordLength] = useState(500);
  const [companyName, setCompanyName] = useState();
  const companyId = localStorage.getItem("companyId");
  const [submit, setSubmit] = useState(false);
  const enquiryService = new EnquiryService();
  const companyService = new CompanyService();

  let { id } = useParams();
  let { id2 } = useParams();
  const [submitForm, setSubmitForm] = useState(JSON.parse(isDiscovery || id2));
  const closePopUp = () => {
    history.push("/app/dashboard");
  };

  useEffect(() => {
    companyService.getCompanyByUser(false).then((res) => {
      if (res && res.company) {
        console.log(res);
        setCompanyName(res.company?.companyName);
      }
    });
  }, []);

  const handleSave = status => {
    setModalOpen(false);
    let requestData = {
      status: status,
      _id: productDemoVendorList.id,
      message: reply
    };

    if (status && !submitForm) {
      acceptRejectRequest(requestData);
    } else {
      acceptRejectDM(requestData);
    }
  };

  const handleRequest = status => {
    setSubmit(true);
    if ((localFile?.length > 0 || meetingLink)) {
      let temp = [];
      if (localFile?.length > 0) {
        localFile.map(file => {
          temp.push(file);
        });
      }

      let requestData = {
        status: status,
        _id: productDemoVendorList.id,
        message: reply,
        meetingDoc: temp,
        meetingLinks: meetingLink
      };

      acceptRejectRequest(requestData);
    } else {
      const autoClose = true;
      const keepAfterRouteChange = false;
      alertService.error("Please enter either file url or document", {
        autoClose,
        keepAfterRouteChange
      });
      // ToastNotifyError("Please enter either file url or document.");
    }
  }

  const acceptRejectRequest = requestData => {
    enquiryService
      .acceptRejectProductDemo(requestData)
      .then(res => {
        if (res) {
          const autoClose = true;
          const keepAfterRouteChange = true;
          alertService.success(res?.message, {
            autoClose,
            keepAfterRouteChange
          });
          closePopUp();
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const acceptRejectDM = requestData => {
    enquiryService
      .acceptRejectDiscoveryMeeting(requestData)
      .then(res => {
        if (res.status) {
          const autoClose = true;
          const keepAfterRouteChange = true;
          alertService.success(res.message, {
            autoClose,
            keepAfterRouteChange
          });
          closePopUp();
        }
      })
      .catch(err => console.log(":: err", err));
  };

  let file = [];
  const handleFilesChange = files => {
    let tempFile = [...localFile];
    if (files.length > 0) {
      const fileDate = {
        companyId,
        createdAt: new Date(),
        name: files[files.length - 1].name,
        location: URL.createObjectURL(files[files.length - 1]),
        mimetype: "",
        file: files[files.length - 1]
      };
      tempFile.push(fileDate);
    }
    setLocalFile(files);
    file = tempFile;
  };

  const deleteLocalFile = index => {
    var tempFile = [...localFile];
    tempFile.splice(index, 1);
    setLocalFile(tempFile);
    file = tempFile;
  };

  const getStatus = data => {
    return status[data];
  };

  const getEmail = email => {
    return `mailto: ${email}`;
  }

  return (
    <>
      {submitForm || productDemoVendorList?.type?.value != 3 ? (
        <form
          style={{ position: "relative" }}
        >
          <Card style={{ borderRadius: "13px" }} className="popup2-card">
            <CardHeader
              title={`${submitForm
                ? "Discovery Meeting Request"
                : "Product Demo Request"
                } - ${productDemoVendorList?.prouductName}`}
              className={
                classes.companyHeadings + " requestly-rfp-heading popup2-header"
              }
            />
            <Divider />
            <CardContent
              className="popup2-card-content"
              style={{ maxWidth: "732px", overflowY: "auto", height: "auto" }}
            >
              {(
                <div>
                  <Grid
                    className={classes.item + " popup2-question"}
                    item
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <p style={{ fontWeight: 700, marginTop: "12px", fontFamily: "Roboto, sans- serif", width: "50%", display: "inline-block" }}>
                      Company Name (Buyer)
                      {productDemoVendorList?.companyName && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.companyName} </div>}
                    </p>

                    {productDemoVendorList?.contactName && productDemoVendorList?.status == 1 && <p style={{ fontWeight: 700, marginTop: "12px", right: '50px', float: "right", textAlign: "left", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                      Contact Name
                      {productDemoVendorList?.contactName && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.contactName} </div>}
                    </p>}
                  </Grid>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p
                      className="popup2-answer"
                      style={{ fontWeight: 700, marginTop: "12px", fontFamily: "Roboto, sans- serif", width: "50%", display: "inline-block" }}
                    >
                      Location
                      {productDemoVendorList?.companyLocation && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.companyLocation}</div>}
                    </p>

                    {productDemoVendorList?.contactEmail && productDemoVendorList?.status == 1 && <p style={{ fontWeight: 700, marginTop: "12px", right: '50px', float: "right", textAlign: "left", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                      Contact Email
                      {productDemoVendorList?.contactEmail && <div><a href={getEmail(productDemoVendorList.contactEmail)}>{productDemoVendorList?.contactEmail} </a></div>}
                    </p>}

                  </Grid>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p
                      className="popup2-answer"
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        marginTop: "12px",
                        color: "#707070",
                        marginBottom: "48px",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      Request Date
                      {productDemoVendorList?.createdAt && <div> {moment(productDemoVendorList?.createdAt).format(
                        "DD MMM YYYY"
                      )} </div>}
                    </p>
                  </Grid>

                  <Divider />
                </div>
              )}
              {!submitForm && (
                <Grid className={classes.discovery} container spacing={0}>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 700 }} className="popup2-question">
                      Meeting Type
                    </p>
                    <p
                      className="popup2-answer"
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        marginTop: "3px",
                        color: "#707070",
                        wordBreak: "break-word",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      {productDemoVendorList?.type?.label}
                    </p>
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={0}>
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <p
                    className="popup2-question"
                    style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif" }}
                  >
                    Discovery Stage
                  </p>
                  <p
                    className="popup2-answer"
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      marginTop: "3px",
                      color: "#707070",
                      wordBreak: "break-word",
                      fontFamily: "Roboto, sans- serif"
                    }}
                  >
                    {productDemoVendorList?.stage?.label}
                  </p>
                </Grid>
              </Grid>

              <Grid className={classes.questions} container spacing={0}>
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <p
                    className="popup2-question"
                    style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif" }}
                  >
                    Questions
                  </p>

                  <p
                    className="popup2-answer"
                    style={{
                      fontWeight: 600,
                      fontSize: "14px",
                      marginTop: "3px",
                      color: "#707070",
                      wordBreak: "break-word",
                      fontFamily: "Roboto, sans- serif"
                    }}
                  >
                    {productDemoVendorList?.description ||
                      productDemoVendorList?.question}{" "}
                  </p>
                </Grid>
              </Grid>

              {productDemoVendorList?.status == 0 && (
                <Grid className={classes.questions} container spacing={0}>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p
                      className="popup2-question"
                      style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif", display: "inline-block" }}
                    >
                      Reply
                    </p>

                    <span className={classes.wordLength}>{wordLength}/500</span>
                    <TextField
                      fullWidth
                      multiline
                      value={reply}
                      onChange={val => {
                        setWordLength(500 - val.target.value.length);
                        setReply(val.target.value);
                      }}
                      variant="outlined"
                      className="requestly-rfp-textbox dm-popup-textfield"
                      inputProps={{
                        style: {
                          height: "70px",
                          fontFamily: "Roboto, sans- serif",
                          overflow: "auto",
                        },
                        maxLength: 500
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {productDemoVendorList?.status != 0 &&
                productDemoVendorList?.message && (
                  <Grid className={classes.questions} container spacing={0}>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p
                        className="popup2-question"
                        style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif" }}
                      >
                        Reply
                      </p>
                      <p
                        className="popup2-answer"
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          marginTop: "3px",
                          color: "#707070",
                          wordBreak: "break-word",
                          fontFamily: "Roboto, sans- serif"
                        }}
                      >
                        {productDemoVendorList?.message}
                      </p>
                    </Grid>
                  </Grid>
                )}

              {productDemoVendorList?.status != 0 && (
                <Grid className={classes.item} item md={12} sm={12} xs={12}>
                  <h4 style={{ fontWeight: 800, textAlign: "center", fontFamily: "Roboto, sans- serif" }}>
                    {getStatus(productDemoVendorList?.status)}
                  </h4>
                </Grid>
              )}
            </CardContent>

            {productDemoVendorList?.status == 0 && (
              <Box
                className={classes.footerBox}
                p={2}
                style={{ height: "130px", fontFamily: "Roboto, sans- serif" }}
              >
                <Button
                  className={
                    classes.button +
                    " requestly-rfp-button popup2-button-decline decline-btn"
                  }
                  type="button"
                  variant="contained"
                  style={{
                    float: "left",
                    marginBottom: "26px",
                    width: "143px",
                    backgroundColor: "#ff4d4d",
                    color: "white",
                    textTransform: "none",
                    fontFamily: "Roboto, sans- serif"
                  }}
                  onClick={() => {
                    setModalOpen(true)
                  }}
                >
                  Decline request
                </Button>

                {((!productDemoVendorList?.creditInfo && !productDemoVendorList?.freeCreditInfo) || (productDemoVendorList?.creditInfo?.type != '2' && productDemoVendorList?.creditInfo?.credits < 1) || (productDemoVendorList?.freeCreditInfo && productDemoVendorList?.freeCreditInfo?.credits < 1)) &&
                  <Button
                    className={
                      classes.button +
                      " requestly-rfp-button popup2-button-decline buy-now-btn"
                    }
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: "16%",
                      width: "143px",
                      textTransform: "none",
                      fontFamily: "Roboto, sans- serif",
                      color: "white !important",
                      // border: "1px solid #52B152"
                    }}
                    onClick={() => {
                      localStorage.setItem('isFromMR', window.location.href)
                      history.push(`/app/subscription-plan?productId=${productDemoVendorList?.productId}`);
                    }}>
                    Buy Now
                  </Button>
                }

                <div style={{ display: "inline-block", float: "right" }}>
                  <Button
                    color="primary"
                    className={
                      classes.button +
                      " requestly-rfp-button popup2-button-accept"
                    }
                    type="button"
                    variant="contained"
                    style={{
                      width: "143px",
                      textTransform: "none",
                      fontFamily: "Roboto, sans- serif"
                    }}
                    onClick={() => {
                      handleSave(1);
                    }}
                    disabled={(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.credits > 0 || productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type == '2' || productDemoVendorList?.freeCreditInfo && productDemoVendorList?.freeCreditInfo?.credits > 0) ? false : true}
                  >
                    Accept request
                  </Button>



                  {(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type != 2 || !productDemoVendorList?.creditInfo && productDemoVendorList?.freeCreditInfo || !productDemoVendorList?.creditInfo && !productDemoVendorList?.freeCreditInfo) && <div
                    style={{
                      position: "absolute",
                      right: "4%",
                      marginTop: "10px"
                    }}
                    className="popup2-credits-p"
                  >
                    <p
                      style={{
                        marginBottom: "6px",
                        fontSize: "10px",
                      }}
                      className={!productDemoVendorList?.creditInfo?.credits && 'text-color-red'}
                    >
                      You have {productDemoVendorList?.creditInfo?.credits || 0} PAID credits
                    </p>
                    <p
                      style={{
                        marginBottom: "4px",
                        fontSize: "10px",
                      }}
                      className={!productDemoVendorList?.freeCreditInfo?.credits && 'text-color-red'}
                    >
                      You have {productDemoVendorList?.freeCreditInfo?.credits || 0} FREE credits
                    </p>
                  </div>
                  }
                  {(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type == 2) &&
                    <div style={{
                      position: "absolute",
                      right: "4%",
                      marginTop: "10px"
                    }}
                      className="popup2-credits-p"
                    >
                      <p
                        style={{
                          marginBottom: "6px",
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Enterprise License
                      </p>
                    </div>}
                </div>
              </Box>
            )}
          </Card>
        </form>
      ) : (
        <div>
          <form
            className={"requestly-rfp-form"}
            style={{ position: "relative" }}
          >
            <Card style={{ borderRadius: "13px" }}>
              <CardHeader
                title={`${submitForm
                  ? "Discovery Meeting Request"
                  : "Product Demo Request"
                  } - ${productDemoVendorList?.prouductName}`}
                className={classes.companyHeadings + " requestly-rfp-heading"}
              />
              <Divider />
              <CardContent
                className=""
                style={{
                  maxWidth: "732px",
                  overflowY: "auto",
                  height: "auto",
                  fontFamily: "Roboto, sans- serif"
                }}
              >
                {(
                  <div>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p style={{ fontWeight: 700, marginTop: "12px", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                        Company Name (Buyer)
                        {productDemoVendorList?.companyName && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.companyName} </div>}
                      </p>

                      {productDemoVendorList?.contactName && productDemoVendorList?.status == 1 && <p style={{ fontWeight: 700, marginTop: "12px", right: '50px', float: "right", textAlign: "left", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                        Contact Name
                        {productDemoVendorList?.contactName && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.contactName} </div>}
                      </p>}
                    </Grid>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p style={{ fontWeight: 700, marginTop: "12px", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                        Location
                        {productDemoVendorList?.companyLocation && <div style={{ fontWeight: 400 }}>{productDemoVendorList?.companyLocation}</div>}
                      </p>

                      {productDemoVendorList?.contactEmail && productDemoVendorList?.status == 1 && <p style={{ fontWeight: 700, marginTop: "12px", right: '50px', float: "right", textAlign: "left", width: "50%", display: "inline-block", fontFamily: "Roboto, sans- serif" }}>
                        Contact Email
                        {productDemoVendorList?.contactEmail && <div><a href={getEmail(productDemoVendorList.contactEmail)}>{productDemoVendorList?.contactEmail} </a></div>}
                      </p>}

                    </Grid>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          marginTop: "12px",
                          color: "#707070",
                          marginBottom: "48px",
                          fontFamily: "Roboto, sans- serif"
                        }}
                      >
                        Request Date
                        {productDemoVendorList?.createdAt && <div> {moment(productDemoVendorList?.createdAt).format(
                          "DD MMM YYYY"
                        )} </div>}
                      </p>
                    </Grid>

                    <Divider />
                  </div>
                )}
                <Grid className={classes.discovery} container spacing={0}>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <span
                      style={{
                        fontWeight: 700,
                        marginBottom: "6px",
                        marginTop: "6px",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      Meeting Type
                    </span>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#707070",
                        marginTop: "5px",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      {productDemoVendorList?.type?.label}
                    </p>
                    {productDemoVendorList?.status == 0 && (
                      <Grid item md={12} xs={10} className="dropzone_custom_class">
                        <DropzoneArea
                          maxFileSize="104857600"
                          dropzoneClass={classes.dropZone}
                          acceptedFiles={[
                            ".mpg",
                            ".mp4",
                            ".mov",
                            ".avi",
                          ]}
                          onChange={handleFilesChange}
                          showFileNames={true}
                          getPreviewIcon={(file) => {
                            return (
                              <img role="presentation" height="50" src={video} />
                            );
                          }}
                        />
                      </Grid>
                    )}
                    {localFile?.length ? (
                      <Grid item md={12} xs={12} style={{ marginTop: "15px", fontFamily: "Roboto, sans- serif" }}>
                        {renderFileList(
                          localFile,
                          classes.table,
                          true,
                          deleteLocalFile,
                          false,
                          companyName
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>

                {productDemoVendorList?.meetingDoc && (
                  <Grid item md={12} xs={12} style={{ marginTop: "15px", fontFamily: "Roboto, sans- serif" }}>
                    {renderFileList(
                      productDemoVendorList?.meetingDoc,
                      classes.table,
                      false,
                      false,
                      false
                    )}
                  </Grid>
                )}

                {productDemoVendorList?.status == 0 && (
                  <Grid className={classes.questions} container spacing={0} style={{ marginTop: "10px", fontFamily: "Roboto, sans- serif" }}>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif", display: "inline-block" }}>
                        File Url
                      </p>
                      {submit && localFile?.length == 0 && !meetingLink &&
                        <span className={classes.errorFileUrl}>*Please enter either file url or document</span>}
                      <TextField
                        fullWidth
                        multiline
                        onChange={val => {
                          setMeetingLink(val.target.value);
                        }}
                        value={meetingLink}
                        variant="outlined"
                        className="requestly-rfp-textbox dm-popup-textfield"
                        inputProps={{
                          style: {
                            height: "70px",
                            fontFamily: "Roboto, sans- serif",
                            overflow: "auto",
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {productDemoVendorList?.status != 0 &&
                  productDemoVendorList?.meetingLinks && (
                    <Grid className={classes.questions} container spacing={0} style={{ marginTop: "10px", fontFamily: "Roboto, sans- serif" }}>
                      <Grid
                        className={classes.item}
                        item
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <p style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif", display: "inline-block" }}>
                          File Url
                        </p>
                        {submit && localFile?.length == 0 && !meetingLink &&
                          <span className={classes.errorFileUrl}>*Please enter either file url or document</span>}
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            marginTop: "3px",
                            color: "#707070",
                            wordBreak: "break-word",
                            fontFamily: "Roboto, sans- serif"
                          }}
                        >
                          <a href={productDemoVendorList?.meetingLinks} target="_blank">{productDemoVendorList?.meetingLinks}</a>
                        </p>
                      </Grid>
                    </Grid>
                  )}

                <Grid container spacing={0}>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p
                      style={{
                        fontWeight: 700,
                        marginBottom: "6px",
                        marginTop: "12px",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      Discovery Stage
                    </p>
                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        marginTop: "3px",
                        color: "#707070",
                        wordBreak: "break-word",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      {productDemoVendorList?.stage?.label}
                    </p>
                  </Grid>
                </Grid>

                <Grid className={classes.questions} container spacing={0}>
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif" }}>
                      Questions
                    </p>

                    <p
                      style={{
                        fontWeight: 600,
                        fontSize: "14px",
                        marginTop: "3px",
                        color: "#707070",
                        wordBreak: "break-word",
                        fontFamily: "Roboto, sans- serif"
                      }}
                    >
                      {productDemoVendorList?.description}
                    </p>
                  </Grid>
                </Grid>

                {productDemoVendorList?.status == 0 && (
                  <Grid className={classes.questions} container spacing={0}>
                    <Grid className={classes.item} item md={12} sm={12} xs={12}>
                      <p style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif", display: "inline-block" }}>
                        Reply
                      </p>
                      <span className={classes.wordLength} style={{ display: "inline-block" }} >{wordLength}/500</span>
                      <TextField
                        fullWidth
                        multiline
                        value={reply}
                        onChange={val => {
                          setWordLength(500 - val.target.value.length);
                          setReply(val.target.value);
                        }}
                        variant="outlined"
                        className="requestly-rfp-textbox dm-popup-textfield"
                        inputProps={{
                          style: {
                            height: "70px",
                            fontFamily: "Roboto, sans- serif",
                            overflow: "auto",
                          },
                          maxLength: 500
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                {productDemoVendorList?.status != 0 &&
                  productDemoVendorList?.message && (
                    <Grid className={classes.questions} container spacing={0}>
                      <Grid
                        className={classes.item}
                        item
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <p style={{ fontWeight: 700, marginBottom: "6px", fontFamily: "Roboto, sans- serif" }}>
                          Reply
                        </p>
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            marginTop: "3px",
                            color: "#707070",
                            wordBreak: "break-word",
                            fontFamily: "Roboto, sans- serif"
                          }}
                        >
                          {productDemoVendorList?.message}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                {productDemoVendorList?.status != 0 && (
                  <Grid className={classes.item} item md={12} sm={12} xs={12}>
                    <h4 style={{ fontWeight: 800, textAlign: "center", fontFamily: "Roboto, sans- serif" }}>
                      {getStatus(productDemoVendorList?.status)}
                    </h4>
                  </Grid>
                )}
              </CardContent>

              {productDemoVendorList?.status == 0 && (
                <Box
                  className={classes.footerBox}
                  p={2}
                  style={{ height: "180px", fontFamily: "Roboto, sans- serif" }}
                >
                  <Button
                    className={classes.button + " requestly-rfp-button decline-btn"}
                    type="button"
                    variant="contained"
                    style={{
                      float: "left",
                      marginBottom: "26px",
                      width: "143px",
                      backgroundColor: "#ff4d4d",
                      color: "white",
                      textTransform: "none",
                      fontFamily: "Roboto, sans- serif"
                    }}
                    onClick={() => {
                      setModalOpen(true)
                    }}
                  >
                    Decline request
                  </Button>


                  {((!productDemoVendorList?.creditInfo && !productDemoVendorList?.freeCreditInfo) || (productDemoVendorList?.creditInfo?.type != '2' && productDemoVendorList?.creditInfo?.credits < 1) || (productDemoVendorList?.freeCreditInfo && productDemoVendorList?.freeCreditInfo?.credits < 1)) &&
                    <Button
                      className={
                        classes.button +
                        " requestly-rfp-button popup2-button-decline buy-now-btn"
                      }
                      type="button"
                      variant="contained"
                      style={{
                        marginLeft: "16%",
                        width: "143px",
                        color: "white !important",
                        textTransform: "none",
                        fontFamily: "Roboto, sans- serif",
                        // border: "1px solid #52B152"
                      }}
                      onClick={() => {
                        localStorage.setItem('isFromMR', window.location.href)
                        history.push(`/app/subscription-plan?productId=${productDemoVendorList?.productId}`);
                      }}>
                      Buy Now
                    </Button>
                  }

                  <div style={{ display: "inline-block", float: "right" }}>
                    <Button
                      color="primary"
                      className={
                        classes.button +
                        " requestly-rfp-button popup2-button-accept"
                      }
                      type="button"
                      variant="contained"
                      style={{
                        width: "143px",
                        textTransform: "none",
                        fontFamily: "Roboto, sans- serif"
                      }}
                      onClick={() => {
                        handleRequest(1);
                      }}
                      disabled={(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.credits > 0 || productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type == '2' || productDemoVendorList?.freeCreditInfo && productDemoVendorList?.freeCreditInfo?.credits > 0) ? false : true}
                    >
                      Accept request
                    </Button>

                    {(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type != 2 || productDemoVendorList?.freeCreditInfo && !productDemoVendorList?.creditInfo || !productDemoVendorList?.freeCreditInfo && !productDemoVendorList?.creditInfo) && <div
                      style={{
                        textAlign: "end",
                        marginTop: "10px",
                        marginRight: "10px",
                        position: "relative",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "6px",
                          fontSize: "10px",
                          marginTop: "10px"
                        }}

                        className={!productDemoVendorList?.creditInfo?.credits && 'text-color-red'}
                      >
                        You have {productDemoVendorList?.creditInfo?.credits || 0} PAID credits
                      </p>

                      <p
                        style={{
                          marginBottom: "4px",
                          fontSize: "10px",
                          marginTop: "10px"
                        }}
                        className={!productDemoVendorList?.freeCreditInfo?.credits && 'text-color-red'}
                      >
                        You have {productDemoVendorList?.freeCreditInfo?.credits || 0} FREE credits
                      </p>
                    </div>
                    }
                    {(productDemoVendorList?.creditInfo && productDemoVendorList?.creditInfo?.type == 2) &&
                      <div style={{
                        position: "absolute",
                        right: "4%",
                        marginTop: "10px"
                      }}
                        className="popup2-credits-p"
                      >
                        <p
                          style={{
                            marginBottom: "6px",
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          Enterprise License
                        </p>
                      </div>
                    }
                  </div>
                </Box>
              )}
            </Card>
          </form>
        </div>
      )
      }
      {
        modalOpen && (
          <ConfirmModal
            modalOpen={modalOpen}
            companyName={productDemoVendorList?.companyName}
            closePopUp={() => {
              setModalOpen(false)
            }}
            onConfirmClick={() => handleSave(2)}
          />
        )
      }
    </>
  );
};

export default ProductDemoVendorModal;
