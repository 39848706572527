import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Toolbar,
  Typography
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import algoliasearch from "algoliasearch";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { hotjar } from 'react-hotjar';
import { loaderService } from "src/components/loader/loader.service";
import EnquiryService from "src/services/enquiry/enquiry.service";
import { algoliaIndex } from "src/utils/algoliadata";
import { history } from "src/utils/utils";
import doc from "../../image/doc_icon_l.png";
import facebookIcon from "../../image/facebook-f.svg";
import jpg from "../../image/jpg_icon_l.png";
import linkedinIcon from "../../image/linkedin.svg";
import mov from "../../image/mov_icon_l.png";
import mpg from "../../image/mpg_icon_l.png";
import pdf from "../../image/pdf_icon_l.png";
import ppt from "../../image/ppt_icon_l.png";
import requestly_logo from "../../image/requestly_logo_white.png";
import requestly_icon from "../../image/requestly_r_white_transparent.svg";
import twitterIcon from "../../image/twitter.svg";
import vimeoIcon from "../../image/vimeo-v.svg";
import xlss from "../../image/xls_icon_l.png";
import youtubeIcon from "../../image/youtube.svg";
import { getUserDetail } from '../../services/rfp/UserDetailService';
import '../shared/css/footer.scss';
import ProductService from "./../../services/product/product.service";
import "./css/product.scss";
import "./style.css";

ReactGA.initialize('UA-205584867-1');
hotjar.initialize('2566851', 6);
const searchClient = algoliasearch(
  "JE3UA8IZ0R",
  "dfb17da784818795fc420e50bfb6cfdb"
);

const searchIndex = searchClient.initIndex(algoliaIndex);

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    flexGrow: 1,
    fontFamily: '"Spartan", sans-serif!important'
  },
  videoStyle: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: -1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  headerStyle: {
    background: "#F2564C"
  },
  title: {
    cursor: "pointer",
    color: "#fff",
    fontSize: "3rem",
    fontWeight: 600,
    padding: "0.2em 0",
    fontFamily: '"Spartan", sans-serif!important'
  },
  productName: {
    color: "#40506b",
    padding: "0.4em 0",
    fontSize: "2rem",
    fontFamily: '"Spartan", sans-serif!important',
    fontWeight: 700,
    display: "block",
    verticalAlign: "middle",
  },
  iconSize: {
    fontSize: "2.1rem",
    color: "#707070"
  },
  companyName: {
    fontSize: "10px",
    display: "inherit",
    color: "white",
    textAlign: "right",
    backgroundColor: "#7c8591",
    fontFamily: '"Spartan", sans-serif!important',
    letterSpacing: ".2rem",
    padding: "0.5em 1.3em",
    borderRadius: "0 2px 2px 0",
    position: "absolute",
    top: "2.5rem",
    right: 0
  },
  getStartedButtonStyle: {
    background: "#F2564C",
    color: "#fff"
  },
  rightNav: {
    width: "100%",
    paddingLeft: "1rem"
  },
  rightNavText: {
    color: "#000",
    fontFamily: '"Spartan", sans-serif!important'
  },
  navList: {
    listStyle: "none",
    padding: "1em 0,",
    margin: 0,
    display: "flex",
    justifyContent: "flex-end"
  },
  navListItem: {
    padding: "0.5em 0",
    marginLeft: "3em",
    color: "black",
    position: "relative",
    cursor: "pointer",
    "&::after": {
      content: '""',
      display: "block",
      height: "3px",
      width: 0,
      backgroundColor: "#F2564C",
      position: "absolute",
      bottom: 0,
      transition: "0.2s"
    }
  },
  searchWrapper: {
    width: "50vW",
    position: "absolute",
    top: "40%"
  },
  searchBarWrap: {
    textAlign: "left"
  },
  paper: {
    textAlign: "center",
    padding: "2px 5px 2px 5px",
    background: "#000",
    color: "#fff"
  },
  footerWrapper: {
    position: "absolute",
    bottom: "2rem",
    width: "70vW",
    textAlign: "center",
    justifyContent: "center"
  },
  iconStyle: {
    fontSize: "2.5rem",
    verticalAlign: "middle"
  },
  footerText: {
    background: "transparent",
    border: 0,
    boxShadow: "unset",
    fontSize: "3rem",
    color: "#fff",
    fontWeight: 200
  },
  paperTextOur: {
    background: "#f2564c"
  },
  paperText: {
    verticalAlign: "middle",
    display: "inline"
  },
  autoCompleteTextStyle: {
    color: "#000",
    fontSize: ".8rem"
  },
  sliderBg: {
    background: "url(/static/media/s1.3a39b803.png)",
    backgroundSize: "cover",
    padding: "0px 0 0 10px",
    display: "block",
    backgroundRepeat: "no-repeat",
    // height: '15rem',
    // minHeight: '15rem',
    backgroundPosition: "center",
    overflow: "hidden"
  },
  logoSecCover: {
    padding: 0
    // display:"flex"
    // paddingTop: '15px'
  },
  companyInfoSec: {
    display: "block",
    float: "left",
    position: "absolute",
    bottom: -9,
    marginBottom: ".2rem"
  },
  companyInfoLink: {
    padding: ".8rem",
    background: "#f2564c",
    color: "#fff",
    borderRadius: ".5rem .5rem 0rem .0rem",
    "&:hover": {
      color: "#fff"
    }
  },
  overviewStyle: {
    paddingTop: "0rem"
  },
  overviewTextStyleDownload: {
    display: "block",
    // paddingLeft: "19px",
    fontSize: ".7rem"
  },
  overviewTextStyleDownloadNoImage: {
    display: "block",
    // paddingLeft: "19px",
    paddingTop: "90px",
    fontSize: ".7rem"
  },
  overviewTextStyle: {
    fontSize: ".7rem"
    // dis[lay:
  },
  companyIntroBlock: {
    position: "absolute",
    top: "-34px",
    left: "19px",
    display: "block",
    background: "transparent",
    padding: "0px 17px"
  },
  boxBorder: {
    position: "relative",
    bottom: "0px",
    backgroundColor: "red"
  },
  boxborder: {
    border: "1px solid gainsboro",
    minHeight: "80px",
    margin: "8px",
    padding: "0px 10px 5px 15px",
    borderRadius: "10px"
  },
  boxborderDownload: {
    border: "1px solid gainsboro",
    margin: "8px",
    padding: "0px 10px 5px 15px",
    height: "100%",
    borderRadius: "10px"
  },
  introText: {
    width: "6.5em",
    fontSize: "20px !important",
    marginTop: "-10px",
    marginLeft: "24px",
    background: "#f4f6f8"
  },
  introTextKeywords: {
    width: "6em",
    fontSize: "20px !important",
    marginTop: "-10px",
    marginLeft: "24px",
    background: "#f4f6f8"
  },
  introTextKeywordsCustomerRoles: {
    width: "8.5em",
    fontSize: "20px !important",
    marginTop: "-10px",
    marginLeft: "24px",
    background: "#f4f6f8"
  },
  introTextKeywordsCustomer: {
    width: "11em",
    fontSize: "20px !important",
    marginTop: "-10px",
    marginLeft: "24px",
    background: "#f4f6f8"
  },
  chipsStyle: {
    margin: ".5rem",
    fontSize: "12px"
  },
  boxborder4: {
    border: "1px solid gainsboro",
    margin: "8px",
    borderRadius: "10px",
    minHeight: "92px"
  },
  detailsText: {
    width: "5rem",
    background: "#f4f6f8",
    marginTop: "-10px",
    marginLeft: "25px",
    fontSize: "20px !important"
  },
  CompetitorText: {
    width: "10rem",
    background: "#f4f6f8",
    marginTop: "-10px",
    marginLeft: "25px",
    fontSize: "20px !important"
  },
  detailsTextDetail: {
    padding: "0px 10px 10px 10px",
    margin: 0,
    fontSize: ".7rem"
  },
  noPadding: {
    paddingLeft: "28px",
    marginTop: "45px"
  },
  autoCompletePopperProduct: {
    width: "30vW!important",
    maxWidth: "30vW!important",
    top: "-3px !important",
    left: "-15%!important",
  },
  playIcon: {
    position: "absolute",
    top: "32%",
    left: "49%",
    fontSize: "4rem",
    cursor: "pointer"
  },
  dialogTitle: {
    padding: 0,
    textAlign: "right"
  },
  engageNewRelicText: {
    height: "44px",
    fontFamily: "Spartan",
    fontSize: "17px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.5em",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    cursor: "pointer",
  },
  engageNewRelicBtn: {
    width: "180px",
    height: "80px",
    margin: "50px 0 113px 195px",
    padding: "16px 28px 7px",
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #dddddd",
    backgroundColor: "#f2564c",
    position: "fixed",
    borderRadius: "10px 0px 0px 10px",
    zIndex: 9999
  },
  // videoGrid: {
  //   position: "relative",
  //   marginBottom: "45px"
  // },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  downloadSection: {
    float: "left",
    margin: "10px",
    padding: "10px 3px"
  },
  fileHover: {},
  userName: {
    top: "24%",
    left: "88%",
    cursor: "pointer",
    position: "absolute",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "140px",
  },
  name: {
    position: "relative",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    height: "3em",
    width: "100%"
  }
}));

const ProductPage = props => {
  const enquiryService = new EnquiryService();
  const [querySearch, setQuerySearch] = useState("");
  const [value, setValue] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  let isMounted = true;
  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  const [options, setOptions] = useState([]);
  const [wouldLikeSelectedOption, setWouldLikeSelectedOption] = useState([]);
  const [product, setProduct] = useState({});
  const [popupStep, setPopupStep] = useState(0);
  const [user_detail, setUserDetail] = useState(null);


  const [FileFullName, setFileFullName] = useState(false);
  const [fileId, setFileId] = useState();
  const [isError, setError] = useState(false);

  const productService = new ProductService();
  const [wouldLikeOptions, setWouldLikeOptions] = useState([]);
  // const wouldLikeOptions = [{
  //   value: 1,
  //   label: '15min discovery meeting'
  // }, {
  //   value: 2,
  //   label: 'Product demo'
  // }, {
  //   value: 3,
  //   label: 'start a Proof of Value/Proof of Concept'
  // }, {
  //   value: 4,
  //   label: 'Access to technical requirements template'
  // }, {
  //   value: 5,
  //   label: 'Request of Information'
  // }, {
  //   value: 6,
  //   label: 'Formal Request for Proposal'
  // }]
  const TabPanel = props => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
  useEffect(() => {
    const abortController = new AbortController();
    // eslint-disable-next-line react/prop-types
    // console.log(props.location.search, "props.location.search...")
    const query = queryString.parse(props.location.search);

    // setQuerySearch(query.search);
    async function fetchData() {
      // You can await here
      await searchIndex
        .getObject(query.search)
        .then(async res => {
          // const productDetail = hits[0];
          if (res && res !== undefined) {
            setProduct(res);
            setQuerySearch(res.product);
          }
        })
        .catch(err => {
          console.log(err, "err");
        });
      // ...
    }
    fetchData();
    return () => abortController.abort();
  }, [props]);

  useEffect(() => {
    getUserDetail().then((success) => {
      setUserDetail(success);
    }).catch(() => {
      setUserDetail(false);
    });
  }, [])

  const isUrl = (s) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
  };

  const goToCompanyPage = async val => {
    console.log(val, "valCompany...");
    isMounted = false;
    if (val && val.product) {
      const query = {
        search: val.objectID
        //category: [].join(','),
        //subCategory: [].join(','),
        //allKeywords: [].join(','),
        // company: [].join(','),
      };
      console.log(query, "query");
      await history.push(
        `/product/${val.product
          .toLowerCase()
          .replace(/\s+/g, "-")}?${queryString.stringify(query)}`,
        {
          data: {
            params: val
          }
        }
      );
    } else if (val && val.allKeywords) {
      const query = {
        search: '',
        category: [].join(','),
        subCategory: [].join(','),
        allKeywords: [`allKeywords:${val.allKeywords}`].join(','),
        company: [].join(','),
        page: 0
      };
      await history.push(`/category/${(val.allKeywords.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.category) {
      const query = {
        search: '',
        category: [`category:${val.category}`].join(','),
        subCategory: [].join(','),
        allKeywords: [].join(','),
        company: [].join(','),
        page: 0
      };
      await history.push(`/category/${(val.category.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.subCategory) {
      const query = {
        search: '',
        category: [].join(','),
        allKeywords: [].join(','),
        company: [].join(','),
        subCategory: [`subCategory:${val.subCategory}`].join(','),
        page: 0
      };
      await history.push(`/search?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.targetPersona) {
      const query = {
        search: '',
        category: [].join(','),
        subCategory: [].join(','),
        allKeywords: [].join(','),
        targetPersona: [`targetPersona:${val.targetPersona}`].join(','),
        company: [].join(','),
        page: 0
      };
      await history.push(`/category/${(val.targetPersona.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.company) {
      const query = {
        search: '',
        category: [].join(','),
        subCategory: [].join(','),
        allKeywords: [].join(','),
        targetPersona: [].join(','),
        company: [`company:${val.company}`].join(','),
        page: 0
      };
      await history.push(`/category/${(val.company.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else {
      const query = {
        search: val,
        category: [].join(","),
        subCategory: [].join(","),
        allKeywords: [].join(","),
        company: [].join(","),
        page: 0
      };
      await history.push(`/search?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    }
  };

  useEffect(() => { }, [isMounted, isDialogOpen]);

  const searchFromAlgolia = async val => {
    const abortController = new AbortController();
    if (isMounted) {
      // setSearchInputValue(val);
      if (val.length < 3) {
        return;
      }
      await searchIndex
        .search(val, {
          attributesToRetrieve: ["product", "category", "subCategory", "logo"],
          facets: ["category", "subCategory"],
          hitsPerPage: 8
        })
        .then(async ({ hits, facets }) => {
          // console.log(res, "res...")
          hits = hits.slice(0, 4);
          const products = hits;
          const { category, subCategory } = facets;
          // console.log(facets, "facets")
          // console.log(hits, "hits")
          // console.log(category[0], "category")
          if (category) {
            // eslint-disable-next-line no-unused-expressions
            Object.keys(category)[0]
              ? products.push({
                category: Object.keys(category)[0]
              })
              : "";
            // eslint-disable-next-line no-unused-expressions
            Object.keys(category)[1]
              ? products.push({
                category: Object.keys(category)[1]
              })
              : "";
          }
          if (subCategory) {
            // eslint-disable-next-line no-unused-expressions
            Object.keys(subCategory)[0]
              ? products.push({
                subCategory: Object.keys(subCategory)[0]
              })
              : "";
            // eslint-disable-next-line no-unused-expressions
            Object.keys(subCategory)[1]
              ? products.push({
                subCategory: Object.keys(subCategory)[1]
              })
              : "";
          }
          setOptions(products);
        });
    }
    // eslint-disable-next-line consistent-return
    return () => abortController.abort();
  };
  const classes = useStyles();
  // const flexContainer = {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   padding: 0
  // };

  const userName = localStorage.getItem("user");

  const goToHome = () => history.push("/");

  const handlePlayIcon = () => {
    setIsDialogOpen(true);
  };

  const toggleHover = e => {
    console.log("in", e);
    setFileId(e);

    setFileFullName(!FileFullName);
  };

  const getWouldLikeOptions = () => {
    loaderService.show(true);
    productService
      .getWouldLikeOptions()
      .then(res => {
        if (res && res.data && res.data.length > 0) {
          setWouldLikeOptions(res.data);
          loaderService.show(false);
        }
      })
      .catch(err => console.log(":: err", err));
  };

  const _submitEnquiry = () => {
    localStorage.removeItem("isPopupOpen");
    if (
      localStorage.getItem("userId") &&
      localStorage.getItem("userId") !== ""
    ) {
      if (wouldLikeSelectedOption.toString() === "1") {
        history.push(
          `/app/discovery-meeting/${product.objectID}/${product.company}`
        );
      } else if (wouldLikeSelectedOption.toString() === "2") {
        history.push(
          `/app/product-discovery/${product.objectID}/${product.company}`
        );
      } else {
        history.push(`/app/create-rfp`);
      }
    } else {
      localStorage.setItem('selectedOption', wouldLikeSelectedOption);
      localStorage.setItem('company', product.company)
      localStorage.setItem('productId', product.objectID)
      props.history.push("/auth/login");
    }
  };
  const isPopupOpen = localStorage.getItem("isPopupOpen");
  useEffect(() => {
    if (isPopupOpen && isPopupOpen !== "") {
      setPopupStep(1);
      getWouldLikeOptions();
    }
  }, [isPopupOpen]);

  const redirectTo = () => {
    if (localStorage.getItem("selectedOption")) {
      if (localStorage.getItem("selectedOption") === "1") {
        history.push(
          `/app/discovery-meeting/${product.objectID}/${product.company}`
        );
      } else if (localStorage.getItem("selectedOption") === "2") {
        history.push(
          `/app/product-discovery/${product.objectID}/${product.company}`
        );
      } else {
        history.push(`/app/create-rfp`);
      }
    }

    localStorage.removeItem("isPopupOpen");
    localStorage.removeItem("selectedOption");
  }

  product &&
    product.videoLinks &&
    product.videoLinks.map((value, key) => {
      if (value.length > 0) {
        product.videoLinks[0] = value.replace("watch?v=", "embed/");
      }
    });

  console.log(product, "product");

  return (
    <div className={classes.root}>
      {product.product !== undefined ? (
        <Helmet>
          <title>{product.product + " | Everything you need to know about " + product.product + " and " + product.category + " software"}</title>
          <meta name="description" content={product.description} />
          <meta name="title" content={product.product} />
        </Helmet>
      ) : (
        ""
      )}

      <AppBar className={classes.headerStyle} position="static">

        {
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
        {
          hotjar.stateChange(window.location.pathname + window.location.search)
        }
        <Toolbar>

          <img class="logoImage" onClick={() => goToHome()} src={requestly_logo} />
          <img class="logoIcon" onClick={() => goToHome()} src={requestly_icon} />

          <div className={classes.rightNav + ' mobile-right-nav'}>
            <nav style={{ position: "relative" }}>
              <div className={`${classes.searchBarWrap} searchBarProduct`}>
                <Autocomplete
                  id="custom-input-demo"
                  value={querySearch}
                  freeSolo
                  classes={{ popper: classes.autoCompletePopperProduct }}
                  getOptionLabel={option =>
                    typeof option === "string"
                      ? `${querySearch}`
                      : `${querySearch}`
                  }
                  filterOptions={x => x}
                  options={options}
                  disableClearable
                  onChange={async (event, newValue) => {
                    goToCompanyPage(newValue);
                  }}
                  onInputChange={(e, val) => {
                    if (val.length < 3) {
                      setOptions([]);
                    } else {
                      searchFromAlgolia(val);
                    }
                  }}
                  renderInput={params => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        class="searchInputStyle"
                        type="search"
                        placeholder="Search company, software name, category, service, CEO..."
                      />
                      <span className="material-icons face">
                        <SearchIcon className={classes.iconSize + ' mobile-search-icon'} />
                      </span>
                    </div>
                  )}
                  renderOption={option => {
                    return (
                      <Grid container alignItems="center" onClick={async () => { isMounted = false; }}>
                        <Grid container alignItems="center" onClick={async () => { isMounted = false; }}>

                          {option && option.product && (option.product !== "requestlyCategoryPage")
                            ? (<div class="resultRow"><div class="resultProductName">{option.product}</div><div class="resultType">Product</div></div>)
                            : option.category && (option.product !== "requestlyCategoryPage") ? (<div class="resultRow"><div class="resultProductName">{option.category}</div><div class="resultType">Category</div></div>)
                              : option.subCategory && (option.product !== "requestlyCategoryPage") ? `Category: ${option.subCategory}`
                                : null
                          }

                        </Grid>
                      </Grid>
                    );
                  }}
                />
                {(user_detail === null || user_detail === false) ? (

                  <div class="userName" onClick={() => { window.location.href = '/auth/login'; }}>
                    LOGIN
                  </div>

                ) : (
                  <div class="loginButton" onClick={() => { window.location.href = '/app/dashboard'; }}>
                    <div>
                      <PersonIcon />
                    </div>
                    <div classe="loginButtonText">
                      {user_detail.userName}
                    </div>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </Toolbar>
      </AppBar>

      {
        product.videoLinks &&
        Array.isArray(product.videoLinks) &&
        product.videoLinks.length !== 0 &&
        product.videoLinks[0] !== "" &&
        isUrl(product.videoLinks[0]) && (
          <Grid className={classes.videoGrid}>
            <iframe
              width="100%"
              height="315"
              title="video"
              onClick={() => {
                console.log("working");
              }}
              src={product.videoLinks[0]}
              allowFullScreen
            />
          </Grid>
        )
      }

      {
        <Grid container
          display="flex"
          justify="flex-end"
          className={classes.gridWidth}
        >
          <Button class="engageButton"
            onClick={() => {
              if (
                !localStorage.getItem("selectedOption") && localStorage.getItem("selectedOption") !== ""
              ) {
                getWouldLikeOptions();
                setPopupStep(1);
              } else {
                redirectTo();
              }
            }}
          >
            <Typography class="engageText"><PhoneIcon /> Engage {product?.company} </Typography>
          </Button>
        </Grid>
      }
      <div className="container-fluid">
        <div className="row">

          <div class="categoryStyle">
            <div class="categorySeparator"> <a class="categoryTitle" onClick={() => goToHome()}>Home</a> / </div>
            <div class="requestlyTooltip">
              <a class="categoryTitle" onClick={() => goToCompanyPage({ category: product.category })}> {product.category} </a>
              <span class="requestlyTooltiptext top">Click to view all products in category {product.category} </span>
            </div>
            {
              product.subCategory !== '' ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div class="categorySeparator">/</div>
                  <div class="requestlyTooltip">
                    <a class="categoryTitle" onClick={() => goToCompanyPage({ subCategory: product.subCategory })}> {product.subCategory} </a>
                    <span class="requestlyTooltiptext top">Click to view all products in category {product.subCategory} </span>
                  </div>
                </div>
              ) : null
            }
            <span class="categorySeparator">/ {product.product}</span>
          </div>
          <div style={{ paddingTop: "0px" }} className={`col-sm-12 col-md-12`}>
            <div className={`${classes.sliderBg} sliderSec`} id="top-margin-c">
              <div className={classes.logoSecCover}>
                <div>
                  {

                    product.logo && product.logo !== ''
                    &&
                    (product.logo !== "https://requestly-media-public.s3.eu-west-2.amazonaws.com/company_logos/")
                    && (<img class="companyLogo"
                      src={product.logo}
                      alt={product.company + " logo"}
                      title={product.company + " logo"}
                    />
                    )
                  }
                  <Typography variant="h1" className={classes.productName}>
                    {product.product}
                  </Typography>
                  <div class="companyName">
                    <div class="requestlyTooltip">
                      <a onClick={() => goToCompanyPage({ company: product.company })}>by {product.company}</a>
                      <span class="requestlyTooltiptext top">Click to view all products by {product.company} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div >
        </div >

        <div className={`row ${classes.overviewStyle}`}>
          <TabPanel class="productInfoPanel" value={value} index={0}>
            <div class="productInfoContainer">
              <Grid container>

                <Grid item xs={12} sm={10} md={10} lg={10}>
                  <div class="requestSection">
                    <div class="requestlyTooltip">
                      <Button class="requestlyButton"
                        onClick={() => {
                          if (
                            !localStorage.getItem("selectedOption") &&
                            localStorage.getItem("selectedOption") !== ""
                          ) {
                            getWouldLikeOptions();
                            setPopupStep(1);
                          } else {
                            redirectTo();
                          }
                        }}
                      >Request Demo <img class="requestlyIcon" src={requestly_icon} /></Button>
                      <span class="requestlyTooltiptext top">Setup a quick product demo directly with the {product.company} team</span>
                    </div>
                    <div class="requestlyTooltip">
                      <Button class="requestlyButton"
                        onClick={() => {
                          if (
                            !localStorage.getItem("selectedOption") &&
                            localStorage.getItem("selectedOption") !== ""
                          ) {
                            getWouldLikeOptions();
                            setPopupStep(1);
                          } else {
                            redirectTo();
                          }
                        }}
                      >Request Meeting <img class="requestlyIcon" src={requestly_icon} /></Button>
                      <span class="requestlyTooltiptext top">Request a 15min meeting or ask any questions you might have directly to the {product.company} team</span>
                    </div>
                  </div>
                  <Box className={classes.boxborder}>
                    <h3 className={classes.introText}>Introduction</h3>
                    <div className={classes.overviewTextStyle} dangerouslySetInnerHTML={{ __html: product.description }} >
                    </div>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Box className={classes.boxborder4}>
                    <h3 className={classes.detailsText}> Details</h3>
                    {
                      product.company ? (<p className={classes.detailsTextDetail}>{product.company} </p>) : ''
                    }
                    {
                      product.clean_url ? (<p className={classes.detailsTextDetail}> Website -  <a href={product.clean_url.includes('http') ? product.clean_url : `http://${product.clean_url}`} target="_blank" rel="noreferrer">{product.clean_url}</a> </p>) : ''
                    }
                    {
                      product.noEmployees ? (<p className={classes.detailsTextDetail}> Employees - {product.noEmployees} </p>) : ''
                    }
                    {
                      product.yearFounded ? (<p className={classes.detailsTextDetail}> Founded - {product.yearFounded} </p>) : ''
                    }
                    {
                      product.annualRevenue ? (<p className={classes.detailsTextDetail}> Revenue - {product.annualRevenue} </p>) : ''
                    }
                    {
                      product.hqLocation ? (<p className={classes.detailsTextDetail}> Location - {product.hqLocation} </p>) : ''
                    }
                  </Box >
                </Grid >

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={5}
                  lg={10}
                  style={{ marginBottom: "37px" }}
                >
                  <Box className={classes.boxborderDownload}>
                    <h3 className={classes.introTextKeywords}>Downloads</h3>

                    {product && product.files
                      ? product.files.map((val, index) => {
                        if (val) {
                          return (
                            <>
                              {val.name.split(".").pop() === "pdf" && (
                                <Grid className={classes.downloadSection}>
                                  {/* <PictureAsPdfIcon style={{ height: "50px", width: "100px", marginTop: "-10px" }} /> */}
                                  {/* <img src={pdf} style={{ marginLeft: "19%" }} /> */}
                                  <img src={pdf} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "xlsx" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={xlss} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "jpg" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={jpg} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "mov" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={mov} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "pptx" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={ppt} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "ppt" && (
                                <>
                                  <img src={ppt} />
                                  <Grid className={classes.downloadSection}>
                                    <span
                                      className={
                                        classes.overviewTextStyleDownload
                                      }
                                    >
                                      {(val.name.length > 12 &&
                                        !FileFullName) ||
                                        (index !== fileId &&
                                          val.name.length > 12) ? (
                                        <a
                                          className={classes.fileHover}
                                          href={val.location}
                                          onMouseEnter={() =>
                                            toggleHover(index)
                                          }
                                        >{`${val.name.substring(
                                          0,
                                          12
                                        )}...`}</a>
                                      ) : (
                                        <a
                                          href={val.location}
                                          onMouseEnter={e => toggleHover(e)}
                                          onMouseLeave={() =>
                                            toggleHover(index)
                                          }
                                        >
                                          {val.name}
                                        </a>
                                      )}
                                    </span>
                                  </Grid>
                                </>
                              )}

                              {val.name.split(".").pop() === "mpg" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={mpg} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "doc" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={doc} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "docx" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={doc} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "mp4" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={mov} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "png" && (
                                <Grid className={classes.downloadSection}>
                                  <img src={doc} />
                                  <span
                                    className={
                                      classes.overviewTextStyleDownload
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}

                              {val.name.split(".").pop() === "csv" && (
                                <Grid className={classes.downloadSection}>
                                  {/* <img src={doc} style={{ height: "39px", width: "55px", marginLeft: "19%" }} /> */}
                                  <span
                                    className={
                                      classes.overviewTextStyleDownloadNoImage
                                    }
                                  >
                                    {(val.name.length > 12 &&
                                      !FileFullName) ||
                                      (index !== fileId &&
                                        val.name.length > 12) ? (
                                      <a
                                        className={classes.fileHover}
                                        href={val.location}
                                        onMouseEnter={() =>
                                          toggleHover(index)
                                        }
                                      >{`${val.name.substring(0, 12)}...`}</a>
                                    ) : (
                                      <a
                                        href={val.location}
                                        onMouseEnter={e => toggleHover(e)}
                                        onMouseLeave={() =>
                                          toggleHover(index)
                                        }
                                      >
                                        {val.name}
                                      </a>
                                    )}
                                  </span>
                                </Grid>
                              )}
                            </>
                          );
                        }
                        return null;
                      })
                      : ""}
                    {product &&
                      product.files &&
                      product.files !== null &&
                      product.files.length === 0 && <p>No Data Provided</p>}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Box className={classes.boxborder4}>
                    <h3 className={classes.CompetitorText}> Competitors</h3>
                    {
                      product && product.competitiors ? product.competitiors.map((val) => {
                        if (val) {
                          return (
                            <Chip
                              key={val}
                              className={classes.chipsStyle}
                              label={val}
                              clickable
                              onClick={() => goToCompanyPage(val)}
                            />
                          );
                        }
                        return null;
                      })
                        : ""
                    }
                    {
                      product &&
                      product.competitiors &&
                      product.competitiors !== null &&
                      product.competitiors.length === 0 && (
                        <p style={{ fontSize: "0.7rem", paddingLeft: "5%" }}>
                          No Data Provided
                        </p>
                      )
                    }
                  </Box >
                </Grid >

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={5}
                  lg={5}
                  style={{ marginBottom: "37px" }}
                >
                  <Box className={classes.boxborder}>
                    <h3 className={classes.introTextKeywords}>Keywords</h3>
                    <span className={classes.overviewTextStyle}>
                      {product && product.allKeywords
                        ? product.allKeywords.map(val => {
                          if (val) {
                            return (
                              <Chip
                                key={val}
                                className={classes.chipsStyle}
                                label={val}
                                clickable
                                onClick={() => goToCompanyPage({ allKeywords: val })}
                              />
                            );
                          }
                          return null;
                        })
                        : ""}
                      {product &&
                        product.allKeywords &&
                        product.allKeywords !== null &&
                        product.allKeywords.length === 0 && (
                          <p>No Data Provided</p>
                        )}
                    </span>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={5}
                  lg={5}
                  style={{ marginBottom: "37px" }}
                >
                  <Box className={classes.boxborder}>
                    <h3 className={classes.introTextKeywordsCustomer}>Notable Customers</h3>
                    <span className={classes.overviewTextStyle}>
                      {product && product.notableCustomer
                        ? product.notableCustomer.map(val => {
                          if (val) {
                            return (
                              <Chip
                                key={val}
                                className={classes.chipsStyle}
                                label={val}
                                clickable
                                onClick={() => goToCompanyPage(val)}
                              />
                            );
                          }
                        })
                        : ""}
                      {product &&
                        product.notableCustomer &&
                        product.notableCustomer !== null &&
                        product.notableCustomer.length === 0 && (
                          <p>No Data Provided</p>
                        )}
                    </span>
                  </Box >
                </Grid >

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={5}
                  lg={10}
                  style={{ marginTop: "37px" }}
                  style={{ marginBottom: "37px" }}
                >
                  <Box className={classes.boxborder}>
                    <h3 className={classes.introTextKeywordsCustomerRoles}>Software for</h3>
                    <span className={classes.overviewTextStyle}>
                      {product && product.targetPersona
                        ? product.targetPersona.map(val => {
                          if (val) {
                            return (
                              <Chip
                                key={val}
                                className={classes.chipsStyle}
                                label={val}
                                clickable
                                onClick={() => goToCompanyPage({ targetPersona: val })}
                              />
                            );
                          }
                          return null;
                        })
                        : ""}
                      {product &&
                        product.targetPersona &&
                        product.targetPersona !== null &&
                        product.targetPersona.length === 0 && (
                          <p>No Data Provided</p>
                        )}
                    </span>
                  </Box >
                </Grid >
              </Grid >
            </div >
          </TabPanel >
        </div >
      </div >
      <div className="owner">
        <p>Are you the owner of this page? <br />
          <div onClick={() => { window.location.href = '/auth/register'; }}>Click here to claim ownership</div></p>
      </div>
      <div className="container-footer">
        <div class="footer-social-links">
          <div class="icon-container">
            <div class="icon-circle"><div class="icon-holder"><a href="https://www.linkedin.com/company/requestlysoftware" title="LinkedIn" target="_blank"><img src={linkedinIcon} class="icon-style" /></a></div></div>
            <div class="icon-circle"><div class="icon-holder"><a href="https://www.facebook.com/Requestly-102800984897043" title="Facebook" target="_blank"><img src={facebookIcon} class="icon-style" /></a></div></div>
            <div class="icon-circle"><a href="https://twitter.com/requestlyCo" title="Twitter" target="_blank"><img src={twitterIcon} class="icon-style" /></a></div>
            <div class="icon-circle"><a href="https://www.youtube.com/channel/UCdhB4ezKN4Ev1GA6YTVw7dg" title="YouTube" target="_blank"><img src={youtubeIcon} class="icon-style" /></a></div>
            <div class="icon-circle"><a href="https://vimeo.com/requestly" title="Vimeo" target="_blank"><img src={vimeoIcon} class="icon-style" /></a></div>
          </div>
        </div>
        <div class="footer-text">
          <div class="footer-copy">
            © requestly 2020
          </div>
          <div class="footer-made">
            Because life is too short.
          </div>
        </div>
      </div>
      {
        popupStep === 1 ? (
          <Modal
            open={true}
            className={classes.modal}
            onClose={() => {
              localStorage.removeItem("isPopupOpen");
              setPopupStep(0);
            }}
          >
            <div class="paperModal">
              <Typography class="modalHeading">I would like a</Typography>
              <div class="modalContainer">
                <List className={classes.root + " popup1-list"}>
                  {wouldLikeOptions && wouldLikeOptions.length > 0 && wouldLikeOptions.map(datum => {
                    const labelId = `checkbox-list-label-${datum.value}`;

                    return (
                      <ListItem key={datum.value}
                        role={undefined}
                        dense
                        onClick={() => {
                          setError(false);
                          const index = wouldLikeSelectedOption.findIndex(
                            item => item === datum.value
                          );
                          if (index !== -1) {
                            wouldLikeSelectedOption.splice(index, 1);
                          } else {
                            wouldLikeSelectedOption.splice(
                              index,
                              wouldLikeSelectedOption.length
                            );
                            wouldLikeSelectedOption.push(datum.value);
                          }
                          setWouldLikeSelectedOption([
                            ...wouldLikeSelectedOption
                          ]);
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={wouldLikeSelectedOption.findIndex(item => item === datum.value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={datum.label} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
              <div class="buttonArea">
                <Button class="letsGoBtn"
                  onClick={() => {
                    console.log('click')
                    if (!(wouldLikeSelectedOption.length === 0)) {
                      setError(false)
                      _submitEnquiry();
                    } else {
                      setError(true);
                    }
                  }}
                >
                  Next
                </Button>

              </div>
              {isError && <div class="modalMessage">Please select a meeting option</div>}
            </div>
          </Modal>
        ) : null
      }
    </div >
  );
};

export default ProductPage;
