
export class CommercialEvaluationAnswerModel {  
        overview = '';
        categoryName = '';
        categories =  [];  // [{ categoryName: '', sections: [{  sectionName: '', questions: [{  question:'',  type: '', score: '' }] }]} ]
        constructor(technicalEvaluationInfo = null){  
                if (technicalEvaluationInfo) {
                        this.overview = technicalEvaluationInfo.overview || this.overview;
                        this.categoryName = technicalEvaluationInfo.categoryName || this.categoryName;
                        if (technicalEvaluationInfo.categories && technicalEvaluationInfo.categories.length) {
                                technicalEvaluationInfo.categories.forEach(category => {
                                        this.categories.push(new Category(category));
                                });
                        }
                }
        }    
}


export class Category{  
        categoryName = '';
        sections =  [];  
        constructor(categoryInfo = null){  
                if (categoryInfo) {
                        this.categoryName = categoryInfo.categoryName || this.categoryName;
                        if (categoryInfo.sections && categoryInfo.sections.length) {
                                categoryInfo.sections.forEach(section => {
                                        this.sections.push(new Section(section));
                                });
                        }
                }
        }    
}

export class Section {  
        sectionName = '';
        questions =  [];  
        constructor(sectionInfo = null){  
                if (sectionInfo) {
                        this.sectionName = sectionInfo.sectionName || this.sectionName;
                        if (sectionInfo.questions && sectionInfo.questions.length) {
                                sectionInfo.questions.forEach(question => {
                                       this.questions.push(new Question(question)) 
                                });
                        }
                }
        }    
}

export class Question {  
        question = '';
        questionId = '';
        type =  '';  
        score =  '';
        answer =  '';
        constructor(questionInfo = null){  
                if (questionInfo) {
                        this.question = questionInfo.question || this.question;
                        this.questionId = questionInfo.questionId || this.questionId;
                        this.type = questionInfo.type || this.type;
                        this.score = questionInfo.score || this.score;
                        this.answer = questionInfo.answer || this.answer;
                }
        }    
}