import {
  Box,
  Button,
  Container,
  Grid, makeStyles, TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { GoogleLogin } from 'react-google-login';
import { hotjar } from 'react-hotjar';
import { withRouter } from 'react-router-dom';
import Page from 'src/components/Page';
import { alertService } from 'src/components/toaster/alert.service';
import { history } from 'src/utils/utils';
import * as Yup from 'yup';
import LoginService from '../../services/sigin-signup/login.service';
import LocalStorageService from '../../services/storage/local-storage.service';

ReactGA.initialize('UA-205584867-1');
hotjar.initialize('2566851', 6);

const useStyles = makeStyles((theme) => ({
  TextField: {
    backgroundColor: '#fff',
    borderRadius: '5px'
  },
  input: {
    color: '#40506b'
  },
  Checkbox: {
    backgroundColor: '#fff',
    color: '#000'
  },
  signupButton: {
    backgroundColor: '#40506b',
    color: '#fff',
    shadowColor: 'rgba(0, 0, 0, 0.5)',
  },
  signupButtonHover: {
    backgroundColor: '#ddd',
    color: '#000',
    shadowColor: 'rgba(0, 0, 0, 0.5)',
  },

  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const loginService = new LoginService();
const localStorageService = LocalStorageService.getService();
const LoginView = () => {
  const classes = useStyles();
  //const navigate = useNavigate();
  // if(localStorage.token){
  //   history.push('/app/dashboard');
  // }

  const redirectRoute = () => {
    const company = localStorage.getItem('company');
    const productId = localStorage.getItem('productId');
    const selectedOption = localStorage.getItem('selectedOption');
    if (selectedOption) {
      if (selectedOption === "1") {
        history.push(
          `/app/discovery-meeting/${productId}/${company}`
        );
      } else if (selectedOption === "2") {
        history.push(
          `/app/product-discovery/${productId}/${company}`
        );
      } else {
        history.push(`/app/create-rfp`);
      }
    } else {
      history.push('/app/dashboard');
    }

    // const lastPathname = localStorage.getItem('lastPathname');
    // const searchParams = localStorage.getItem('searchParams');
    // if (lastPathname && lastPathname !== '') {
    //   history.push(`${lastPathname}${searchParams && searchParams !== '' ? searchParams : ''}`);
    // } else {
    //   history.push('/app/dashboard');
    // }
  }
  const responseGoogle = (response) => {
    if (response && response.profileObj) {
      console.log(response);
      const values = {
        userName: response.profileObj.name,
        email: response.profileObj.email,
        password: response.profileObj.googleId,
        isGoogleLogin: true,
        token: response.accessToken
      };
      loginService.userSignup(values).then((res) => {
        if (res) {
          localStorage.setItem('user', res.userName)
          localStorageService.setToken(res);
          history.push('/app/dashboard');
        } else {
          loginService.userLogin(values).then((res) => {
            if (res) {
              localStorage.setItem('user', res.userName);
              localStorageService.setToken(res);
              redirectRoute();
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('lastPathname');
      localStorage.removeItem('searchParams');
    }
  }, []);
  console.log(window.location)
  return (
    <Page
      className={classes.root}
      title="requestly Login"
    >
      {
        ReactGA.pageview(window.location.pathname + window.location.search)
      }
      {
        hotjar.stateChange(window.location.pathname + window.location.search)
      }
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <div class="authRegisterText">Don't have an account? <a href={(window.location.hostname === 'requestly.co') ? "https://requestly.co/auth/register" : window.location.origin + "/auth/register"}><signinLinkText> Sign up</signinLinkText></a></div>
          <div></div>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={(values) => {
              loginService.userLogin(values).then((res) => {
                if (
                  res &&
                  !isNaN(res.isAdminVerified) &&
                  res.isAdminVerified &&
                  !isNaN(res.isVerified) &&
                  res.isVerified
                ) {
                  localStorage.setItem('user', res.userName);
                  localStorageService.setToken(res);
                  redirectRoute();
                }
                if (
                  res &&
                  isNaN(res.isAdminVerified) &&
                  !res.isAdminVerified &&
                  isNaN(res.isVerified) &&
                  !res.isVerified
                ) {
                  alertService.success(
                    res.message ?? "Please verify your email."
                  )
                }
              }).error((err) => {
                console.log(':: err ', err);
              });

            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (

              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                    align="center"

                  >
                    Sign In to Your Account
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                  style={{ "marginBottom": "10px" }}
                >

                </Grid>

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  className={classes.TextField}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  className={classes.TextField}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    className={classes.signupButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Log in
                  </Button>
                </Box>
                <Box mt={3} mb={1}>
                  <Typography align="center" variant="body1" id="orRow" >
                    <div class="lineSeparators"></div>
                    <div class="orTxt"> or </div>
                    <div class="lineSeparators"></div>
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                    align="center"
                    id="gbtnL"
                    style={{ "marginTop": "2px", "text-align": "center" }}
                  >
                    <GoogleLogin
                      clientId="419841113107-trjo9gcajni1ngr7ahqo3kb1j39famfn.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={'single_host_origin'}

                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default withRouter(LoginView);
