import React from 'react';
import { render } from 'react-dom';
import { Formik } from "formik";
import yup from "yup";
import { makeStyles } from '@material-ui/core';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
export default class Thumb extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      thumb: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();
      if (nextProps.file.constructor == File) {
        reader.onloadend = () => {

          this.setState({ loading: false, thumb: reader.result });

        };

        reader.readAsDataURL(nextProps.file);
      }
      else {
        this.setState({ loading: false, thumb: nextProps.file });
      }
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    return (
      <>

        { this.props.stopChange ?
          (
            <img src={thumb}
              alt={file.name}
              className="profilePics img-thumbnail logo-clickable"
              height={200}
              width={200} />) : (<label for="logo">
                <img src={thumb}
                  alt={file.name}
                  className="profilePics img-thumbnail logo-clickable"
                  height={200}
                  width={200} />{/*<CameraAltIcon style={{ marginRight: '25%', cursor: 'pointer' }} />*/}</label>)}

      </>
    );
  }
}
