import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const saveServerToken = (data) => {
  if (data.token) {
    localStorage.setItem('token', data.access_token);
  }
};

export const deleteServerToken = (server_token) => {
  localStorage.clear();
  localStorage.clear();
};

export const getHTTPHeader = function () {
  const header = {
    ...headers,
    token: localStorage.servertoken ? localStorage.token : null
  };
  return header;
};
export const getServerTokenDetails = () => {
  if (localStorage.token) {
    return (localStorage.servertoken);
  }

  return null;
};

export const customCompanyValidations = async (values) => {
  var error_array = {};
  if (values.companyName == '') {
    error_array['companyName'] = 'Please add Company Name';
  }

  if (typeof values.overview !== 'object') {
    // error_array.push('Please add Company Overview</br>');
    error_array['overview'] = 'Please add Company Overview';
  } else {
    var success_flag = false;
    await values.overview.blocks.map(txt => {
      if (txt.text !== '') {
        success_flag = true;
      }
    })
    if (!success_flag) {
      // error_array.push('Please fill out company overview</br>');
      error_array['overview'] = 'Please add Company Overview';
    }
  }

  if (values.logo == '/static/icon-img.png') {
    // error_array.push('Please add a logo</br>');
    error_array['logo'] = 'Please add a logo';
  } else if ((values.logo == '/static/icon-img.png') && (values.logo.name === undefined || values.logo.name === '')) {
    //error_array.push('Please add a logo</br>');
    error_array['logo'] = 'Please add a logo';
  }

  var success_flag_location = false;
  await values.locations.map(newTxt => {
    if (newTxt.officeLocation !== '') {
      success_flag_location = true;
    }
  })
  if (!success_flag_location) {
    error_array['locations'] = 'Please fill out atleast one Office Location';
    // error_array.push('Please fill out atleast one Office Location</br>');
  }
  // Object.keys(error_array);
  return error_array;
}

export const customProjectInfoValidation = async (values) => {
  //alert('bye');
  var error_array = {};
  if (values.projectName == '') {
    //error_array.push('Please add Project Name</br>');
    error_array['projectName'] = 'Please add Project Name';
  }



  if (values.contacts.length < 2) {
    //error_array.push('Please add at least 2 contacts</br>');
    error_array['contacts'] = 'Please add at least 2 contacts';
  }

  if (typeof values.projectDescription !== 'object') {
    //error_array.push('Please fill out Project Description</br>');
    error_array['projectDescription'] = 'Please fill out Project Description';
  } else {
    var success_flag = false;
    await values.projectDescription.blocks.map(txt => {
      if (txt.text !== '') {
        success_flag = true;
      }
    })
    if (!success_flag) {
      error_array['projectDescription'] = 'Please fill out Project Description';
    }
  }

  var success_flag_contacts = true;
  await values.contacts.map(newTxt => {
    if (newTxt.contactName === '' || newTxt.contactRole === '') {
      success_flag_contacts = false;
    }
  })
  if (!success_flag_contacts) {
    //error_array.push('Please fill out atleast two Contact name and roles</br>');
    error_array['contactRole'] = 'Please fill out atleast two Contact name and roles';
  }

  var success_flag_timeline = true;
  await values.timelineItems.map(newTxt => {
    if (newTxt.mileStoneName === '' || newTxt.mileStoneDate === '') {
      success_flag_timeline = false;
    }
  })
  if (!success_flag_timeline) {
    //error_array.push('Please fill out timeline properly</br>');
    error_array['timelineItems'] = 'Please fill out timeline and dates properly';
  }
  return error_array;
}

export const customProductValidations = async (values) => {
  //alert('bye');
  var error_array = {};
  if (values.productName == '') {
    //error_array.push('Please add Product Name</br>');
    error_array['productName'] = 'Please add Product Name';
  }
  if (values.productLaunchDate == '') {
    //error_array.push('Please add Product Launch Date</br>');
    error_array['productLaunchDate'] = 'Please add Product Launch Date';

  }

  if (values.notableCustomers.length === 0) {
    //error_array.push('Please add Customer</br>');
    error_array['notableCustomers'] = 'Please add Customer';

  }
  if (values.keywords.length === 0) {
    //error_array.push('Please add Keywords</br>');
    error_array['keywords'] = 'Please add Keywords';

  }
  if (values.competitors.length === 0) {
    //error_array.push('Please add Competitors</br>');
    error_array['competitors'] = 'Please add Competitors';

  }
  if (values.targetPersona.length === 0) {
    //error_array.push('Please add Target Persona</br>');
    error_array['targetPersona'] = 'Please add Target Persona';

  }

  if (typeof values.productInfo !== 'object') {
    //error_array.push('Please add Product Information</br>');
    error_array['productInfo'] = 'Please add Product Information';
  } else {
    var success_flag = false;
    await values.productInfo.blocks.map(txt => {
      if (txt.text !== '') {
        success_flag = true;
      }
    })
    if (!success_flag) {
      //error_array.push('Please fill out product information</br>');
      error_array['productInfo'] = 'Please add Product Information';
    }
  }
  return error_array;
}
