import UserActionTypes from "./types";


const initialState = {
  rfpData: {},
  rfpSubmitTechnicalData: {},
  loading: false,
  error: false,
  submitRfpTechnicalDataUpdate: {
    overview: '',
    categoryName: '',
    categories: [],
  },
  rfpSubmitCommericalData: {},
  rfpSubmitCommericalDataUpdate: {
    overview: '',
    categoryName: '',
    categories: [],
  },
  SubmitRfpStepUpdate: ''
};
const submitRfpReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case UserActionTypes.GET_RFP_BY_USER_ID_START:
      return {
        ...state,
        rfpData: '',
        loading: true,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_ID_SUCCESS:
      return {
        ...state,
        rfpData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_START:
      return {
        ...state,
        rfpSubmitTechnicalData: '',
        loading: true,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_SUCCESS:
      return {
        ...state,
        rfpSubmitTechnicalData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_TECHNICAL_UPDATE:
      return {
        ...state,
        submitRfpTechnicalDataUpdate: action.payload,
        error: false,
      };

    case UserActionTypes.SUBMIT_RFP_UPDATE_STEPPER:
      return {
        ...state,
        SubmitRfpStepUpdate: action.payload,
      };

    //Commerical Requirement
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_START:
      return {
        ...state,
        rfpSubmitCommericalData: '',
        loading: true,
        error: false,
      };

    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_SUCCESS:
      return {
        ...state,
        rfpSubmitCommericalData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_SUBMIT_COMMERCIAL_UPDATE:
      return {
        ...state,
        loading: false,
        rfpSubmitCommericalDataUpdate: action.payload,
        error: true,
      };

    default:
      return state;
  }
};

export default submitRfpReducer;