/* eslint-disable no-unused-expressions */
import {
  AppBar, Avatar, Checkbox,
  Divider,
  FormControlLabel, Grid,
  Icon, List, ListItem, ListSubheader, Toolbar, Typography
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import CategoryIcon from '@material-ui/icons/Category';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { default as Person, default as PersonIcon } from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import KeyIcon from '@material-ui/icons/VpnKey';
import { Autocomplete } from '@material-ui/lab';
import algoliasearch from 'algoliasearch/lite';
import { loadCSS } from 'fg-loadcss';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";
import { hotjar } from 'react-hotjar';
import ReactPaginate from 'react-paginate';
import { algoliaIndex } from 'src/utils/algoliadata';
import { history } from 'src/utils/utils';
import facebookIcon from "../../image/facebook-f.svg";
import linkedinIcon from "../../image/linkedin.svg";
import requestly_logo from "../../image/requestly_logo_white.png";
import requestly_icon from "../../image/requestly_r_white_transparent.svg";
import twitterIcon from "../../image/twitter.svg";
import vimeoIcon from "../../image/vimeo-v.svg";
import youtubeIcon from "../../image/youtube.svg";
import { getUserDetail } from '../../services/rfp/UserDetailService';
import '../shared/css/footer.scss';
import './css/categoryInfo.scss';
import './css/product.scss';
import "./style.css";

ReactGA.initialize('UA-205584867-1');
hotjar.initialize('2566851', 6);

const searchClient = algoliasearch('JE3UA8IZ0R', 'dfb17da784818795fc420e50bfb6cfdb');
const searchIndex = searchClient.initIndex(algoliaIndex);
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
    fontFamily: '"Spartan", sans-serif!important'
  },
  h2: {
    fontSize: '14px'
  },
  chipsStyle: {
    margin: ".5rem",
    fontSize: "12px",
    background: "#40506b",
  },
  videoStyle: {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  headerStyle: {
    background: '#F2564C'
  },
  title: {
    cursor: 'pointer',
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 600,
    padding: '0.2em 0',
    fontFamily: '"Spartan", sans-serif!important'
  },
  getStartedButtonStyle: {
    background: '#F2564C',
    color: '#fff'
  },
  rightNav: {
    width: '100%',
    paddingLeft: '78px',
  },
  rightNavText: {
    color: '#000',
    fontFamily: '"Spartan", sans-serif!important'
  },
  navList: {
    listStyle: 'none',
    padding: '1em 0,',
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  navListItem: {
    padding: '0.5em 0',
    marginLeft: '3em',
    color: 'black',
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      display: 'block',
      height: '3px',
      width: 0,
      backgroundColor: '#F2564C',
      position: 'absolute',
      bottom: 0,
      transition: '0.2s'
    }
  },

  iconSize: {
    color: "#707070",
  },
  searchWrapper: {
    width: '50vW',
    position: 'absolute',
    top: '40%'
  },
  searchBarWrap: {
    textAlign: 'left'
  },
  paper: {
    textAlign: 'center',
    padding: '2px 5px 2px 5px',
    background: '#000',
    color: '#fff'
  },
  footerWrapper: {
    position: 'absolute',
    bottom: '2rem',
    width: '70vW',
    textAlign: 'center',
    justifyContent: 'center'
  },
  iconStyle: {
    fontSize: '2.5rem',
    verticalAlign: 'middle'
  },
  footerText: {
    background: 'transparent',
    border: 0,
    boxShadow: 'unset',
    fontSize: '3rem',
    color: '#fff',
    fontWeight: 200
  },
  paperTextOur: {
    background: '#f2564c'
  },
  paperText: {
    verticalAlign: 'middle',
    display: 'inline'
  },
  autoCompleteTextStyle: {
    color: '#000',
    fontSize: '.7rem'
  },
  sliderBg: {
    background: 'url(/static/media/s1.3a39b803.png)',
    backgroundSize: 'cover',
    padding: '0px 0 0 10px',
    display: 'block',
    backgroundRepeat: 'no-repeat',
    height: '30rem',
    minHeight: '30rem',
    backgroundPosition: 'center',
    overflow: 'hidden'
  },
  logoSecCover: {
    padding: 0
  },
  companyInfoSec: {
    display: 'block',
    float: 'left',
    position: 'absolute',
    bottom: 0,
    marginBottom: '.2rem'
  },
  companyInfoLink: {
    padding: '1.4rem',
    background: '#f2564c',
    color: '#fff',
    borderRadius: '.5rem .5rem 0rem .0rem',
    '&:hover': {
      color: '#fff'
    }
  },
  overviewStyle: {
    paddingTop: '0rem'
  },
  overviewTextStyle: {
    fontSize: '1rem'
  },
  companyIntroBlock: {
    position: 'absolute',
    top: '-34px',
    left: '19px',
    display: 'block',
    background: 'transparent',
    padding: '0px 17px'
  },
  boxBorder: {
    position: 'relative',
    bottom: '0px',
    backgroundColor: 'red'
  },
  boxborder: {
    border: '1px solid gainsboro',
    margin: '8px',
    padding: '0px 10px 5px 15px',
    borderRadius: '10px'
  },
  introText: {
    width: '6.5em',
    fontSize: '1.6rem!important',
    marginTop: '-10px',
    marginLeft: '24px',
    background: '#f4f6f8'
  },
  boxborder4: {
    border: '1px solid gainsboro',
    margin: '8px',
    borderRadius: '10px'
  },
  detailsText: {
    width: '5rem',
    background: '#f4f6f8',
    marginTop: '-10px',
    marginLeft: '25px',
    fontSize: '1.6rem!important'
  },
  detailsTextDetail: {
    padding: '0px 10px 10px 10px',
    margin: 0,
    fontSize: '1rem'
  },
  noPadding: {
    padding: 0
  },
  autoCompletePopperProduct: {
    width: '30vW!important',
    maxWidth: '30vW!important',
    top: '-3px!important',
    left: '-15%!important'
  },
  autoCompleteTextStyle: {
    color: '#000',
    fontSize: '.7rem'
  },
  gridStyle: {
    display: 'inline-block'
  },
  listHeader: {
    fontSize: '14px',
    fontWeight: '700',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.22',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#40506b',
    padding: '1rem',
    background: "#fff",
  },
  containerPadding: {
    padding: '0rem'
  },
  expandMore: {
    textAlign: 'center',
    justifyContent: 'center'
  },
  employeeFilterList: {
    marginTop: '1rem'
  },
  listItemCategory: {
    paddingTop: 0,
    paddingBottom: 0,
    display: '-webkit-box'
  },
  categoriesName: {
    padding: '0!important',
    margin: 0
  },
  gridStyleList: {
    padding: '0 1rem'
  },

  checkBoxStyleList: {
    width: "100%",
    minWidth: '30px'
  },
  logoFit: {
    objectFit: 'contain!important'
  },
  productNameStyle: {
    fontWeight: 'bolder',
    color: "#40506b"
  },
  productOverview: {
    fontSize: '.8rem',
    color: '#000000!important',
    float: 'left!important',
    textAlign: 'justify',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': 4,
    '-webkitBoxOrient': 'vertical'
  },
  listItemDetailStyle: {
    paddingTop: '1em',
    paddingBottom: '1em'
  },
  productListStyle: {
    paddingTop: 0
  },
  productImage: {
    borderRadius: '0!important'
  },
  closeIconStyle: {
    cursor: 'pointer',
    fontSize: '1.2rem'
  },
  loginButton: {
    position: "absolute",
    left: "82%",
    top: "24%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row"
  },
  loginButtonText: {
    position: "relative",
    top: "2px",
    left: "5px",
    fontSize: "16px",
    display: "inline-flex",
    alignItems: "center"
  },
  userName: {
    top: "24%",
    left: "88%",
    cursor: "pointer",
    position: "absolute",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "140px",
  },
  '@global': {
    '.MuiAvatar-root': {
      width: "90px",
      height: "90px",
      marginRight: "10px"
    },
    '.MuiIcon-root': {
      fontSize: "1rem !important"
    }
  },

}));

const ProductPage = (props) => {
  let isMounted = true;
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [isSubOpen, setIsSubOpen] = React.useState(false);
  const [isKeywordOpen, setIsKeywordOpen] = React.useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = React.useState(false);

  const [options, setOptions] = useState([]);
  const [product, setProduct] = useState([]);
  const [querySearch, setQuerySearch] = useState('');
  const [categories, setCategories] = useState({});
  const [subCategories, setSubcategories] = useState({});
  const [allKeywords, setAllKeywords] = useState({});
  const [targetPersona, setTargetPersona] = useState({});
  const [company, setCompany] = useState({});
  const [numericFilters, setNumericFilters] = useState([]);
  const [page, setPage] = useState(0);
  const [nbPages, setNbPages] = useState(0);
  const [nbHits, setNbHits] = useState(0);
  const [showProducts, setShowProducts] = useState({ category: false, subCategory: false, company: false, keywords: false, val: '' })

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedAllKeywords, setSelectedAllKeywords] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedTargetPersona, setSelectedTargetPersona] = useState([]);
  const [user_detail, setUserDetail] = useState(null);

  function chipBuilder(chipTitle) {
    var title = chipTitle.toString().split(",");
    return title;
  }

  function titleBuilder(categoryTitle, subcategoryTitle, companyTitle, personaTitle, keywordsTitle) {
    let title = "";
    const category = categoryTitle;
    const subCategory = subcategoryTitle;
    const company = companyTitle;
    const persona = personaTitle;
    const keywords = keywordsTitle;

    (category.length > 0) & (subCategory.length > 0) & (company.length > 0) & (persona.length > 0) & (keywords.length > 0) ? (
      /* 4: Cat - SubCat - Persona - Keywords */
      title = ((company).toString()).replace("company:", "") + " " + ((category).toString()).replace("category:", "").replace("Software", "") + " & " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " (" + keywords + ") Software for " + persona + "s in 2022"
    ) : (company.length > 0) & (category.length > 0) & (persona.length > 0) & (keywords.length > 0) ? (
      /* 4: Company - Cat - Persona - Keywords */
      title = "Best " + ((company).toString()).replace("company:", "").replace("Software", "") + " & " + ((category).toString()).replace("category:", "").replace("Software", "") + " (" + keywords + ") Software for " + persona + "s in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (persona.length > 0) & (keywords.length > 0) ? (
      /* 4: Cat - SubCat - Persona - Keywords */
      title = "Best " + ((category).toString()).replace("category:", "").replace("Software", "") + " (" + keywords + ") Software for " + persona + "s in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (company.length > 0) & (persona.length > 0) ? (
      /* 4: Cat - SubCat - Company - Persona */
      title = ((company).toString()).replace("company:", "") + " " + ((category).toString()).replace("category:", "").replace("Software", "") + "& " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " Software for " + persona + "s in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (company.length > 0) & (keywords.length > 0) ? (
      /* 4: Cat - SubCat - Company - Keywords */
      title = ((company).toString()).replace("company:", "") + " " + ((category).toString()).replace("category:", "").replace("Software", "") + "& " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " Software (" + keywords + ") in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (company.length > 0) ? (
      /* 3: Cat - SubCat - Company */
      title = ((company).toString()).replace("company:", "") + " " + ((category).toString()).replace("category:", "").replace("Software", "") + "& " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " Software in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (persona.length > 0) ? (
      /* 3: Cat - SubCat - Persona */
      title = "Best " + ((category).toString()).replace("category:", "").replace("Software", "") + "& " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " Software for " + persona + "s in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) & (keywords.length > 0) ? (
      /* 3: Cat - SubCat - Keywords */
      title = "Best " + ((category).toString()).replace("category:", "").replace("Software", "") + "& " + ((subCategory).toString()).replace("subCategory:", "").replace("Software", "") + " Software (" + keywords + ") in 2022"
    ) : (company.length > 0) & (persona.length > 0) & (keywords.length > 0) ? (
      /* 3: Company - Persona - Keywords */
      title = ((company).toString()).replace("company:", "") + " Software for " + persona + " (" + keywords + ") in 2022"
    ) : (category.length > 0) & (subCategory.length > 0) ? (
      /* 3: Cat - SubCat - Keywords */
      title = "Top " + ((category).toString()).replace("category:", "") + " & " + ((subCategory).toString()).replace("subCategory:", "") + " in 2022"
    ) : (category.length > 0) & (persona.length > 0) ? (
      /* 2: Cat - Persona */
      title = "Top " + ((category).toString()).replace("category:", "") + " for " + persona + "s in 2022"
    ) : (keywords.length > 0) & (persona.length > 0) ? (
      /* 2: Persona - Keywords */
      title = "Top Software for " + persona + "s (" + keywords + ") in 2022"
    ) : (company.length > 0) & (persona.length > 0) ? (
      /* 2: Company - Persona */
      title = ((company).toString()).replace("company:", "") + " Software for " + persona + "s in 2022"
    ) : (company.length > 0) & (keywords.length > 0) ? (
      /* 2: Company - Keywords */
      title = ((company).toString()).replace("company:", "") + " Software (" + keywords + ") in 2022"
    ) : (company.length > 0) & (category.length > 0) ? (
      /* 2: Company - Category */
      title = ((company).toString()).replace("company:", "") + " " + ((category).toString()).replace("category:", "") + " in 2022"
    ) : (company.length > 0) & (subCategory.length > 0) ? (
      /* 2: Company - SubCategory */
      title = ((company).toString()).replace("company:", "") + " " + ((subCategory).toString()).replace("subCategory:", "") + " in 2022"
    ) : subCategory.length > 0 ? (
      /* 1: Sub Categories */
      title = "Best " + ((subCategory).toString()).replace("subCategory:", "") + " in 2022"
    ) : category.length > 0 ? (
      /* 1: Categories */
      title = "Top " + ((category).toString()).replace("category:", "") + " in 2022"
    ) : persona.length > 0 ? (
      /* 1: Persona */
      title = "Top software for " + persona + "s in 2022"
    ) : (keywords.length > 0) ? (
      /* 1: Keywords */
      title = "Top " + keywords + " software in 2022"
    ) : (company.length > 0) ? (
      /* 1: Company */
      title = ((company).toString()).replace("company:", "") + " software in 2022"
    ) : null

    title = title.replace(/subCategory:/g, "").replace(/allKeywords:/g, "").replace(/targetPersona:/g, "").replace(/,/g, " and ")
    return title;
  }
  useEffect(() => {
    getUserDetail().then((success) => {
      setUserDetail(success);
    }).catch(() => {
      setUserDetail(false);
    });
  }, [])

  const fetchData = async () => {
    const query = queryString.parse(props.location.search);
    setQuerySearch(query.search);
    //alert(query.search)
    // eslint-disable-next-line no-nested-ternary
    setPage(query.page);
    if (query.category && query.category !== '') {
      setSelectedCategories(query.category.split(','));
    }
    if (query.subCategory && query.subCategory !== '') {
      setSelectedSubCategories(query.subCategory.split(','));
    }
    if (query.allKeywords && query.allKeywords !== '') {
      setSelectedAllKeywords(query.allKeywords.split(','));
    }
    if (query.targetPersona && query.targetPersona !== '') {
      setSelectedTargetPersona(query.targetPersona.split(','));
    }
    if (query.company && query.company !== '') {
      setSelectedCompany(query.company.split(','));
    }
    // if (query.company && query.company !== '') {
    //   setSelectedCompany(query.company.split(','));
    // }
    // eslint-disable-next-line react/prop-types
    // You can await here
    await searchIndex
      .search(query.search, {
        hitsPerPage: 20,
        facets: ['category', 'subCategory', 'allKeywords', 'company', 'targetPersona'],
        facetFilters: [
          query.category ? query.category.split(',') : [],
          query.subCategory ? query.subCategory.split(',') : [],
          query.allKeywords ? query.allKeywords.split(',') : [],
          query.targetPersona ? query.targetPersona.split(',') : [],
          query.company ? query.company.split(',') : []
        ],
        page,
        numericFilters
      })
      .then(async ({
        hits, facets, nbPages, page, nbHits
      }) => {
        setIsFirstLoad(false);
        let productDetail = hits;
        console.log(facets, "facets")
        const { category, subCategory, allKeywords, company, targetPersona } = facets;
        category ? setCategories(category) : setCategories([]);
        subCategory ? setSubcategories(subCategory) : setSubcategories([]);
        allKeywords ? setAllKeywords(allKeywords) : setAllKeywords([]);
        company ? setCompany(company) : setCompany([]);
        targetPersona ? setTargetPersona(targetPersona) : setCompany([]);
        let arrayWithoutLogo = [];
        let arrayWithLogo = [];

        productDetail.map((value) => {
          if (value.logo === "") {
            arrayWithoutLogo.push(value)
          } else {
            arrayWithLogo.push(value)
          }
        })
        productDetail = [...arrayWithLogo, ...arrayWithoutLogo]

        setProduct(productDetail);
        if (nbHits > 20 && (querySearch !== "" || query.category !== "" || query.allKeywords.length !== "" || query.targetPersona.length !== "" || query.company.length !== "" || query.subCategory.length !== "") || ((selectedCategories.length === 1) || (selectedSubCategories.length === 1) || (selectedAllKeywords.length === 1) || (selectedCompany.length === 1)) || (selectedTargetPersona.length === 1)) {
          setNbPages(nbPages);
        } else {
          setNbPages(0);
        }
        setNbHits(nbHits);
        isMounted = true;
      });
  };
  // when page hits and props change then hit a search
  useEffect(() => {
    if (isMounted) {
      isMounted = false;
      if (isFirstLoad) {
        fetchData();
      }
    }
  }, [props.location]);


  // change pagination and filters function
  const getProductList = async () => {
    setIsFirstLoad(true);
    const query = queryString.parse(props.location.search);

    await searchIndex
      .search(querySearch, {
        facets: ['category', 'subCategory', 'allKeywords', 'company', 'targetPersona'],
        facetFilters: [selectedCategories, selectedSubCategories, selectedAllKeywords, selectedCompany, selectedTargetPersona],
        numericFilters,
        hitsPerPage: 20,
        page
      })
      .then(({
        hits, facets, nbPages, page, nbHits
      }) => {

        let productDetail = hits
        const { category, subCategory } = facets;
        if (category) {
          setCategories(category);
        } else {
          setCategories([]);
        }
        if (subCategory) {
          setSubcategories(subCategory);
        } else {
          setSubcategories([]);
        }
        if (facets.allKeywords) {
          setAllKeywords(facets.allKeywords);
        } else {
          setAllKeywords([]);
        }
        if (facets.company) {
          setCompany(facets.company);
        } else {
          setCompany([]);
        }
        if (facets.targetPersona) {
          setTargetPersona(facets.targetPersona);
        } else {
          setTargetPersona([]);
        }
        let arrayWithoutLogo = [];
        let arrayWithLogo = [];

        productDetail.map((value) => {
          if (value.logo === "") {
            arrayWithoutLogo.push(value)
          } else {
            arrayWithLogo.push(value)
          }
        })
        productDetail = [...arrayWithLogo, ...arrayWithoutLogo]
        setProduct(productDetail);
        if ((nbHits > 20 && querySearch !== "") || ((selectedCategories.length === 1) || (selectedSubCategories.length === 1) || (selectedAllKeywords.length === 1) || (selectedCompany.length === 1) || (selectedTargetPersona.length === 1))) {
          setNbPages(nbPages);
        } else {
          setNbPages(0);
        }
        setNbHits(nbHits);

        setIsFirstLoad(false);
      })
      .catch((err) => {
        console.log(':: err', err);
      });
  };

  // when filter is changed
  useEffect(() => {
    function filterPageUpdate() {
      const queryDetail = {
        search: querySearch,
        category: selectedCategories.join(','),
        company: selectedCompany.join(','),
        allKeywords: selectedAllKeywords.join(','),
        targetPersona: selectedTargetPersona.join(','),
        subCategory: selectedSubCategories.join(','),
        employee: numericFilters.join(','),
        page
      };
      function setParams() {
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname
          }?${queryString.stringify(queryDetail)}`;
        window.history.pushState({ path: newurl }, '', newurl);
      }
      // history.push(`/product-list?${queryString.stringify(query)}`);
      if (!isFirstLoad) {
        const url = setParams();
        // props.history.push(`?${queryString.stringify(queryDetail)}`)
        getProductList();
      }
    }

    if (!isFirstLoad) {
      filterPageUpdate();
    }
  }, [selectedCategories, selectedSubCategories, numericFilters, page, querySearch, selectedCompany, selectedAllKeywords, selectedTargetPersona]);

  const changeCategoryFilter = (category, event) => {
    const cat = category;
    const e = event.target.checked;
    if (e) {
      let new_sub_cat = (cat.split(':')[1]).toLowerCase().replace(/\s+/g, "-");
      history.push(window.location.pathname + '/' + new_sub_cat);

      setSelectedCategories([...selectedCategories, cat]);
      setShowProducts({ ...showProducts, category: true })
      setPage(0);
    } else {
      let new_sub_cat = (cat.split(':')[1]).toLowerCase().replace(/\s+/g, "-");
      let new_pathname = window.location.pathname;
      let updated_pathname = new_pathname.replace("/" + new_sub_cat, "");
      //alert(updated_pathname)
      history.push(updated_pathname);

      setSelectedCategories(selectedCategories.filter((item) => item !== cat));
      setShowProducts({ ...showProducts, category: false })
      setPage(0);
    }
  };

  const changeCompanyFilter = (companyParam, event = false) => {
    const cat = companyParam;
    const e = event.target.checked;
    if (e) {
      setSelectedCompany([...selectedCompany, cat]);
      setShowProducts({ ...showProducts, company: true })
      setPage(0);
    } else {
      setSelectedCompany(selectedCompany.filter((item) => item !== cat));
      setShowProducts({ ...showProducts, company: false })
      setPage(0);
    }
  };

  const changeAllKeywordsFilter = (keywords, event) => {
    const cat = keywords;
    const e = event.target.checked;
    if (e) {
      setSelectedAllKeywords([...selectedAllKeywords, cat]);
      setShowProducts({ ...showProducts, keywords: true })
      setPage(0);
    } else {
      setSelectedAllKeywords(selectedAllKeywords.filter((item) => item !== cat));
      setShowProducts({ ...showProducts, keywords: false })
      setPage(0);
    }
  };

  const changeTargetPersonaFilter = (targetPersona, event) => {
    const cat = targetPersona;
    const e = event.target.checked;
    if (e) {
      setSelectedTargetPersona([...selectedTargetPersona, cat]);

      setPage(0);
    } else {
      setSelectedTargetPersona(selectedTargetPersona.filter((item) => item !== cat));
      setPage(0);
    }
  };

  const changeSubCategoryFilter = (category, event) => {
    event.persist();
    if (event.target.checked) {
      let new_sub_cat = (category.split(':')[1]).toLowerCase().replace(/\s+/g, "-");
      history.push(window.location.pathname + '/' + new_sub_cat);

      setSelectedSubCategories([...selectedSubCategories, category]);
      setShowProducts({ ...showProducts, subCategory: true })
      setPage(0);
    } else {
      let new_sub_cat = (category.split(':')[1]).toLowerCase().replace(/\s+/g, "-");
      let new_pathname = window.location.pathname;
      let updated_pathname = new_pathname.replace("/" + new_sub_cat, "");
      //alert(updated_pathname)
      history.push(updated_pathname);
      setSelectedSubCategories(selectedSubCategories.filter((item) => item !== category));
      setShowProducts({ ...showProducts, subCategory: false })
      setPage(0);
    }
  };

  // go to company page (for search bar)
  const goToCompanyPage = async (val) => {
    isMounted = false;
    setIsFirstLoad(true);
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setNumericFilters([]);
    if (val && val.product) {
      const query = {
        search: val.objectID,
        //category: [].join(','),
        //subCategory: [].join(','),
        //allKeywords: [].join(','),
        //company: [].join(',')
      };
      await history.push(`/product/${(val.product.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.category) {
      const query = {
        search: '',
        category: [`category:${val.category}`].join(','),
        //subCategory: [].join(','),
        //allKeywords: [].join(','),
        //company: [].join(','),
        page: 0
      };
      setShowProducts({ ...showProducts, val: '' })

      await history.push(`/category/${(val.category.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else if (val && val.subCategory) {
      const query = {
        search: '',
        // search: val.subCategory,
        //category: [].join(','),
        //allKeywords: [].join(','),
        //company: [].join(','),
        subCategory: [`subCategory:${val.subCategory}`].join(','),
        page: 0
      };
      setShowProducts({ ...showProducts, val: '' })
      await history.push(`/category/${(val.subCategory.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    } else {
      const query = {
        search: val,
        page: 0
      };
      await history.push(`/search/${(val.toLowerCase()).replace(/\s+/g, "-")}?${queryString.stringify(query)}`, {
        data: {
          params: val
        }
      });
    }
  };

  // to refresh the page on state change
  useEffect(() => {
  }, [isMounted, product]);

  // search via algolia for search bar
  const searchFromAlgolia = async (val) => {
    if (isMounted) {
      // setSearchInputValue(val);
      if (val.length < 3) {
        return;
      }
      await searchIndex
        .search(val, {
          attributesToRetrieve: ['product', 'category', 'subCategory', 'targetPersona'],
          facets: ['category', 'subCategory', 'targetPersona'],
          hitsPerPage: 8
        })
        .then(async ({ hits, facets }) => {
          hits = hits.slice(0, 4);
          const products = hits;
          const { category, subCategory } = facets;
          if (category) {
            // eslint-disable-next-line no-unused-expressions
            Object.keys(category)[0]
              ? products.push({
                category: Object.keys(category)[0]
              })
              : '';
            // eslint-disable-next-line no-unused-expressions
            Object.keys(category)[1]
              ? products.push({
                category: Object.keys(category)[1]
              })
              : '';
          }
          if (subCategory) {
            // eslint-disable-next-line no-unused-expressions
            Object.keys(subCategory)[0]
              ? products.push({
                subCategory: Object.keys(subCategory)[0]
              })
              : '';
            // eslint-disable-next-line no-unused-expressions
            Object.keys(subCategory)[1]
              ? products.push({
                subCategory: Object.keys(subCategory)[1]
              })
              : '';
          }
          setOptions(products);
        });
    }
  };

  function removeSearchQuery() {
    setShowProducts({ ...showProducts, val: '' })
    setQuerySearch('');
    setNbHits(0);
    setNbPages(0);
    // goToCompanyPage('');
  }

  const handleClick = () => {
    setOpen(!open);
  };
  const handleSubClick = () => {
    setIsSubOpen(!isSubOpen);
  };

  const handlePageClick = (data) => {
    setPage(data.selected);
    window.scrollTo(0, 0);
  };
  const classes = useStyles();

  const goToHome = () => history.push('/');
  const userName = localStorage.getItem('user');


  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    );

    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);


  return (
    <div className={classes.root}>
      <Helmet>
        <title>{titleBuilder(selectedCategories, selectedSubCategories, selectedCompany, selectedTargetPersona, selectedAllKeywords)}</title>
        <meta name="description" content={titleBuilder(selectedCategories, selectedSubCategories, selectedCompany, selectedTargetPersona, selectedAllKeywords)} />
      </Helmet>

      <AppBar className={classes.headerStyle} position="static">
        {
          ReactGA.pageview(window.location.pathname + window.location.search)
        }
        {hotjar.stateChange(window.location.pathname + window.location.search)}
        <Toolbar>
          <img class="logoImage" onClick={() => goToHome()} src={requestly_logo} />
          <img class="logoIcon" onClick={() => goToHome()} src={requestly_icon} />

          <div className={classes.rightNav + ' mobile-right-nav'}>
            <nav style={{ position: "relative" }}>
              <div className={`${classes.searchBarWrap} searchBarProduct`}>
                <Autocomplete
                  id="custom-input-demo"
                  value={querySearch}
                  freeSolo
                  classes={{
                    popper: classes.autoCompletePopperProduct
                  }}
                  getOptionLabel={(option) =>
                  (typeof option === 'string'
                    ? `${querySearch}`
                    : `${querySearch}`)}
                  filterOptions={(x) => x}
                  options={options}
                  disableClearable
                  onChange={async (event, newValue) => {
                    goToCompanyPage(newValue);

                  }}
                  onInputChange={(e, val) => {
                    if (val.length < 3) {
                      setOptions([]);
                    } else {
                      searchFromAlgolia(val);
                      setShowProducts({ ...showProducts, val: val })
                      if (val == '') {
                        removeSearchQuery()
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        class="searchInputStyle"
                        type="search"
                        placeholder="Search company, software name, category, service, CEO..."
                      />
                      <span className="material-icons face">
                        <SearchIcon className={classes.iconSize} />
                      </span>
                    </div>
                  )}
                  renderOption={(option) => {
                    return (
                      <Grid
                        container
                        alignItems="center"
                        onClick={async () => {
                          isMounted = false;
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          onClick={async () => {
                            isMounted = false;
                          }}
                        >
                          <Typography variant="body2" className={classes.autoCompleteTextStyle}>

                            {option && option.product && (option.product !== "requestlyCategoryPage")
                              ? `Product: ${option.product}`
                              : option.category && (option.product !== "requestlyCategoryPage") ? `Category: ${option.category}`
                                : option.subCategory && (option.product !== "requestlyCategoryPage") ? `Category: ${option.subCategory}`
                                  : null
                            }

                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />

              </div>
              {(user_detail === null || user_detail === false) ? (

                <div class="userName" onClick={() => { window.location.href = '/auth/login'; }}>
                  LOGIN
                </div>

              ) : (
                <div class="loginButton" onClick={() => { window.location.href = '/app/dashboard'; }}>
                  <div>
                    <PersonIcon />
                  </div>
                  <div classe="loginButtonText">
                    {user_detail.userName}
                  </div>
                </div>
              )}
            </nav>
          </div>
        </Toolbar>
      </AppBar>
      <Grid class="pageContainer">
        <Grid container class="contentContainer">

          <Grid item class="navContainer">
            <List
              class="navListStyle"
              component="nav"
              key="category"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <ListSubheader
                  className={`${classes.listHeader}`}
                  component="div"
                  id="nested-list-subheader"
                >
                  Category
                </ListSubheader>
              )}
            >
              {Object.keys(categories).map((key) => {
                return (
                  <ListItem class="listItemCategory" key={key}>
                    <div class="navIte">
                      <FormControlLabel

                        label={(
                          <div class="navItem">
                            <div class="navText">{`${key}`}</div>
                            <div class="navCount">{categories[key]}</div>
                          </div>
                        )}
                        control={(
                          <Checkbox
                            disableRipple
                            checked={selectedCategories.includes(`category: ${key}`)}
                            onChange={(event) => {
                              changeCategoryFilter(`category: ${key}`, event);
                            }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                          />
                        )}
                      />
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <List
              class="navListStyle"
              component="nav"
              key="subCategory"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <ListSubheader
                  className={`${classes.listHeader}`}
                  component="div"
                  id="nested-list-subheader"
                >
                  Sub Category
                </ListSubheader>
              )}
            >
              {Object.keys(subCategories).map((key, index) => {
                return (
                  <ListItem class="listItemCategory" key={key}>
                    <div class="navIte">
                      <FormControlLabel

                        label={(
                          <div class="navItem">
                            <div class="navText">{`${key}`}</div>
                            <div class="navCount">{subCategories[key]}</div>
                          </div>
                        )}
                        control={(
                          <Checkbox
                            disableRipple
                            checked={selectedSubCategories.includes(`subCategory: ${key}`)}
                            onChange={(event) => {
                              changeSubCategoryFilter(`subCategory: ${key}`, event);
                            }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                            name={key}
                          />
                        )}
                      />
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <List
              class="navListStyle"
              component="nav"
              key="company"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <ListSubheader
                  className={`${classes.listHeader}`}
                  component="div"
                  id="nested-list-subheader"
                >
                  company
                </ListSubheader>
              )}
            >
              {Object.keys(company).map((key, index) => {
                return (
                  <ListItem class="listItemCategory" key={key}>
                    <div class="navIte">
                      <FormControlLabel

                        label={(
                          <div class="navItem">
                            <div class="navText">{`${key}`}</div>
                            <div class="navCount">{company[key]}</div>
                          </div>
                        )}
                        control={(
                          <Checkbox
                            disableRipple
                            checked={selectedCompany.includes(`company: ${key}`)}
                            onChange={(event) => {
                              changeCompanyFilter(`company: ${key}`, event);
                            }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                            name={key}
                          />
                        )}
                      />
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            {/* Target persona */}
            <List
              class="navListStyle"
              component="nav"
              key="targetPersona"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <ListSubheader
                  className={`${classes.listHeader}`}
                  component="div"
                  id="nested-list-subheader"
                >
                  Software for
                </ListSubheader>
              )}
            >
              {Object.keys(targetPersona).map((key, index) => {
                return (
                  <ListItem class="listItemCategory" key={key}>
                    <div class="navIte">
                      <FormControlLabel

                        label={(
                          <div class="navItem">
                            <div class="navText">{`${key}`}</div>
                            <div class="navCount">{targetPersona[key]}</div>
                          </div>
                        )}
                        control={(
                          <Checkbox
                            disableRipple
                            checked={selectedTargetPersona.includes(`targetPersona: ${key}`)}
                            onChange={(event) => {
                              changeTargetPersonaFilter(`targetPersona: ${key}`, event);
                            }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                            name={key}
                          />
                        )}
                      />
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <List
              class="navListStyle"
              component="nav"
              key="allKeywords"
              aria-labelledby="nested-list-subheader"
              subheader={(
                <ListSubheader
                  className={`${classes.listHeader}`}
                  component="div"
                  id="nested-list-subheader"
                >
                  Keywords
                </ListSubheader>
              )}
            >
              {Object.keys(allKeywords).map((key, index) => {
                return (
                  <ListItem class="listItemCategory" key={key}>
                    <div class="navIte">
                      <FormControlLabel

                        label={(
                          <div class="navItem">
                            <div class="navText">{`${key}`}</div>
                            <div class="navCount">{allKeywords[key]}</div>
                          </div>
                        )}
                        control={(
                          <Checkbox
                            disableRipple
                            checked={selectedAllKeywords.includes(`allKeywords: ${key}`)}
                            onChange={(event) => {
                              changeAllKeywordsFilter(`allKeywords: ${key}`, event);
                            }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                            name={key}
                          />
                        )}
                      />
                    </div>
                  </ListItem>
                );
              })}
            </List>
            <Divider />

          </Grid>
          <Grid item xs={12} sm={8} lg={9} md={8} class="resultsContainer">
            <Grid>
              <h1 class="categoryPageTitle">
                {titleBuilder(selectedCategories, selectedSubCategories, selectedCompany, selectedTargetPersona, selectedAllKeywords)}
              </h1>
            </Grid>

            {showProducts.val.length > 0 || selectedCompany.length > 0 || selectedCategories.length > 0
              || selectedSubCategories.length > 0 || selectedAllKeywords.length || selectedTargetPersona.length > 0

              ? (<List key="productDetailList" name="productDetail" className={`${classes.productListStyle}`}>
                {product.map((detail) => {

                  return (
                    detail.contentType === 'categoryPageContent' ? (
                      <div class="categoryInfoContainer">
                        <div class="categoryDescription">{detail.description}</div>
                        <div class="categoryContent">
                          <div class="categoryVideo">
                            <iframe width="560" height="315" src={`${detail.mainVideo}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                          </div>
                          <div class="categoryVendors">
                            <div>
                              <h3 class="vendorTitle"><i class="fas fa-crown" aria-hidden="true"></i> Top Vendors</h3>
                              {detail.topVendors.map((key) => (
                                (detail.topVendors.length > 0) ? (
                                  <Chip
                                    avatar={< Avatar alt={key} src={key.split("|")[1]} />}
                                    className={classes.chipsStyle}
                                    key={key}
                                    clickable
                                    size="medium"
                                    label={key.split("|")[0]}
                                    onClick={() => { window.location.href = `${key.split("|")[2]}`; }}
                                  />
                                ) : null
                              ))}
                              <hr class="line" />
                            </div>
                            <div>
                              <h3 class="vendorTitle"><i class="fas fa-fire" aria-hidden="true"></i> Challenger Vendors</h3>
                              {detail.challengerVendors.map((key) => (
                                (detail.challengerVendors.length > 0) ? (
                                  <Chip
                                    avatar={< Avatar src={key.split("|")[1]} />}
                                    className={classes.chipsStyle}
                                    key={key}
                                    size="medium"
                                    label={key.split("|")[0]}
                                    onClick={() => { window.location.href = `${key.split("|")[2]}`; }}
                                  />
                                ) : null
                              ))}
                            </div>

                          </div>
                        </div>
                        <div class="kpiInfo">KPIs
                          {detail.kpiInfo.map((key) => (
                            (detail.kpiInfo.length > 0) ? (
                              <Chip
                                clickable
                                className={classes.chipsStyle}
                                key={key}
                                size="small"
                                label={key.split("|")[0]}
                              />
                            ) : null
                          ))}
                        </div>
                      </div>

                    ) : null
                  );
                })}
              </List>) : null}

            <div class="searchQueryContainer">
              <div class="queryChips">

                {Object.keys(categories).map((key) => {
                  return (

                    (selectedCategories.length === 1 && Object.keys(categories).length === 1) ?

                      <div class="requestlyTooltip">
                        <Chip
                          icon={<CategoryIcon />}
                          size="small"
                          className={classes.chipsStyle}
                          label={selectedCategories.toString().replace('category:', '')}
                          onClick={(event) => {
                            changeCategoryFilter(`category: ${key}`, event);
                          }}
                          onDelete={(event) => {
                            changeCategoryFilter(`category: ${key}`, event);
                          }}
                        />
                        <span class="requestlyTooltiptext top">Remove Category filter</span>
                      </div>
                      : null
                  );
                })}

                {Object.keys(subCategories).map((key) => {
                  return (

                    (selectedSubCategories.length === 1 && Object.keys(subCategories).length === 1) ?
                      <div class="requestlyTooltip">
                        <Chip
                          icon={<CategoryIcon />}
                          size="small"
                          className={classes.chipsStyle}
                          label={`${key}`}
                          onClick={(event) => {
                            changeSubCategoryFilter(`subCategory: ${key}`, event);
                          }}
                          onDelete={(event) => {
                            changeSubCategoryFilter(`subCategory: ${key}`, event);
                          }}
                        />
                        <span class="requestlyTooltiptext top">Remove Subcategory filter</span>
                      </div>
                      : null
                  );
                })}

                {Object.keys(company).map((key) => {
                  return (

                    (selectedCompany.length === 1 && Object.keys(company).length === 1) ?
                      <div class="requestlyTooltip">
                        <Chip
                          className={classes.chipsStyle}
                          icon={<BusinessIcon />}
                          key={key}
                          size="small"
                          label={`${key}`}
                          onClick={(event) => {
                            changeCompanyFilter(`company: ${key}`, event);
                          }}
                          onDelete={(event) => {
                            changeCompanyFilter(key, event);
                          }}
                        />
                        <span class="requestlyTooltiptext top">Remove Company filter</span>
                      </div>
                      : null
                  );
                })}

                {chipBuilder(selectedTargetPersona).map((key) => (

                  (selectedTargetPersona.length > 0) ? (
                    <div class="requestlyTooltip">
                      <Chip
                        icon={<Person />}
                        className={classes.chipsStyle}
                        key={key}
                        size="small"
                        label={key.replace("targetPersona:", "")}
                        onClick={(event) => {
                          changeTargetPersonaFilter(key, event);
                        }}
                        onDelete={(event) => {
                          changeTargetPersonaFilter(key, event);
                        }}
                      />
                      <span class="requestlyTooltiptext top">Remove Software for filter</span>
                    </div>
                  ) : null
                ))}

                {chipBuilder(selectedAllKeywords).map((key) => (

                  (selectedAllKeywords.length > 0) ? (
                    <div class="requestlyTooltip">
                      <Chip
                        icon={<KeyIcon />}
                        className={classes.chipsStyle}
                        size="small"
                        label={key.toString().replace("allKeywords:", "")}
                        onClick={(event) => {
                          changeAllKeywordsFilter(key, event);
                        }}
                        onDelete={(event) => {
                          changeAllKeywordsFilter(key, event);
                        }}
                      />
                      <span class="requestlyTooltiptext top">Remove Keyword</span>
                    </div>
                  ) : null
                ))}

                {showProducts.val.length > 0 ? (
                  <div class="requestlyTooltip">
                    <Chip
                      icon={<SearchIcon />}
                      size="small"
                      label={"\"" + showProducts.val + "\""}
                      onClick={() => { removeSearchQuery() }}
                      onDelete={() => { removeSearchQuery() }}
                    />
                    <span class="requestlyTooltiptext top">Remove Search Term</span>
                  </div>
                ) : null

                }
              </div>
              {showProducts.val.length > 0 || selectedCompany.length > 0 || selectedCategories.length > 0
                || selectedSubCategories.length > 0 || selectedAllKeywords.length > 0 || selectedTargetPersona.length > 0 ?

                <div class="queryResultsCount"> {' '}{nbHits}{' '}products found</div>
                : ""
              }

            </div>

            <div class="searchResultsContiner">

              {showProducts.val.length > 0 || selectedCompany.length > 0 || selectedCategories.length > 0
                || selectedSubCategories.length > 0 || selectedAllKeywords.length || selectedTargetPersona.length > 0

                ? (<List key="productDetailList" name="productDetail" className={`${classes.productListStyle}`}>
                  {product.map((detail) => {

                    return (
                      detail.contentType !== 'categoryPageContent' ? (
                        <div>
                          <buttonListItem class="resultsListItem" onClick={() => goToCompanyPage(detail)} key={detail.objectID}>
                            <div class="productDetail">
                              <div class="productLogo">
                                {
                                  detail.logo && detail.logo !== '' && (detail.logo !== "https://requestly-media-public.s3.eu-west-2.amazonaws.com/company_logos/undefined")
                                    ? (<img class="productLogoImage" src={detail.logo} />)
                                    : <div class="noLogo"></div>
                                }
                              </div>
                              <div class="productInfo">
                                <div class="mobileTitle">
                                  {
                                    detail.logo && detail.logo !== '' && (detail.logo !== "https://requestly-media-public.s3.eu-west-2.amazonaws.com/company_logos/undefined")
                                      ? (<img class="productLogoImageMobile" src={detail.logo} />)
                                      : <div class="noLogoMobile"></div>
                                  }
                                  <h2 class="productTitle">{detail.product}</h2>
                                </div>
                                <div class="productDescription">{detail.description && detail.description.replace(/<[^>]+>/g, '').substring(0, 250)}...</div>
                                <div class="productCategory">

                                  {detail.category.length > 0 ? (
                                    <div class="productCategoryChip"
                                      onClick={(event) => {
                                        changeCategoryFilter(`category: ${detail.category}`, event);
                                      }}
                                    >
                                      {detail.category}
                                    </div>
                                  ) : null}
                                  {detail.subCategory.length > 0 ? (
                                    <div class="productSubCategoryChip">
                                      {detail.subCategory}
                                    </div>
                                  ) : null}

                                </div>
                              </div>
                            </div>
                            <div class="companyDetail">
                              <div class="companyTitle">Company Details</div>
                              {detail.company ? (
                                <div class="companyInfo companyData">
                                  <Icon style={{ marginRight: "5px" }} className="far fa-building" />{detail.company}
                                </div>
                              ) : ('')}

                              {detail.hqLocation ? (
                                <div class="companyInfo">
                                  <Icon style={{ marginRight: "5px" }} className="far fa-compass" />
                                  {detail.hqLocation}
                                </div>
                              ) : ('')}

                              <div class="companyInfo"><Icon style={{ marginRight: "5px" }} className="fas fa-globe" />
                                <a href={detail.clean_url.includes('http') ? detail.clean_url : `http://${detail.clean_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {detail.clean_url}
                                </a>
                              </div>
                            </div>
                          </buttonListItem>

                        </div>) : null
                    );
                  })}
                </List>) : "Please enter a search term or use the navigation on the left to refine your search. If you want to setup a discovery meeting or product demo just search for the company or product name"}
            </div>
          </Grid>
        </Grid>
        {nbPages > 1 && (showProducts.val.length > 0 || selectedCompany.length > 0 || selectedCategories.length > 0
          || selectedSubCategories.length > 0 || selectedAllKeywords.length > 0 || selectedTargetPersona.length > 0) ? (
          <Grid container justify="center">
            <ReactPaginate
              previousLabel="< Prev"
              nextLabel="Next >"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={nbPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              forcePage={parseInt(page)}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              activeClassName="active"
              showFirstButton={true}
              showLastButton={true}
            />
          </Grid>
        ) : (
          ''
        )}

      </Grid>
      <div className="container-footer">
        <div class="footer-social-links">
          <div class="icon-container">
            <div class="icon-circle"><div class="icon-holder"><a href="https://www.linkedin.com/company/requestlysoftware" title="LinkedIn" target="_blank"><img src={linkedinIcon} class="icon-style" /></a></div></div>
            <div class="icon-circle"><div class="icon-holder"><a href="https://www.facebook.com/Requestly-102800984897043" title="Facebook" target="_blank"><img src={facebookIcon} class="icon-style" /></a></div></div>
            <div class="icon-circle"><a href="https://twitter.com/requestlyCo" title="Twitter" target="_blank"><img src={twitterIcon} class="icon-style" /></a></div>
            <div class="icon-circle"><a href="https://www.youtube.com/channel/UCdhB4ezKN4Ev1GA6YTVw7dg" title="YouTube" target="_blank"><img src={youtubeIcon} class="icon-style" /></a></div>
            <div class="icon-circle"><a href="https://vimeo.com/requestly" title="Vimeo" target="_blank"><img src={vimeoIcon} class="icon-style" /></a></div>
          </div>
        </div>
        <div class="footer-text">
          <div class="footer-copy">
            © requestly 2020
          </div>
          <div class="footer-made">
            Because life is too short.
          </div>
        </div>
      </div>
    </div >

  );
};

export default ProductPage;
