/* eslint-disable eol-last */
import axios from "axios";
import { loaderService } from "../../components/loader/loader.service";

export const getUserDetail = async () => {
  const baseUrl =
    window.location.hostname === "requestly.co"
      ? "https://prod-api.requestly.co/"
      : "https://dev-api.requestly.co/";

  loaderService.show(true);
  const url = `${baseUrl}users/me`;
  return axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token")
      }
    })
    .then(response => {
      loaderService.show(false);
      return response.data;
    })
    .catch(error => {
      loaderService.show(false);
      return false;
    });
};
