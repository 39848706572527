/* eslint-disable eol-last */
import { loaderService } from '../../components/loader/loader.service';
import { alertService } from '../../components/toaster/alert.service';
import AxiosInstance from '../../interceptor/http-intercepor';
import Configuration from '../../utils/config';

class BillingService {
  constructor() {
    this.config = new Configuration();
  }

  async getCreditHistory(isFromRFP, rfpId) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem('userId');
    let url = this.config.baseUrl + this.config.creditHistory;

    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async getSubsciptionPlans() {
    const self = this;
    loaderService.show(true);
    let url = this.config.baseUrl + this.config.subscriptionPlans;

    return AxiosInstance.get(url)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch((error) => {
        self.handleError(error);
      });
  }

  async createCheckoutSession(data) {
    loaderService.show(true);

    const url = this.config.baseUrl + this.config.createCheckoutSession;

    const config = {
      headers: {
        'content-type': 'application/json',
      }
    }

    return AxiosInstance.post(url, data, config)
      .then((response) => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false
      window.scrollTo(0, 0)
      alertService.error(error.response.data.message, { autoClose, keepAfterRouteChange })
    } else if (error.request) {
      // The request was made but no response was received
      console.log(':: error ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(':: Error', error.message);
    }
  }
}

export default BillingService;
