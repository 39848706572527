import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton, makeStyles, Toolbar
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { history } from 'src/utils/utils';


const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const [notifications] = useState([]);
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpenDiscovery, setModalOpenDiscovery] = useState(false)
  const [modalOpenProductDemo, setModalOpenProductDemo] = useState(false)
  const [modalOpenProductDemoVendor, setModalOpenProductDemoVendor] = useState(false)


  const logout = () => {

    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance()
      if (auth2 != null) {
        auth2.signOut().then(auth2.disconnect().then('onLogoutSuccess'))
      }

    }
    localStorage.clear();
    history.push('/auth/login')
  }

  const handleClick = () => {
    setModalOpen(true)
  }

  const productDemo = () => {
    setModalOpenProductDemo(true)
  }

  const productDemoVendor = () => {
    setModalOpenProductDemoVendor(true)
  }

  const discoveryMetting = () => {
    setModalOpenDiscovery(true)
  }

  console.log(modalOpenProductDemoVendor, "modalOpenProductDemoVendor")

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/app/dashboard">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon onClick={handleClick} />
            </Badge>
          </IconButton>


          <IconButton style={{ "fontSize": "16px", }} color="inherit" onClick={logout}>

            <InputIcon style={{ "marginRight": "5px", }} />
            Logout
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <div>
      </div>
    </AppBar>

  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
