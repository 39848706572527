import {
  AppBar, Box,
  Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PropTypes from 'prop-types';
import React from 'react';
import RFPDashboardService from 'src/services/rfp/rfp-dashboard.service';
import { questionScoringType, questionType } from 'src/views/shared/data/dropdowns.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formControl: {
    minWidth: 120,
  },
  hidden: { display: 'none' },
  commercialTab: {
    maxWidth: 270,
  }
}
));

const VendorResponses = ({ vendorResponseState, vendorOrginalResponseState, setVendorResponseState, vendorFilterState, setVendorFilterState, selectedFilterState, updateSelectedFilterState, saveCategoryAns, vendorResponseCommercialState, updateSelectedFilterCommercialState, selectedFilterStateCommercial, vendorFilterStateCommercial, setVendorResponseCommercialState }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expandedCategory, setExpandedCategory] = React.useState('');
  const [expandedSection, setExpandedSection] = React.useState('');
  const [expandedQuestion, setExpandedQuestion] = React.useState('');
  const [commercial_tab_value, setCommercialTabValue] = React.useState(0);

  const rfpDashboardService = new RFPDashboardService();

  const [age, setAge] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState({ isDisabledCommercialSection: true, isDisabledTechnicalSection: true });


  const [main_cat, setMainCat] = React.useState([]);
  const [section_main_cat, setSectionMainCat] = React.useState([]);


  const handleUpdateFilters = (event, type) => {

    if (type === 'vendors') {
      if (event.target.value.includes("All Vendors")) {
        selectedFilterState = { vendors: Array(0), responseType: "", category: "", section: "" }
      } else {
        selectedFilterState.vendors = event.target.value;
      }
    } else if (type === 'responseType') {
      selectedFilterState.responseType = event.target.value;
    } else if (type === 'category') {
      selectedFilterState.category = event.target.value;
      if (event.target.value === "Select") {
        setIsDisabled({ ...isDisabled, isDisabledTechnicalSection: true });
      } else {
        setIsDisabled({ ...isDisabled, isDisabledTechnicalSection: false });
      }
      selectedFilterState.section = '';
    } else if (type === 'section') {
      selectedFilterState.section = event.target.value;
    }
    updateSelectedFilterState({ ...selectedFilterState });
  };

  const handleUpdateFiltersCommercial = (event, type) => {

    if (type === 'vendors') {
      if (event.target.value.includes("All Vendors")) {
        selectedFilterStateCommercial = { vendors: Array(0), responseType: "", category: "", section: "" }
      } else {
        selectedFilterStateCommercial.vendors = event.target.value;
      }
    } else if (type === 'responseType') {
      selectedFilterStateCommercial.responseType = event.target.value;
    } else if (type === 'category') {
      selectedFilterStateCommercial.category = event.target.value;
      if (event.target.value === "Select") {
        setIsDisabled({ ...isDisabled, isDisabledCommercialSection: true });
      } else {
        setIsDisabled({ ...isDisabled, isDisabledCommercialSection: false });
      }
      selectedFilterStateCommercial.section = '';
    } else if (type === 'section') {
      selectedFilterStateCommercial.section = event.target.value;
    }

    updateSelectedFilterCommercialState({ ...selectedFilterStateCommercial });
  };

  const handleChangeBuyerResponse = (event, vendorIndex, categoryIndex, sectionIndex, questionIndex, vendorId) => {
    //console.log(vendorResponseState);
    //alert(vendorId)
    vendorResponseState.responses[vendorIndex].categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answerRating = event.target.value;
    setVendorResponseState({ ...vendorResponseState });
    vendorResponseState.type = "technical";
    saveCategoryAns(vendorResponseState);
  };

  const handleChangeBuyerResponseCommercial = (event, vendorIndex, categoryIndex, sectionIndex, questionIndex, vendorId) => {

    vendorResponseCommercialState.responses[vendorIndex].categories[categoryIndex].sections[sectionIndex].questions[questionIndex].answerRating = event.target.value;
    setVendorResponseCommercialState({ ...vendorResponseCommercialState });
    //console.log(vendorResponseCommercialState)
    //alert(vendorId);
    vendorResponseCommercialState.type = "commercial";
    saveCategoryAns(vendorResponseCommercialState);
  }

  const handleChangeMainCat = (name, id) => {
    var cat = [...main_cat];
    if (cat.includes(id)) {
      var index = cat.indexOf(id);
      if (index !== -1) {
        cat.splice(index, 1);
      }
    } else {
      cat.push(id);
    }
    setMainCat(cat);
  }
  const handleChange = (category) => (event, isExpanded) => {
    setExpandedCategory(category);
    if (expandedCategory !== category) {
      setExpandedCategory(category);
    } else {
      setExpandedCategory('');
    }
  };

  const handleChangeCommercialTab = (event, newValue) => {
    setCommercialTabValue(newValue);
  }


  const handleSectionChange = (id) => {
    var cat = [...section_main_cat];
    if (cat.includes(id)) {
      var index = cat.indexOf(id);
      if (index !== -1) {
        cat.splice(index, 1);
      }
    } else {
      cat.push(id);
    }
    setSectionMainCat(cat);
  }

  const handleQuestionChange = (question) => (event, isExpanded) => {
    if (expandedQuestion !== question) {
      setExpandedQuestion(question);
    } else {
      setExpandedQuestion('');
    }
  };

  const camelize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const answer = (answerRating) => {
    return questionScoringType.find(type => type.value === answerRating).label;
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function a11yPropsCommercial(index) {
    return { id: `commercial-simple-tab-${index}`, 'aria-controls': `commercial-simple-tabpanel-${index}` };
  }
  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  console.log(vendorResponseState, "vendorResponseState")

  return (
    <div className={classes.root}>
      <Grid style={{ "margin-top": "3px", "margin-bottom": "10px" }} container spacing={3}>
        <Grid spacing={3} md={12} item sm={12} xs={12} className="padding-requestly-Completion">
          <AppBar position="static" color="default">
            <Tabs className="requestly-dashboard-inner-tabs" value={value} onChange={handleChange1} indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              <Tab label="Scores" {...a11yProps(0)} />
              <Tab label="Completion" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} className="requestly-table-vendors">
            <TableContainer component={Paper}>
              <Table className={classes.table + ' requestly-dashboard-table'} aria-label="simple table">
                <TableHead style={{ "background-color": "#40506b" }}>
                  <TableRow>
                    <TableCell style={{ "color": "#fff", "text-align": "center" }}> Vendors </TableCell>
                    <TableCell style={{ "color": "#fff", "text-align": "center" }}> Category </TableCell>
                    <TableCell style={{ "color": "#fff", "text-align": "left" }} colSpan={(vendorOrginalResponseState.responses.length > 0) ? vendorOrginalResponseState.responses[0].maxSectionLength : 1}> Section </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="">
                  {
                    (vendorOrginalResponseState.responses.length > 0) ?
                      (vendorOrginalResponseState.responses.map(vendor => (
                        vendorOrginalResponseState.responses[0].categories.map((category, index) => (
                          <React.Fragment>
                            <TableRow>
                              {index === 0 ? (
                                <TableCell
                                  component="th"
                                  rowSpan={vendor.categories.length}
                                  style={{ "text-align": "center", "border": "rgb(212 205 205) solid thin" }}
                                > {vendor.companyName} </TableCell>
                              ) : null}
                              <TableCell style={{ "text-align": "center", "border": "rgb(212 205 205) solid thin" }}> {category.categoryName} </TableCell>
                              {vendorOrginalResponseState.responses[0].categories[index].sections.map((section, sectionIndex) => (
                                <TableCell style={{ "text-align": "center" }}> {section.sectionName} <br /> {vendor.categories[index].sections[sectionIndex].sectionPercentage} %</TableCell>
                              ))}
                            </TableRow>
                          </React.Fragment>
                        ))
                      ))) :
                      (<TableRow><TableCell style={{ "text-align": "center" }} colSpan={3}> No Vendor Response Yet </TableCell></TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead style={{ "background-color": "#40506b" }}>
                  <TableRow>
                    <TableCell style={{ "color": "#fff", "text-align": "center" }}> Vendors </TableCell>
                    <TableCell style={{ "color": "#fff", "text-align": "center" }}> Category </TableCell>
                    <TableCell style={{ "color": "#fff", "text-align": "left" }} colSpan={(vendorOrginalResponseState.responses.length > 0) ? vendorOrginalResponseState.responses[0].maxSectionLength : 1}> Section </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    (vendorOrginalResponseState.responses.length > 0) ?
                      (vendorOrginalResponseState.responses.map(vendor => (
                        vendorOrginalResponseState.responses[0].categories.map((category, index) => (
                          <React.Fragment>
                            <TableRow>
                              {index === 0 ? (<TableCell component="th" rowSpan={vendor.categories.length} style={{ "text-align": "center", "border": "rgb(212 205 205) solid thin" }}> {vendor.companyName} </TableCell>) : null}
                              <TableCell style={{ "text-align": "center", "border": "rgb(212 205 205) solid thin" }}> {category.categoryName} </TableCell>
                              {vendorOrginalResponseState.responses[0].categories[index].sections.map((section, sectionIndex) => (
                                <TableCell style={{ "text-align": "center" }}> {section.sectionName} <br /> {vendor.categories[index].sections[sectionIndex].sectionQuestionAnsweredPercentage} %</TableCell>
                              ))}
                            </TableRow>
                          </React.Fragment>
                        ))
                      ))) :
                      (<TableRow><TableCell style={{ "text-align": "center" }} colSpan={3}> No Vendor Response Yet </TableCell></TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </Grid>
      </Grid>
      <React.Fragment>

        <AppBar position="static" color="default">
          <Tabs value={commercial_tab_value} onChange={handleChangeCommercialTab} aria-label="simple tabs example">
            <Tab label="Technical Requirements" {...a11yPropsCommercial(0)} />
            <Tab label="Commercial Requirements" className={classes.commercialTab} {...a11yPropsCommercial(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={commercial_tab_value} index={0}>

          <Grid style={{ "margin-top": "15px", "margin-bottom": "10px" }} container spacing={3}>
            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">All Vendors</InputLabel>
                <Select
                  fullWidth
                  multiple
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedFilterState.vendors}
                  onChange={(event) => handleUpdateFilters(event, 'vendors')}
                  label="All Vendors"
                  className="requestly-rfp-textbox-dashboard"
                >

                  <MenuItem name="all" value="All Vendors">
                    <option >All Vendors</option>
                  </MenuItem>
                  {vendorFilterState.vendor.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Response Type"
                name="companyType"
                onChange={(event) => handleUpdateFilters(event, 'responseType')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterState.responseType}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
              >
                <option key='' value='Select'>Select</option>
                {questionType.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Category"
                name="companyType"
                onChange={(event) => handleUpdateFilters(event, 'category')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterState.category}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
              >
                <option key='' value='Select'>Select</option>
                {vendorFilterState.categories.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Section"
                name="companyType"
                onChange={(event) => handleUpdateFilters(event, 'section')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterState.section}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
                disabled={isDisabled.isDisabledTechnicalSection}
              >
                <option key='' value='Select'>{isDisabled.isDisabledTechnicalSection ? "Select A category First" : "select"}</option>
                {vendorFilterState.sections.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          {vendorResponseState?.responses?.length ? (
            <TableContainer className="requestly-dashboard-table-container" id="dataTblBox" component={Paper}>
              <Table className={classes.table + ' requestly-dashboard-table-class'} aria-label="sticky table">
                <TableBody>
                  <Table className={classes.table} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ "min-width": "840px" }}></TableCell>
                        {vendorResponseState.responses.map((vendor) => (<TableCell align="center" style={{ "width": "100px", "word-break": "break-word", "maxWidth": '100px' }}> {vendor.companyName.substr(0, 15)} </TableCell>))}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ "min-width": "840px" }}></TableCell>
                        {vendorResponseState.responses.map((vendor) => (<TableCell align="center" style={{ "width": "100px", "maxWidth": '100px' }}> {vendor.vendorScore}/{vendor.total} </TableCell>))}
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableBody>
              </Table>

              {
                vendorResponseState.responses[0].categories.map((category, index) => (
                  <React.Fragment>
                    <Table className={classes.table + ' requestly-dashboard-table-outer-class'} aria-label="sticky table">
                      <TableBody>
                        <Table className={classes.table} aria-label="sticky table">
                          <TableHead>
                            <TableRow style={{ "background-color": "#40506b", "color": "#fff", "font-size": "16px" }}>
                              <TableCell
                                onClick={e => handleChangeMainCat(category.categoryName, index)}
                                style={{ "min-width": "840px" }}>
                                {
                                  //(expandedCategory == category.categoryName) 
                                  (main_cat.includes(index))
                                    ?
                                    <ArrowDropUpIcon /> :
                                    <ArrowDropDownIcon />}
                                {category.categoryName}
                              </TableCell>

                              {vendorResponseState.responses.map((vendor) => (<TableCell align="center" style={{ "width": "100px", "maxWidth": '100px' }}>
                                {vendor.categories[index].technicalScore}/{vendor.categories[index].total}
                              </TableCell>))}
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableBody>
                    </Table>

                    <Table
                      className={classes.table + ''}
                      aria-label="sticky table"
                      className={(main_cat.includes(index)) ? classes.hidden : ''}
                    >
                      <TableBody>
                        <Table className={classes.table + ' requestly-dashboard-table-inner-class'} aria-label="sticky table">
                          {vendorResponseState.responses[0].categories[index].sections.map((section, sectionIndex) => (
                            <React.Fragment>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    //onClick={handleSectionChange(section.sectionName + '-' + sectionIndex)}
                                    onClick={e => handleSectionChange(index + '-' + sectionIndex)}
                                    style={{ "min-width": "840px", }}
                                  >
                                    {(section_main_cat.includes(index + '-' + sectionIndex))
                                      ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                                    }
                                    {section.sectionName}
                                  </TableCell>
                                  {vendorResponseState.responses.map((vendor) => (
                                    <TableCell align="center" style={{ "width": "100px", "maxWidth": '100px' }}> {vendor.categories[index].sections[sectionIndex].technicalScore}/{vendor.categories[index].sections[sectionIndex].total} </TableCell>))
                                  }
                                </TableRow>
                              </TableHead>
                              <TableBody className={(section_main_cat.includes(index + '-' + sectionIndex)) ? classes.hidden : ''}>
                                {vendorResponseState.responses[0].categories[index].sections[sectionIndex].questions.map((question, questionIndex) => (
                                  (question.type === 'yes-no') ?
                                    (<TableRow style={{ "min-width": "1840px", }} >
                                      <TableCell component="th" > {question.question} </TableCell>
                                      {vendorResponseState.responses.map((vendor) => (<TableCell align="center" style={{ "min-width": "85px", "position": "relative", "padding": "10px" }}> {camelize(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer)}
                                        {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer.length === 0 && <p style={{ "position": "absolute", "top": "37%", right: "40%", fontSize: "12px", fontWeight: "400" }}> 0 </p>}
                                        {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "no" && <p style={{ "position": "absolute", "top": "70%", "right": "45%", fontSize: "12px", fontWeight: "400" }}>0</p>}
                                        {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "yes" && <p style={{ "position": "absolute", "top": "70%", "right": "45%", fontSize: "12px", fontWeight: "400" }}>{question.score}</p>}

                                      </TableCell>))}
                                    </TableRow>) :
                                    (<React.Fragment>
                                      <TableRow onClick={handleQuestionChange(question.question)}>
                                        <TableCell style={{ "min-width": "840px", }} component="th"> {(expandedQuestion == question.question) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}   {question.question} </TableCell>

                                        {vendorResponseState.responses.map((vendor) => (<TableCell align="center" style={{ "min-width": "85px", "word-break": "break-word", "position": "relative", "paddingRight": "10px" }}>
                                          {answer(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating)}
                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "fully-met" && <p style={{ "position": "absolute", "top": "70%", "right": "37%", fontSize: "12px", fontWeight: "400" }}>
                                            {(vendor.categories[index].sections[sectionIndex].questions[questionIndex].score)}
                                          </p>}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "partially-met" && <p style={{ "position": "absolute", "top": "77%", "right": "33%", fontSize: "12px", fontWeight: "400" }}>
                                            {(vendor.categories[index].sections[sectionIndex].questions[questionIndex].score) / 2}
                                          </p>}
                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "not-met" && <p style={{ "position": "absolute", "top": "70%", "right": "37%", fontSize: "12px", fontWeight: "400" }}> 0 </p>}


                                        </TableCell>))}



                                      </TableRow>

                                      <Table className={expandedQuestion == question.question + ' requestly-dashboard-table-inner-2-class' ? classes.hidden : 'requestly-dashboard-table-inner-2-class'} aria-label="simple table">
                                        <TableBody>
                                          {vendorResponseState.responses.map((vendor, vendorIndex) => (
                                            <TableRow>
                                              <TableCell component="th">{vendor.companyName}  </TableCell>
                                              <TableCell align="left"> {camelize(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer)} </TableCell>
                                              <TableCell align="center">
                                                <TextField
                                                  fullWidth
                                                  label="Score"
                                                  name="companyType"
                                                  onChange={(event) => handleChangeBuyerResponse(event, vendorIndex, index, sectionIndex, questionIndex, vendor.vendorId)}
                                                  select
                                                  SelectProps={{ native: true }}
                                                  value={vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating}
                                                  variant="outlined"
                                                  className="requestly-rfp-textbox-dashboard"
                                                  disabled={vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "" && vendor.categories[index].sections[sectionIndex].questions[questionIndex].type === "detailed"}
                                                >
                                                  {questionScoringType.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </TextField>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </React.Fragment>)
                                ))}
                              </TableBody></React.Fragment>
                          ))}
                        </Table>
                      </TableBody>

                    </Table>


                  </React.Fragment>
                ))
              }

            </TableContainer>
          ) : null}
        </TabPanel>

        <TabPanel value={commercial_tab_value} index={1}>
          <Grid style={{ "margin-top": "15px", "margin-bottom": "10px" }} container spacing={3}>

            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label-2">All Vendors</InputLabel>
                <Select
                  fullWidth
                  multiple
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedFilterStateCommercial.vendors}
                  onChange={(event) => handleUpdateFiltersCommercial(event, 'vendors')}
                  label="All Vendors"
                  className="requestly-rfp-textbox-dashboard"
                >
                  <MenuItem name="all" value="All Vendors">
                    <option >All Vendors</option>
                  </MenuItem>
                  {vendorFilterStateCommercial.vendor.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Response Type"
                name="companyType"
                onChange={(event) => handleUpdateFiltersCommercial(event, 'responseType')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterStateCommercial.responseType}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
              >
                <option key='' value='Select'>Select</option>
                {questionType.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Category"
                name="companyType"
                onChange={(event) => handleUpdateFiltersCommercial(event, 'category')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterStateCommercial.category}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
              >
                <option key='' value='Select'>Select</option>
                {vendorFilterStateCommercial.categories.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid spacing={3} md={3} item sm={3} xs={3}>
              <TextField
                fullWidth
                label="Section"
                name="companyType"
                onChange={(event) => handleUpdateFiltersCommercial(event, 'section')}
                select
                SelectProps={{ native: true }}
                value={selectedFilterStateCommercial.section}
                variant="outlined"
                className="requestly-rfp-textbox-dashboard"
                disabled={isDisabled.isDisabledCommercialSection}
              >
                <option key='' value='Select'>{isDisabled.isDisabledCommercialSection ? "Select A Category First" : "select"}</option>
                {vendorFilterStateCommercial.sections.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>

          </Grid>
          {vendorResponseCommercialState?.responses?.length ? (
            <TableContainer className="requestly-dashboard-table-container" id="dataTblBox" component={Paper}>
              <Table className={classes.table + ' requestly-dashboard-table-class'} aria-label="sticky table">
                <TableBody>
                  <Table className={classes.table} aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ "min-width": "840px" }}></TableCell>
                        {vendorResponseCommercialState.responses.map((vendor) => (<TableCell align="center" style={{ "min-width": "85px", "word-break": "break-word" }}> {vendor.companyName.substr(0, 15)} </TableCell>))}
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ "min-width": "840px" }}></TableCell>
                        {vendorResponseCommercialState.responses.map((vendor) => (<TableCell align="center" style={{ "max-width": "100px", width: "100px" }}> {vendor.vendorScore}/{vendor.total} </TableCell>))}
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableBody>
              </Table>

              {
                vendorResponseCommercialState.responses[0].categories.map((category, index) => (
                  <React.Fragment>
                    <Table className={classes.table + ' requestly-dashboard-table-outer-class'} aria-label="sticky table">
                      <TableBody>
                        <Table className={classes.table} aria-label="sticky table">
                          <TableHead>
                            <TableRow style={{ "background-color": "#40506b", "color": "#fff", "font-size": "16px" }}>
                              <TableCell
                                onClick={e => handleChangeMainCat(category.categoryName, index)}
                                style={{ "min-width": "840px" }}>
                                {
                                  //(expandedCategory == category.categoryName) 
                                  (main_cat.includes(index))
                                    ?
                                    <ArrowDropUpIcon /> :
                                    <ArrowDropDownIcon />}
                                {category.categoryName}
                              </TableCell>

                              {vendorResponseCommercialState.responses.map((vendor) => (<TableCell align="center" style={{ "width": "100px", "maxWidth": '100px' }}>
                                {vendor.categories[index].technicalScore}/{vendor.categories[index].total}
                              </TableCell>))}
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableBody>
                    </Table>

                    <Table
                      className={classes.table + ''}
                      aria-label="sticky table"
                      className={(main_cat.includes(index)) ? classes.hidden : ''}
                    >
                      <TableBody>
                        <Table className={classes.table + ' requestly-dashboard-table-inner-class'} aria-label="sticky table">
                          {vendorResponseCommercialState.responses[0].categories[index].sections.map((section, sectionIndex) => (
                            <React.Fragment>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    onClick={e => handleSectionChange(index + '-' + sectionIndex)}
                                    style={{ "min-width": "840px", }}
                                  >
                                    {(section_main_cat.includes(index + '-' + sectionIndex))
                                      ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                                    }
                                    {section.sectionName}
                                  </TableCell>
                                  {vendorResponseCommercialState.responses.map((vendor) => (
                                    <TableCell align="center" style={{ "width": "100px", "maxWidth": '100px' }}> {vendor.categories[index].sections[sectionIndex].technicalScore}/{vendor.categories[index].sections[sectionIndex].total} </TableCell>))
                                  }
                                </TableRow>
                              </TableHead>
                              <TableBody className={(section_main_cat.includes(index + '-' + sectionIndex)) ? classes.hidden : ''}>
                                {vendorResponseCommercialState.responses[0].categories[index].sections[sectionIndex].questions.map((question, questionIndex) => (
                                  (question.type === 'yes-no') ?
                                    (<TableRow>
                                      <TableCell component="th" style={{ "min-width": "840px", }}> {question.question} </TableCell>
                                      {vendorResponseCommercialState.responses.map((vendor) => (
                                        <TableCell align="center" style={{ "min-width": "85px", "position": "relative", "padding": "10px" }}>

                                          {camelize(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer)}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer.length === 0 && <p style={{ "position": "absolute", "top": "37%", right: "40%", fontSize: "12px", fontWeight: "400" }}> 0 </p>}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "no" && <p style={{ "position": "absolute", "top": "70%", "right": "45%", fontSize: "12px", fontWeight: "400" }}>0</p>}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "yes" && <p style={{ "position": "absolute", "top": "70%", "right": "45%", fontSize: "12px", fontWeight: "400" }}>{question.score}</p>}

                                        </TableCell>))}
                                    </TableRow>) :
                                    (<React.Fragment>
                                      <TableRow onClick={handleQuestionChange(question.question)}>
                                        <TableCell style={{ "min-width": "840px", }} component="th"> {(expandedQuestion == question.question) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}   {question.question} </TableCell>
                                        {vendorResponseCommercialState.responses.map((vendor) => (<TableCell align="center" style={{ "min-width": "85px", "word-break": "break-word", position: "relative", paddingRight: "10px" }}> {answer(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating)}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "fully-met" && <p style={{ "position": "absolute", "top": "70%", "right": "37%", fontSize: "12px", fontWeight: "400" }}>
                                            {(vendor.categories[index].sections[sectionIndex].questions[questionIndex].score)}
                                          </p>}

                                          {/* {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "partially-met" && <p style={{ "position": "absolute", "top": "77%", "right": "33%", fontSize: "12px", fontWeight: "400" }}>
                                            {(vendor.categories[index].sections[sectionIndex].questions[questionIndex].score) / 2}
                        
                                          </p>} */}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "partially-met" && <p
                                            style={{ position: "absolute", fontSize: "12px", fontWeight: "400", left: "39%", top: "74%" }}
                                          >
                                            {(vendor.categories[index].sections[sectionIndex].questions[questionIndex].score) / 2}

                                          </p>}

                                          {vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating === "not-met" && <p style={{ "position": "absolute", "top": "70%", "right": "37%", fontSize: "12px", fontWeight: "400" }}> 0 </p>}

                                        </TableCell>
                                        ))}
                                      </TableRow>
                                      <Table className={expandedQuestion == question.question + ' requestly-dashboard-table-inner-2-class' ? classes.hidden : 'requestly-dashboard-table-inner-2-class'} aria-label="simple table">
                                        <TableBody>
                                          {vendorResponseCommercialState.responses.map((vendor, vendorIndex) => (
                                            <TableRow>
                                              <TableCell component="th">{vendor.companyName}  </TableCell>
                                              <TableCell align="left"> {camelize(vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer)} </TableCell>
                                              <TableCell align="center">
                                                <TextField
                                                  fullWidth
                                                  label="Score2"
                                                  name="companyType"
                                                  onChange={(event) => handleChangeBuyerResponseCommercial(event, vendorIndex, index, sectionIndex, questionIndex, vendor.vendorId)}
                                                  select
                                                  SelectProps={{ native: true }}
                                                  value={vendor.categories[index].sections[sectionIndex].questions[questionIndex].answerRating}
                                                  variant="outlined"
                                                  className="requestly-rfp-textbox-dashboard"
                                                  disabled={vendor.categories[index].sections[sectionIndex].questions[questionIndex].answer === "" && vendor.categories[index].sections[sectionIndex].questions[questionIndex].type === "detailed"}
                                                >
                                                  {questionScoringType.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                      {option.label}
                                                    </option>
                                                  ))}
                                                </TextField>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </React.Fragment>)
                                ))}
                              </TableBody></React.Fragment>
                          ))}
                        </Table>
                      </TableBody>

                    </Table>


                  </React.Fragment>
                ))
              }

            </TableContainer>
          ) : null}
        </TabPanel>

      </React.Fragment>
    </div>
  );
}
export default VendorResponses
