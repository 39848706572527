import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import Tooltip from "@material-ui/core/Tooltip";
import { Form, Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CompanyService from 'src/services/company/company.service';
import { history } from 'src/utils/utils';
import { renderFileList } from 'src/views/shared/components/FileList';
import { useStyles } from './LegalContractsStyle';
import { LegalContractsValidations } from './LegalContractsValidations';

const LegalContracts = ({ handleNext, handleSkip, handleBack, className, completed, legalState, step, totalStepCount, deleteLegalDocument, currentStepCompleteStatus, acceptStatus, is_submitted = false, ...rest }) => {
  console.log('acceptStatus', acceptStatus)
  const classes = useStyles();
  const companyService = new CompanyService();
  const [localFile, setLocalFile] = React.useState([]);
  const [companyName, setCompanyName] = React.useState();
  // const [isHover, setIsHover] = React.useState(false);
  const companyId = localStorage.getItem('companyId')

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (acceptStatus == 1 && open) {
      setOpen(false);
    }
  }, [acceptStatus, open]);

  const getCompanyData = () => {
    companyService.getCompanyByUser(false).then(
      res => {
        if (res !== undefined && res) {
          if (res?.company?.companyName) {
            setCompanyName(res.company.companyName);
          }
        }
      }
    ).catch((err) => console.log(':: err', err))
  }

  useEffect(() => {
    getCompanyData()
  }, []);
  let file = []

  const handleFilesChange = (files) => {
    let tempFile = [...localFile];
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        const fileDate = {
          userName: localStorage.getItem('user'),
          userId: localStorage.getItem('userId'),
          companyName,
          companyId,
          createdAt: new Date(),
          name: files[i].name,
          location: URL.createObjectURL(files[i]),
          mimetype: '',
          file: files[i]
        }
        tempFile.push(fileDate)
      }

    }
    setLocalFile(tempFile);
    file = tempFile;
  }

  const deleteLocalFile = (index) => {
    var tempFile = [...localFile];
    tempFile.splice(index, 1)
    setLocalFile(tempFile)
    file = tempFile
  }

  var type = '';
  return (
    <Formik
      initialValues={legalState}
      enableReinitialize={true}
      validationSchema={LegalContractsValidations}
      onSubmit={(values) => {
        values.step = step;
        values.type = type;
        values.file = localFile.map(datum => datum.file);
        handleNext(values);
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form className={'requestly-rfp-form company-information mb-5'}>
          <div className={classes.pageSize + ' company-info-div'}>
            <Card className={classes.CardBorder}>
              <CardHeader subheader="Upload legal contracts" title="Legal Contracts"
                className={classes.companyHeadings + ' requestly-rfp-heading'} />
              <Divider />

              <CardContent>
                <Box mt={3}>
                  <Grid className={classes.listChecks} container spacing={0}>
                    <Grid md={12} sm={12} xs={12}>
                      <Grid item md={12} xs={12}>
                        <DropzoneArea
                          acceptedFiles={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.png', '.jpg', '.jpeg']}
                          onChange={handleFilesChange}
                          showFileNames={true}
                          maxFileSize="104857600"
                          showPreviewsInDropzone={false}
                          filesLimit={20}
                        />
                      </Grid>
                      {localFile?.length ? (
                        <Grid item md={12} xs={12} style={{ marginTop: '15px' }}>
                          {renderFileList(localFile, classes.table, true, deleteLocalFile)}
                        </Grid>
                      ) : null}

                      {values.legalDocuments_docs && values.legalDocuments_docs.length > 0 ? (
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          <Box className={classes.uploadedFileContainer}>
                            <Typography className={classes.uploadedFileText}>
                              Uploaded files
                            </Typography>
                          </Box>
                          {renderFileList(values.legalDocuments_docs, classes.table, false, deleteLegalDocument)}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {(step !== 0 && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                        </Button>
                      </Grid>
                    ) : null}
                    {(step !== totalStepCount && !is_submitted) ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                        </Button>
                      </Grid>
                    ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <Grid item md={3} sm={3} xs={3}>
                            <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                          </Grid> : ''
                      ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        (!is_submitted) ?
                          <>
                            <Grid item md={3} sm={3} xs={3}>
                              <Tooltip
                                title={'You must accept RFP invite to submit the RFP response'}
                                placement="top"
                                arrow
                                onOpen={() => {
                                  if (acceptStatus != 1 && !open) {
                                    setOpen(true);
                                  }
                                }}
                                onClose={() => setOpen(false)}
                                open={open}
                              >
                                <div onMouseOver={() => {
                                  setOpen(true)
                                }}
                                  onMouseLeave={() => setOpen(false)}>
                                  <Button style={{ width: '100%' }}
                                    disabled={acceptStatus == 1 ? false : true}
                                    color="primary" type="submit" variant="contained"
                                    onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Submit Response</Button>
                                </div>
                              </Tooltip>
                            </Grid>
                            {/* {acceptStatus != 1 && <Grid item md={3} sm={3} xs={3}>
                              <span style={{ width: '100%', fontSize: '8px' }}>You must accept RFP invite to submit the RFP response</span>
                            </Grid>} */}
                          </> : ''
                      ) : step === totalStepCount ?
                        (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                            </Grid> : ''
                        ) : (
                          (!is_submitted) ?
                            <Grid item md={3} sm={3} xs={3}>
                              <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                            </Grid> : ''
                        )}
                    {
                      (is_submitted) ? <Grid item md={4} sm={4} xs={4}>
                        <Button style={{ width: '100%' }} color="primary" type="submit" variant="contained" onClick={e => history.push('/app/dashboard')}> Return to Dashboard</Button>
                      </Grid> : ''
                    }
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </Form>
      )}
    </Formik>
  );
};

LegalContracts.propTypes = {
  className: PropTypes.string
};

export default LegalContracts;
