import Button from '@material-ui/core/Button';
import React from 'react';
import { history } from 'src/utils/utils';




const ReturnToDashboard = (props) => {
  return (
    <div style={{ maxWidth: '720px', margin: 'auto', textAlign: 'right', marginBottom: '10px' }}>
      <Button color="primary" type="button" variant="contained" onClick={e => history.push('/app/dashboard-rfp/' + props.rfp_id)}>
        Return to RFP Dashboard
            </Button>
    </div>
  );
};

export default ReturnToDashboard;
