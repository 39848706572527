import { loaderService } from "../../components/loader/loader.service";
import { alertService } from "../../components/toaster/alert.service";
import AxiosInstance from "../../interceptor/http-intercepor";
import Configuration from "../../utils/config";

class EnquiryService {
  constructor() {
    this.config = new Configuration();
  }

  async submitEnquiry(enquiryObj) {
    loaderService.show(true);
    const userId = localStorage.getItem("userId");
    enquiryObj.userId = userId;

    // companyObj.rfpId= companyObj.rfpId? companyObj.rfpId : '5f5b95af2f5dd3cde517e762';
    const url = this.config.baseUrl + this.config.submitEnquiry;

    const config = {
      headers: {
        "content-type": "application/json"
      }
    };
    return AxiosInstance.post(url, enquiryObj, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getProductDemoOptions() {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.productDemoOptions;

    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false;
      alertService.error(error.response.data.message, {
        autoClose,
        keepAfterRouteChange
      });
      // Request made and server responded
      console.log(":: error ", error);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(":: error ", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(":: Error", error.message);
    }
  }

  async addProductDemo(data) {
    loaderService.show(true);

    const url = this.config.baseUrl + this.config.addProductDemo;

    const config = {
      headers: {
        "content-type": "application/json"
      }
    };

    return AxiosInstance.post(url, data, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getDiscoveryOptions() {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.discoveryStage;

    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async addDiscoveryMeeting(data) {
    loaderService.show(true);

    const url = this.config.baseUrl + this.config.addDiscoveryMeeting;

    const config = {
      headers: {
        "content-type": "application/json"
      }
    };

    return AxiosInstance.post(url, data, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async getProductDemoList() {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.productDemoList;

    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async getDiscoveryMeetingList() {
    const self = this;
    loaderService.show(true);
    const url = this.config.baseUrl + this.config.discoveryMeetingList;

    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async acceptRejectProductDemo(data) {
    loaderService.show(true);
    const formData = new FormData();
    formData.append("status", data?.status);
    formData.append("_id", data?._id);
    if (data?.message) {
      formData.append("message", data?.message);
    }

    if (data?.meetingLinks) {
      formData.append("meetingLinks", data?.meetingLinks);
    }

    if (data?.meetingDoc && data?.meetingDoc?.length > 0) {
      data.meetingDoc.map(el => {
        formData.append("meetingDoc", el);
      });
    }

    const url = this.config.baseUrl + this.config.acceptRejectProductDemo;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        type: "formData"
      }
    };

    return AxiosInstance.post(url, formData, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async acceptRejectDiscoveryMeeting(data) {
    loaderService.show(true);

    const url = this.config.baseUrl + this.config.acceptRejectDiscoveryMeeting;

    const formData = new FormData();
    formData.append("status", data?.status);
    formData.append("_id", data?._id);
    if (data?.message) {
      formData.append("message", data?.message);
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        type: "formData"
      }
    };

    return AxiosInstance.post(url, formData, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  async changeVisitStatus(data) {
    loaderService.show(true);

    const url = this.config.baseUrl + this.config.changeVisitStatus;

    const config = {
      headers: {
        "content-type": "application/json"
      }
    };

    return AxiosInstance.post(url, data, config)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .catch(error => {
        this.handleError(error);
      });
  }
}

export default EnquiryService;
