export const mileStones = [
  { mileStoneName: 'RFP START', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Accept Invite & NDA', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Vendor Questions', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Feedback', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'RFP Submission', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Short List', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Final Presentation', description: '', mileStoneDate: '', isFixed: true },
  { mileStoneName: 'Selection and Contract Agreement', description: '', mileStoneDate: '', isFixed: true }/*,
  {mileStoneName:'Project Start', description: '', mileStoneDate:'', isFixed: true}*/
]
