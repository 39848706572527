const LocalStorageService = (function () {
  let _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem('token', tokenObj.token);
    localStorage.setItem('userId', tokenObj.userId);
    localStorage.setItem('companyId', tokenObj.companyId);
  }
  function _getAccessToken() {
    return localStorage.getItem('token');
  }
  function _getUserId() {
    return localStorage.getItem('userId');
  }

  function _clearToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getUserId,
    clearToken: _clearToken
  };
}());
export default LocalStorageService;
