import { Container, Grid, StepButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import { omitBy } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Page from 'src/components/Page';
import { alertService } from 'src/components/toaster/alert.service';
import CommercialDetailService from 'src/services/rfp/commercial-detail.service';
import FinalPresentationService from 'src/services/rfp/final-presentation.service';
import NDAService from 'src/services/rfp/nda.service';
import ProjectService from 'src/services/rfp/project.service';
import RFPService from 'src/services/rfp/rfp.service';
import TechnicalDetailService from 'src/services/rfp/technical-detail.service';
import VendorService from 'src/services/rfp/vendor.service';
import { history } from 'src/utils/utils';
import CommercialEvaluation from 'src/views/CommercialEvaluation/CommercialEvaluation';
import FinalPresentationAdd from 'src/views/FinalPresentationAdd/FinalPresentation';
import NDA from 'src/views/nda/NDA';
import { NDAModel } from 'src/views/nda/NDAModel';
import { ProjectModel } from 'src/views/ProjectInformation/ProjectModel';
import { TechnicalEvaluationModel } from 'src/views/TechnicalEvaluation/TechnicalEvaluationModel';
import CompanyService from '../../../services/company/company.service';
import { getRfpByIdCommericalUpdate, getRfpByIdTechnicalUpdate, getRfpByUserIdStart, rfpUpdateStepper, saveTechnicalDetailsStart } from "../../../store/updateRfp/actions";
import { CommercialEvaluationModel } from '../../CommercialEvaluation/CommercialEvaluationModel';
import CompanyDetails from '../../CompanyInformation/CompanyDetails';
import { CompanyModel } from '../../CompanyInformation/CompanyModel';
import { FinalPresentationModel } from '../../FinalPresentation/FinalPresentationModel';
import ProjectDetails from '../../ProjectInformation/ProjectDetails';
import TechnicalEvaluation from '../../TechnicalEvaluation/TechnicalEvaluation';
import VendorInvite from '../../VendorInvite/VendorInvite';
import { VendorInviteModel } from '../../VendorInvite/VendorInviteModel';
import { rapid } from '../CreateRfpView/data/processConfiguration';
import { getSteps } from './RfpStepsConfig';
import { useStyles } from './UpdateRfpStyle';

const UpdateRfpView = ({ match, getRfpByUserIdStart, rfpData, getRfpByIdTechnicaStart, technicalData, saveTechnicalDetailsStart, technicalDataSuccess, getRfpByIdCommericalStart, rfpUpdateStepper, getRfpByIdTechnicalUpdate, getRfpByIdCommericalUpdate }) => {
  const [completed, setCompleted] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState();
  const [totalStepCount, setTotalStepCount] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);
  const [companyState, setCompanyState] = React.useState(new CompanyModel());
  const [projectState, setProjectState] = React.useState(new ProjectModel());
  const [technicalEvaluationState, setTechnicalEvaluationState] = React.useState(new TechnicalEvaluationModel());
  const [commercialEvaluationState, setCommercialEvaluationState] = React.useState(new CommercialEvaluationModel());
  const [vendorInviteState, setVendotInviteState] = React.useState(new VendorInviteModel());
  const [finalPresentationState, setFinalPresentationState] = React.useState(new FinalPresentationModel());
  const [ndaState, setNDAState] = React.useState(new NDAModel());
  const [type, setType] = React.useState();
  const stepsDataKeys = Object.keys(rapid)
  const initialStepsData = stepsDataKeys.filter(function (id) { return rapid[id] });
  const [allSteps, setAllSteps] = React.useState(initialStepsData);

  const classes = useStyles();
  const companyService = new CompanyService();
  const projectService = new ProjectService();
  const vendorService = new VendorService();
  const rfpService = new RFPService();
  const technicalDetailService = new TechnicalDetailService();
  const commercialDetailService = new CommercialDetailService();
  const ndaService = new NDAService();
  const finalPresentationService = new FinalPresentationService();

  const steps = getSteps(allSteps);
  const autoClose = true;
  const keepAfterRouteChange = true;
  const rfpId = match.params.rfpId;

  const handleReset = () => {
    setActiveStep(0);
  };

  const extractAndSetSteps = (stepsData = null, rfpStepper = null) => {
    const stepsNeedToRemove = ['rfpPublished', 'vendorShortList', 'statementOfWork', 'proofOfconceptValue', 'references', 'pricingNegotiations', 'contactAgreements', 'rfpCompletion', 'companyId', 'submittedVendorIds', 'processTypeValue', 'scopeValue', 'isSubmitted'];
    // console.log(stepsData, "stepsData");
    const stepsDataKeys = Object.keys(stepsData)

    const allSteps = stepsDataKeys.filter(function (id) {
      if (stepsNeedToRemove.includes(id)) {
        return false;
      }
      return stepsData[id]
    });


    setTotalStepCount(allSteps.length - 1);
    if (rfpStepper) {
      const newCompleted = completed;
      var currentActiveStep = null;
      allSteps.map((step, index) => {
        if (rfpStepper[step]) {
          newCompleted[index] = true;
        } else {
          if (currentActiveStep === null) {
            currentActiveStep = index;
          }
        }
      });
      setCompleted(newCompleted);
      console.log(rfpStepper);
      console.log(allSteps)
    }
    setAllSteps(allSteps);
  }


  useEffect(() => {
    getRFPData()

  }, activeStep);


  useEffect(() => {
    if (technicalDataSuccess !== undefined && technicalDataSuccess.response) {
      const res = technicalDataSuccess.response.data
      if (res !== undefined && res) {
        if (type === 'publish') {
          history.push('/app/dashboard');
        } else if (type === 'next') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          getStepData(activeStep + 1);
        } else {
          const autoClose = true;
          const keepAfterRouteChange = false;
          alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
        }
      }
    }

  }, [technicalDataSuccess])


  const getRFPData = () => {
    rfpService.getrfpByById(rfpId).then(
      res => {
        // console.log(res, "resupdate...")
        if (res !== undefined && res) {
          extractAndSetSteps(res.rfp, res.rfpStepper);
          getStepData(activeStep);
        }
      }
    ).catch((error) => console.log(':: error', error))
  }

  const getTechnicalDetailData = () => {
    technicalDetailService.getTechnicalDetailById(rfpId).then(
      res => {
        if (res !== undefined && res) {
          res.technicalEval.overview = JSON.parse(res.technicalEval.overview[0]);
          setTechnicalEvaluationState(new TechnicalEvaluationModel(res.technicalEval));

        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    getRfpByIdTechnicalUpdate(
      technicalEvaluationState);
  }, [technicalEvaluationState])

  const saveTechnicalDetails = (values) => {
    technicalDetailService.addTechnicalDetail(values, rfpId).then(
      res => {


        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getCommercialDetailData = () => {
    commercialDetailService.getCommercialDetailById(rfpId).then(
      res => {

        if (res !== undefined && res) {
          res.commercialEval.overview = JSON.parse(res.commercialEval.overview[0]);
          setCommercialEvaluationState(new CommercialEvaluationModel(res.commercialEval));

        }
      }
    ).catch(() => setHasError(true))
  }

  useEffect(() => {
    getRfpByIdCommericalUpdate(commercialEvaluationState);
  }, [commercialEvaluationState])

  const saveCommercialDetails = (values) => {
    commercialDetailService.addCommercialDetail(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveProject = (values) => {
    let rfpId = match.params.rfpId;
    projectService.addProject(values, rfpId).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveNDA = (values) => {
    values.rfpId = match.params.rfpId;
    ndaService.addNDA(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getNDA = () => {
    ndaService.getNDA(rfpId).then(
      res => {
        if (res !== undefined && res) {
          setNDAState(new NDAModel(res.nda.length ? res.nda[0] : res.nda));
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveFinalPresentation = (values) => {
    values.rfpId = match.params.rfpId;
    finalPresentationService.addFinalPresenation(values).then(
      res => {
        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const getFinalPresentation = () => {
    finalPresentationService.getFinalPresentation(rfpId).then(
      res => {

        if (res !== undefined && res && res.finalPresenation) {
          setFinalPresentationState(new FinalPresentationModel(res.finalPresenation));
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveVendor = (values) => {
    values.rfpId = match.params.rfpId;
    vendorService.addVendor(values).then(
      res => {

        if (res !== undefined && res) {
          if (values.type === 'publish') {
            history.push('/app/dashboard');
          } else if (values.type === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            getStepData(activeStep + 1);
          } else {
            const autoClose = true;
            const keepAfterRouteChange = false;
            alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
          }
        }
      }
    ).catch(() => setHasError(true))
  }

  const deleteSupportingMaterial = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      projectService.deleteSupportingMaterial(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }

  }

  const deleteEmail = (oldData) => {
    if (oldData._id) {
      vendorService.deleteVendorEmail(oldData._id).then(res => {
        if (res) {

        }
      })
    }
  }

  const deleteNDA = (fileName) => {
    if (window.confirm('Are you sure to delete this file?')) {
      ndaService.deleteNDA(fileName, rfpId).then((res) => {
        if (res !== undefined && res) {
          getStepData(activeStep);
        }
      })
    }

  }

  const getVendor = () => {
    vendorService.getVendor(rfpId).then(
      res => {

        if (res !== undefined && res) {
          setVendotInviteState(new VendorInviteModel(res.vendor));
        }
      }
    ).catch(() => setHasError(true))
  }

  const saveCompany = (values) => {
    companyService.addCompany(values, true, rfpId).then((res) => {
      if (res) {
        if (values.type === 'publish') {
          history.push('/app/dashboard');
        } else if (values.type === 'next') {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          getStepData(activeStep + 1);
        } else {
          const autoClose = true;
          const keepAfterRouteChange = false;
          alertService.success('Details updated successfully.', { autoClose, keepAfterRouteChange });
        }
      }
    }).error((err) => {
      console.log(':: err', err);
    });
  }

  const getStepData = (step) => {
    setCurrentStep(step);
    switch (allSteps[step]) {
      case 'companyInformation':
        companyService.getCompanyByUser(true, rfpId).then(
          res => {
            // console.log(res, "res");
            if (res !== undefined && res) {
              if (res.company?.overview) {
                res.company.overview = JSON.parse(res.company.overview);
              }

              if (res.company.locations) {
                const locations = res.company.locations.map(datum => {
                  if (datum.officeFunction) {
                    if (typeof datum.officeFunction === "object") {
                      return datum;
                    }
                    return {
                      officeLocation: datum.officeLocation,
                      officeFunction: [datum.officeFunction]
                    }
                  }
                  return datum;
                });
                setCompanyState(new CompanyModel({
                  ...omitBy(res.company, 'locations'),
                  locations
                }))
              } else {
                setCompanyState(new CompanyModel(res.company))
              }
            }
          }
        ).catch((error) => console.log(':: error', error))
        break;
      case 'projectInformation':
        projectService.getProject(rfpId).then(
          res => {


            if (res !== undefined && res) {
              res.project.projectDescription = JSON.parse(res.project.projectDescription);
              const a = new ProjectModel(res.project);

              setProjectState(a);
            }
          }
        ).catch(() => setHasError(true))
        break;
      case 'technicalRequirements':
        getTechnicalDetailData();
        break;
      case 'commercialEvaluation':
        getCommercialDetailData();
        break;
      case 'vendorInvite':

        getVendor();
        break;
      case 'nda':
        getNDA();
        companyService.getCompanyByUser(true, rfpId).then(
          res => {

            if (res !== undefined && res) {
              const loc = res.company.locations[0].replace(/'/g, '"');
              res.company.locations = JSON.parse(loc)
              if (res.company?.overview) {
                res.company.overview = JSON.parse(res.company.overview);
              }
              res.company.keywords = JSON.parse(res.company.keywords);
              setCompanyState(new CompanyModel(res.company))
            }
          }
        ).catch(() => setHasError(true))
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        getFinalPresentation();
        break;
      case 'references':
        break;
      case 'pricingNegotiations':
        break;
      case 'contactAgreements':
        break;
      case 'rfpCompletion':
        break;
      default:

        break;
    }
  }

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    getStepData(step);
  };

  const handleNext = (values) => {
    setType(values.type);
    handleComplete();
    switch (allSteps[values.step]) {
      case 'companyInformation':
        saveCompany(values);
        setActiveStep((prevActiveStep) => 1);
        break;
      case 'projectInformation':
        saveProject(values);
        break;
      case 'technicalRequirements':
        saveTechnicalDetails(values)
        break;
      case 'commercialEvaluation':
        saveCommercialDetails(values);
        break;
      case 'vendorInvite':
        saveVendor(values);
        break;
      case 'nda':
        saveNDA(values);
        break;
      case 'vendorShortList':
        break;
      case 'proofOfconceptValue':
        break;
      case 'statementOfWork':
        break;
      case 'finalPresentation':
        saveFinalPresentation(values)
        break;
      case 'references':
        break;
      case 'pricingNegotiations':
        break;
      case 'contactAgreements':
        break;
      case 'rfpCompletion':
        break;
      default:
        break;
    }
  };
  const isStepOptional = (step) => {
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = (step) => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    getStepData(activeStep + 1);
  };

  function getStepContent(step) {
    rfpUpdateStepper(step)
    switch (allSteps[step]) {
      case 'companyInformation':
        return (<Container maxWidth="md">

          <CompanyDetails isSubmitView={false} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setCompanyState={setCompanyState} companyState={companyState} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} />
        </Container >);
      case 'projectInformation':
        return (<Container maxWidth="md">

          <ProjectDetails isSubmitView={false} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} projectState={projectState} deleteSupportingMaterial={deleteSupportingMaterial} setProjectState={setProjectState} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} />
        </Container>);
      case 'technicalRequirements':
        return (<Container maxWidth="sm" className={classes.custWidthRFP}>

          <TechnicalEvaluation isSubmitView={false} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} rfpId={rfpId} technicalEvaluationState={technicalEvaluationState} />
        </Container>);
      case 'commercialEvaluation':
        return (<Container maxWidth="md">

          <CommercialEvaluation handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} rfpId={rfpId} />
        </Container>);
      case 'vendorInvite':
        return (<Container maxWidth="md">

          <VendorInvite handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} rfpId={rfpId} vendorInviteState={vendorInviteState} setVendotInviteState={setVendotInviteState} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} deleteEmail={deleteEmail} />
        </Container>);
      case 'nda':
        return (<Container maxWidth="md">

          <NDA handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} ndaState={ndaState} step={step} completed={Object.values(completed).length} companyState={companyState} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} deleteNDA={deleteNDA} />
        </Container>);
      case 'finalPresentation':
        return (<Container maxWidth="md">

          <FinalPresentationAdd isSubmitView={false} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setFinalPresentationState={setFinalPresentationState} finalPresentationState={finalPresentationState} step={step} completed={Object.values(completed).length} currentStepCompleteStatus={completed[step]} totalStepCount={totalStepCount} />
        </Container>);
      case 'rfpCompletion':
        return (<Container maxWidth="md">

          <CompanyDetails handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} setCompanyState={setCompanyState} companyState={companyState} step={step} />
        </Container>);
      default:
        return 'Unknown step';
    }
  }



  return (
    <Page className={classes.root} title="Update RFP">
      <div id="rightContain">
        <Container maxWidth="lg" className="main-wrapper">
          <div className={classes.root}>
            <Grid container spacing={0}>
              <Grid className={classes.leftStepPanel} item md={2} sm={3} xs={12} className="sd_stepper requestly-positon">
                <Stepper nonLinear activeStep={activeStep} orientation="vertical" className="ins_stepper requestly-stepper">
                  {steps
                    .filter(label => !!label)
                    .map((label, index) => (
                      <Step key={label} className="requestly-stepper-step">
                        <StepButton className={classes.buttonStepper + ' requestly-span'} onClick={handleStep(index)} completed={completed[index]}>
                          {label}
                        </StepButton>
                        <StepContent>
                        </StepContent>
                      </Step>
                    ))
                  }
                </Stepper>
              </Grid>

              <Grid container item xs={4} className={classes.inputAll + ' marginLeftSet'} md={9} item sm={9} xs={12}>

                <Typography>{getStepContent(activeStep)}</Typography>
                {activeStep === steps.length && (
                  <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </Page >
  );
};

const mapStateToProps = state => ({
  rfpData: state.updateRfp.rfpData,
  technicalDataSuccess: state.updateRfp.rfpTechnicalDataSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByUserIdStart: (value) => dispatch(getRfpByUserIdStart(value)),
  getRfpByIdTechnicalUpdate: (value) => dispatch(getRfpByIdTechnicalUpdate(value)),
  saveTechnicalDetailsStart: (value) => dispatch(saveTechnicalDetailsStart(value)),
  getRfpByIdCommericalUpdate: (value) => dispatch(getRfpByIdCommericalUpdate(value)),
  rfpUpdateStepper: (value) => dispatch(rfpUpdateStepper(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRfpView);

