import { mileStones } from "./preDefinedTimelines";

export class ProjectModel {
  projectName = '';
  projectDescription = '';
  businessObjective = '';
  supportingMaterial: '';
  metrics = '';
  decisionProcess = '';
  decisionCriteria = '';
  contacts = [{ contactName: '', contactRole: '' }, { contactName: '', contactRole: '' }];
  timelineItems = JSON.parse(JSON.stringify(mileStones));
  isData = false;
  constructor(projectInfo = null) {
    if (projectInfo) {

      this.isData = true;
      this.projectName = (projectInfo.projectName && projectInfo.projectName !== 'undefined') ? projectInfo.projectName : this.projectName;
      this.supportingMaterial = (projectInfo.supportingMaterial && projectInfo.supportingMaterial !== 'undefined') ? projectInfo.supportingMaterial : this.supportingMaterial;
      this.supportingMaterial_docs = (projectInfo.supportingMaterial_docs && projectInfo.supportingMaterial_docs !== 'undefined') ? projectInfo.supportingMaterial_docs : this.supportingMaterial_docs;
      this.projectDescription = (projectInfo.projectDescription && projectInfo.projectDescription !== 'undefined') ? projectInfo.projectDescription : this.projectDescription;
      this.businessObjective = (projectInfo.businessObjective && projectInfo.businessObjective !== 'undefined') ? projectInfo.businessObjective : this.businessObjective;
      this.metrics = (projectInfo.metrics && projectInfo.metrics !== 'undefined') ? projectInfo.metrics : this.metrics;
      this.decisionProcess = (projectInfo.decisionProcess && projectInfo.decisionProcess !== 'undefined') ? projectInfo.decisionProcess : this.decisionProcess;
      this.decisionCriteria = (projectInfo.decisionCriteria && projectInfo.decisionCriteria !== 'undefined') ? projectInfo.decisionCriteria : this.decisionCriteria;
      this.contacts = (projectInfo.contacts && projectInfo.contacts[0] !== 'undefined') ? projectInfo.contacts : this.contacts;
      this.timelineItems = (projectInfo.timelineItems && projectInfo.timelineItems[0] !== 'undefined') ? projectInfo.timelineItems : this.timelineItems;
    }
  }
}
