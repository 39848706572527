
export const getSteps = (activeSteps) => {
  const stepsName = { 
    companyInformation: 'Company Information',
    projectInformation: 'Project Information', 
    technicalRequirements: 'Technical Requirements',
    commercialEvaluation: 'Commercial Evaluation',
    vendorInvite: 'Vendor Invite',
    nda: 'Non-Disclosure Agreements',
    vendorShortList: 'Vendor Short List',
    proofOfconceptValue: 'Proof of Concept/Value',
    statementOfWork: 'Statement of Work',
    finalPresentation: 'Final Presentation',
    references: 'References',
    pricingNegotiations: 'Pricing/Negotiations',
    contactAgreements: 'Contract Agreements',
    rfpCompletion: 'RFP Completion'
  };
  return activeSteps.map(step => { return stepsName[step] });
}