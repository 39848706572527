import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getRfpByIdCommericalFailed, getRfpByIdCommericalSuccess, getRfpByIdTechnicaFailed, getRfpByIdTechnicalSuccess, getRfpByUserIdFailed, getRfpByUserIdSuccess, saveTechnicalDetailsFailed, saveTechnicalDetailsSuccess } from "./actions";
import updateRfpService from "./service";
import actionTypes from "./types";

function* helloSaga() {
  console.log('Hello Sagas!')
}

function* watchGetRfpByUserIdStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_USER_ID_START, fetchUserDetailsApiAsync);
}

function* fetchUserDetailsApiAsync({ payload }) {
  try {
    const response = yield call(updateRfpService.userDetails, payload);
    const data = { response };
    yield put(getRfpByUserIdSuccess(data));
  } catch (error) {
    yield put(getRfpByUserIdFailed(error));
  }
}

function* watchGetRfpByIdTechnicalStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_ID_TECHNICAL_START, fetchTechnicalDetailsApiAsync);
}

function* fetchTechnicalDetailsApiAsync({ payload }) {
  try {
    const response = yield call(updateRfpService.TechnicalDetails, payload);
    const data = { response };
    yield put(getRfpByIdTechnicalSuccess(data));
  } catch (error) {
    yield put(getRfpByIdTechnicaFailed(error));
  }
}

function* saveTechnicalDetailsStart() {
  yield takeEvery(actionTypes.SAVE_TECHNICAL_DETAILS_START, fetchSaveTechnicalDetailsAsync);
}

function* fetchSaveTechnicalDetailsAsync({ payload }) {
  try {
    console.log(payload, "payload...")
    const response = yield call(updateRfpService.TechnicalDetailsSubmit, payload);
    const data = { response };
    yield put(saveTechnicalDetailsSuccess(data));
  } catch (error) {
    yield put(saveTechnicalDetailsFailed(error));
  }
}

//Commerical Requirements


function* watchGetRfpByIdCommericalStart() {
  yield takeEvery(actionTypes.GET_RFP_BY_ID_COMMERICAL_START, fetchCommericalDetailsApiAsync);
}

function* fetchCommericalDetailsApiAsync({ payload }) {
  try {
    const response = yield call(updateRfpService.commericalDetails, payload);
    const data = { response };
    yield put(getRfpByIdCommericalSuccess(data));
  } catch (error) {
    yield put(getRfpByIdCommericalFailed(error));
  }
}


export function* updateRfpSaga() {
  yield all([
    helloSaga(),
    watchGetRfpByUserIdStart(),
    watchGetRfpByIdTechnicalStart(),
    saveTechnicalDetailsStart(),
    watchGetRfpByIdCommericalStart()
  ])
}