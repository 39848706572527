import { Button, Grid, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from "react-redux";
import 'src/views/shared/css/common.scss';
import { questionType } from 'src/views/shared/data/dropdowns.js';
import { getRfpByIdCommericalUpdate, getRfpByIdTechnicalUpdate } from "../../store/updateRfp/actions";
import { useStyles } from '../../views/TechnicalEvaluation/TechnicalEvaluationStyle';


const TechinicalRequirementQuestion = ({ categoryIndex, sectionIndex, questionIndex, questionInfo, values, touched, errors, technicalEvalUpdateData, getRfpByIdTechnicalUpdate, commercialEvalUpdateData, getRfpByIdCommericalUpdate, step, ...rest }) => {
  const classes = useStyles();

  function deleteQuestion(categoryIndex, sectionIndex, questionIndex) {
    if (window.confirm('Are you sure to delete this question?')) {
      if (step === 2) {
        const dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions.splice(questionIndex, 1);
        technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
        getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
      }

    }
    if (step === 3) {
      const dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions.splice(questionIndex, 1);
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    }
  }

  const handleQuestionBlur = (event, categoryIndex, sectionIndex, questionIndex, type) => {
    let dataRedux // type: 1 - Location , 2 - Function 
    if (step === 2) {
      if (type === 1) { technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].question = event.target.value; }
      else if (type === 2) {
        technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = event.target.value;
      } else {
        dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].type = event.target.value;
      }
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
    }

    if (step === 3) {
      if (type === 1) { commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].question = event.target.value; }
      else if (type === 2) {
        commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = event.target.value;
      } else {
        dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].type = event.target.value;
      }
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    }
  }

  const handleQuestionChange = (event, categoryIndex, sectionIndex, questionIndex, type) => { // type: 1 - Location , 2 - Function 
    let dataRedux
    if (step === 2) {
      if (type === 1) {
        dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].question = event.target.value;
      } else if (type === 2) {
        dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = event.target.value;
      } else {
        dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].type = event.target.value;
        if (event.target.value === 'detailed') {
          dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = 5
        }
        if (event.target.value === 'yes-no') {
          dataRedux = technicalEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = 1
        }
      }
      technicalEvalUpdateData = { ...technicalEvalUpdateData, dataRedux };
      getRfpByIdTechnicalUpdate(technicalEvalUpdateData)
    }

    if (step === 3) {
      if (type === 1) {
        dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].question = event.target.value;
      } else if (type === 2) {
        dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = event.target.value;
      } else {
        dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].type = event.target.value;
        if (event.target.value === 'detailed') {
          dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = 5
        }
        if (event.target.value === 'yes-no') {
          dataRedux = commercialEvalUpdateData.categories[categoryIndex].sections[sectionIndex].questions[questionIndex].score = 1
        }
      }
      commercialEvalUpdateData = { ...commercialEvalUpdateData, dataRedux };
      getRfpByIdCommericalUpdate(commercialEvalUpdateData)
    }
  }


  return (
    <React.Fragment>
      <div key={questionIndex} className="question-score">
        <Grid item md={11} sm={10} xs={12}>
          <Grid item md={7} xs={12}>
            <TextField
              error={Boolean(touched.question && errors.question)}
              fullWidth
              helperText={touched.question && errors.question}
              label="Question"
              multiline
              name={`questions[${questionIndex}].question`}
              value={questionInfo.question}
              onBlur={(e) => handleQuestionBlur(e, categoryIndex, sectionIndex, questionIndex, 1)}
              onChange={(e) => handleQuestionChange(e, categoryIndex, sectionIndex, questionIndex, 1)}
              variant="outlined"
              className="requestly-rfp-textbox"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              error={Boolean(touched.score && errors.score)}
              fullWidth
              multiline
              helperText={touched.score && errors.score}
              label="Score"
              name={`questions[${questionIndex}].score`}
              value={questionInfo.score}
              onBlur={(e) => handleQuestionBlur(e, categoryIndex, sectionIndex, questionIndex, 2)}
              onChange={(e) => handleQuestionChange(e, categoryIndex, sectionIndex, questionIndex, 2)}
              variant="outlined"
              className="requestly-rfp-textbox"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              error={Boolean(touched.type && errors.type)}
              fullWidth
              helperText={touched.type && errors.type}
              label="Type"
              name={`questions[${questionIndex}].type`}
              select
              multiline
              SelectProps={{ native: true }}
              value={questionInfo.type}
              onBlur={(e) => handleQuestionBlur(e, categoryIndex, sectionIndex, questionIndex, 3)}
              onChange={(e) => handleQuestionChange(e, categoryIndex, sectionIndex, questionIndex, 3)}
              variant="outlined"
              className="requestly-rfp-textbox requestly-h-46"
            >
              {questionType.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item md={1} sm={12} xs={12} align='center'>
          <Button className={classes.addMinus + ' delete-row-button'} variant="contained" type="button">
            <Typography className={classes.addMinus}>  <DeleteIcon className="site-delete-icon" onClick={() => deleteQuestion(categoryIndex, sectionIndex, questionIndex)} /> </Typography>
          </Button>
        </Grid>
      </div>
      <div className={classes.root}>
      </div>
    </React.Fragment>
  );
};
TechinicalRequirementQuestion.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  technicalEvalUpdateData: state.updateRfp.rfpTechnicalDataUpdate,
  commercialEvalUpdateData: state.updateRfp.rfpCommericalDataUpdate,
  step: state.updateRfp.rfpStepUpdate
});

const mapDispatchToProps = (dispatch) => ({
  getRfpByIdTechnicalUpdate: (value) => dispatch(getRfpByIdTechnicalUpdate(value)),
  getRfpByIdCommericalUpdate: (value) => dispatch(getRfpByIdCommericalUpdate(value))

});

export default connect(mapStateToProps, mapDispatchToProps)(TechinicalRequirementQuestion);
