import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import CompanyService from 'src/services/company/company.service';
import { renderFileList } from 'src/views/shared/components/FileList';
import { alertService } from '../../components/toaster/alert.service';
import { useStyles } from './NDAStyle';
import { NDAValidations } from './NDAValidations';
import './style.css';

const NDA = ({ handleNext, handleSkip, handleBack, className, completed, companyState, ndaState, step, totalStepCount, currentStepCompleteStatus, deleteNDA, ...rest }) => {
  const classes = useStyles();
  const companyService = new CompanyService();
  const [localFile, setLocalFile] = React.useState([]);
  const [companyName, setCompanyName] = React.useState();
  const companyId = localStorage.getItem('companyId')

  const getCompanyData = () => {
    companyService.getCompanyByUser(false).then(
      res => {
        if (res !== undefined && res) {
          if (res?.company?.companyName) {
            setCompanyName(res.company.companyName);
          }
        }
      }
    ).catch((err) => console.log(':: err', err))
  }

  useEffect(() => {
    getCompanyData()
  }, []);

  let file = []
  const handleFilesChange = (files) => {
    let tempFile = [...localFile];
    if (files.length > 0) {
      for (var i = 0; i < files.length; i++) {
        const fileDate = {
          userName: localStorage.getItem('user'),
          userId: localStorage.getItem('userId'),
          companyName,
          companyId,
          createdAt: new Date(),
          name: files[i].name,
          location: URL.createObjectURL(files[i]),
          mimetype: '',
          file: files[i]
        }
        tempFile.push(fileDate)
      }
    }
    setLocalFile(tempFile)
    file = tempFile
  }

  const deleteLocalFile = (index) => {
    var tempFile = [...localFile];
    tempFile.splice(index, 1)
    setLocalFile(tempFile)
    file = tempFile
  }


  var type = '';
  return (
    <Formik
      initialValues={ndaState}
      enableReinitialize={true}
      validationSchema={NDAValidations}
      onSubmit={(values) => {
        console.log(values);
        //alert('hi');
        if (localFile.length === 0 && values.companyMNDA === false && values.companyNDADocument_docs.length === 0) {
          const autoClose = true;
          const keepAfterRouteChange = false
          window.scrollTo(0, 0)
          alertService.error('Please upload 1 NDA File', { autoClose, keepAfterRouteChange })
        } else {
          values.step = step;
          values.type = type;
          values.file = localFile.map(datum => datum.file);;
          handleNext(values);
        }

      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form className={'requestly-rfp-form company-information mb-5'}>
          <div className={'company-info-div'}>
            <Card style={{ 'marginBottom': '50px' }}>
              <CardHeader
                subheader="Select your NDA preferences"
                title="Non-Disclosure Agreements"
                className={classes.companyHeadings + ' requestly-rfp-heading width-auto requestly-rfp1'} />
              <Divider />
              <CardContent>
                <Grid className={classes.item + ' requstly-pragraph-css'} item md={12} sm={12} xs={12}>
                  <Typography className="font-family">Select what form of mutual non-disclosure agreements are acceptable to your organisation.</Typography>
                </Grid>
                <Box mt={3}>
                  <Grid className={classes.listChecks + ' non-custom-label'} container spacing={0}>
                    <Grid md={12} sm={12} xs={12}>
                      <FormControlLabel className="font-family" control={(<Checkbox checked={values.vendorMNDA} onChange={handleChange} name="vendorMNDA" />)} label={"Vendor must use " + companyState.companyName + " NDA"} />
                    </Grid>
                    {(values.vendorMNDA) ?
                      (<Grid item md={12} xs={12} className='file-upload-non'>
                        <DropzoneArea
                          maxFileSize="104857600"
                          acceptedFiles={['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.png', '.jpg', '.jpeg']}
                          onChange={handleFilesChange}
                          showFileNames={true}
                          fileObjects
                          showPreviewsInDropzone={false}
                          filesLimit={20}

                        />
                      </Grid>) : null}
                    {localFile?.length ? (
                      <Grid item md={12} xs={12} style={{ marginTop: '15px' }}>
                        {renderFileList(localFile, classes.table, true, deleteLocalFile)}
                      </Grid>
                    ) : null}
                    {values.companyNDADocument_docs &&
                      values.companyNDADocument_docs.length > 0 &&
                      values.vendorMNDA
                      ? (
                        <Grid item xl={12} lg={12} md={12} xs={12} style={{ marginBottom: '30px' }}>
                          <Box className={classes.uploadedFileContainer}>
                            <Typography className={classes.uploadedFileText}>
                              Uploaded files
                            </Typography>
                          </Box>
                          {renderFileList(values.companyNDADocument_docs, classes.table, false, deleteNDA)}
                        </Grid>
                      )
                      : null}
                    <Grid md={12} sm={12} xs={12}>
                      <FormControlLabel control={(<Checkbox checked={values.companyMNDA} onChange={handleChange} name="companyMNDA" />)} label="We can use Vendor's NDA" />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <div class="footer-btn-fixed">
                <footer class="page-footer green">
                  <Grid container spacing={2} justify="center">
                    {step !== 0 ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.BackButton + ' font-family'} color="primary" type="button" variant="contained" onClick={handleBack}>
                          Back
                        </Button>
                      </Grid>
                    ) : null}
                    {step !== totalStepCount ? (
                      <Grid item md={3} sm={3} xs={3}>
                        <Button className={classes.ButtonSkip + ' font-family'} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                          Skip
                        </Button>
                      </Grid>
                    ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
                        </Grid>
                      ) : null}
                    {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
                      (
                        <Grid item md={3} sm={3} xs={3}>
                          <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
                        </Grid>
                      ) : step === totalStepCount ?
                        (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className="font-family" color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                          </Grid>
                        ) : (
                          <Grid item md={3} sm={3} xs={3}>
                            <Button className={classes.NextButton + ' font-family'} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                          </Grid>
                        )}
                  </Grid>
                </footer>
              </div>
            </Card>
          </div>
        </form>
      )}
    </Formik>
  );
};

NDA.propTypes = {
  className: PropTypes.string
};

export default NDA;
