import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {

  },
  testMan: {
    display: "inline-flex!important"
  },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  footerBackground: {
    background: '#f7f7f7'
  },

  ButtonSkip: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  input: {
    color: "black"
  },
  NextButton: {
    background: '#40506b',
    color: '#fff',
    hover: { backgroundColor: 'pink' }
  },
  cardScrollArea: {
    //maxHeight: "72vH",
    //overflowX: "scroll",
    //overflowX: "hidden",

  },
  CardBorder: {
    borderRadius: "15px"

  },
  pageSize: {
    paddingBottom: "2px"
  },
  CompanyInfo: {
    maxWidth: "76vH"
  },
  '@global': {
    '.MuiOutlinedInput-input': {
      padding: "10.3px 14px"
    },
    '.MuiAccordion-root.Mui-expanded': {
      margin: "0px 0px"
    }
  },
  widthFill: { width: '100%' },
  customInputs: { width: '100%' },
  addPlus: {},
  addMinus: {},
  profileImg: { textAlign: 'center', height: 'auto', padding: '0' },
  profilePics: { borderRadius: '50%', height: '120px', width: '120px', border: '2px solid #4688f0', padding: '0' }
}));
