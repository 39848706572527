/* eslint-disable eol-last */
import { loaderService } from "../../components/loader/loader.service";
import { alertService } from "../../components/toaster/alert.service";
import AxiosInstance from "../../interceptor/http-intercepor";
import Configuration from "../../utils/config";

class RFPService {
  constructor() {
    this.config = new Configuration();
  }

  async addRFP(rfpObj) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem("userId");
    rfpObj.companyInformation = rfpObj.stepsValue.companyInformation;
    rfpObj.projectInformation = rfpObj.stepsValue.projectInformation;
    rfpObj.technicalRequirements = rfpObj.stepsValue.technicalRequirements;
    rfpObj.commercialEvaluation = rfpObj.stepsValue.commercialEvaluation;
    rfpObj.vendorInvite = rfpObj.stepsValue.vendorInvite;
    rfpObj.nda = rfpObj.stepsValue.nda;
    rfpObj.vendorShortList = rfpObj.stepsValue.vendorShortList;
    rfpObj.proofOfconceptValue = rfpObj.stepsValue.proofOfconceptValue;
    rfpObj.statementOfWork = rfpObj.stepsValue.statementOfWork;
    rfpObj.finalPresentation = rfpObj.stepsValue.finalPresentation;
    rfpObj.references = rfpObj.stepsValue.references;
    rfpObj.pricingNegotiations = rfpObj.stepsValue.pricingNegotiations;
    rfpObj.contactAgreements = rfpObj.stepsValue.contactAgreements;
    rfpObj.rfpCompletion = rfpObj.stepsValue.rfpCompletion;
    rfpObj.userId = userId;
    const url = this.config.addRfp;
    return AxiosInstance.post(url, rfpObj)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async saveInvite(rfpObj) {
    loaderService.show(true);
    const self = this;
    const userId = localStorage.getItem("userId");
    rfpObj.userId = userId;
    const url = this.config.saveAcceptRejectInvite;
    return AxiosInstance.post(url, rfpObj)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async getrfpByUser(filter = "all") {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem("userId");
    const url = `${this.config.getRfp + userId}?filterBy=${filter}`;
    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  async getrfpByById(rfpId, isSubmit? = false) {
    const self = this;
    loaderService.show(true);
    const userId = localStorage.getItem("userId");
    let url = this.config.getRfpbyId + rfpId;
    console.log(isSubmit, "isSubmit");
    if (isSubmit) {
      url = `${url}/${userId}`;
    }
    return AxiosInstance.get(url)
      .then(response => {
        loaderService.show(false);
        if (response.status !== 200) {
          this.handleResponseError(response);
        }
        return response.data;
      })
      .then(res => {
        return res;
      })
      .catch(error => {
        self.handleError(error);
      });
  }

  handleResponseError(response) {
    loaderService.show(false);
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  handleError(error) {
    loaderService.show(false);
    if (error.response) {
      const autoClose = true;
      const keepAfterRouteChange = false;
      alertService.error(error.response.data.message, {
        autoClose,
        keepAfterRouteChange
      });
      // alertService.error('Error hhh', { id: 'left-alert' });
      // Request made and server responded
      console.log(":: error", error.response.data);
      // alert(error.response.data.message);
      console.log(":: error", error.response.status);
      console.log(":: error", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.log(":: error", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(":: Error", error.message);
    }
  }
}

export default RFPService;
