
export class TechnicalEvaluationModel {  
        overview = '';
        categoryName = '';
        categories =  [];  // [{ categoryName: '', sections: [{  sectionName: '', questions: [{  question:'',  type: '', score: '' }] }]} ]
        constructor(technicalEvaluationInfo = null){  
                if (technicalEvaluationInfo) {
                        this.overview = technicalEvaluationInfo.overview || this.overview;
                        this.categoryName = technicalEvaluationInfo.categoryName || this.categoryName;
                        this.categories = technicalEvaluationInfo.categories || this.categories;
                }
        }    
}  