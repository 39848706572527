import {
  Container,
  makeStyles
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import RFPDashboardService from 'src/services/rfp/rfp-dashboard.service';
import BasicRfpConfiguration from './BasicRfpConfiguration';
import { RfpDetailModel } from './RfpDetailModel';
import { RfpSettingModel } from './RfpSettingModel';
import { RfpTimeLineModel } from './RfpTimeLineModel';
import { VendorFilterDropdownModel, VendorFilterDropdownModelCommercial, VendorResponseCommercialModel, VendorResponseModel } from './VendorResponseModel';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = (props) => {
  const [rfpTimeLineState, setRfpTimeLineState] = React.useState(new RfpTimeLineModel());
  const [rfpSettingState, setRfpSetting] = React.useState(new RfpSettingModel());
  const [rfpVendorInviteState, setRfpVendorInviteState] = React.useState(new RfpDetailModel());
  const [vendorResponseState, setVendorResponseState] = React.useState(new VendorResponseModel());
  const [scoreUpdate, setScoreUpdate] = useState(false);
  const [vendorResponseCommercialState, setVendorResponseCommercialState] = React.useState(new VendorResponseCommercialModel());

  const [vendorOrginalResponseState, setVendorOrginalResponseState] = React.useState(new VendorResponseModel());

  const [vendorOrginalResponseCommercialState, setVendorOrginalResponseCommercialState] = React.useState(new VendorResponseCommercialModel());

  const [vendorFilterState, setVendorFilterState] = React.useState(new VendorFilterDropdownModel());
  const [vendorFilterStateCommercial, setVendorFilterStateCommercial] = React.useState(new VendorFilterDropdownModelCommercial());

  const [selectedFilterState, setSelectedFilterState] = React.useState({
    vendors: [],
    responseType: '',
    category: '',
    section: ''
  });

  const [selectedFilterStateCommercial, setSelectedFilterStateCommercial] = React.useState({
    vendors: [],
    responseType: '',
    category: '',
    section: ''
  });

  const classes = useStyles();
  const rfpId = props.match.params.rfpId;
  const users = [];
  const categories = [];
  const categories_commercial = [];
  const sections = [];
  const sections_commercial = [];
  const rfpDashboardService = new RFPDashboardService();
  useEffect(() => {
    getRfpDashboard();
    getRfpTimeline();
    //getRfpDashboardDetail();
    setScoreUpdate(false)
    getVendorResponses();
    getrfpByById();
    console.log("calling...")
  }, [scoreUpdate]);

  const getrfpByById = () => {
    rfpDashboardService.getrfpByIdName(rfpId).then(res => {
      // console.log(res, "res");
      if (res !== undefined)
        setRfpSetting(new RfpSettingModel(res.rfp));
    })
  }

  const getRfpDashboard = () => {
    rfpDashboardService.getrfpById(rfpId).then(res => {
      if (res !== undefined)
        setRfpVendorInviteState(new RfpDetailModel(res.rfpDetail, res.invitedRFPSteper));
    })
  }

  const getRfpTimeline = () => {
    rfpDashboardService.getrfpTimelines(rfpId).then(res => {
      if (res !== undefined)
        setRfpTimeLineState(new RfpTimeLineModel(res.timeline));
    })
  }


  const getVendorResponses = () => {
    rfpDashboardService.getVendorResponse(rfpId).then(res => {

      //code for technical
      if (res !== undefined) {
        getFilterList(res.technicalDetails);
        setVendorResponseState(new VendorResponseModel(res.technicalDetails));
        setVendorOrginalResponseState(new VendorResponseModel(res.technicalDetails));
        if (res.technicalDetails.length > 0) {
          filterByUser(res.technicalDetails[0]._id, res.technicalDetails)
        }
        //code for technical ends 

        //code for commercial
        getFilterListCommercial(res.commercialDetails);
        setVendorResponseCommercialState(new VendorResponseCommercialModel(res.commercialDetails));
        setVendorOrginalResponseCommercialState(new VendorResponseCommercialModel(res.commercialDetails));
        //code ends
      }
    })
  }



  const getFilterList = (techDetail) => {
    techDetail.forEach(element => {
      let user = {}
      user.id = element._id;
      user.name = element.companyName;
      users.push(user);
      element.categories.forEach((element, index) => {
        const catExists = categories.some(cat => cat.name == element.categoryName);
        if (categories.length == 0 || !catExists) {
          let company = {}
          company.id = index + element.categoryName;
          company.name = element.categoryName;
          categories.push(company);
        }
        element.sections.forEach((element, index) => {
          const secExists = sections.some(sec => sec.name == element.sectionName);
          if (!secExists || sections.length == 0) {
            let section = {}
            section.id = index + element.categoryName;
            section.name = element.sectionName;
            // sections.push(section)
          }

        });
      });
    });
    let vendorFilterData = {};
    vendorFilterData.vendor = users;
    vendorFilterData.categories = categories;
    vendorFilterData.sections = sections;
    setVendorFilterState(new VendorFilterDropdownModel(vendorFilterData));

    var tempSelectedFilterState = { ...selectedFilterState };
    /*if (users) {
      var tempVendorArray = [];
      users.map((vendor) => {
        tempSelectedFilterState.vendors.push(vendor.name)
      })
      setSelectedFilterState(tempSelectedFilterState);
    }*/
  }

  const getFilterListCommercial = (techDetail) => {
    techDetail.forEach(element => {
      // let user = {}
      // user.id = element._id;
      // user.name = element.companyName;
      // users.push(user);
      element.categories.forEach((element, index) => {
        const catExists = categories_commercial.some(cat => cat.name == element.categoryName);
        if (categories.length == 0 || !catExists) {
          let company = {}
          company.id = index + element.categoryName;
          company.name = element.categoryName;
          categories_commercial.push(company);
        }
        element.sections.forEach((element, index) => {
          const secExists = sections_commercial.some(sec => sec.name == element.sectionName);
          if (!secExists || sections_commercial.length == 0) {
            let section = {}
            section.id = index + element.categoryName;
            section.name = element.sectionName;
          }

        });
      });
    });

    let vendorFilterData = {};
    vendorFilterData.vendor = users;
    vendorFilterData.categories = categories_commercial;
    vendorFilterData.sections = sections_commercial;
    setVendorFilterStateCommercial(new VendorFilterDropdownModelCommercial(vendorFilterData));

  }


  const filterByUser = (id, list) => {
    let filterResponse = list.filter(function (e) {
      return e._id == id;
    });

  }
  const getSectionByCatCommercial = (name, techDetail) => {
    techDetail = JSON.parse(JSON.stringify(techDetail))
    techDetail.forEach(element => {
      element.categories.forEach((element, index) => {
        if (element.categoryName == name) {
          element.sections.forEach((element, index) => {
            const secExists = sections.some(sec => sec.name == element.sectionName);
            if (!secExists || sections.length == 0) {
              let section = {}
              section.id = index + element.categoryName;
              section.name = element.sectionName;
              sections.push(section)
            }

          });
        }

      });

    });

    // setVendorResponseState(new VendorResponseModel(techDetail))
    let vendorFilterData = {};
    vendorFilterData.sections = sections;
    vendorFilterData.categories = vendorFilterStateCommercial.categories;
    vendorFilterData.vendor = vendorFilterStateCommercial.vendor;
    setVendorFilterStateCommercial(new VendorFilterDropdownModelCommercial(vendorFilterData));
  }
  // need to implement
  const getSectionByCat = (name, techDetail) => {
    techDetail = JSON.parse(JSON.stringify(techDetail))
    techDetail.forEach(element => {
      element.categories.forEach((element, index) => {
        if (element.categoryName == name) {
          element.sections.forEach((element, index) => {
            const secExists = sections.some(sec => sec.name == element.sectionName);
            if (!secExists || sections.length == 0) {
              let section = {}
              section.id = index + element.categoryName;
              section.name = element.sectionName;
              sections.push(section)
            }

          });
        }

      });

    });

    // setVendorResponseState(new VendorResponseModel(techDetail))
    let vendorFilterData = {};
    vendorFilterData.sections = sections;
    vendorFilterData.categories = vendorFilterState.categories;
    vendorFilterData.vendor = vendorFilterState.vendor;
    setVendorFilterState(new VendorFilterDropdownModel(vendorFilterData));

  }


  const applyFilter = (filterValue, techDetail, is_commercial = false) => {

    techDetail = JSON.parse(JSON.stringify(techDetail))
    let filteredArr = [];
    if (filterValue.vendors.length > 0) {

      filteredArr = techDetail.filter((el) => {
        return filterValue.vendors.some((f) => {
          return f === el.companyName;
        });
      });
    }
    else {
      filteredArr = techDetail;
    }
    if (filterValue.category !== "" && filterValue.category !== "Select") {
      if (filteredArr.length == 0) {
        filteredArr = techDetail;
      }
      filteredArr.forEach(element => {
        let filterCategories = element.categories.filter((cat) => {
          return cat.categoryName == filterValue.category;
        })
        element.categories = filterCategories;
      });

    }
    if (filterValue.section !== "" && filterValue.section !== "Select") {
      filteredArr.forEach(element => {
        element.categories.forEach((element, index) => {

          let filteredSec = element.sections.filter((sec) => {
            return sec.sectionName == filterValue.section;
          })
          element.sections = filteredSec;

        });
      });
    }
    if (filterValue.responseType !== "" && filterValue.responseType !== 'Select') {
      if (filteredArr.length == 0) {
        filteredArr = techDetail;
      }
      filteredArr.forEach(element => {
        element.categories.forEach((element, index) => {
          element.sections.forEach((element, index) => {
            let filteredQues = element.questions.filter((question) => {
              return question.type == filterValue.responseType;
            })
            element.questions = filteredQues;
          })

        });
      });
    }
    if (is_commercial) {
      setVendorResponseCommercialState(new VendorResponseCommercialModel(filteredArr))
    } else {
      setVendorResponseState(new VendorResponseModel(filteredArr))
    }


  }

  const updateSelectedFilterCommercialState = (state) => {
    setSelectedFilterStateCommercial(state);

    if (state.category !== '' && state.section == "") {
      getSectionByCatCommercial(state.category, vendorOrginalResponseCommercialState.responses)
    }
    applyFilter(state, vendorOrginalResponseCommercialState.responses, true);
  }

  const updateSelectedFilterState = (state) => {
    setSelectedFilterState(state);

    if (state.category !== '' && state.section == "") {
      getSectionByCat(state.category, vendorOrginalResponseState.responses)
    }
    applyFilter(state, vendorOrginalResponseState.responses);
  }

  const saveCategoryAns = (catObj) => {
    //console.log(catObj); alert('hi')
    rfpDashboardService.updateRfpCategory(catObj).then(res => {
      getVendorResponses();
      setScoreUpdate(true)
    })
  }

  console.log(vendorFilterState, "vendorFilterState")

  return (

    <Page className={classes.root} title="RFP">
      <div id="rightContain">
        <Container maxWidth="lg" className="main-wrapper">
          <BasicRfpConfiguration
            rfpId={rfpId}
            rfpTimeLineState={rfpTimeLineState}
            rfpSettingState={rfpSettingState}
            rfpVendorInviteState={rfpVendorInviteState}
            vendorResponseState={vendorResponseState}
            vendorOrginalResponseState={vendorOrginalResponseState} vendorFilterState={vendorFilterState}
            vendorFilterStateCommercial={vendorFilterStateCommercial}
            setVendorFilterState={setVendorFilterState}
            setVendorResponseState={setVendorResponseState}
            selectedFilterState={selectedFilterState}
            selectedFilterStateCommercial={selectedFilterStateCommercial}
            updateSelectedFilterState={updateSelectedFilterState} updateSelectedFilterCommercialState={updateSelectedFilterCommercialState}
            saveCategoryAns={saveCategoryAns}
            vendorResponseCommercialState={vendorResponseCommercialState}
            setVendorResponseCommercialState={setVendorResponseCommercialState}
          />
        </Container>
      </div>
    </Page>
  );
};

export default SettingsView;
