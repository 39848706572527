import { Hidden, makeStyles, Typography } from "@material-ui/core";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "src/components/loader/loader.component";
import { Alert } from "src/components/toaster/alert.component";
import { alertService } from "src/components/toaster/alert.service";
import { history } from "src/utils/utils";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {}
  },
  wrapperFull: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0
    }
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden"
  },
  content: {
    flex: "1 1 auto",
    height: "100%"
    // overflow: 'auto'
  },
  tgClassHide: {
    position: "fixed",
    zIndex: "1201",
    top: "110px",
    left: "220px",
    backgroundColor: "#4688f0",
    color: "#fff",
    padding: "2px 5px",
    cursor: "pointer"
  },
  tgClassShow: {
    position: "fixed",
    zIndex: "1201",
    top: "125px",
    left: "53px",
    backgroundColor: "#4688f0",
    color: "#fff",
    padding: "2px 5px",
    cursor: "pointer"
  }
}));

const DashboardLayout = props => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  const [isSideBarOpen, setSideBarOpen] = useState(true);
  const [temp_rfp_name, setTempRfpName] = React.useState("");

  const handleHideSideBarToggle = () => {
    isSideBarOpen ? setSideBarOpen(false) : setSideBarOpen(true);
  };

  useEffect(() => {
    const data = window.localStorage.getItem("submitResponseStatus");
    const autoClose = true;
    const keepAfterRouteChange = false;
    if (data === "true") {
      alertService.success("Thanks your RFP is submitted successfully", {
        autoClose,
        keepAfterRouteChange
      });
    }
    setTimeout(
      () => window.localStorage.setItem("submitResponseStatus", "false"),
      3000
    );
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("update-rfp") ||
      location.pathname.includes("edit-rfp") ||
      location.pathname.includes("submit-rfp")
    ) {
      const parsed = queryString.parse(window.location.search);
      if (parsed.product_name !== undefined) {
        setTempRfpName(parsed.product_name);
      } else {
        setTempRfpName("");
      }
    } else {
      setTempRfpName("");
    }
  }, [window.location.pathname]);

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        {isSideBarOpen ? (
          <Typography className={classes.tgClassHide}>
            {" "}
            <CompareArrowsIcon
              style={{ color: "#fff" }}
              onClick={handleHideSideBarToggle}
            />{" "}
          </Typography>
        ) : (
          <Typography className={classes.tgClassShow}>
            {" "}
            <CompareArrowsIcon
              style={{ color: "#fff" }}
              onClick={handleHideSideBarToggle}
            />{" "}
          </Typography>
        )}
      </Hidden>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isSideBarOpen={isSideBarOpen}
      />
      <Alert />
      <div className={isSideBarOpen ? classes.wrapper : classes.wrapperFull}>
        <div className={classes.contentContainer}>
          <div
            className={classes.content + " dashboard-breadcrumb"}
            style={{ position: "relative" }}
          >
            {temp_rfp_name !== "" ? (
              <div
                class="welcome-text"
                style={{ position: "absolute", left: "18%", top: "0.5%" }}
              >
                <h3 style={{ fontSize: "18px", color: "grey" }}>
                  <b
                    style={{ cursor: "pointer" }}
                    onClick={e => history.push("/app/dashboard")}
                  >
                    Dashboard / {temp_rfp_name}
                  </b>
                </h3>
              </div>
            ) : (
              ""
            )}

            <Loader />
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
