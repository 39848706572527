import { Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
const useStyles = makeStyles(() => ({
  root: {},
  BackButton: {
    marginLeft: "10px",
    marginRight: "10px"
  },
}));
var buttonStyle = {
  backgroundColor: '#000',
}
const FooterActionComponent = ({ step, totalStepCount, completed, currentStepCompleteStatus, handleSkip, handleSubmit, handleBack, values, type }) => {
  const classes = useStyles();
  return (
    <div class="footer-btn-fixed">
      <footer class="page-footer green">
        <Grid container spacing={2} justify="center">
          {step !== 0 ? (
            <Grid item md={3} sm={3} xs={3}>
              <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                Back
                  </Button>
            </Grid>
          ) : null}
          {step !== totalStepCount ? (
            <Grid item md={3} sm={3} xs={3}>
              <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                Skip
              </Button>
            </Grid>
          ) : null}
          {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
            (
              <Grid item md={3} sm={3} xs={3}>
                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save</Button>
              </Grid>
            ) : null}
          {(completed > totalStepCount || (completed === totalStepCount && !currentStepCompleteStatus)) ?
            (
              <Grid item md={3} sm={3} xs={3}>
                <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'publish'; handleSubmit(values); }}> Publish</Button>
              </Grid>
            ) : step === totalStepCount ?
              (
                <Grid item md={3} sm={3} xs={3}>
                  <Button color="primary" type="submit" variant="contained" onClick={(values) => { type = 'save'; handleSubmit(values); }}>Save </Button>
                </Grid>
              ) : (
                <Grid item md={3} sm={3} xs={3}>
                  <Button className={classes.NextButton} color="primary" type="submit" variant="contained" onClick={(values) => { type = 'next'; handleSubmit(values); }}>Next </Button>
                </Grid>
              )}
        </Grid>
      </footer>
    </div>
  );
};

export default FooterActionComponent;
