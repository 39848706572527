import { Button, Fade, Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  ModalBox: {
    // marginLeft: "36px",
    marginTop: "36px",
  },
  name: {
    marginTop: "20px",
    fontSize: '15px',
    color: '#0C1234 !important',
    fontWeight: "500",
    font: "Roboto",
    lineHeight: "22px"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    borderRadius: '12px',
    width: "370px",
    maxWidth: '370px',
    // maxHeight: '370px',
    boxShadow: theme.shadows[5],
  },
  container: {
    // width: "370px",
    position: "relative",
    // marginLeft: "-13px"
  },
  buttonBox: {
    marginLeft: "97px",
    marginBottom: "28px",
    marginRight: "23px",
    marginTop: "12px",
    display: "flex",

  },
  saveContainer: {
    marginTop: "30px",
    marginLeft: "14%",
    marginBottom: "30px"
  },
  title: {
    fontSize: '15px',
    color: '#0C1234 !important',
    fontWeight: '500',
    font: 'Roboto',
    lineHeight: '20px',
    textAlign: 'center',
  },
  saveButton: {
    margin: "0px 0px 0px 20px"
  },
  redText: {
    color: 'red',
    textTransform: "uppercase"
  }
}));

const ConfirmModal = ({ modalOpen, onConfirmClick, closePopUp, companyName }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        open={modalOpen}
        onClose={() => closePopUp()}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <>

            <div className={classes.paper}>
              <Grid className={classes.ModalBox}>
                <Grid item className={classes.container} >
                  <Typography className={classes.title} style={{ paddingLeft: '10px', paddingRight: '10px' }}>Are you sure you want decline the request from <span className={classes.redText}>{companyName}</span>? This Could have future implecations on your relationship.</Typography>
                  <Grid
                    item
                    container
                    className={classes.saveContainer}
                  >
                    <Grid item xs={12}>
                      <span
                        style={{
                          fontSize: '12px',
                          color: '#263344',
                          cursor: 'pointer',
                          marginTop: '8px',
                          marginLeft: "121px"

                        }}
                        onClick={() => closePopUp()}
                      >
                        Cancel
                      </span>
                      <Button
                        className={classes.saveButton}
                        variant="contained"
                        color="primary"
                        onClick={() => onConfirmClick()}
                      >
                        <span style={{ fontSize: '12px' }}>
                          Confirm
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        </Fade>
      </Modal>

    </>
  )
}

export default ConfirmModal
