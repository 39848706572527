
import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  acceptInviateHeader: {
    background: '#f7f7f7 !important',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  blueBtn: { backgroundColor: '#4688f0', padding: '6px 16px', fontSize: '14px', },
  redBtn: { backgroundColor: '#dc4718', padding: '6px 16px', fontSize: '14px', },
  resetContainer: {
    padding: theme.spacing(3),
  },
  leftStepPanel: { backgroundColor: '#fff', },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  custWidthRFP: { maxWidth: '100%', margin: '0', },
  iconSlides: {},
  dltBtns: {},
  blueBtn: { backgroundColor: '#40506b', padding: '6px 16px', fontSize: '14px', },
  buttonStepper: { textAlign: 'left !important' },
  declineBtn: {
    backgroundColor: "rgb(255, 77, 77) !important",
    color: "white",
    fontSize: "13px !important",
  },
  buyNowBtn: {
    backgroundColor: "#52B152 !important",
    color: "white",
    fontSize: "13px !important",
    width: "143px",
    // border: "1px solid rgb(82, 177, 82)",

    "&:hover": {
      // backgroundColor: "#40506b !important",
      // color: "white !important"
      // borderColor: "1px solid none !important"
    },
  },
  acceptBtn: {
    backgroundColor: '#40506b',
    fontSize: "13px !important",
    color: "white",
    width: "143px",
  },
  disabledButton: {
    backgroundColor: "#BBB !important",
  },
}));
