import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(({
  root: {},
  item2: {
    '@global': {
      '.MuiFormControlLabel-label.Mui-disabled': {
        position: "absolute",
        marginTop: "9px"
      },
    }
  },
  cardScrollArea: {
    //maxHeight: "72vH",
    //overflowX: "scroll",
    //overflowX: "hidden",

  },
  footerBackground: {
    background: "#f7f7f7"
  },
  CardBorder: {
    borderRadius: "15px"

  },
  paper: { textAlign: 'center' },
  companyHeadings: { backgroundColor: '#40506b', color: '#fff', },
  titleTag: { margin: 'auto', },
  inputAll: {},
  listChecks: {},
  footerBox: {},
  width100: { width: '100%', },
  pdLL: { paddingLeft: '40px', },
  finalBoxTable: {},
}));