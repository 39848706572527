
export class LegalContractsModel {  
  legalDocuments=[];
  isData = false;
  constructor(legalInfo = null){  
          if (legalInfo && legalInfo.length>0) {
                 this.isData = true;
                 legalInfo.forEach(element => {
                  this.legalDocuments.push(new legalContract(element))
           });
                 
          }
  }    
}  

export class legalContract{
  email='';
  userName= "";
  legalDocuments=''
  legalDocuments_docs= '';
  constructor(legalInfo = null){  
          if (legalInfo) {
                 this.isData = true;
                 this.legalDocument =legalInfo.legalDocuments || this.legalDocuments;
                 this.legalDocuments_docs =legalInfo.legalDocuments_docs || this.legalDocuments_docs;
                 this.email =legalInfo.email || this.email;
                  this.userName = legalInfo.userName || this.userName;
          }
  }    
}