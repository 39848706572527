import UserActionTypes from "./types";


const initialState = {
  rfpData: {},
  rfpTechnicalData: {},
  loading: false,
  error: false,
  rfpTechnicalDataUpdate: {
    overview: '',
    categoryName: '',
    categories: [],
  },
  rfpTechnicalDataSuccess: {},
  rfpCommericalData: {},
  rfpCommericalDataUpdate: {
    overview: '',
    categoryName: '',
    categories: [],
  },
  rfpCommericalDataSuccess: {},
  rfpStepUpdate: ''
};
const updateRfpReducer = (state = initialState, action) => {
  // console.log(action, "action");
  switch (action.type) {
    case UserActionTypes.GET_RFP_BY_USER_ID_START:
      return {
        ...state,
        rfpData: '',
        loading: true,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_ID_SUCCESS:
      return {
        ...state,
        rfpData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_USER_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_TECHNICAL_START:
      return {
        ...state,
        rfpTechnicalData: '',
        loading: true,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_TECHNICAL_SUCCESS:
      return {
        ...state,
        rfpTechnicalData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_TECHNICAL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_TECHNICAL_UPDATE:
      return {
        ...state,
        rfpTechnicalDataUpdate: action.payload,
        error: false,
      };
    case UserActionTypes.SAVE_TECHNICAL_DETAILS_START:
      return {
        ...state,
        rfpTechnicalDataSuccess: '',
        error: false,
      };
    case UserActionTypes.SAVE_TECHNICAL_DETAILS_SUCCESS:
      return {
        ...state,
        rfpTechnicalDataSuccess: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.SAVE_TECHNICAL_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case UserActionTypes.RFP_UPDATE_STEPPER:
      return {
        ...state,
        rfpStepUpdate: action.payload,
      };

    //Commerical Requirement
    case UserActionTypes.GET_RFP_BY_ID_COMMERICAL_START:
      return {
        ...state,
        rfpCommericalData: '',
        loading: true,
        error: false,
      };

    case UserActionTypes.GET_RFP_BY_ID_COMMERICAL_SUCCESS:
      return {
        ...state,
        rfpCommericalData: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.GET_RFP_BY_ID_COMMERICAL_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserActionTypes.GET_RFP_BY_ID_COMMERICAL_UPDATE:
      return {
        ...state,
        loading: false,
        rfpCommericalDataUpdate: action.payload,
        error: true,
      };
    case UserActionTypes.SAVE_COMMERCIAL_DETAILS_START:
      return {
        ...state,
        rfpCommercialDataSuccess: '',
        error: false,
      };
    case UserActionTypes.SAVE_COMMERCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        rfpCommercialDataSuccess: action.payload,
        loading: false,
        error: false,
      };
    case UserActionTypes.SAVE_COMMERCIAL_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default updateRfpReducer;