import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Form, Formik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './NdaAnswerStyle';
import { NdaAnswerValidations } from './NdaAnswerValidations';

const NdaAnswer = ({ handleNext, handleSkip, handleBack, className, completed, ndaState, step, currentStepCompleteStatus, acceptStatus, totalStepCount, deleteNDAAns, ...rest }) => {
  const classes = useStyles();
  let file = ''
  const handleFilesChange = (files) => {
    file = files;
  }

  var type = '';
  return (
    <Formik
      initialValues={ndaState}
      enableReinitialize={true}
      validationSchema={NdaAnswerValidations}
      onSubmit={(values) => {
        values.step = step;
        values.type = type;
        values.file = file;
        handleNext(values);
      }}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form className={'requestly-rfp-form company-information non-disclousre mb-5'}>
          <Card className={classes.CardBorder + ' company-info-div'} style={{ width: '720px' }}>
            <CardHeader subheader="Uploaded NDA's" title="Non-Disclosure Agreements"
              className={classes.companyHeadings + ' requestly-rfp-heading width-auto'} />
            <Divider />
            <CardContent>
              <Grid className={classes.item + ' div-heading'} item md={12} sm={12} xs={12}>
                <Typography>Uploaded Files</Typography>
              </Grid>
              <Box mt={3}>
                <Grid className={classes.listChecks} container spacing={0}>
                  <Grid md={12} sm={12} xs={12} className="Presentation-Agenda-table">
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left" className="bg-gray"> Vendor Name </TableCell>
                            <TableCell align="left" className="bg-gray"> Date </TableCell>
                            <TableCell align="left" className="bg-gray">  File Name </TableCell>
                            <TableCell align="left" className="bg-gray"> Download </TableCell>
                          </TableRow>
                        </TableHead>

                        {values.ndas && values.ndas.length > 0 ? (
                          <TableBody>
                            {values.ndas && values.ndas.map((item) => {
                              return item.companyNDAAnsDocument_docs?.map(datum => (
                                <TableRow>
                                  <TableCell align="left"> {datum.companyName} </TableCell>
                                  <TableCell align="left" >
                                    {moment(datum.createdAt).format('DD MMMM, YYYY')}
                                  </TableCell>
                                  <TableCell align="left"> {datum.name} </TableCell>
                                  <TableCell align="left" href={datum.location}
                                    target="_blank"><a href={datum.location}
                                    ><GetAppIcon ></GetAppIcon></a>  </TableCell>
                                  {/* <TableCell align="center"> <DeleteIcon onClick={() =>deleteNDAAns(item.companyNDAAnsDocument[0])}></DeleteIcon> </TableCell> */}

                                </TableRow>
                              )) ?? null;
                            }
                            )
                            }
                          </TableBody>) : (<TableBody><TableRow> Nothing uploaded yet...</TableRow></TableBody>)}
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <div class="footer-btn-fixed">
              <footer class="page-footer green">
                <Grid container spacing={2} justify="center">
                  {step !== 0 ? (
                    <Grid item md={3} sm={3} xs={3}>
                      <Button className={classes.BackButton} color="primary" type="button" variant="contained" onClick={handleBack}>
                        Back
                      </Button>
                    </Grid>
                  ) : null}
                  {step !== totalStepCount ? (
                    <Grid item md={3} sm={3} xs={3}>
                      <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                        Skip
                      </Button>
                    </Grid>
                  ) : null}
                  {step !== totalStepCount ? (
                    <Grid item md={3} sm={3} xs={3}>
                      <Button className={classes.ButtonSkip} color="primary" type="button" variant="contained" onClick={() => handleSkip('vendorInvite')}>
                        Next
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </footer>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

NdaAnswer.propTypes = {
  className: PropTypes.string
};

export default NdaAnswer;
